import { Component, OnInit, Inject } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { Http, Response } from '@angular/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IfStmt } from '@angular/compiler';
import { MatTableDataSource } from '@angular/material/table';
import { MatButton, MatDatepickerInputEvent } from '@angular/material';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common'
import { strictEqual } from 'assert';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-pointer',
  templateUrl: './pointer.component.html',
  // styleUrls: ['./pointer.component.scss'],
  providers: [DorseyService]
})
export class PointerComponent implements OnInit {
  ErrorMessage: string;
  projectData: any;
  condition = false;
  status = false;
  employeeData: any;
  processData: any;
  activityData: any;
  pointerTypeData: any;
  frustrationtypeData: any;
  departmentData: any;
  teamData: any;
  severityData: any;
  statusData: any;

  EmployeeID: string;
  ProcessID: string;
  ActivityID: string;
  PointerTypeID = "1";
  FrustrationTypeID: string;
  DepartmentID: string;
  TeamID: string;
  StatusID: string;
  SeverityID: string;
  Subject: string;
  Description: string;
  PointerID: string;
  pointerData: any;
  tempActivity = [];
  tempFrustrationType = [];
  pointerFrustration: any;
  FrustrationTypeIDs = [];
  newPointer = false;
  ProjectID: string;
  searchtext: string

  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;
  frustPaging = false;
  saveFilter = [];
  CompanyID: string;
  isDisable = false;
  disableSearch = false;
  currentUser: string;
  MenuPermissions: any;
  menuData: any;
  menuID: string;
  menuName: string;
  ParentID: any;
  subActivityStatus = false;
  SubActivityID: string;
  tempSubActivity = [];
  userTeamID: string;

  suggestionAssignTypes = false;
  loginCompanyName: any;
  empID: string;
  updatedDate = new Date();
  updatedBy: any;
  constructor(private http: Http, private dorseyService: DorseyService,
    public dialog: MatDialog, private storage: LocalStorageService) {
    this.CompanyID = this.storage.retrieve('companyID');
    this.userTeamID = this.storage.retrieve('TeamID');
    this.currentUser = this.storage.retrieve('user');
    this.menuData = this.storage.retrieve('menuData');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
    this.loginCompanyName = this.storage.retrieve('loginCompanyName');

    this.empID = this.storage.retrieve('EmployeeID');
  }

  ngOnInit() {

    let tempdata = this.menuData;
    if (this.empID.toString() !== '0') {
      this.EmployeeID = this.empID;
    }
    else {
      this.EmployeeID = "0";
    }
    this.menuData = tempdata.filter(x => x.MenuName.toString().toLowerCase() === 'frustration');
    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }

    this.GetProcess();
    this.GetActivity();
    this.GetEmployee();
    this.GetDepartment();
    this.GetPointerType();
    this.GetSeverity();
    this.GetStatus();
    this.GetTeam();
    this.GetFrustrationType();
    this.GetPointer();
    this.GetProjects();

  }
  OpenPointer() {
    this.newPointer = true;
    this.dialogCall('new');
  }


  Searchtext() {
    this.pointerFrustration = this.saveFilter;

    let tempFrust = this.pointerFrustration.filter(
      (item) => {
        return (
          (item.ProcessName !== null && item.ProcessName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ActivityName !== null && item.ActivityName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.TeamName !== null && item.TeamName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.Subject !== null && item.Subject.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.Description !== null && item.Description.toLowerCase().indexOf(this.searchtext.toLowerCase()).trim() > -1) ||
          (item.ProjectName !== null && item.ProjectName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
        );
      }

    );
    this.pointerFrustration = tempFrust;
    if (this.pointerFrustration.length == 0) {
      // this.GetActivity();
      this.isDisable = true;
    }
    if (this.searchtext === '') {
      this.GetPointer();
      this.isDisable = false;
    }
  }

  GetPointer() {
    this.dorseyService.GetPointer(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.pointerData = subData;
      this.pointerFrustration = this.pointerData.filter(x => x.PointerTypeID != null && x.PointerTypeID.toString() === "1")
      let tempFrustData = this.pointerFrustration;
      this.saveFilter = JSON.parse(JSON.stringify(this.pointerFrustration));
      this.rowCount = this.pointerFrustration.length;

      if (this.pointerFrustration.length === 0) {
        this.frustPaging = false;
        this.isDisable = true;
        this.disableSearch = true;
      }
      else {
        this.frustPaging = true;
        this.isDisable = false;
        this.disableSearch = false;
        if (this.userTeamID != '0' && this.userTeamID !== null && this.CompanyID !== '0') {
          this.pointerFrustration = tempFrustData.filter(x => x.TeamID.toString() === this.userTeamID.toString() && x.CompanyID === this.CompanyID);
        }
        else {
          this.pointerFrustration = tempFrustData.filter(x => x.CompanyID === this.CompanyID);
        }


        while (this.dynamicRows < this.rowCount) {
          this.rowsOnPageSet.push(this.dynamicRows);
          this.dynamicRows = this.dynamicRows + 10;
        }
        if (!this.rowsOnPageSet.includes(this.rowCount)) this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet.length === 1) {
          if (this.rowsOnPageSet[0] <= 10)
            this.rowsOnPage = this.rowsOnPageSet[0];
        }
      }
    });
  }

  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  GetProjects() {
    this.dorseyService.GetProjects(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.projectData = subData;

    });
  }
  GetEmployee() {
    this.dorseyService.GetEmployee(this.CompanyID).subscribe(subData => {

      subData = JSON.parse(subData['_body']);
      this.employeeData = subData;
      let temmEmployee = this.employeeData;

      if (this.TeamID != '0') {
        this.employeeData = temmEmployee.filter(x => x.TeamID === this.TeamID || x.CompanyID == this.CompanyID);
      } else {
        this.employeeData = temmEmployee.filter(x => x.CompanyID === this.CompanyID);
      }
    });
  }
  AddToProject() {
    this.newPointer = false;
    this.dialogCall('assign');
  }

  exportAsXLSX() {
    let allData = JSON.parse(JSON.stringify(this.pointerFrustration));
    for (let i = 0; i < allData.length; i++) {

      allData[i].Process = allData[i].ProcessName;
      allData[i].Activity = allData[i].ActivityName;
      allData[i].Team = allData[i].TeamName;
      allData[i]["Issue ID"] = allData[i].PointerID;
      allData[i].Type = allData[i].FrustrationsTypeName;
      allData[i].Project = allData[i].ProjectName;
      allData[i]["Status"] = allData[i].StatusName;
      allData[i]["Severity"] = allData[i].SeverityName;

      allData[i].Subject = allData[i].Subject;
      allData[i].Description = allData[i].Description;
      delete (allData[i].PointerID);
      delete (allData[i].ProcessName);
      delete (allData[i].ActivityName);
      delete (allData[i].TeamName);
      delete (allData[i].PointerID);
      delete (allData[i].FrustrationsTypeName);
      delete (allData[i].ProjectName);
      delete (allData[i].StatusName);
      delete (allData[i].SeverityName);
      delete (allData[i].SeverityID);
      delete (allData[i].EmployeeID);
      delete (allData[i].ActivityID);
      delete (allData[i].PointerTypeID);
      delete (allData[i].StatusID);
      delete (allData[i].DepartmentID);
      delete (allData[i].ProcessID)
      delete (allData[i].UpdatedDate);
      delete (allData[i].UpdatedBy);
      delete (allData[i].ProjectID)
      delete (allData[i].TeamID);
      delete (allData[i].FrustrationsTypeID);
      delete (allData[i].Date)

      delete (allData[i].IsActive);
      delete (allData[i].CreatedDate);
      delete (allData[i].CreatedBy)
    }

    // const json = {
    //   data:allData
    //       }
    //       this.dorseyService.Export(json,this.loginCompanyName+'_frustration',this.loginCompanyName,'Frustration').subscribe(data=>{

    //       });

    this.dorseyService.generateExcel(allData, this.loginCompanyName + '_frustration' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Frustration', new Date(), this.loginCompanyName + " Frustration Report", copyRight, 'FFFFFF00', 'FF0000FF');


    //  this.dorseyService.exportAsExcelFile(allData,this.loginCompanyName+'_frustration');
  }


  GetFrustrationType() {
    this.dorseyService.GetFrustrationType("1", this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);

      let tempType: any;
      tempType = subData;
      if (tempType.length === 0) {
        this.suggestionAssignTypes = false;
        this.dorseyService.GetFrustrationType('1', "0").subscribe(subData => {
          subData = JSON.parse(subData['_body']);
          this.frustrationtypeData = subData;
        });
      }
      else {
        this.frustrationtypeData = tempType;
        this.suggestionAssignTypes = true;
      }

    });


  }
  GetDepartment() {
    this.dorseyService.GetDepartment().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.departmentData = subData;
    });
  }

  GetPointerType() {
    this.dorseyService.GetPointerType().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.pointerTypeData = subData;
    });
  }
  GetStatus() {
    this.dorseyService.GetStatus().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.statusData = subData;
      this.StatusID = this.statusData[0].StatusID;
    });
  }
  GetSeverity() {
    this.dorseyService.GetSeverity().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.severityData = subData;
    });
  }

  GetTeam() {
    this.dorseyService.GetTeam(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.teamData = subData;
    });
  }
  GetActivity() {
    this.dorseyService.GetActivity(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.activityData = subData;
    });
  }
  GetProcess() {
    this.dorseyService.GetProcess(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.processData = subData;
      let processTemp = this.processData;
      this.processData = processTemp.filter(x => x.TeamID !== 0);
    });
  }


  dialogCall(msg: string) {
    const dialogRef = this.dialog.open(PointerDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: msg + 'æææ' + false,
    });
    if (msg === 'new') {
      this.searchtext = '';
      this.GetPointer();
    }
    dialogRef.componentInstance.EmployeeID = this.EmployeeID;

    dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.componentInstance.processData = this.processData;
    dialogRef.componentInstance.activityData = this.activityData;
    dialogRef.componentInstance.departmentData = this.departmentData;
    dialogRef.componentInstance.pointerTypeData = this.pointerTypeData;
    dialogRef.componentInstance.severityData = this.severityData;
    dialogRef.componentInstance.statusData = this.statusData;
    dialogRef.componentInstance.teamData = this.teamData;
    dialogRef.componentInstance.employeeData = this.employeeData;
    dialogRef.componentInstance.frustrationtypeData = this.frustrationtypeData;
    dialogRef.componentInstance.tempActivity = this.tempActivity;
    dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
    dialogRef.componentInstance.CompanyID = this.CompanyID;
    dialogRef.componentInstance.tempSubActivity = this.tempSubActivity;
    dialogRef.componentInstance.SubActivityID = this.SubActivityID;
    dialogRef.componentInstance.updatedBy = this.updatedBy;
    dialogRef.componentInstance.updatedDate = this.updatedDate
    if (msg === 'edit') {
      this.status = true;
      dialogRef.componentInstance.EmployeeID = this.EmployeeID;
      dialogRef.componentInstance.ProcessID = this.ProcessID;
      dialogRef.componentInstance.ActivityID = this.ActivityID;
      dialogRef.componentInstance.PointerTypeID = this.PointerTypeID;
      dialogRef.componentInstance.PointerID = this.PointerID;
      dialogRef.componentInstance.DepartmentID = this.DepartmentID;
      dialogRef.componentInstance.FrustrationTypeIDs = this.FrustrationTypeIDs;
      dialogRef.componentInstance.TeamID = this.TeamID;
      dialogRef.componentInstance.StatusID = this.StatusID;
      dialogRef.componentInstance.SeverityID = this.SeverityID;
      dialogRef.componentInstance.Subject = this.Subject;
      dialogRef.componentInstance.Description = this.Description;
      dialogRef.componentInstance.tempActivity = this.tempActivity;
      dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
      dialogRef.componentInstance.ProjectID = this.ProjectID;
      dialogRef.componentInstance.subActivityStatus = this.subActivityStatus;
      dialogRef.componentInstance.SubActivityID = this.SubActivityID;
      dialogRef.componentInstance.updatedBy = this.updatedBy
      dialogRef.componentInstance.updatedDate = this.updatedDate
    }

    dialogRef.afterClosed().subscribe(result => {


      if (this.searchtext !== '') {
        this.Searchtext();
      }
      else {
        this.GetFrustrationType();
        this.GetPointer();
      }

      if (result.toString() === 'true') {
        let statusMsg = '';
        if (msg === 'new' || msg != 'assign') {
          statusMsg = 'Saved successfully';
          this.dynamicRows = 10;
          this.rowsOnPageSet = [];
          this.GetPointer();
        }
        else {
          statusMsg = 'Updated successfully';

        }
        const dialogRefsub = this.dialog.open(PointerDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: statusMsg + 'æææ' + false,
          //  width: '30%'
        });
        dialogRefsub.afterClosed().subscribe(result1 => {
          this.GetPointer();
          this.GetFrustrationType();
          //  this.Searchtext();
        });
      }
    });
  }
  GetTempActivity(ProcessID: string) {
    this.tempActivity = [];
    for (let i = 0; i < this.activityData.length; i++) {
      if (this.activityData[i].ProcessID === ProcessID) {
        this.tempActivity.push(this.activityData[i]);
      }
    }
  }
  // GetFrustrations(PointerTypeID:string){
  //   for(let i=0;i<this.frustrationtypeData.length;i++){
  //     if(this.frustrationtypeData[i].PointerTypeID===PointerTypeID){
  //       this.tempFrustrationType.push(this.frustrationtypeData[i]);
  //     }
  //   }

  // }
  GetTempSubActivity(ActivityID) {
    this.tempSubActivity = [];
    for (let i = 0; i < this.activityData.length; i++) {
      if (this.activityData[i].ParentID === ActivityID) {
        this.tempSubActivity.push(this.activityData[i]);
      }
    }
  }
  EditPointer(element, text) {

    this.newPointer = true;
    this.GetTempActivity(element.ProcessID);


    for (let i = 0; i < this.processData.length; i++) {
      if (this.processData[i].ProcessID === element.ProcessID) {
        this.TeamID = this.processData[i]['TeamID'];

      }
    }

    //this.GetFrustrations(element.PointerTypeID);
    this.ProjectID = element.ProjectID
    this.PointerID = element.PointerID;
    this.ProcessID = element.ProcessID;
    this.ActivityID = element.ActivityID;
    this.PointerTypeID = element.PointerTypeID;
    this.EmployeeID = element.EmployeeID;
    this.SeverityID = element.SeverityID;
    this.StatusID = element.StatusID;
    this.Subject = element.Subject;
    this.Description = element.Description;
    this.DepartmentID = element.DepartmentID;
    this.TeamID = element.TeamID;
    this.updatedDate = element.UpdatedDate;
    this.updatedBy = element.UpdatedBy;
    this.FrustrationTypeIDs.push(element.FrustrationsTypeID);

    for (let i = 0; i < this.activityData.length; i++) {
      if (element.ActivityID === this.activityData[i].ActivityID) {
        this.ParentID = this.activityData[i].ParentID;
      }

    }
    if (this.ParentID.toString() === '0') {
      this.ActivityID = element.ActivityID;
      this.subActivityStatus = false;
    } else {
      this.subActivityStatus = true;
      this.SubActivityID = element.ActivityID;
      this.ActivityID = this.ParentID;
    }

    this.dialogCall('edit');
  }


  ifMaxRow() {

    if (this.rowsOnPage == this.pointerData.length) {
      this.rowsOnPage = this.rowsOnPage - 1;
    }
  }

  DeletePointer(element) {

    this.newPointer = true;
    this.PointerID = element.PointerID;


    const dialogRef = this.dialog.open(PointerDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'Are you sure you want to delete frustation: ' + element.PointerID + '?æææ' + true,
      // width: '40%'
    });
    //   this.condition = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        /// this.condition = true;
        this.dorseyService.DeletePointer(this.PointerID).subscribe(data => {

          if (data.ok) {
            this.ifMaxRow();
            this.condition = true;
            this.dialogCall('Deleted successfully');
          }
          this.dynamicRows = 10;
          this.rowsOnPageSet = [];
          this.GetPointer();
        });
      }
    });
  }



}



@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
  // styleUrls: ['dialog.css'],
  providers: [DorseyService, DatePipe],
})
export class PointerDialogComponent implements OnInit {
  suggestionAssignTypes = false;
  projectData: any;
  ProjectID: string;
  condition = false;
  status = false;
  employeeData: any;
  processData: any;
  activityData: any;
  pointerTypeData: any;
  frustrationtypeData: any;
  departmentData: any;
  teamData: any;
  severityData: any;
  statusData: any;
  dataSelf = '';
  EmployeeID: string;
  currentUser: string;
  ProcessID: string;
  ActivityID: string;
  PointerTypeID = "1";
  FrustrationTypeID: string;
  DepartmentID: string;
  TeamID: string;
  StatusID: string;
  SeverityID: string;
  Subject: string;
  Description: string;
  PointerID: string;
  tempActivity = [];
  tempFrustrationType = [];
  processCtrl = new FormControl();
  conditionActivity = '';
  filteredProcess: Observable<any[]>;
  tempProcess: any;
  tempDepart: any;
  CompanyID: string;
  processTeamData: any;
  subActivityStatus = false;
  tempSubActivity = [];
  SubActivityID: string;
  typeAvai = false;
  FTextBoxType: string;
  FTextBoxType2: any;
  FrustrationTypeIDs = [];
  typeEdit = false;
  showDd = false;
  TypeIDS: any;
  pointerData: any;
  frustTypeName: any;
  editClick = false;
  notEdit = true;
  enableEdit = true;
  disableFrustType = false;
  updatedBy: any;
  updatedDate = new Date();
  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<PointerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dorseyService: DorseyService, private datepipe: DatePipe) {
    this.data = this.data.split('æææ');
    if (this.data[1] === 'true') {
      this.condition = true;
    } else if (this.data[1] === 'false') {
      this.condition = false;
    }
    else {
      if (this.data[1] === '') {
        this.conditionActivity = 'process';
      } else {
        this.conditionActivity = 'activity';
      }
      //  if (this.data[1] === '') {
      //    this.conditionActivity = 'process';
      //  } else {
      //    this.conditionActivity = 'activity';
      //  }
      // }

      // else {
      //   if(this.data[1]=='issue')
      //   {
      //     this.conditionActivity = '';
      //   }
    }
    if (this.data[0] === 'new') {
      this.disableFrustType = false;
    } else {
      this.disableFrustType = true;
    }
    this.data = this.data[0];
  }

  ngOnInit() {
    let data = this.frustrationtypeData.filter(x => x.CompanyID.toString() === this.CompanyID.toString());
    if (data.length == 0) {
      this.suggestionAssignTypes = true;
    }
    else {
      this.typeAvai = false;
      this.suggestionAssignTypes = false;
    }
    this.GetStatus();
    if (this.SubActivityID) {
      this.GetTempSubActivity(this.ActivityID);
    }
  }
  GetStatus() {
    this.dorseyService.GetStatus().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.statusData = subData;
    });
  }
  onYesClick() {
    this.dialogRef.close(true);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ToggleSubActivity() {
    this.subActivityStatus = !this.subActivityStatus;
  }

  AddPointer() {
    let count = 0;
    const Editcount = 0;

    if (this.ProcessID === undefined || //this.ActivityID === undefined ||this.EmployeeID === undefined ||  this.Description===''|| this.Description === undefined ||
      this.PointerTypeID === undefined || this.FrustrationTypeID === undefined ||
      this.TeamID === undefined || this.SeverityID === undefined || this.Subject == undefined || this.StatusID === undefined ||

      this.ProcessID === '' || // this.ActivityID === '' || this.EmployeeID === '' ||
      this.PointerTypeID === '' ||
      //this.FrustrationTypeIDs.length === 0 ||  
      this.TeamID === '' || this.SeverityID === '' || this.Subject.trim() === '' || this.StatusID === '' || this.FrustrationTypeID.length === 0) {

      this.dataSelf = 'Please enter mandatory(*) fields';
      if (this.data === 'new') {

      }
      count++;
    }

    if (count === 0 && Editcount === 0) {
      this.dataSelf = '';
      if (this.data === 'new') {

        this.Saving(this.data);
      } else {
        this.Saving(this.data);
      }
    }
  }
  frustrationTypesDa(event) {
    this.FrustrationTypeID = event.value;
  }
  Saving(msg: string) {
    if (this.ActivityID === '' || this.ActivityID === undefined) {
      this.ActivityID = "0";
    }

    if (this.ProjectID === '' || this.ProjectID === undefined || this.ProjectID === null) {
      this.ProjectID = "0";
    }
    let activityId: string;
    if (this.subActivityStatus) {
      activityId = this.SubActivityID
    }
    else {
      activityId = this.ActivityID;
    }
    const json = {
      CompanyID: this.CompanyID,
      PointerID: this.PointerID,
      EmployeeID: "0",
      ProessID: this.ProcessID,
      ActivityID: activityId,
      PointerTypeID: this.PointerTypeID,
      FrustrationsTypeID: this.FrustrationTypeID,
      DepartmentID: "0",
      TeamID: this.TeamID,
      SeverityID: this.SeverityID,
      StatusID: this.StatusID,
      Subject: this.Subject,
      Description: this.Description,
      CreatedBy: this.currentUser,
      UpdatedBy: this.currentUser,
      ProjectID: this.ProjectID,
    }
    if (msg === 'new') {

      this.dorseyService.SavePointer(json, 'No').subscribe(data => {

        if (data['_body'].toString() !== "0") {

          if (this.PointerTypeID.toString() === "1") {
            this.PointerID = data['_body'].toString();
            const caseData = {
              PointerID: this.PointerID,
              CreatedBy: this.currentUser,
              UpdatedBy: this.currentUser
            };
            this.dorseyService.SaveCase(caseData).subscribe(data => {
              if (data['_body'].toString() !== '0') {
                {

                }
              }
            });

            // this.dialogRef.close(true);
            this.dataSelf = 'Saved succesfully';
            // this.FrustrationTypeIDs=[];
            this.EmployeeID = '';
            this.DepartmentID = '';
            this.FrustrationTypeID = '';
            this.Subject = '';
            this.Description = '';
            this.SeverityID = '';
            this.StatusID = this.statusData[0].StatusID;
            this.PointerID = '';
            this.FTextBoxType2 = '';
            this.showDd = true;
            this.FrustrationTypeIDs = [];

          }
          else {
            this.dataSelf = 'Updated successfully';
          }
        }

      });
    } else {

      this.dorseyService.SavePointer(json, 'No').subscribe(data => {

        if (data['_body'].toString() !== '0') {
          this.dataSelf = 'Updated successfully';
          setTimeout(() => {
            this.dialogRef.close(true);
          }, 1000);
        } else {
          this.dataSelf = 'Updated failed';
        }
      });
    }
  }


  Clear() {
    this.dialogRef.close(false);
  }
  employee(event) {
    this.EmployeeID = event.value;
    let tempEmp = this.employeeData.filter(x => x.EmployeeID === this.EmployeeID);

    if (tempEmp.length > 0 && tempEmp.length === 1) {
      this.tempDepart = this.departmentData.filter(x => x.DepartmentID === tempEmp[0].DepartmentID);
      this.DepartmentID = this.tempDepart[0].DepartmentID;

    }
  }
  process(event) {
    this.ProcessID = event.value;
    this.tempActivity = [];
    this.GetTempActivity(this.ProcessID);


    for (let i = 0; i < this.processData.length; i++) {
      if (this.processData[i].ProcessID === this.ProcessID) {
        this.TeamID = this.processData[i]['TeamID'];

      }
    }

  }
  GetTempActivity(processID) {
    this.tempSubActivity = [];
    for (let i = 0; i < this.activityData.length; i++) {
      if (this.activityData[i].ProcessID === processID) {
        this.tempActivity.push(this.activityData[i]);
      }
    }
  }
  activity(event) {
    this.tempSubActivity = [];
    this.ActivityID = event.value;
    this.GetTempSubActivity(this.ActivityID);
  }

  GetTempSubActivity(ActivityID) {
    this.tempSubActivity = [];
    for (let i = 0; i < this.activityData.length; i++) {

      if (this.activityData[i].ParentID === ActivityID) {
        this.tempSubActivity.push(this.activityData[i]);
      }
    }
  }
  pointerType(event) {
    this.PointerTypeID = event.value;
    this.tempFrustrationType = [];
    this.GetTempFrastrations();
  }

  GetTempFrastrations() {
    for (let i = 0; i < this.frustrationtypeData.length; i++) {
      if (this.frustrationtypeData[i].PointerTypeID === this.PointerTypeID) {
        this.tempFrustrationType.push(this.frustrationtypeData[i]);
      }
    }
  }

  frustrationType(event) {
    this.FrustrationTypeIDs = event.value;

    let data = this.frustrationtypeData.filter(x => x.FrustrationsTypeID === this.FrustrationTypeIDs);
    if (data.length > 0) {
      this.FrustrationTypeIDs = data[0];
    }

    this.enableEdit = false;
    if (!this.notEdit) {
      this.typeEdit = true;
    }
  }
  frustrationTypes(event) {
    this.FrustrationTypeIDs = event.value;
  }
  department(event) {
    this.DepartmentID = event.value;
  }

  team(event) {
    this.TeamID = event.value;

  }

  GetKeyProcessTeam(TeamID) {
    this.dorseyService.GetKeyProcessTeam().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.processTeamData = subData;
      for (let i = 0; i < this.processTeamData.length; i++) {
        if (this.processTeamData[i].TeamID === TeamID) {
          this.tempProcess.push(this.processTeamData[i]);
        }
      }
    });
  }

  severity(event) {
    this.SeverityID = event.value;
  }

  statusChange(event) {
    this.StatusID = event.value;
  }

  AddType() {
    // console.log("addtype");
    this.typeAvai = true;
  }
  CancelType() {
    this.FTextBoxType = '';
    this.typeAvai = false;
    // this.suggestionAssignTypes=false;
    // this.GetFrustrationType();
    this.typeEdit = false;
    // this.notEdit = true;
    // this.enableEdit=true;
  }
  SaveType() {
    // console.log(this.PointerTypeID);
    let count = 0;
    const Editcount = 0;

    if (this.FTextBoxType === undefined || this.FTextBoxType.trim() === '') {

      this.dataSelf = 'Please enter mandatory(*) fields';
      if (this.data === 'new') {

      }
      count++;
    }

    if (count === 0 && Editcount === 0) {
      this.dataSelf = '';
      if (this.data === 'new') {

        this.SaveTypeDetails(this.data);
      } else {
        this.SaveTypeDetails(this.data);
      }
    } else {

    }

  }

  SaveTypeDetails(msg: string) {
    // console.log(this.FrustrationTypeIDs);
    if (this.FTextBoxType !== undefined || this.FTextBoxType !== '') {
      this.FrustrationTypeIDs = [];
      if (msg === 'new') {
        this.FrustrationTypeIDs.push(this.FTextBoxType);
        // console.log(this.FrustrationTypeIDs);
        this.TypeIDS = this.FrustrationTypeIDs
      }

      if (msg === 'edit') {
        // console.log(this.frustrationtypeData);
        // console.log(this.FTextBoxType2);
        this.FrustrationTypeIDs.push(this.FTextBoxType2.FrustrationsTypeName);
        // console.log(this.FrustrationTypeIDs);
        this.TypeIDS = this.FrustrationTypeIDs[0].FrustrationsTypeName;
      }
    }

    const json = {
      FrustrationsTypeID: this.FrustrationTypeIDs[0].FrustrationsTypeID,
      CompanyID: this.CompanyID,
      TypeIDS: this.TypeIDS,
      CreatedBy: this.currentUser,
      PointerTypeID: 1
    }
    if (msg === 'new') {
      // console.log(json);
      this.dorseyService.SaveTypes(json, 'No').subscribe(data => {
        //  console.log(data['_body'].toString());
        if (data['_body'].toString() === 'true') {
          // console.log(data['_body']);
          this.GetFrustrationType();
          this.FTextBoxType = '';
          this.enableEdit = true;
          this.dataSelf = 'Frustration type saved successfully';
          this.typeAvai = false;
          // this.suggestionAssignTypes=false;
          // this.dialogRef.close(true);

        } else {
          this.dataSelf = 'Frustration type saved failed';
        }

      });
    }
    else {
      this.dorseyService.SaveTypes(json, 'No').subscribe(data => {
        // console.log(data['_body']);
        if (data['_body'].toString() === 'true') {


          this.dataSelf = 'Frustration type updated successfully';
          setTimeout(() => {
            this.dataSelf = '';
          }, 2000);
          this.typeAvai = false;
          this.GetFrustrationType();
          // this.FTextBoxType='';

        } else {
          this.dataSelf = 'Frustration type updated failed';
        }
      });
    }

  }

  EditableType() {
    this.typeEdit = true;


    // if(this.FTextBoxType2 === undefined || this.FTextBoxType2 === ''){
    //   this.dataSelf = "Select Frustration Type in dropdown";
    //   setTimeout(() => {
    //     this.dataSelf='';
    //   }, 2000);
    // }else{
    //   this.notEdit = false;
    //   this.editClick=true;
    //  // this.EditType();
    //   this.dataSelf='';
    // }
  }

  EditType() {

    if (this.FTextBoxType2.FrustrationsTypeName === undefined || this.FTextBoxType2.FrustrationsTypeName === '') {
      this.dataSelf = "Please enter mandatory(*) fields"
    } else {
      //console.log(element);
      // console.log(this.FrustrationTypeIDs);
      //this.showDd=true;
      this.SaveTypeDetails('edit');
      this.typeEdit = false;
      // this.FTextBoxType= ''
    }


  }

  GetPointer() {
    this.dorseyService.GetPointer(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.pointerData = subData;
    });
  }


  DeleteType(element, name) {

    const dialogRef = this.dialog.open(PointerDialogComponent2, {

      panelClass: 'custom-dialog-container',
      data: 'Are you sure you want to delete frustation type:' + name + '?æææ' + true,
      // width: '40%'
    });
    //   this.condition = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log(this.FrustrationTypeIDs);
        // console.log(element);
        for (let i = 0; i < this.FrustrationTypeIDs.length; i++) {
          this.FrustrationTypeID = this.FrustrationTypeIDs[i].FrustrationsTypeID;
          // console.log(this.FrustrationTypeID);
        }
        this.GetPointer();
        let issueCount = 0;
        setTimeout(() => {
          // console.log(this.pointerData);
          for (let i = 0; i < this.pointerData.length; i++) {
            //  console.log(element, this.pointerData[i].FrustrationsTypeID);
            if (element == this.pointerData[i].FrustrationsTypeID) {
              issueCount++;
            }


          }

          /// this.condition = true;
          // console.log(!issueCount);
          if (!issueCount) {
            // console.log("****");
            this.dorseyService.DeleteType(element).subscribe(data => {
              //alert('Are you sure ');
              this.notEdit = true;
              this.dataSelf = "Deleted successfully";
              if (data.ok) {
                this.condition = true;
                // this.dialogCall('Deleted successfully');
              }
              this.GetFrustrationType();
              this.FTextBoxType = '';
            });

          } else {
            this.dataSelf = "This frustration type is in use";
          }

        }, 500);


      }
    });





    this.typeEdit = false;
  }
  Tranfer() {

    const json = {
      CompanyID: this.CompanyID,
      TypeIDS: JSON.stringify(this.FrustrationTypeIDs),
      CreatedBy: this.currentUser,
      PointerTypeID: 1
    }

    this.dorseyService.SaveTypes(json, 'No').subscribe(data => {
      // console.log(data['_body'].toString());
      if (data['_body'].toString() === 'true') {


        this.GetFrustrationType();
        // this.dialogRef.close(true);
        this.dataSelf = 'Frustration type saved successfully';
        setTimeout(() => {
          this.dataSelf = '';
        }, 2000);
      } else {
        this.dataSelf = 'Frustration type saved failed';
        setTimeout(() => {
          this.dataSelf = '';
        }, 2000);
      }

    });
  }
  GetFrustrationType() {
    this.dorseyService.GetFrustrationType("1", this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);

      let tempType: any;
      tempType = subData;
      //  console.log(tempType);
      if (tempType.length === 0) {
        this.suggestionAssignTypes = true;
        this.dorseyService.GetFrustrationType('1', "0").subscribe(subData => {
          subData = JSON.parse(subData['_body']);
          this.frustrationtypeData = subData;
          //  console.log(this.frustrationtypeData)
        });
      }
      else {
        this.frustrationtypeData = tempType;
        this.suggestionAssignTypes = false;
      }

    });


  }

}


@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
  styleUrls: ['dialog.css'],
  providers: [DorseyService]
})
export class PointerDialogComponent2 implements OnInit {
  condition = false;
  processData: any;
  //ProcessID:string;
  dataSelf: string;
  TeamName: string;
  TeamID: string;
  teamData: any;
  CompanyID: string;
  currentUser: string;
  TeamDescription: string;
  processIDs = [];
  updatedBy: any;
  updatedDate = new Date();
  constructor(
    public dialogRef: MatDialogRef<PointerDialogComponent2>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dorseyService: DorseyService) {
    // dialogRef.disableClose=true;
    this.data = this.data.split('æææ');
    if (this.data[1] === 'true') {
      this.condition = true;
    } else {
      this.condition = false;
    }
    this.data = this.data[0];
  }
  ngOnInit() {

  }
  onYesClick() {
    this.dialogRef.close(true);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  Clear() {
    this.dialogRef.close(false);
  }

}
