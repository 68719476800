import { Injectable } from "@angular/core";
import * as Msal from "msal";
import { Observable } from "rxjs";
import { AzureDetails } from "./azure-details.model";
// @Injectable({
//   providedIn: "root",
// })
export class MsalUserService {
  private accessToken: any;
  public clientApplication: Msal.UserAgentApplication = null;
  constructor() {
    this.clientApplication = new Msal.UserAgentApplication(
      AzureDetails.ClientId,
      "https://login.microsoftonline.com/" + AzureDetails.TenantId,
      this.authCallback,
      {
        storeAuthStateInCookie: true,
        //cacheLocation: 'localStorage' ,
      }
    );
  }
  public GetAccessToken(): Observable<any> {
    if (
      sessionStorage.getItem("msal.idtoken") !== undefined &&
      sessionStorage.getItem("msal.idtoken") != null
    ) {
      this.accessToken = sessionStorage.getItem("msal.idtoken");
    }
    return this.accessToken;
  }

  public authCallback(errorDesc, token, error, tokenType) {
    if (token) {
        console.log(token);
    } else {
      console.log(error + ":" + errorDesc);
    }
  }

  public getCurrentUserInfo() {
    const user = this.clientApplication.getUser();
    console.log(user);
  }

  public logout() {
    this.clientApplication.logout();
  }

  public login(){
   this.clientApplication.loginRedirect();
  }
}