
import { Component, OnInit } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Local } from 'protractor/built/driverProviders';

@Component({
  selector: 'app-report-activity-issues',
  templateUrl: './report-activity-issues.component.html',
  styleUrls: ['./report-activity-issues.component.scss']
})
export class ReportActivityIssuesComponent implements OnInit {
  loginCompanyName: any;
  companyID: string;
  processData: any;
  activityData: any;
  pointersData: any;
  finalList = [];
  frust = [];
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  processPaging = false;
  searchtext: string;
  saveFilter = [];
  CompanyID: string;
  isDisable = false;
  disableSearch = false;
  companyName: string;
  headerDate = new Date();
  searchFilter: any;

  constructor(private dorseyService: DorseyService, private storage: LocalStorageService) {

    this.companyID = this.storage.retrieve('companyID');
    this.loginCompanyName = this.storage.retrieve('loginCompanyName');

  }

  ngOnInit() {
    //  this.GetProcess();
    //  this.GetActivity();
    this.GetActivityIssues();

  }

  GetActivityIssues() {
    this.dorseyService.GetActivityIssues(this.companyID).subscribe(process => {
      this.pointersData = JSON.parse(process['_body']);
      if (this.pointersData.length === 0) {
        this.isDisable = true;
        this.disableSearch = true;
        this.processPaging = false;
      }
      else {
        this.processPaging = true;
        this.isDisable = false;
        this.disableSearch = false;
        this.saveFilter = JSON.parse(JSON.stringify(this.pointersData));
        this.rowCount = this.pointersData.length;
        this.processPaging = true;
        while (this.dynamicRows < this.rowCount) {
          this.rowsOnPageSet.push(this.dynamicRows);
          this.dynamicRows = this.dynamicRows + 10;
        }
        if (!this.rowsOnPageSet.includes(this.rowCount))
          this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet.length === 1) {
          if (this.rowsOnPageSet[0] <= 10)
            this.rowsOnPage = this.rowsOnPageSet[0];
        }
      }
    });


  }

  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  Searchtext() {
    this.pointersData = this.saveFilter;

    let tempFrustrations = this.pointersData.filter(
      (item) => {
        return (
          (item.ProcessName !== null && item.ProcessName.toLowerCase().indexOf(this.searchtext.toLowerCase()) > -1) ||
          (item.ActivityName !== null && item.ActivityName.toLowerCase().indexOf(this.searchtext.toLowerCase()) > -1)
        );
      }

    );
    this.pointersData = tempFrustrations;
    if (this.pointersData.length == 0) {
      // this.GetActivity();
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.GetActivityIssues();
      this.isDisable = false;
    }
  }


}
