export enum ScoreCardTypeEnum {
    Department = "Department",
    Team = "Team"
}

export interface ScoreCard {
    ScoreCardType: string,
    DepartmentId: number,
    TeamId: number,
    DepartmentName: string,
    TeamName: string
}