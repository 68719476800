import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LocalStorageService } from 'ngx-webstorage';
import { ScoreCard, ScoreCardTypeEnum } from '../performance-score-card/performance-score-card.model';
import { PerformanceScoreCardService } from '../performance-score-card/performance-score-card.service';
import { QuarterService } from '../services/quarter.service';
import { Frequency, PerformanceScoreCard, PSCMetricDetails, ScoreCardMaster } from './add-score-card.model';
import { ValidationDialogComponent } from './validation-dialog';
import { ResponseDialogComponent } from './response-dialog';

@Component({
  selector: 'app-add-score-card',
  templateUrl: './add-score-card.component.html',
  styleUrls: ['./add-score-card.component.scss']
})
export class AddScoreCardComponent implements OnInit {
  isViewScoreCard: boolean = true;
  companyId: number;
  quarterList: any = [];
  currentUser: string;
  selectedQuarterId: number;
  quarterMonths: string;
  scoreCard: ScoreCard;
  teamName: string;
  selectedYear: Date;
  step: number = 0;
  selectedMetrics: Array<PSCMetricDetails> = [];
  YearPickerConfig: Partial<BsDatepickerConfig>;
  maxDate: Date;
  minDate: Date;
  errorMessages: string[] = [];
  frequencies: string[] = [Frequency.Monthly, Frequency.Weekly];
  scoreCardMaster: ScoreCardMaster;
  pscMetricDetails: Array<PSCMetricDetails> = [];
  performanceScoreCard: PerformanceScoreCard;
  scoreCardMasterId: number;
  quarterName: string;
  selectedYearName: Number;
  scoreCardMasterList: ScoreCardMaster[];
  WeekDateList = [];
  toBeDeletedMetrics: number[] = [];
  quarters: any;
  targetSymbolsList: string[] = ['<', '<=', '>', '>=', '==', 'Yes', 'No'];
  scoreCardPreview: boolean = false;
  quarterDetails = [];

  constructor(private storage: LocalStorageService,
    private scoreCardService: PerformanceScoreCardService,
    private quarterService: QuarterService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog) {
    this.YearPickerConfig = Object.assign({},
      { containerClass: 'theme-dark-blue', showWeekNumbers: false, dateInputFormat: 'YYYY' });
    this.currentUser = this.storage.retrieve('user');
    this.scoreCardService.scoreCard.subscribe(resp => {
      if (!resp) {
        this.router.navigate(['performance-score-card']);
        return;
      }
      this.scoreCard = resp;
      this.currentUser = this.storage.retrieve('user');
    });
  }


  ngOnInit(): void {
    this.companyId = this.storage.retrieve('companyID');
    this.maxDate = new Date();
    this.minDate = new Date("01/01/2020");
    this.scoreCardMasterId = Number(this.route.snapshot.paramMap.get('id'));
    this.step = Number(this.route.snapshot.paramMap.get('stepId'));
    if (this.scoreCard.TeamName) {
      this.teamName = this.scoreCard.TeamName;
    }
    this.scoreCardService.GetPerformanceScoreCardDetails(this.companyId, this.scoreCard.DepartmentId, this.scoreCard.TeamId, this.scoreCardMasterId).subscribe(resp => {
      this.scoreCardMasterList = resp.Data;
      this.pscMetricDetails = resp.AdditionalData.PSCDetails;
      if (this.scoreCard.ScoreCardType === ScoreCardTypeEnum.Department) {
        this.scoreCardMasterList = this.scoreCardMasterList.filter(ent => ent.ScoreCardType === ScoreCardTypeEnum.Department);
      }
      if (this.scoreCard.ScoreCardType === ScoreCardTypeEnum.Team) {
        this.scoreCardMasterList = this.scoreCardMasterList.filter(ent => ent.TeamID === this.scoreCard.TeamId);
        this.pscMetricDetails = this.pscMetricDetails.filter(ent => ent.TeamID === this.scoreCard.TeamId);
      }
      this.quarterDetails = resp.AdditionalData.QuarterDetails;
      this.getQuarters(resp.AdditionalData.QuarterDetails);
      if (this.scoreCardMasterList.length > 0) {
        this.selectedMetrics = this.pscMetricDetails.filter(ent => ent.IsSelected === true);
      }
      if (this.selectedMetrics.length == 0) {
        this.pscMetricDetails.forEach(ele => {
          ele.IsSelected = false;
          ele.UOM = ele.Frequency = null;
          ele.GreenTargetSymbol = ele.YellowTargetSymbol = ele.RedTargetSymbol = null;
          ele.GreenMinimum = ele.GreenMaximum = ele.YellowMinimum = ele.YellowMaximum = null;
          ele.RedMinimum = ele.RedMaximum = null;
          ele.Week1 = ele.Week2 = ele.Week3 = ele.Week4 = ele.Week5 = ele.Week6 = null;
          ele.Week7 = ele.Week8 = ele.Week9 = ele.Week10 = ele.Week11 = ele.Week12 = ele.Week13 = ele.Week14 = null;
        });
      }
      if (this.scoreCardMasterId != 0) {
        this.scoreCardMaster = this.scoreCardMasterList.find(ele => ele.Id === this.scoreCardMasterId);
        this.selectedQuarterId = this.scoreCardMaster ? this.scoreCardMaster.QuarterId : 0;
        this.selectedYear = this.scoreCardMaster ? new Date("01/01/" + this.scoreCardMaster.Year) : null;
        this.quarterName = this.quarterList.find(ent => ent.QuarterId == this.selectedQuarterId).QuarterName;
        this.selectedYearName = this.selectedYear.getFullYear();
        var months = this.quarters.find(ent => ent.QuarterId === this.selectedQuarterId).QuarterMonths;
        this.WeekDateList = this.quarterService.getWeekMonths(months, Number(this.selectedYearName), this.quarters[0].QuarterWeekDay, this.quarters[0].QuarterWeekDate);
      }
      this.processData();
    });
  }

  getQuarters(quarterDetails): void {
    if (quarterDetails.length == 0) {
      return;
    }
    var selectedMonthId = this.quarterService.getMonths().find(val => val.MonthName == quarterDetails[0].MonthName).MonthId;
    this.quarters = this.quarterService.generateQuarters(selectedMonthId, this.companyId, this.currentUser, quarterDetails[0].QuarterWeekDay, quarterDetails[0].QuarterWeekDate);
    this.quarters.forEach(val => {
      this.quarterList.push(
        {
          QuarterId: val.QuarterId,
          QuarterName: val.QuarterName,
          // QuarterName: val.QuarterName + "(" + this.getQuarterMonths(val.QuarterMonths) + ")",
          QuarterMonths: this.getQuarterMonths(val.QuarterMonths)
        }
      )
    });
  }

  getQuarterMonths(quarters): string {
    var months = [];
    quarters.forEach(val => {
      months.push(val.MonthName);
    });
    return months.join(',');
  }

  targetChange(metric): void {
    if (!metric.GreenTargetSymbol || metric.GreenTargetSymbol == 'null') {
      metric.GreenTargetSymbol = null;
    } else {
      metric.GreenMaximum = null;
      if (metric.GreenTargetSymbol === 'Yes')
        metric.GreenMinimum = 2;
      else if (metric.GreenTargetSymbol === 'No')
        metric.GreenMinimum = 1;
    }
    if (!metric.YellowTargetSymbol || metric.YellowTargetSymbol == 'null') {
      metric.YellowTargetSymbol = null;
    } else {
      metric.YellowMaximum = null;
      if (metric.YellowTargetSymbol === 'Yes')
        metric.YellowMinimum = 2;
      else if (metric.YellowTargetSymbol === 'No')
        metric.YellowMinimum = 1;
    }
    if (!metric.RedTargetSymbol || metric.RedTargetSymbol == 'null') {
      metric.RedTargetSymbol = null;
    } else {
      metric.RedMaximum = null;
      if (metric.RedTargetSymbol === 'Yes')
        metric.RedMinimum = 2;
      else if (metric.RedTargetSymbol === 'No')
        metric.RedMinimum = 1;
    }
  }


  getMetrics(): void {
    this.errorMessages = [];
    if (!this.selectedQuarterId) {
      this.errorMessages.push('Please select Quarter');
    }
    if (!this.selectedYear) {
      this.errorMessages.push('Please select Year');
    }

    if (this.scoreCardMasterId === 0 && this.selectedYear && this.selectedQuarterId) {
      if (this.scoreCardMasterList.findIndex(ent => ent.Year === this.selectedYear.getFullYear() && ent.QuarterId === this.selectedQuarterId) > -1) {
        this.errorMessages.push('Score card already exist for selected quarter and year');
      }
    }

    if (this.errorMessages.length != 0) {
      this.dialog.open(ValidationDialogComponent, {
        data: {
          errorMessages: this.errorMessages
        }
      });
      return;
    }

    if (this.scoreCardMasterId == 0) {
      this.quarterName = this.quarterList.find(ent => ent.QuarterId == this.selectedQuarterId).QuarterName;
      this.selectedYearName = this.selectedYear.getFullYear();
      var months = this.quarters.find(ent => ent.QuarterId === this.selectedQuarterId).QuarterMonths;
      this.WeekDateList = this.quarterService.getWeekMonths(months, Number(this.selectedYearName), this.quarters[0].QuarterWeekDay, this.quarters[0].QuarterWeekDate);
    }
    this.step++;
  }

  //step 2
  getSelectedMetrics(): void {
    this.errorMessages = [];
    if (this.selectedMetrics.length === 0) {
      this.errorMessages.push('Please select atleast one Metric');
    }

    if (this.errorMessages.length != 0) {
      this.dialog.open(ValidationDialogComponent, {
        data: {
          errorMessages: this.errorMessages
        }
      });
      return;
    }
    this.processData();
    this.step++;
  }

  //step 3
  generateWeeks(): void {
    this.errorMessages = [];

    // var selectedTarget = this.selectedMetrics.filter(ent => !ent.GreenMinimum || !ent.GreenMaximum
    //   || !ent.YellowMinimum || !ent.YellowMaximum ||
    //   !ent.RedMinimum || !ent.RedMaximum);

    // if (selectedTarget.length > 0) {
    //   this.errorMessages.push('Please enter Targets');
    // }
    var selectedFrequency = this.selectedMetrics.filter(ent => !ent.Frequency || ent.Frequency == "null");
    if (selectedFrequency.length > 0) {
      this.errorMessages.push('Please select Frequency');
    }

    var selectedUOM = this.selectedMetrics.filter(ent => !ent.UOM || !ent.UOM.trim());
    if (selectedUOM.length > 0) {
      this.errorMessages.push('Please enter UOM');
    }

    if (this.errorMessages.length != 0) {
      this.dialog.open(ValidationDialogComponent, {
        data: {
          errorMessages: this.errorMessages
        }
      });
      return;
    }
    this.step++;
  }

  //step 4
  generateScoreCard(): void {
    this.scoreCardPreview = true;
    this.saveScoreCardDetails(false);
    this.step++;
  }

  saveScoreCardDetails(showDialog = true): void {
    if (this.scoreCardMasterId == 0) {
      this.scoreCardMaster = {
        Id: 0,
        CompanyID: this.companyId,
        ScoreCardType: this.scoreCard.ScoreCardType,
        DepartmentID: this.scoreCard.DepartmentId,
        TeamID: this.scoreCard.TeamId,
        QuarterId: this.selectedQuarterId,
        Year: Number(this.selectedYear.getFullYear()),
        ScoreCardName: this.getScoreCardName(),
        CreatedBy: this.currentUser,
        UpdatedBy: this.currentUser,
        QuarterName: '',
        CompanyName: '',
        DepartmentName: '',
        TeamName: ''
      }
    }
    this.performanceScoreCard = { scoreCardMaster: this.scoreCardMaster, pscMetricDetails: this.selectedMetrics, metricIds: this.toBeDeletedMetrics };
    this.scoreCardService.SavePerformanceScoreCardDetails(this.performanceScoreCard).subscribe(resp => {
      if (resp.Data) {
        this.scoreCardMaster.Id = resp.Data.ScoreCardMaster.Id;
        this.scoreCardMasterId = resp.Data.ScoreCardMaster.Id;
        this.selectedMetrics = [...resp.Data.pscMetricDetails];
        this.selectedMetrics.forEach(ele => {
          this.pscMetricDetails.find(ele1 => ele.PointerID === ele1.PointerID).Id = ele.Id;
        });
        if (showDialog) {
          this.dialog.open(ResponseDialogComponent, {
            data: {
              message: 'Save successful'
            }
          });
        }
      }
    });
  }

  metricChange(metric): void {
    if (metric.IsSelected) {
      this.selectedMetrics.push(metric);
    }
    else {
      this.selectedMetrics = this.selectedMetrics.filter(ent => ent.PointerID != metric.PointerID);
      if (metric.Id != 0) {
        this.toBeDeletedMetrics.push(metric.Id);
      }
    }
  }

  //validations
  setTargets(metric): void {
    // if (metric.GreenMinimum == null || Number(metric.GreenMaximum) < Number(metric.GreenMinimum)) {
    //   metric.GreenMaximum = null;
    // }
    // if (metric.YellowMinimum == null || Number(metric.YellowMaximum) < Number(metric.YellowMinimum)) {
    //   metric.YellowMaximum = null;
    // }
    // if (metric.RedMinimum == null || Number(metric.RedMaximum) < Number(metric.RedMinimum)) {
    //   metric.RedMaximum = null;
    // }
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  //step 0
  goToQuarterSelection(): void {
    this.selectedYearName = this.quarterName = undefined;
    this.step--;
  }

  //step 1
  goToMetricList() {
    this.selectedMetrics = [...this.selectedMetrics];
    this.step--;
  }

  goToGeneratedWeeks() {
    this.scoreCardPreview = false;
    this.step--;
  }

  //private methods
  private getScoreCardName(): string {
    if (this.scoreCard.ScoreCardType === ScoreCardTypeEnum.Department) {
      return `${this.scoreCard.DepartmentName}_${this.quarterList.find(ent => ent.QuarterId === this.selectedQuarterId).QuarterName}_${this.selectedYear.getFullYear()}`;
    }
    else {
      return `${this.scoreCard.DepartmentName}_${this.scoreCard.TeamName}_${this.quarterList.find(ent => ent.QuarterId === this.selectedQuarterId).QuarterName}_${this.selectedYear.getFullYear()}`;
    }
  }

  private processData(): void {
    const goalSeen = {};
    this.selectedMetrics = this.selectedMetrics.sort((a, b) => {
      return a.GoalName.localeCompare(b.GoalName);
    }).map(x => {
      const goalSpan = goalSeen[x.GoalName] ? 0 :
        this.selectedMetrics.filter(y => y.GoalName === x.GoalName).length;
      goalSeen[x.GoalName] = true;
      return { ...x, goalSpan };
    });
  }

}

