import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { LocalStorageService } from 'ngx-webstorage';
import { PSCMetricDetails } from '../add-score-card/add-score-card.model';
import { ScoreCard } from '../performance-score-card/performance-score-card.model';
import * as XLSX from 'xlsx';
import { DorseyService } from '../dorsey.service';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-view-score-card',
  templateUrl: './view-score-card.component.html',
  styleUrls: ['./view-score-card.component.scss']
})
export class ViewScoreCardComponent implements OnInit {
  @Input() selectedMetrics: Array<PSCMetricDetails> = [];
  @Input() scoreCard: any;
  @Input() isViewScoreCard: boolean;
  @Input() WeekDateList: [];
  @Input() isScoreCardEnable: boolean;

  @Output() viewScoreCardChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() goToAddScoreCardChanged = new EventEmitter();
  @Input() showDetails: boolean = false;
  excelMetricList: Array<PSCMetricDetails> = [];
  showLogo: boolean = false;
  MenuPermissions: any;
  menuData: any;
  menuID: string;
  menuName: string;
  loginCompanyName: string;
  employeeDetails: any;

  constructor(private storage: LocalStorageService,
    private dorseyService: DorseyService) {
    this.menuData = this.storage.retrieve('menuData');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
    this.loginCompanyName = this.storage.retrieve('loginCompanyName');
    this.employeeDetails = this.storage.retrieve('employeeDetails');
  }

  ngOnInit(): void {
    this.menuData = this.menuData.filter(x => x.MenuName.toString().toLowerCase() === 'score card');
    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    this.excelMetricList = JSON.parse(JSON.stringify(this.selectedMetrics));//[...this.selectedMetrics];
    this.excelMetricList.forEach(ent => {
      ent.UOM = format.test(ent.UOM) ? "'" + ent.UOM + "'" : ent.UOM;
    });
  }

  exportAsXLSX(): void {
    var fileName = this.loginCompanyName + 'ScoreCard' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear() + ".xlsx";
    /* table id is passed over here */
    let element = document.getElementById('table1');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Score Card');

    /* save to file */
    XLSX.writeFile(wb, fileName);

  }

  backToScoreCard(): void {
    this.viewScoreCardChanged.emit(this.isViewScoreCard);
  }

  goToAddScoreCard(): void {
    //emitting step id, need to navigate to step 4 in edit score card
    this.goToAddScoreCardChanged.emit(4);
  }

  print1() { window.print() }
  print(e): void {
    document.getElementById(e).style.marginTop = "20px";
    var printContents = document.getElementById(e).innerHTML;
    var popupWin = window.open('', '_blank', 'width=auto,height=auto');
    popupWin.document.open();
    popupWin.document.write(printContents);
    popupWin.document.close();
  }

  exportAsXLSX1(): void {
    let allData = JSON.parse(JSON.stringify(this.selectedMetrics));
    for (let i = 0; i < allData.length; i++) {
      allData[i]["Goal "] = allData[i].GoalName;
      allData[i]["Metric "] = allData[i].Description;
      allData[i]["Green"] = (allData[i].GreenMaximum) + "-" + (allData[i].GreenMinimum);
      allData[i]["Yellow"] = (allData[i].YellowMaximum) + "-" + (allData[i].YellowMinimum);
      allData[i]["Red"] = (allData[i].RedMaximum) + "-" + (allData[i].RedMinimum);
      allData[i].Frequency = allData[i].Frequency;
      allData[i].UOM = allData[i].UOM;
      allData[i]["Wk1"] = allData[i].Week1;
      allData[i]['Wk2'] = allData[i].Week2;
      allData[i]['Wk3'] = allData[i].Week3;
      allData[i]['Wk4'] = allData[i].Week4;
      allData[i]['Wk5'] = allData[i].Week5;
      allData[i]['Wk6'] = allData[i].Week6;
      allData[i]['Wk7'] = allData[i].Week7;
      allData[i]['Wk8'] = allData[i].Week8;
      allData[i]['Wk9'] = allData[i].Week9;
      allData[i]['Wk10'] = allData[i].Week10;
      allData[i]['Wk11'] = allData[i].Week11;
      allData[i]['Wk12'] = allData[i].Week12;
      allData[i]['Wk13'] = allData[i].Week13;
      allData[i]['Wk14'] = allData[i].Week14;
      delete (allData[i].Frequency);
      delete (allData[i].UOM);
      delete (allData[i].GoalName);
      delete (allData[i].GreenMaximum);
      delete (allData[i].GreenMinimum);
      delete (allData[i].YellowMaximum);
      delete (allData[i].YellowMinimum);
      delete (allData[i].RedMaximum);
      delete (allData[i].RedMinimum);
      delete (allData[i].Description);
      delete (allData[i].Id);
      delete (allData[i].PSCMasterId);
      delete (allData[i].PointerID);
      delete (allData[i].GoalID);
      delete (allData[i].GoalName);
      delete (allData[i].TeamID);
      delete (allData[i].DepartmentID);
      delete (allData[i].CompanyID);
      delete (allData[i].IsSelected);
      delete (allData[i].GreenTargetSymbol)
      delete (allData[i].YellowTargetSymbol)
      delete (allData[i].RedTargetSymbol)
      delete (allData[i].goalSpan)
      delete (allData[i].Week1);
      delete (allData[i].Week2);
      delete (allData[i].Week3);
      delete (allData[i].Week4);
      delete (allData[i].Week5);
      delete (allData[i].Week6);
      delete (allData[i].Week7);
      delete (allData[i].Week8);
      delete (allData[i].Week9);
      delete (allData[i].Week10);
      delete (allData[i].Week11);
      delete (allData[i].Week12);
      delete (allData[i].Week13);
      delete (allData[i].Week14);
    }
    this.dorseyService.generateExcelForScoreCard(allData, this.loginCompanyName + 'ScoreCard' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Score Card', "Company: " + this.loginCompanyName, '', "Score Card Report", copyRight, 'FFFFFF00', 'FF0000FF');
  }

  myStyleWeek(metric, i) {
    var color = '';
    if (metric['Week' + i] !== null && metric.GreenMinimum !== null) {
      if (!metric.GreenTargetSymbol &&
        (
          (Number(metric['Week' + i]) >= Number(metric.GreenMinimum)) && (Number(metric['Week' + i]) <= Number(metric.GreenMaximum)) ||
          (Number(metric['Week' + i]) <= Number(metric.GreenMinimum)) && (Number(metric['Week' + i]) >= Number(metric.GreenMaximum))
        )) {
        color = '#0fbf0f';
      } else if (metric.GreenTargetSymbol && metric['Week' + i] !== null) {
        if (metric.GreenTargetSymbol !== 'Yes' && metric.GreenTargetSymbol !== 'No') {
          if (eval(metric['Week' + i] + metric.GreenTargetSymbol + metric.GreenMinimum)) {
            color = '#0fbf0f';
          }
        } else if ((metric.GreenTargetSymbol == 'Yes' || metric.GreenTargetSymbol == 'No') && metric['Week' + i] == metric.GreenMinimum) {
          color = '#0fbf0f';
        }
      }
    }

    if (metric['Week' + i] !== null && metric.YellowMinimum !== null) {
      if (!metric.YellowTargetSymbol &&
        (
          (Number(metric['Week' + i]) >= Number(metric.YellowMinimum) && Number(metric['Week' + i]) <= Number(metric.YellowMaximum)) ||
          (Number(metric['Week' + i]) <= Number(metric.YellowMinimum) && Number(metric['Week' + i]) >= Number(metric.YellowMaximum))
        )) {
        color = 'yellow';
      } else if (metric.YellowTargetSymbol && metric['Week' + i] !== null) {
        if (metric.YellowTargetSymbol !== 'Yes' && metric.YellowTargetSymbol !== 'No') {
          if (eval(metric['Week' + i] + metric.YellowTargetSymbol + metric.YellowMinimum)) {
            color = 'yellow';
          }
        }
        else if ((metric.YellowTargetSymbol == 'Yes' || metric.YellowTargetSymbol == 'No') && Number(metric['Week' + i]) == Number(metric.YellowMinimum)) {
          color = 'yellow';
        }
      }
    }
    if (metric['Week' + i] !== null && metric.RedMinimum !== null) {
      if (!metric.RedTargetSymbol &&
        (
          (Number(metric['Week' + i]) >= Number(metric.RedMinimum) && Number(metric['Week' + i]) <= Number(metric.RedMaximum)) ||
          (Number(metric['Week' + i]) <= Number(metric.RedMinimum) && Number(metric['Week' + i]) >= Number(metric.RedMaximum))
        )) {
        color = '#ff0505';
      } else if (metric.RedTargetSymbol && metric['Week' + i] !== null) {
        if (metric.RedTargetSymbol !== 'Yes' && metric.RedTargetSymbol !== 'No') {
          if (eval(metric['Week' + i] + metric.RedTargetSymbol + metric.RedMinimum)) {
            color = '#ff0505';
          }
        }
        else if ((metric.RedTargetSymbol == 'Yes' || metric.RedTargetSymbol == 'No') && Number(metric['Week' + i]) == Number(metric.RedMinimum)) {
          color = '#ff0505';
        }
      }
    }
    return { "background-color": color, "font-weight": "bold" };
    //}
  }

  public convetToPDF() {
    //this.showLogo = true;
    let DATA = document.getElementById('print-section');
    DATA.style.display = "block";
    DATA.style.paddingTop = "20px";
    document.body.style['zoom'] = '60%';
    html2canvas(DATA)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'landscape',
        });
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('ScoreCard.pdf');
        this.showLogo = false;
      });
    document.body.style['zoom'] = '100%';
  }

  printScoreCard(printSection): void {
    document.body.style['zoom'] = '50%';
    window.print();
    document.body.style['zoom'] = '100%';
  }

}
