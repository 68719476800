import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DorseyService } from '../dorsey.service';
import { LocalStorageService } from 'ngx-webstorage';
import { EmployeeLoginLevel } from '../common/models/login-level.model';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  providers: [DorseyService]
})
export class ProcessComponent implements OnInit {
  employeeLevelLogin = EmployeeLoginLevel;
  status = false;
  processData: any;
  granualProcess = [];
  teamData: any;
  saveFilter = [];
  procData: any;
  employeeDetails: any;
  Permissons: any;
  CompanyID: string;
  ProcessID: string;
  ProcessName: string;
  DepartmentID: string;
  EmployeeID: number;
  ProcessDescription: string;
  TeamID: number;
  condition = false;
  headerText: string;
  searchtext: string;
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;
  processPaging = false;
  isDisable = false;
  disableSearch = false;

  CurrentUser: string;

  MenuPermissions: any;
  menuData: any;
  menuID: string;
  menuName: string;
  empID: string;
  sequenceList = [];
  squence = null;

  loginCompanyName: any;
  lastSequence: number;
  TeamName: any;
  UserID: any;

  constructor(public dialog: MatDialog, public dorseyService: DorseyService, private storage: LocalStorageService) {
    this.empID = this.storage.retrieve('EmployeeID');
    this.CompanyID = this.storage.retrieve('companyID');
    this.CurrentUser = this.storage.retrieve('user');
    this.menuData = this.storage.retrieve('menuData');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
    this.loginCompanyName = this.storage.retrieve('loginCompanyName');
    this.UserID = this.storage.retrieve('UserID');
    this.employeeDetails = this.storage.retrieve('employeeDetails');
    this.getTeam();
  }

  ngOnInit() {
    if (this.employeeDetails) {
      this.EmployeeID = this.employeeDetails.EmployeeID;
      this.TeamID = this.employeeDetails.TeamID;
    }
    else {
      this.EmployeeID = 0;
    }
    this.menuData = this.menuData.filter(x => x.MenuName.toString().toLowerCase() === 'process');
    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }
    this.getProcess();

  }
  openProcess(): void {
    this.TeamID = 0;
    this.squence = '';
    this.sequenceList = [];
    this.dialogCall('new');
  }
  getTeam(): void {
    this.dorseyService.GetTeam(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.teamData = subData;
      if (this.employeeDetails) {
        if (this.employeeDetails.IsEmployeeLoginLevel == this.employeeLevelLogin.Department) {
          this.teamData = this.teamData.filter(ent => ent.DepartmentID == this.employeeDetails.DepartmentID)
        }
        else if (this.employeeDetails.IsEmployeeLoginLevel == this.employeeLevelLogin.Team) {
          this.teamData = this.teamData.filter(ent => ent.DepartmentID == this.employeeDetails.DepartmentID && ent.TeamID == this.employeeDetails.TeamID)
          this.TeamName = this.teamData[0].TeamName;
        }
        else {
          this.teamData = subData;
        }
      }

    });
  }
  searchText(): void {
    this.processData = this.saveFilter;
    let tempProcess = this.processData.filter(
      (item) => {
        return (
          (item.ProcessName !== null && item.ProcessName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.CompanyName !== null && item.CompanyName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ProcessDescription !== null && item.ProcessDescription.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.TeamName !== null && item.TeamName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
        );
      }

    );

    this.processData = tempProcess;
    if (this.processData.length == 0) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }


    if (this.searchtext === '') {
      this.processData = this.saveFilter;
      this.rowCount = this.processData.length;
      this.isDisable = false;
      this.getProcessPagination(this.rowCount);
    }
    else {
      this.rowCount = tempProcess.length;
      this.getProcessPagination(this.rowCount);
      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }


  }
  getProcess(): void {

    this.processData = [];
    this.dorseyService.GetProcess(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.processData = subData;
      this.storage.store("procData", this.processData);
      this.rowCount = this.processData.length;
      if (this.processData.length === 0) {
        this.processPaging = false;
        this.isDisable = true;
        this.disableSearch = true;
      }
      else {

        this.processPaging = true;
        this.isDisable = false;
        for (let i = 0; i < this.processData.length; i++) {
          this.sequenceList.push(Number(this.processData[i].squence));
        }
        this.disableSearch = false;
        this.rowsOnPageSet = [];
        this.dynamicRows = 10;
        if (this.employeeDetails) {
          if (this.employeeDetails.IsEmployeeLoginLevel == this.employeeLevelLogin.Department) {
            this.processData = this.processData.filter(entity1 => this.teamData.find(entity2 => entity1.TeamID == entity2.TeamID));
          }
          else if (this.employeeDetails.IsEmployeeLoginLevel == this.employeeLevelLogin.Team) {
            this.processData = this.processData.filter(ent => ent.TeamID == this.employeeDetails.TeamID)
          }
          else {
            let data = this.processData.filter(x => x.CompanyID === this.CompanyID);
            this.processData = data;
          }

          if (!this.employeeDetails.PermissionJson) {
            this.rowCount = this.processData.length;
            this.saveFilter = JSON.parse(JSON.stringify(this.processData));
            this.getProcessPagination(this.rowCount)
            return;
          }
          this.Permissons = JSON.parse(this.employeeDetails.PermissionJson);
          this.Permissons = this.Permissons["Process"];
          if (this.Permissons != undefined) {
            let processKeys = Object.keys(this.Permissons);

            for (let i = 0; i < processKeys.length; i++) {
              let actData = this.processData.filter(x => x.ProcessID.toString() === processKeys[i].toString());

              if (actData.length > 0) {
                let permissonKeys = Object.keys(this.Permissons[processKeys[i]]);

                if (permissonKeys.indexOf('R') > -1)
                  actData[0]['R'] = this.Permissons[processKeys[i]]['R'];
                else
                  actData[0]['R'] = false;


                if (permissonKeys.indexOf('C') > -1)
                  actData[0]['C'] = this.Permissons[processKeys[i]]['C'];
                else
                  actData[0]['C'] = false;


                if (permissonKeys.indexOf('U') > -1)
                  actData[0]['U'] = this.Permissons[processKeys[i]]['U'];
                else
                  actData[0]['U'] = false;


                if (permissonKeys.indexOf('D') > -1)
                  actData[0]['D'] = this.Permissons[processKeys[i]]['D'];
                else
                  actData[0]['D'] = false;

                this.granualProcess.push(actData[0]);
              }
            }
            this.processData = this.granualProcess;
          }
        }
        else {
          let data = this.processData.filter(x => x.CompanyID === this.CompanyID);
          this.processData = data;
        }
        if (this.processData.length > 0) {
          this.rowCount = this.processData.length;
          this.processPaging = true;
          this.isDisable = false;
          this.disableSearch = false;
          this.saveFilter = JSON.parse(JSON.stringify(this.processData));

          this.getProcessPagination(this.rowCount)
        }
        else if (this.processData.length === 0) {
          this.processPaging = false;
          this.isDisable = true;
          this.disableSearch = true;
        }

      }
    });
  }

  getProcessPagination(RowCount): void {
    this.dynamicRows = 10;
    this.rowsOnPageSet = [];
    this.rowsOnPage = 10;
    this.rowCount = RowCount;
    while (this.dynamicRows < this.rowCount) {
      this.rowsOnPageSet.push(this.dynamicRows);
      this.dynamicRows = this.dynamicRows + 10;
    }
    if (!this.rowsOnPageSet.includes(this.rowCount)) {
      this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }



  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }


  exportAsXLSX(): void {
    let allData = JSON.parse(JSON.stringify(this.processData));

    for (let i = 0; i < allData.length; i++) {
      // allData[i].Company=allData[i].CompanyName;
      allData[i].Team = allData[i].TeamName;
      allData[i].Process = allData[i].ProcessName;
      allData[i].Description = allData[i].ProcessDescription;
      allData[i].Sequence = allData[i].squence;
      delete (allData[i].VersionID);
      delete (allData[i].TeamName);
      delete (allData[i].CompanyName);
      delete (allData[i].ProcessName);
      delete (allData[i].ProcessDescription);
      delete (allData[i].DepartmentName);
      delete (allData[i].EmployeeName);
      delete (allData[i].ProcessDescription);
      delete (allData[i].ProcessID);
      delete (allData[i].IsActive);
      delete (allData[i].CreatedDate);
      delete (allData[i].CreatedBy);
      delete (allData[i].squence);
      delete (allData[i].UpdatedDate);
      delete (allData[i].UpdatedBy);
      delete (allData[i].CompanyID);
      delete (allData[i].DepartmentID);
      delete (allData[i].EmployeeID);
      delete (allData[i].TeamID);
      delete (allData[i].R);
      delete (allData[i].C);
      delete (allData[i].U);
      delete (allData[i].D);

    }

    this.dorseyService.generateExcel(allData, this.loginCompanyName + '_process' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Process', "Company: " + this.loginCompanyName, '', "Process Report", copyRight, 'FFFFFF00', 'FF0000FF');
  }
  dialogCall(msg: string): void {

    const dialogRef = this.dialog.open(ProcessDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: msg + 'æææ' + false,
    });

    dialogRef.componentInstance.EmployeeID = this.EmployeeID;
    dialogRef.componentInstance.CurrentUser = this.CurrentUser;
    dialogRef.componentInstance.teamData = this.teamData;
    dialogRef.componentInstance.CompanyID = this.CompanyID;
    dialogRef.componentInstance.processData = this.processData;

    if (msg === 'new') {
      this.searchtext = '';
      this.getProcess();
      dialogRef.componentInstance.TeamID = this.TeamID;
    }

    if (msg === 'edit') {
      this.status = true;
      this.headerText = 'Edit Process';

      dialogRef.componentInstance.CompanyID = this.CompanyID;
      dialogRef.componentInstance.ProcessName = this.ProcessName;
      dialogRef.componentInstance.processID = this.ProcessID;
      dialogRef.componentInstance.TeamID = this.TeamID;
      dialogRef.componentInstance.EmployeeID = this.EmployeeID;
      dialogRef.componentInstance.TeamName = this.TeamName;
      dialogRef.componentInstance.DepartmentID = this.DepartmentID;
      dialogRef.componentInstance.ProcessDescription = this.ProcessDescription;
      dialogRef.componentInstance.squence = this.squence;
      dialogRef.componentInstance.sequenceList = this.sequenceList;
      dialogRef.componentInstance.procData = this.procData;
      dialogRef.componentInstance.oldTeamId = this.TeamID;

    }

    dialogRef.afterClosed().subscribe(result => {
      if (this.searchtext !== '' && this.searchtext !== undefined) {
        this.searchText();
      }
      else {
        this.getProcess();
      }

      if (result.toString() === 'true') {

        let statusMsg = '';
        if (msg === 'new') {
          statusMsg = 'Saved successfully';
        }
        else {
          statusMsg = 'Updated successfully';
        }

        this.getProcess();
        const dialogRefsub = this.dialog.open(ProcessDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: statusMsg + 'æææ' + false,
          //  width: '30%'
        });
        setTimeout(() => {
          this.getProcess();
        }, 500);
        dialogRefsub.afterClosed().subscribe(result1 => {
          this.dynamicRows = 10;
          if (this.searchtext !== '' && this.searchtext !== undefined) {
            this.searchText();
          }
          else {
            this.getProcess();
          }
        });
      }

    });
  }

  editProcess(process): void {
    this.CompanyID = process.CompanyID;
    this.ProcessID = process.ProcessID;
    this.ProcessName = process.ProcessName;
    this.DepartmentID = process.DepartmentID;
    this.EmployeeID = process.EmployeeID;
    this.ProcessDescription = process.ProcessDescription;
    this.squence = process.squence;
    this.TeamID = process.TeamID;

    this.procData = this.storage.retrieve("procData");
    for (let i = 0; i < this.procData.length; i++) {
      if (this.TeamID === this.procData[i].TeamID) {
        this.sequenceList.push(Number(this.procData[i].squence));
      }
    }
    this.dialogCall('edit');
  }

  equals(objOne, objTwo) {
    if (typeof objOne !== 'undefined' && typeof objTwo !== 'undefined') {
      return objOne.id === objTwo.id;
    }
  }




  ifMaxRow() {

    if (this.rowsOnPage == this.processData.length) {
      this.rowsOnPage = this.rowsOnPage - 1;
    }
  }

  deleteProcess(process): void {
    this.ProcessID = process.ProcessID;
    this.CompanyID = process.CompanyID;
    this.TeamID = process.TeamID;
    this.squence = process.squence;

    const dialogRef = this.dialog.open(ProcessDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'Are you sure you want to delete process: ' + process.ProcessName + '?æææ' + true,
      // width: '40%'
    });
    //   this.condition = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dorseyService.DeleteProcess(this.ProcessID, this.CompanyID, this.TeamID, this.squence).subscribe(data => {

          if (data.ok) {
            this.ifMaxRow();
            this.condition = true;
            this.dialogCall('Deleted successfully');

          }
          this.dynamicRows = 10;
          this.rowsOnPageSet = [];
          this.getProcess();
        });
      }
    });



  }


}

@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
  //  styleUrls: ['dialog.css'],
  providers: [DorseyService]
})
export class ProcessDialogComponent implements OnInit {
  CurrentUser: string;
  condition = false;
  dataSelf: string;
  headerText: string;

  TeamID: number;
  ProcessName: string;
  CompanyID: string;
  processID: string;
  ProcessDescription: string;
  DepartmentID: string;
  EmployeeID: number;
  TeamName: any;

  processData: any;
  teamData: any[];
  squence = null;
  sequenceList: number[] = [];
  conditionActivity = '';
  lastSequence: number;
  disableTeam = false;
  procData: any;
  oldTeamId: any;
  btnDisabled = false;
  constructor(
    public dialogRef: MatDialogRef<ProcessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dorseyService: DorseyService) {
    this.data = this.data.split('æææ');

    if (this.data[1] === 'true') {
      this.condition = true;
    } else if (this.data[1] === 'false') {
      this.condition = false;
    }
    else {
      if (this.data[1] === '') {
        this.conditionActivity = 'process';
      } else {
        this.conditionActivity = 'process';
      }
    }
    this.data = this.data[0];
  }
  ngOnInit() {

    if (this.TeamID !== 0 && this.data === 'new') {
      this.sequenceList = [];
      if (this.processData.length > 0) {
        for (let i = 0; i < this.processData.length; i++) {
          if (this.processData[i].TeamID === this.TeamID) {
            this.sequenceList.push(Number(this.processData[i].squence));
          }
        }
      }
      let seq = this.sequenceList.length + 1;
      if (this.sequenceList.length !== 0) {
        this.sequenceList.push(seq);
      }
      else {
        this.sequenceList.push(1);
      }

      if (this.sequenceList.length !== 0) {
        this.squence = this.sequenceList[this.sequenceList.length - 1];
      }
      this.sequenceList.sort();
      this.sequenceList.sort(this.sortNumber);
    }
    if (this.TeamID !== 0 && this.conditionActivity === 'process') {
      this.sequenceList = [];
      if (this.processData.length > 0) {
        for (let i = 0; i < this.processData.length; i++) {
          if (this.processData[i].TeamID === this.TeamID) {

            this.sequenceList.push(Number(this.processData[i].squence));
          }
        }
      }

      let seq = this.sequenceList.length + 1;

      if (this.sequenceList.length !== 0) {

        this.sequenceList.push(seq);
      }
      else this.sequenceList.push(1);
      this.squence = this.sequenceList[this.sequenceList.length - 1]
        ;

      this.sequenceList.sort();
      this.sequenceList.sort(this.sortNumber);
    }

    if (!this.TeamID) {
      this.squence = '';
      this.sequenceList = [];
    }

    if (this.data === 'edit' || this.data === 'rearrange') {
      this.sequenceList = [];
      if (this.data === 'edit') {
        this.processData = this.procData;
      }
      for (let i = 0; i < this.processData.length; i++) {
        if (this.processData[i].TeamID === this.TeamID) {
          this.sequenceList.push(this.processData[i].squence);
        }
      }
    }

    this.sequenceList.sort();
    this.sequenceList.sort(this.sortNumber);
    if (this.data === 'edit') {
      if (this.TeamID !== 0) {
        this.disableTeam = true;
      }
    }

  }

  sortNumber(a, b) {
    return a - b;
  }

  getProcess() {
    this.dorseyService.GetProcess(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.processData = subData;

    });
  }
  addProcess(): void {
    this.btnDisabled = true;

    setTimeout(() => {
      this.btnDisabled = false;
    }, 1000);
    if (!this.ProcessName.trim()) {
      this.dataSelf = '';
      this.dataSelf = 'Please enter mandatory(*) fields';
      return;
    }
    this.saving(this.data);

  }
  teamChange(event) {
    this.TeamID = Number(event.value);
    this.sequenceList = [];
    this.squence = '';

    this.processData.sort(function (a, b) {
      return a.squence - b.squence;
    });
    if (this.processData.length > 0) {
      for (let i = 0; i < this.processData.length; i++) {
        if (this.processData[i].TeamID.toString() === this.TeamID.toString()) {
          this.sequenceList.push(Number(this.processData[i].squence));
        }
      }
    }

    if (this.TeamID != this.oldTeamId) {
      let seq = this.sequenceList.length + 1;

      if (this.sequenceList.length !== 0) {
        this.sequenceList.push(seq);
      }
      else this.sequenceList.push(1);
    }

    this.squence = this.sequenceList[this.sequenceList.length - 1];
    this.sequenceList.sort();
    this.sequenceList.sort(this.sortNumber);
    this.lastSequence = this.sequenceList.length;

  }
  onYesClick() {
    this.dialogRef.close(true);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  saving(msg: string): void {

    const json = {
      CompanyID: this.CompanyID,
      processName: this.ProcessName,
      createdby: this.CurrentUser,
      processID: this.processID,
      updatedby: this.CurrentUser,
      ProcessDescription: this.ProcessDescription,
      DepartmentID: "0",
      EmployeeID: this.EmployeeID,
      type: msg,
      TeamID: this.TeamID,
      squence: this.squence,
      lastSequence: this.lastSequence
    };

    if (msg === 'new') {
      this.dorseyService.SaveProcess(json, 'No').subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.dataSelf = 'Saved successfully';
          this.DepartmentID = '';
          this.processID = '';
          this.ProcessName = '';
          this.ProcessDescription = '';

          if (!this.TeamID) {
            this.squence = '';
            this.sequenceList = [];
          }
          else {
            this.squence = this.sequenceList[this.sequenceList.length - 1] + 1;
            this.sequenceList.push(this.squence);
          }
          this.getProcess();
        } else {
          this.dataSelf = 'Save failed';
        }

      });
    } else {
      this.dorseyService.SaveProcess(json, 'No').subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Updated successfully';
        }
      });
    }
  }
  clear(): void {
    this.dialogRef.close(false);
  }
}
