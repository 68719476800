import { Component, OnInit } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { LocalStorageService } from 'ngx-webstorage';
import { EmployeeLoginLevel } from '../common/models/login-level.model';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-frustration-reports',
  templateUrl: './frustration-reports.component.html',
  // styleUrls: ['./frustration-reports.component.scss']
})
export class FrustrationReportsComponent implements OnInit {
  copyRight: string = copyRight;
  FrustNoProcessData: any
  searchtext: string;
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  processPaging = false;
  saveFilter = [];
  CompanyID: string;
  isDisable = false;
  disableSearch = false;
  UserTeamID: string;
  teamData: any;
  TeamID: any;
  filterData = [];
  EmployeeID: string;
  pointerData: any;
  tempTeam = [];
  TeamName: string;
  companyName: string;
  headerDate = new Date();
  employeeData: any;
  copyDate: number;
  employeeDetails: any;
  constructor(public dorseyService: DorseyService, private storage: LocalStorageService) {
    this.EmployeeID = this.storage.retrieve('EmployeeID');
    this.companyName = this.storage.retrieve('LoginCompanyName');
    this.employeeDetails = this.storage.retrieve('employeeDetails');
    if (this.EmployeeID.toString() === "0") {

    }
    else {
      this.TeamID = this.storage.retrieve('TeamID');
    }
  }

  ngOnInit() {
    this.TeamID = this.storage.retrieve('TeamID');
    this.CompanyID = this.storage.retrieve('companyID');
    this.GetFrustNoProcess();
    this.GetTeam();
    this.copyDate = (new Date()).getFullYear();
    if (this.employeeDetails) {
      this.EmployeeID = this.employeeDetails.EmployeeID;
      this.TeamID = this.employeeDetails.TeamID;
    }
    else {
      this.EmployeeID = '0';
      this.TeamID = 0;
    }
  }

  GetSelectedReport(event) {
    this.searchtext = '';
    this.TeamID = event.value;
    let teamd = this.teamData.filter(x => x.TeamID === this.TeamID)
    if (teamd.length > 0) {
      this.TeamName = teamd[0].TeamName;
    }
    this.ConstructReport();
  }

  GetTeam() {

    this.TeamName = '';
    this.dorseyService.GetTeam(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);

      this.dorseyService.GetFrustNoProcess(this.CompanyID).subscribe(subDat1 => {
        subDat1 = JSON.parse(subDat1['_body']);
        this.FrustNoProcessData = subDat1;
        this.teamData = subData;

        if (this.employeeDetails) {
          if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) {
            this.teamData = this.teamData.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID);

          }
          else if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
            this.teamData = this.teamData.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID && ent.TeamID == this.employeeDetails.TeamID);
            this.TeamID = this.teamData[0].TeamID;
            this.TeamName = this.teamData[0].TeamName;
          }
        }
        if (this.TeamID == 0) {
          this.teamData = this.teamData.filter(entity1 => this.FrustNoProcessData.find(entity2 => entity1.TeamID == entity2.TeamID));
        }
        if (this.teamData.length > 0 && !this.TeamID) { this.teamData.splice(0, 0, { TeamID: 0, TeamName: 'Aggregate Report' }); }
        this.ConstructReport();
      });
    });
  }
  ConstructReport() {

    this.filterData = [];
    this.dorseyService.GetFrustNoProcess(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.FrustNoProcessData = subData;
      if (this.TeamID == 0)
        this.filterData = this.FrustNoProcessData.filter(entity1 => this.teamData.find(entity2 => entity1.TeamID == entity2.TeamID));
      else {
        this.filterData = this.FrustNoProcessData.filter(entity1 => entity1.TeamID == this.TeamID);
      }

      if (this.filterData.length === 0) {
        this.processPaging = false;
        this.isDisable = true;
        this.disableSearch = true;
      }
      else {
        this.processPaging = true;
        this.isDisable = false;
        this.disableSearch = false;
        this.dynamicRows = 10;
        this.rowsOnPageSet = [];
        this.saveFilter = JSON.parse(JSON.stringify(this.filterData));

        this.rowCount = this.filterData.length;
        while (this.dynamicRows < this.rowCount) {
          this.rowsOnPageSet.push(this.dynamicRows);
          this.dynamicRows = this.dynamicRows + 10;
        }
        if (!this.rowsOnPageSet.includes(this.rowCount))
          this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet.length > 0) {
          if (this.rowsOnPageSet[0] <= 10)
            this.rowsOnPage = this.rowsOnPageSet[0];
        }
      }
    });
  }
  GetFrustNoProcess() {

    this.dorseyService.GetFrustNoProcess(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.FrustNoProcessData = subData;

    });
  }

  Searchtext() {
    this.filterData = this.saveFilter;
    let tempFrustrationProcess = this.filterData.filter(
      (item) => {
        return (
          (item.ProcessName !== null && item.ProcessName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.CompanyName !== null && item.CompanyName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ProcessDescription !== null && item.ProcessDescription.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.TeamName !== null && item.TeamName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
        );
      }

    );
    this.filterData = tempFrustrationProcess;
    if (this.filterData.length == 0) {
      // this.GetActivity();
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      //this.GetFrustNoProcess();
      this.filterData = this.saveFilter;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      this.rowCount = this.filterData.length;
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount))
        this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet) {

        this.rowsOnPage = this.rowsOnPageSet[0];
      }
      this.isDisable = false;
    } else {

      this.rowCount = tempFrustrationProcess.length;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount)) {
        this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }

      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }


  exportAsXLSX() {
    let allData = JSON.parse(JSON.stringify(this.filterData));

    for (let i = 0; i < allData.length; i++) {
      allData[i][" No. "] = i + 1;
      allData[i].Company = allData[i].CompanyName;
      allData[i].Team = allData[i].TeamName;
      allData[i].Process = allData[i].ProcessName;
      allData[i].Description = allData[i].ProcessDescription;

      delete (allData[i].ProcessID);
      delete (allData[i].CompanyName);
      delete (allData[i].ProcessName);
      delete (allData[i].ActivityName);
      delete (allData[i].ProcessDescription);
      delete (allData[i].TeamName);
      delete (allData[i].TeamID);


    }
    let name: any;
    if (this.TeamName) {
      name = this.TeamName;
    }
    else {
      name = "Aggregate Report";
    }


    this.dorseyService.generateExcel(allData, this.companyName + '_Process with no frustrations' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Process with no frustrations', "Company: " + this.companyName, "Team: " + name, "Process with no frustrations Report", copyRight, 'FFFFFF00', 'FF0000FF');
  }

  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }


}
