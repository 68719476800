import { Component, OnInit, Inject } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as c3 from 'c3';
import * as d3 from 'd3';
import { LocalStorageService } from 'ngx-webstorage';
import htmlToImage from 'html-to-image';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-report-project',
  templateUrl: './report-project.component.html',
  // styleUrls: ['./report-project.component.scss'],
  providers: [DorseyService],
  inputs: ['dashboardProject', 'dashboardPro']
})
export class ReportProjectComponent implements OnInit {
  copyRight: string = copyRight;
  pointerData: any;
  projectData: any;
  projectStatusData: any;

  frustData = [];
  frust = [];
  view = {};
  dataSource: Object;
  filterProjectData = [];
  searchtext: string = '';
  issueData: any;
  issueList = [];
  finalList = [];
  categories = [];
  totalCount = 0;
  dashboardProject = true;
  dashboardPro = true;
  warningMsg = '';
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  activityPaging = false;
  CompanyID: string;
  saveFilter: any;
  isDisable = false;
  projectbyStatus: any;
  wrng: string;
  disableSearch = false;
  UserTeamID: string;
  companyName: string;
  headerDate = new Date();
  copyDate: number;
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;

  TeamName: string;
  EmployeeID: string;
  constructor(private dorseyService: DorseyService, private dialog: MatDialog, private storage: LocalStorageService) {
    this.UserTeamID = this.storage.retrieve('TeamID');
    this.companyName = sessionStorage.getItem('loginCompanyName');
    this.EmployeeID = this.storage.retrieve('EmployeeID');
  }

  ngOnInit() {
    this.CompanyID = this.storage.retrieve('companyID');
    this.GetPointer();
    this.GetProjects();
    this.GetProjectStatus();
    this.copyDate = (new Date()).getFullYear();
  }
  GetPointer() {
    this.finalList = [];
    this.GetProjects();
    this.dorseyService.GetPointer(this.CompanyID).subscribe(pointer => {
      this.pointerData = JSON.parse(pointer['_body']);
      if (this.pointerData.length > 0) {
        this.issueData = this.pointerData.filter(x => x.PointerTypeID === 1);
      }

      if (this.projectData) {
        for (let i = 0; i < this.projectData.length; i++) {
          if (this.issueData != undefined) {
            let data = this.issueData.filter(x => x.ProjectID != null && x.ProjectID === this.projectData[i].ProjectID);
            if (data.length > 0) {
              this.finalList.push({
                'id': this.projectData[i].ProjectID,
                'name': this.projectData[i].ProjectName,
                'data': data,
                'status': this.projectData[i].ProjectStatusName,
                'team': this.projectData[i].TeamName,
                'owner': this.projectData[i].ProjectOwner
              });
            } else {
              this.finalList.push({
                'id': this.projectData[i].ProjectID,
                'name': this.projectData[i].ProjectName,
                'data': data,
                'status': this.projectData[i].ProjectStatusName,
                'team': this.projectData[i].TeamName,
                'owner': this.projectData[i].ProjectOwner
              });
            }
          }
          else {
            this.finalList.push({
              'id': this.projectData[i].ProjectID,
              'name': this.projectData[i].ProjectName,
              'status': this.projectData[i].ProjectStatusName,
              'team': this.projectData[i].TeamName,
              'owner': this.projectData[i].ProjectOwner
            });
          }
        }
      }
      this.rowCount = this.finalList.length;
      if (this.finalList.length === 0) {
        this.activityPaging = false;
      }
      else {
        this.saveFilter = JSON.parse(JSON.stringify(this.finalList));
        this.activityPaging = true;

        while (this.dynamicRows < this.rowCount) {
          this.rowsOnPageSet.push(this.dynamicRows);
          this.dynamicRows = this.dynamicRows + 10;
        }
        if (!this.rowsOnPageSet.includes(this.rowCount)) this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet.length == 1) {
          if (this.rowsOnPageSet[0] <= 10)
            this.rowsOnPage = this.rowsOnPageSet[0];
        }
      }
    });
  }

  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }
  sortByValue(jsObj) {
    var items = Object.keys(jsObj).map(function (key) {
      return [key, jsObj[key]];
    });

    // Sort the array based on the second element
    items.sort(function (first, second) {
      return second[1] - first[1];
    });
    return items;
  }
  GetProjects() {
    this.dorseyService.GetProjects(this.CompanyID).subscribe(project => {
      this.projectData = JSON.parse(project['_body']);

    });
  }
  GetProjectStatus() {

    this.dorseyService.GetProjectStatus().subscribe(status => {
      this.projectStatusData = JSON.parse(status['_body']);
      this.dorseyService.GetProjects(this.CompanyID).subscribe(project => {
        this.projectData = JSON.parse(project['_body']);
        this.totalCount = this.projectData.length;
        var values = [];
        var perito = [];
        var sum = 0;

        if (this.projectData.length) {
          for (let i = 0; i < this.projectStatusData.length; i++) {

            let data = this.projectData.filter(x => x.ProjectStatusID === this.projectStatusData[i].ProjectStatusID);
            if (data.length > 0) {
              this.frustData.push(data);
              this.view['CategoryName'] = this.projectStatusData[i].ProjectStatusName;
              this.categories[this.projectStatusData[i].ProjectStatusName] = data.length;
              sum = sum + data.length;
              this.frust.push({
                'data': data,
                'label': this.projectStatusData[i].ProjectStatusName,
                'value': data.length
              });
              this.frust.sort(function (obj1, obj2) {

                return obj2.value - obj1.value;
              });
            }
          }
        } else {
          this.warningMsg = 'No data available';
          return;
        }
        if (this.finalList.length === 0) {
          this.isDisable = false;
          this.disableSearch = false;
        }
        else {
          this.isDisable = true;
          this.disableSearch = true;
        }
        let sortOrder = this.sortByValue(this.categories);

        let perVal = 0;
        this.categories = [];
        for (let v = 0; v < sortOrder.length; v++) {
          values.push(sortOrder[v][1]);
          this.categories.push(sortOrder[v][0])
          perVal = perVal + sortOrder[v][1];
          perito.push((perVal / sum));

        }
        var obj = {
          'Projects Count': values,
          'Cumulative percentage': perito
        };
        let chart = c3.generate({
          bindto: '#chart',
          data: {
            json: obj,
            axes: {

              'Cumulative percentage': 'y2'
            },
            type: 'bar',
            types: {

              'Cumulative percentage': 'line',

            }, groups: [
              ['Projects Count', 'Cumulative percentage']
            ]
          },

          title: {
            text: "Project Status",
            padding: {
              top: 20,
              right: 20,
              bottom: 40,
              left: 50
            }
          },
          color: {
            pattern: ['#4aabe7', '#d55632']
          },

          legend: {
            show: true,
            position: "right"

          },
          axis: {
            x: {
              type: 'category',

              categories: this.categories,
              tick: {
                rotate: 50,
                multiline: false
              },
              height: 100
            },
            y: {

              label: 'Projects Count',
              tick: {
                format: d3.format('d'),
                values: d3.ticks(0, sortOrder[0][1], sortOrder[0][1])
              }
            },
            y2: {
              show: true,
              label: 'Cumulative percentage',
              max: 1,
              min: 0,
              padding: { top: 3, bottom: 0 },
              tick: {
                format: d3.format(',.0%')
              }

            },

          }

        });
      });

    });

  }

  Searchtext() {
    this.projectbyStatus = [];
    this.projectbyStatus = this.saveFilter;
    let tempprojectbystatus = this.projectbyStatus.filter(
      (item) => {
        return (
          (item.team != null && item.team.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.name != null && item.name.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.status != null && item.status.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.owner != null && item.owner.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.data != null && (item.data.filter(
            (da) => {
              return (
                da.ProcessName != null && da.ProcessName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1
                || da.ActivityName != null && da.ActivityName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1
                || da.StatusName != null && da.StatusName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1
                || da.Description != null && da.Description.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1
                || da.Subject != null && da.Subject.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1
                || da.PointerID != null && da.PointerID.toString().indexOf(this.searchtext) > -1
              )

            }
          )
          )
          ).length
        )
      }
    );

    this.finalList = tempprojectbystatus;
    if (this.finalList.length == 0) {
      setTimeout(() => {
        this.wrng = "No data available";
      }, 100);
      this.isDisable = false;
    } else {
      this.isDisable = true;
    }
    if (this.searchtext === '') {
      this.projectbyStatus = this.saveFilter;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      this.rowCount = this.projectbyStatus.length;
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount))
        this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet) {

        this.rowsOnPage = this.rowsOnPageSet[0];
      }
      this.isDisable = true;
    } else {
      this.rowCount = tempprojectbystatus.length;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount)) {
        this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }

      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }

  exportAsXLSX(): void {
    let allData = JSON.parse(JSON.stringify(this.finalList));
    for (let i = 0; i < allData.length; i++) {
      delete (allData[i].id);
      allData[i][" No. "] = i + 1;
      allData[i]['Project Team'] = allData[i].team;
      allData[i].Project = allData[i].name;
      allData[i]['Project Status'] = allData[i].status;
      allData[i]['Project Owner'] = allData[i].owner;
      delete allData[i].status;
      delete allData[i].team;
      delete allData[i].owner;
      delete allData[i].name;
      let teamName = '';
      let processName = '';
      let IssueStatus = '';
      let subject = '';
      let Description = '';
      let PointerID = '';
      if (allData[i].data) {
        let Data = allData[i].data;
        for (let i = 0; i < Data.length; i++) {
          teamName = teamName + Data[i].TeamName + '\n';
          processName = processName + Data[i].ProcessName + '\n';
          IssueStatus = IssueStatus + Data[i].StatusName + '\n';
          subject = subject + Data[i].Subject + '\n';
          Description = Description + Data[i].Description + '\n';
        }
        allData[i]['Frustration Team'] = teamName;
        allData[i].Process = processName;
        allData[i].Frustration = subject;
        allData[i].Description = Description;
        allData[i].Status = IssueStatus;
        delete allData[i].data;
        delete allData[i].IssueID;
      }

    }
    let projectTypeCountData = JSON.parse(JSON.stringify(this.frust));
    for (let i = 0; i < projectTypeCountData.length; i++) {
      projectTypeCountData[i].label = this.frust[i].label;
      projectTypeCountData[i].value = this.frust[i].value;
      delete (projectTypeCountData[i].data);
    }
    let Cumulative = document.getElementById('chart').querySelector('svg').querySelectorAll('.c3-target-Cumulative-percentage');
    let Cumulativecircle = document.getElementById('chart').querySelector('svg').querySelectorAll('.c3-circle');

    let circle = Array.from(Cumulativecircle);
    circle.forEach(function (element) {
      element.setAttribute("style", "fill:#e75632")
    })

    let cumulativegraph = Array.from(Cumulative)
    cumulativegraph.forEach(function (element) {
      element.setAttribute("style", "fill:none")
    })
    let image = '';
    htmlToImage.toPng(document.getElementById('chart'),)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        image = dataUrl;
      });
    let team: any;
    team = '';

    setTimeout(() => {
      this.dorseyService.generateReportExcel(projectTypeCountData, allData, image, this.companyName + '_Project report by status' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Project report by status', "Company: " + this.companyName, team, "Project report by status Report", copyRight, 'FFFFFF00', 'FF0000FF');
    }, 2000);
  }

  getData(data) {
    const dialogRef = this.dialog.open(ReportProjectDialogComponent, {
      width: '750px',
      data: { data, data1: this.finalList }
    });
    dialogRef.componentInstance.final = this.finalList;
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.html',
  // styleUrls: ['dialog.css']
})
export class ReportProjectDialogComponent implements OnInit {

  data: any;
  final: any;
  data1 = [];
  dataMismatch = [];
  constructor(
    public dialogRef: MatDialogRef<ReportProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) { }

  ngOnInit() {
    this.data = this.dialogData.data;
    for (let j = 0; j < this.data.length; j++) {
      if (this.final.length > 0) {
        let dataCompare = this.final.filter(x => x.id === this.data[j].ProjectID);
        if (dataCompare.length > 0) {
          this.data1.push({
            'status': dataCompare[0].status,
            'name': dataCompare[0].name,
            'data': dataCompare[0].data
          });
        }
        else {
          this.data1.push({
            'status': this.data[j].ProjectStatusName,
            'name': this.data[j].ProjectName,
          });
        }
      }
      else {
        this.data1.push({
          'status': this.data[j].ProjectStatusName,
          'name': this.data[j].ProjectName,

        });
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
