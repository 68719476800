import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GoalService } from '../services/goal.service';
import { DorseyService } from '../dorsey.service';
import { LocalStorageService } from 'ngx-webstorage';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.scss']
})
export class GoalsComponent implements OnInit {

  GoalsList: any;
  TempGoalsList: any;
  GoalName: string;
  GoalDescription: string;
  GoalID: any;
  isDisable = false;
  disableSearch = false;
  GoalPaging = false;
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  isDesc: boolean = false;
  searchtext: string;
  saveFilter = [];
  condition = false;
  currentUser: string;
  CompanyID: string;
  dataSelf: string;
  column = "";
  direction: number;
  arrow = true;
  menuData: any;
  menuID: string;
  menuName: string;
  editGoalName: string;
  empID: string;
  MenuPermissions: any;
  loginCompanyName: any;
  userTeamID: any;
  TeamName: string;
  teamData: any;
  userRoleAssignmentData: any;
  rolesData: any;
  UserID: any;
  EmpROleID: any;
  inUse = false;
  metricData: any;
  constructor(private dorseyService: DorseyService,
    public dialog: MatDialog,
    public storage: LocalStorageService,
    private goalService: GoalService) {
    this.currentUser = this.storage.retrieve('user');
    this.menuData = this.storage.retrieve('menuData');
    this.empID = this.storage.retrieve('EmployeeID');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
    this.loginCompanyName = this.storage.retrieve('loginCompanyName');
    this.userTeamID = this.storage.retrieve("TeamID");
    this.UserID = this.storage.retrieve('UserID');
    this.EmpROleID = this.storage.retrieve('EmpRoleID');
  }

  ngOnInit(): void {
    this.CompanyID = this.storage.retrieve('companyID');
    this.menuData = this.menuData.filter(x => x.MenuName.toString() === 'Goals');
    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }
    this.getGoals();
    this.getMetrics();
  }

  getMetrics(): void {
    this.dorseyService.GetPointer(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.metricData = subData;
      this.metricData = this.metricData.filter(x => x.PointerTypeID != null && x.PointerTypeID.toString() === "2");
    });
  }

  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }
  /*
  New Goal Modal open
  */
  NewGoal() {
    this.dialogCall('new');
  }
  /*
Retriving Company Goals
*/
  getGoals(): void {
    this.rowsOnPage = 10;
    this.goalService.getGoals(parseInt(this.CompanyID)).subscribe(resp => {
      this.GoalsList = resp.Data;
      this.TempGoalsList = this.GoalsList;
      this.rowCount = this.GoalsList.length;
      if (this.GoalsList.length === 0) {
        this.GoalPaging = false;
        this.isDisable = true;
        this.disableSearch = true;
      }
      else {
        this.saveFilter = this.GoalsList;
        this.GoalPaging = true;
        this.isDisable = false;
        this.disableSearch = false;
        this.GoalPagination(this.rowCount);
      }
    });


  }
  /*Set Pagination for goals */

  GoalPagination(RowCount) {
    this.dynamicRows = 10;
    this.rowsOnPageSet = [];
    this.rowsOnPage = 10;
    this.rowCount = RowCount;
    while (this.dynamicRows < this.rowCount) {
      this.rowsOnPageSet.push(this.dynamicRows);
      this.dynamicRows = this.dynamicRows + 10;
    }
    if (!this.rowsOnPageSet.includes(this.rowCount)) {
      this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }
  /*
  Search Goals data
  */

  searchText() {
    this.GoalsList = this.saveFilter;
    let tempListGoals = this.GoalsList.filter(
      (item) => {
        return (
          (item.GoalName !== null && item.GoalName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.GoalDescription !== null && item.GoalDescription.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)

        );
      }
    );
    this.GoalsList = tempListGoals;
    if (this.GoalsList.length == 0) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.GoalsList = this.saveFilter;
      this.rowCount = this.GoalsList.length;
      this.isDisable = false;
      this.GoalPagination(this.rowCount);
    }
    else {
      this.rowCount = tempListGoals.length;
      this.GoalPagination(this.rowCount);
      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)

          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }

  /*
  Dialog open
  */
  dialogCall(msg: string) {
    const dialogRef = this.dialog.open(GoalDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: msg + 'æææ' + false,

    });
    dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.componentInstance.GoalsList = this.GoalsList;
    dialogRef.componentInstance.CompanyID = this.CompanyID;
    if (msg === 'new') {
      this.searchtext = '';
      this.GoalPagination(this.TempGoalsList.length);
      this.GoalsList = this.TempGoalsList;
    }
    if (msg === 'edit') {
      dialogRef.componentInstance.GoalName = this.GoalName;
      dialogRef.componentInstance.GoalDescription = this.GoalDescription;
      dialogRef.componentInstance.GoalID = this.GoalID;
      dialogRef.componentInstance.editGoalName = this.editGoalName;
    }

    dialogRef.afterClosed().subscribe(result => {
      if (this.searchtext !== '' && this.searchtext !== undefined) {
        this.searchText();
      }
      else {
        this.getGoals();
      }
      if (result.toString() === 'true') {
        let statusMsg = '';
        if (msg === 'new') {
          statusMsg = 'Saved successfully';
        }
        else {
          statusMsg = 'Updated successfully';
        }
        this.getGoals();
        const dialogRefsub = this.dialog.open(GoalDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: statusMsg + 'æææ' + false,
          //  width: '30%'
        });

        setTimeout(() => {
          this.getGoals();
        }, 500);
        dialogRefsub.afterClosed().subscribe(result1 => {
          this.dynamicRows = 10;
          if (this.searchtext !== '') {
            this.searchText();
          }
          else {
            this.getGoals();
          }
        });
      }
    });
  }

  /*
  Edit goal
  */
  EditGoal(goalRow) {
    this.GoalID = goalRow.GoalID;
    this.GoalName = goalRow.GoalName;
    this.GoalDescription = goalRow.GoalDescription;
    this.editGoalName = goalRow.GoalName;
    this.CompanyID = goalRow.CompanyID;
    this.dialogCall('edit');
  }

  ifMaxRow() {
    if (this.rowsOnPage == this.GoalsList.length) {
      this.rowsOnPage = this.rowsOnPage - 1;
    }
  }

  /*
  Delete Goal
  */
  DeleteGoal(GoalRow: any) {
    this.GoalID = GoalRow.GoalID;
    const dialogRef = this.dialog.open(GoalDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'Are you sure you want to delete goal: ' + GoalRow.GoalName + '?æææ' + true,

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.metricData && !this.metricData.find(ent => ent.GoalID == this.GoalID)) {
          this.goalService.deleteGoal(this.GoalID).subscribe(resp => {
            if (resp.Data == true) {
              this.ifMaxRow();
              this.condition = true;
              this.dialogCall('Deleted successfully');
              this.getGoals();
            }
          });
        } else {
          this.dialogCall("Delete Failed. This Goal is associated with Metrics");
        }
      }
    });
  }

  exportAsXLSX() {
    let allData = JSON.parse(JSON.stringify(this.GoalsList));
    for (let i = 0; i < allData.length; i++) {
      allData[i]["Goal Name"] = allData[i].GoalName;
      allData[i]["Description"] = allData[i].GoalDescription;
      delete (allData[i].GoalID);
      delete (allData[i].GoalName);
      delete (allData[i].GoalDescription);
      delete (allData[i].CreatedDate);
      delete (allData[i].CreatedBy);
      delete (allData[i].UpdatedDate);
      delete (allData[i].UpdatedBy);
      delete (allData[i].IsActive);
      delete (allData[i].CompanyID);
    }


    this.dorseyService.generateExcel(allData, this.loginCompanyName + '_goals' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Goals', "Company: " + this.loginCompanyName, '', "Goals Report", copyRight, 'FFFFFF00', 'FF0000FF');

  }
}

@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
})
export class GoalDialogComponent implements OnInit {
  GoalDescription: string;
  condition = false;
  dataSelf: string;
  GoalName: string;
  GoalID: string;
  GoalsList: any = [];
  CompanyID: string;
  currentUser: string;

  editGoalName: string;
  constructor(
    public dialogRef: MatDialogRef<GoalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private goalService: GoalService, private storage: LocalStorageService) {
    this.data = this.data.split('æææ');
    if (this.data[1] === 'true') {
      this.condition = true;
    } else {
      this.condition = false;
    }
    this.data = this.data[0];
  }

  ngOnInit(): void {
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ValidateGoal(): void {
    this.dataSelf = '';
    if (!this.GoalName.trim()) {
      this.dataSelf = 'Please enter mandatory(*) fields';
      return;
    }
    this.saveGoal(this.data);
  }

  saveGoal(msg: string): void {
    const entity = {
      GoalName: this.GoalName,
      CreatedBy: this.currentUser,
      GoalID: this.GoalID,
      CompanyID: this.CompanyID,
      UpdatedBy: this.currentUser,
      GoalDescription: this.GoalDescription
    };
    if (msg === 'new') {
      if (this.GoalsList.find(ent => ent.GoalName.toLowerCase().trim() === this.GoalName.toLowerCase().trim())) {
        this.dataSelf = "Goal name already exists";
        return;
      }
      this.goalService.saveGoal(entity).subscribe(resp => {
        if (resp.Data === true) {
          this.dataSelf = 'Saved successfully';
          this.GoalName = '';
          this.GoalDescription = '';
          setTimeout(() => {
            this.dataSelf = '';
          }, 2000);
        } else {
          this.dataSelf = 'Save failed';
        }
      });
    }
    else {
      if (this.editGoalName != this.GoalName)
        if (this.GoalsList.find(ent => ent.GoalName.toLowerCase().trim() === this.GoalName.toLowerCase().trim())) {
          this.dataSelf = "Goal name already exists";
          return;
        }
      this.goalService.saveGoal(entity).subscribe(resp => {
        if (resp.Data === true) {
          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Updated successfully';
        }
      });
    }
  }

  Clear(): void {
    this.dialogRef.close(false);
  }
}
