import { Component, OnInit, Inject } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStorageService } from 'ngx-webstorage';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
})
export class RolesComponent implements OnInit {
  userRoleAssign: any;
  userData: any;
  pageName = "";
  index = 0;
  roleData: any;
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;
  processPaging = true;
  saveFilter = [];
  tempRoleData: any;
  Roles = [];
  status = false;
  headerText: string;
  RoleName: any;
  RoleDescription: any;
  searchtext = '';
  CompanyID: string;
  roleID: string;
  rowID: string;
  PermissonJosn: any;
  granualPermissions = {};
  isDisable = false;
  disableSearch = false;
  condition = false;
  CurrentUser: string;
  MenuJson: any;
  MenuPermissions: any;
  menuData: any;
  menuID: string;
  menuName: string;
  menuRoleID: string;
  gruanualEdit: any;
  emproleID: any;
  compRoleID: any;
  activities: any;
  processes: any;
  ReadOnlyRoleID: any;
  userTeamID: any;
  loginCompanyName: any;
  TeamAdminRoleID: any;
  TeamMemberRoleID: any;
  employeeData: any;
  companyRole: any;
  empID: any;
  TeamName: string;
  teamData: any;
  userRoleAssignmentData: any;
  rolesData: any;
  UserID: any;
  isScoreCardEnable: boolean = false;
  constructor(private dorsey: DorseyService, private dialog: MatDialog, private storage: LocalStorageService) {
    this.CurrentUser = this.storage.retrieve('user');
    this.menuData = this.storage.retrieve('menuData');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
    this.menuRoleID = this.storage.retrieve('RoleID');
    this.emproleID = this.storage.retrieve('EmpRoleID');
    this.compRoleID = localStorage.getItem('companyRoleID');
    this.CompanyID = this.storage.retrieve('companyID');
    this.userTeamID = this.storage.retrieve('TeamID');
    this.empID = this.storage.retrieve('EmployeeID');

    this.ReadOnlyRoleID = this.storage.retrieve('ReadOnlyRoleID');
    this.TeamAdminRoleID = this.storage.retrieve('TeamAdminRoleID');
    this.TeamMemberRoleID = this.storage.retrieve('TeamMemberRoleID');
    this.isScoreCardEnable = this.storage.retrieve('isScoreCardEnable');
    this.UserID = this.storage.retrieve('UserID');

    this.loginCompanyName = this.storage.retrieve('loginCompanyName');

  }

  ngOnInit() {

    this.GetEmployee();
    this.GetProcess();
    this.GetActivities();
    this.GetUserROleAssignment();
    this.getTeam();
    this.GetUserRoleAssignment();

    this.menuData = this.menuData.filter(x => x.MenuName.toString().toLowerCase() === 'roles');

    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }
    this.dorsey.GetRoles(this.CompanyID).subscribe(roles => {
      this.Roles = JSON.parse(roles['_body']);
    });
    this.GetRoles();
  }

  CreateRole(type: string) {
    this.dialogCall(type);
  }


  GetProcess() {
    this.dorsey.GetSelectedRolesData(this.CompanyID, "0", 'KeyProcess').subscribe(process => {
      this.processes = JSON.parse(process['_body']);
    });
  }

  GetUserROleAssignment() {
    this.dorsey.GetUserRoleAssignment().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.userRoleAssign = subData;
    });
  }
  GetActivities() {
    this.dorsey.GetSelectedRolesActivityData(this.CompanyID, "0", 'Activities').subscribe(activity => {
      this.activities = JSON.parse(activity['_body']);
    });
  }


  dialogCall(msg: string) {
    const dialogRef = this.dialog.open(RolePermissionsDiaglogComponent, {
      panelClass: 'custom-dialog-container',
      data: msg + 'æææ' + false,
      // width: '50%',
    });


    dialogRef.componentInstance.tempRoleData = this.tempRoleData;
    dialogRef.componentInstance.roleData = this.roleData;
    dialogRef.componentInstance.CompanyID = this.CompanyID;
    dialogRef.componentInstance.CurrentUser = this.CurrentUser;

    dialogRef.componentInstance.menuRoleID = this.menuRoleID;
    dialogRef.componentInstance.rowID = this.rowID;
    dialogRef.componentInstance.editedPerissions = this.PermissonJosn;
    dialogRef.componentInstance.MenuJson = this.MenuJson;
    dialogRef.componentInstance.processes = this.processes;
    dialogRef.componentInstance.activities = this.activities;
    dialogRef.componentInstance.roleID = this.roleID;
    dialogRef.componentInstance.isScoreCardEnable = this.isScoreCardEnable;

    if (msg === 'edit') {
      this.status = true;
      this.headerText = 'Edit Role';

      dialogRef.componentInstance.granualEdit = this.gruanualEdit;
      dialogRef.componentInstance.RoleName = this.RoleName;
      dialogRef.componentInstance.RoleDescription = this.RoleDescription;
      dialogRef.componentInstance.roleID = this.roleID;
    }

    if (msg === 'new') {
      this.searchtext = '';
      this.GetRoles();
    }


    dialogRef.afterClosed().subscribe(result => {

      if (this.searchtext !== '' && this.searchtext !== undefined) {
        this.Searchtext();
      }
      else {
        this.GetRoles();
      }

      if (result && result.toString() === 'true') {
        let statusMsg = '';
        if (msg === 'new') {

          statusMsg = 'Saved successfully';
        } else {

          statusMsg = 'Updated successfully';
        }

        this.GetRoles();
        const dialogsRefsub = this.dialog.open(RolePermissionsDiaglogComponent, {
          panelClass: 'custom-dialog-container',
          data: statusMsg + 'æææ' + false,
          //  width: '30%'
        });

        setTimeout(() => {
          this.GetRoles();
        }, 500);
        dialogsRefsub.afterClosed().subscribe(result1 => {
          this.ifMaxRowAdd();
          this.dynamicRows = 10;
          // this.rowsOnPageSet=[];

          if (this.searchtext !== '') {
            this.Searchtext();
          }
          else {
            this.GetRoles();
          }
        });
      }
    });
  }
  Searchtext() {
    this.roleData = this.saveFilter;
    let teamRoles = this.roleData.filter(
      (item) => {
        return (
          (item.RoleName !== null && item.RoleName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
          || (item.RoleDescription !== null && item.RoleDescription.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1));
      }

    );
    this.roleData = teamRoles;

    if (this.roleData.length == 0) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.roleData = this.saveFilter;
      this.rowCount = this.roleData.length;
      this.isDisable = false;
      this.getRolePagination(this.rowCount);
    }
    else {
      this.rowCount = teamRoles.length;
      this.getRolePagination(this.rowCount);
      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }

  }

  getRolePagination(RowCount): void {
    this.dynamicRows = 10;
    this.rowsOnPageSet = [];
    this.rowsOnPage = 10;
    this.rowCount = RowCount;
    while (this.dynamicRows < this.rowCount) {
      this.rowsOnPageSet.push(this.dynamicRows);
      this.dynamicRows = this.dynamicRows + 10;
    }
    if (!this.rowsOnPageSet.includes(this.rowCount)) {
      this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }
  GetUserRoleAssignment() {
    this.dorsey.GetUserRoleAssignment().subscribe(userRoles => {
      this.userRoleAssignmentData = JSON.parse(userRoles['_body']);
      this.dorsey.GetRoles(this.CompanyID).subscribe(users => {
        this.rolesData = JSON.parse(users['_body']);
        if (this.empID.toString() !== '0') {
          let roles = this.userRoleAssignmentData.filter(x => x.UserID === this.UserID);
          if (roles.length > 0) {
            this.emproleID = roles[0].RoleID;
            let roleData = this.rolesData.filter(x => x.RoleID === this.emproleID);
            if (roleData.length > 0) {
              this.MenuPermissions = JSON.parse(roleData[0].MenuJson);
            }

          }
        }
      });
    });
  }
  GetEmployee() {
    this.dorsey.GetEmployee(this.CompanyID).subscribe(subData => {

      subData = JSON.parse(subData['_body']);
      this.employeeData = subData;

      if (this.empID.toString() !== '0') {
        let filterEmp = this.employeeData.filter(x => x.EmployeeID === this.empID);
        if (filterEmp.length > 0) {
          this.userTeamID = filterEmp[0].TeamID;
        }
      }
    });
  }
  exportAsXLSX() {
    let allData = JSON.parse(JSON.stringify(this.roleData));
    for (let i = 0; i < allData.length; i++) {
      allData[i].Role = allData[i].RoleName;
      allData[i].Description = allData[i].RoleDescription;
      delete (allData[i].CompanyID);
      delete (allData[i].RowID);
      delete (allData[i].RoleID);
      delete (allData[i].UpdatedBy);
      delete (allData[i].UpdatedDate);
      delete (allData[i].CreatedBy);
      delete (allData[i].CreatedDate);
      delete (allData[i].IsActive);
      delete (allData[i].MenuJson);
      delete (allData[i].PermissionJson);
      delete (allData[i].RoleName);
      delete (allData[i].RoleDescription);
    }


    this.dorsey.generateExcel(allData, this.loginCompanyName + '_roles' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Roles', "Company: " + this.loginCompanyName, '', 'Roles Report', copyRight, 'FFFFFF00', 'FF0000FF');
  }
  getTeam() {
    this.TeamName = '';
    this.dorsey.GetTeam(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.teamData = subData;
      let tempData = this.teamData.filter(x => x.TeamID === this.userTeamID);
      if (tempData.length > 0) {
        this.TeamName = tempData[0].TeamName;
      }
    });
  }

  GetRoles() {
    this.roleData = [];
    this.dorsey.GetRoles(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.dorsey.GetUsersList().subscribe(users => {
        this.userData = JSON.parse(users['_body']);
        this.roleData = subData;
        this.tempRoleData = subData;
        this.dynamicRows = 10;
        this.rowsOnPageSet = [];
        if (this.roleData.length === 0) {
          this.processPaging = false;
          this.isDisable = true;
          this.disableSearch = true;
        }
        else {
          if (this.userTeamID !== "0" && this.userTeamID !== null && this.CompanyID.toString() !== "0") {
            let data = this.userData.filter(x => x.CompanyID === this.CompanyID);
            this.companyRole = data;
            if (data.length > 0) {
              let assignROle = this.userRoleAssign.filter(x => x.UserID === data[0].UserID);
              if (assignROle.length > 0) {
                let roles = this.roleData.filter(x => x.RoleID != assignROle[0].RoleID);
                this.roleData = roles;
                let filterROle = this.roleData.filter(x => x.RoleName === "Team Admin" && x.RoleID === this.emproleID);
                if (filterROle.length > 0) {
                }
                else {
                  let adminRole = this.roleData.filter(x => x.RoleID !== this.TeamAdminRoleID);
                  this.roleData = adminRole;
                  let memberRole = adminRole.filter(x => x.RoleName === "Team Member" && x.RoleID === this.emproleID)
                  if (memberRole.length > 0) {
                  }
                  else {
                    let emproles = adminRole.filter(x => x.RoleID === this.emproleID);
                    if (emproles.length > 0) {
                      this.roleData = emproles
                    }
                    else {
                      this.roleData = adminRole;
                    }
                  }
                }

              }
            }
            this.rowCount = this.roleData.length;
            this.saveFilter = JSON.parse(JSON.stringify(this.roleData));
            this.processPaging = true;
            this.isDisable = false;
            this.disableSearch = false;

            this.getRolePagination(this.rowCount);

          }
          else {
            this.rowCount = this.roleData.length;
            this.saveFilter = JSON.parse(JSON.stringify(this.roleData));
            this.processPaging = true;
            this.isDisable = false;
            this.disableSearch = false;
            this.getRolePagination(this.rowCount);

          }
        }
      });
    });
  }

  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  EditRole(element) {
    this.RoleName = element.RoleName;
    this.RoleDescription = element.RoleDescription;
    this.roleID = element.RoleID;
    this.MenuJson = element.PermissionJson;
    this.gruanualEdit = element.PermissionJson;
    this.rowID = element.RowID;
    this.PermissonJosn = element.MenuJson;
    this.dialogCall('edit');
  }

  ifMaxRowDelete() {
    if (this.rowsOnPage == this.roleData.length) {
      this.rowsOnPage = this.rowsOnPage - 1;
    }
  }

  ifMaxRowAdd() {
    if (this.rowsOnPage == this.roleData.length) {
      this.rowsOnPage = this.rowsOnPage + 1;
    }
  }

  DeleteRole(element) {
    this.rowID = element.RowID;
    const dialogRef = this.dialog.open(RolePermissionsDiaglogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'Are you sure you want to delete role: ' + element.RoleName + '?æææ' + true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let issueCount = 0
        for (let i = 0; i < this.employeeData.length; i++) {
          if (element.RoleID == this.employeeData[i].RoleID) {
            issueCount++;
          }
        }
        if (!issueCount) {
          this.dorsey.DeleteRole(this.rowID).subscribe(data => {
            if (data.ok) {
              this.ifMaxRowDelete();
              this.condition = true;
              this.dialogCall('Deleted successfully');
            }
            this.dynamicRows = 10;
            this.rowsOnPageSet = [];
            this.GetRoles();

          });
        } else {
          this.dialogCall("This role is in use");
        }
      }
    });
  }
}

@Component({
  selector: 'app-role-dialog',
  templateUrl: './dialog.html',
  styleUrls: ['./dialog.scss']
})

export class RolePermissionsDiaglogComponent implements OnInit {
  tempRoleData: any;
  CurrentUser: string;
  dataSelf: string;
  condition = false;
  teams = [];
  processes = [];
  activities = [];
  MainJson = {};
  MainJosnIds = {};
  teamCheckList = [];
  processCheckList = [];
  activityCheckList = [];
  mapCheck = false;
  showGranual = false;
  roleData: any;
  RoleName: any;
  RoleDescription: any;
  CompanyID: string;
  permissionsList = ['R', 'C', 'U', 'D'];
  teamList = [];
  processList = [];
  activityList = [];
  mapList = [];
  checkedList: any;
  checkedList2 = [];
  checkedList3: any;
  perDict = [];
  checkedList4 = [];
  PermissonJosn = {};

  granual = {};
  editedPerissions: any;
  MenuJson: any;
  MapPermission = {};
  TeamPermisson = {};
  ProcessPermissons = {};
  ActivityPermissions = {};
  menuPermissions = {};
  memuKeys = {};
  MapKeys = {};
  TeamKeys = {};
  ProcessKeys = {};
  activityKeys = {};
  menuRoleID: string;
  companyMenuKeys = { R: false, C: false, U: false, D: false };
  teamMenuKeys = { R: false, C: false, U: false, D: false };
  EmployeeMenuKeys = { R: false, C: false, U: false, D: false };
  ProcessMapMenuKeys = { R: false, C: false, U: false, D: false };
  processMenuKeys = { R: false, C: false, U: false, D: false };
  activityMenuKeys = { R: false, C: false, U: false, D: false };
  metricMenuKeys = { R: false, C: false, U: false, D: false };
  frustrationMenuKeys = { R: false, C: false, U: false, D: false };
  projectsMenuKeys = { R: false, C: false, U: false, D: false };
  casesMenuKeys = { R: false, C: false, U: false, D: false };
  ReportsMenuKeys = { R: false, C: false, U: false, D: false };
  rolesMenuKeys = { R: false, C: false, U: false, D: false };
  frustrationsTypeMenuKeys = { R: false, C: false, U: false, D: false };
  alertTypeMenukeys = { R: false, C: false, U: false, D: false };
  metricTableMenuKeys = { R: false, C: false, U: false, D: false };
  userManualMenuKeys = { R: false, C: false, U: false, D: false };

  departmentMenuKeys = { R: false, C: false, U: false, D: false };
  goalMenuKeys = { R: false, C: false, U: false, D: false };
  scoreCardMenuKeys = { R: false, C: false, U: false, D: false };
  quarterMenuKeys = { R: false, C: false, U: false, D: false };

  mapGranualKeys = { R: false, C: false, U: false, D: false };
  processGranualkeys = { R: false, C: false, U: false, D: false };
  activitiesGranualKeys = { R: false, C: false, U: false, D: false };
  teamGranualKeys = { R: false, C: false, U: false, D: false };

  companyMenuPermissions = {};
  teamMenuPermissions = {};
  EmployeeMenuPermissions = {};
  ProcessMapMenuPermissions = {};
  processMenuPermissions = {};
  activityMenuPermissions = {};
  metricMenuPermissions = {};
  frustrationMenuPermissions = {};
  projectsMenuPermissions = {};
  casesMenuPermissions = {};
  ReportsMenuPermissions = {};
  rolesMenuPermissions = {};
  frustrationsTypeMenuPermissions = {};
  alertTypeMenyPermissions = {};
  metricTableMenuPermissions = {};
  userManualTableMenuPermissions = {};
  departmentTableMenuPermissions = {};
  goalTableMenuPermissions = {};
  scoreCardTableMenuPermissions = {};
  quarterTableMenuPermissions = {};
  granualEdit: any;
  granualPermissions = {};
  parentGranuls = ['Map', 'Team', 'Activity', 'Process']

  rowID = '';
  roleID = '';
  menuData = [];
  errorMessage: string;
  newPermissions = {};
  newGranualPermissions = {};
  checked = false;
  checkedCB = {};
  btnDisabled = false;
  isScoreCardEnable: boolean = false;
  constructor(public dialogRef: MatDialogRef<RolePermissionsDiaglogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dorseyService: DorseyService, private dialog: MatDialog) {
    //  dialogRef.disableClose=true;
    this.data = this.data.split('æææ');

    if (this.data[1] === 'true') {
      this.condition = true;
    } else {
      this.condition = false;
    }
    this.data = this.data[0];

  }

  ngOnInit() {
    this.MainJson = [];
    let savePermissions = {};

    if (this.menuRoleID === '' || this.menuRoleID === undefined || this.menuRoleID.toString() === '0') {
      let tempRoles = this.tempRoleData;
      this.tempRoleData = tempRoles.filter(x => x.CompanyID === this.CompanyID);
    }
    if (this.data == 'edit') {
      this.PermissonJosn = JSON.parse(this.editedPerissions);
      this.companyMenuKeys = this.PermissonJosn['Company']['101'];
      this.rolesMenuKeys = this.PermissonJosn['Roles']['102'];
      this.EmployeeMenuKeys = this.PermissonJosn['Employee']['103'];
      this.processMenuKeys = this.PermissonJosn['Process']['104'];
      this.teamMenuKeys = this.PermissonJosn['Team']['105'];
      this.ProcessMapMenuKeys = this.PermissonJosn['ProcessMap']['106'];
      this.activityMenuKeys = this.PermissonJosn['Activity']['107'];
      this.metricMenuKeys = this.PermissonJosn['Metric']['108'];
      this.frustrationMenuKeys = this.PermissonJosn['Frustration']['109'];
      this.projectsMenuKeys = this.PermissonJosn['Projects']['110'];
      this.casesMenuKeys = this.PermissonJosn['Frustrations']['111'];
      this.ReportsMenuKeys = this.PermissonJosn['Reports']['112'];
      this.frustrationsTypeMenuKeys = this.PermissonJosn['FrustrationsType']['113'];
      this.alertTypeMenukeys = this.PermissonJosn['Alerts']['114'];
      this.metricTableMenuKeys = this.PermissonJosn['Metric Table']['115'];
      this.departmentMenuKeys = this.PermissonJosn['Departments']['117'];
      this.goalMenuKeys = this.PermissonJosn['Goals']['118'];
      this.scoreCardMenuKeys = this.PermissonJosn['Score Card']['119'];
      this.quarterMenuKeys = this.PermissonJosn['Quarter']['120'];
      this.MenuJson = JSON.parse(this.granualEdit);
      savePermissions = this.PermissonJosn;
    }
    else {
      this.rowID = '';
      this.PermissonJosn = {};
      savePermissions = {};
      if (this.menuRoleID) {
        this.newPermissions = JSON.parse(this.tempRoleData[0].MenuJson);
        if (this.tempRoleData[0].PermissionJson === '') {
        }
        else {
          this.granualPermissions = JSON.parse(this.tempRoleData[0].PermissionJson);
        }
      }
      else {
        this.newPermissions = JSON.parse(this.tempRoleData[0].MenuJson);
        if (this.tempRoleData[0].PermissionJson === '') {
        }
        else {
          this.granualPermissions = JSON.parse(this.tempRoleData[0].PermissionJson);
        }
      }
      savePermissions = this.newPermissions;
      this.PermissonJosn = this.newPermissions;
      this.MenuJson = this.granualPermissions;
      let company = {};
      company['101'] = {
        'R': true,
        'C': true,
        'U': true,
        'D': true,
      }
      let menusList = Object.keys(this.newPermissions);
      for (let i = 0; i < menusList.length; i++) {
        let menuIds = Object.keys(this.newPermissions[menusList[i]]);
        let subLevel = this.newPermissions[menusList[i]];
        for (let j = 0; j < menuIds.length; j++) {
          this.newPermissions[menusList[i]][menuIds[j]]['R'] = false;
          this.newPermissions[menusList[i]][menuIds[j]]['C'] = false;
          this.newPermissions[menusList[i]][menuIds[j]]['U'] = false;
          this.newPermissions[menusList[i]][menuIds[j]]['D'] = false;
        }
      }
    }
    this.CreateJson(this.MenuJson);
    this.GetMenuMaster();
    if (this.data === 'new') {
      this.newPermissions['ProcessMap']['106']['R'] = true;
      this.newPermissions['Score Card']['119']['R'] = true;
    }
  }
  GetMenuMaster() {
    this.dorseyService.GetMenuMaster().subscribe(subData => {
      this.menuData = JSON.parse(subData['_body']);
      if (!this.isScoreCardEnable) {
        this.menuData = this.menuData.filter(ent => ent.MenuName != 'Score Card');

      }
    });
  }

  onYesClick() {
    this.dialogRef.close(true);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  CreateJson(savePermissions) {
    this.MainJson = {};
    this.teams = [];
    this.mapList = [];
    this.activityList = [];
    this.processList = [];
    this.MainJosnIds = [];
    this.teamList = [];
    this.dorseyService.GetTeam(this.CompanyID).subscribe(teams => {
      this.teams = JSON.parse(teams['_body']);

      if (this.rowID) {
        if (!(Object.keys(savePermissions).indexOf('Map') < -1)) {
        }
        if (savePermissions["Map"]) {
          let mapKeys = Object.keys(savePermissions["Map"]);
          for (let i = 0; i < mapKeys.length; i++) {
            {
              let perkeys = Object.keys(savePermissions["Map"][mapKeys[i]]);
              let perTemkeys = {};
              for (let j = 0; j < perkeys.length; j++) {
                perTemkeys[perkeys[j]] = savePermissions["Map"][mapKeys[i]][perkeys[j]];
              }
              this.mapList['Map'] = perTemkeys;
            }
          }
        }
      }

      let i = 0;
      let j = 0;
      if (this.teams.length === 0) {
        this.showGranual = false;
      }
      else {

        this.showGranual = true;
      }

      this.teams.forEach(cteam => {
        let subJson = {};
        let ListProcess = {};

        this.teamCheckList[cteam.TeamName + "æææ" + cteam.TeamID] = false;
        i++;

        this.processes.forEach(cprocess => {
          const ActivityList = [];
          if (cprocess.TeamID === cteam.TeamID) {
            this.processCheckList[cprocess.ProcessName + "æææ" + cprocess.ProcessID] = false;
            j++;
            {
              const activityIds = [];
              this.activities.forEach(cactivity => {
                if (cprocess.ProcessID === cactivity.ProcessID && cprocess.TeamID === cteam.TeamID && cactivity.TeamID === cteam.TeamID) {
                  ActivityList.push(cactivity.ActivityName + "æææ" + cactivity.ActivityID);
                  activityIds.push(cactivity.ActivityID);
                  if (this.rowID) {
                    if (savePermissions["Activity"]) {
                      let activityKeys = Object.keys(savePermissions["Activity"]);
                      for (let i = 0; i < activityKeys.length; i++) {
                        if (Number(cactivity.ActivityID) === Number(activityKeys[i])) {
                          let perkeys = Object.keys(savePermissions["Activity"][activityKeys[i]]);
                          let perTemkeys = {};
                          for (let j = 0; j < perkeys.length; j++) {
                            perTemkeys[perkeys[j]] = savePermissions["Activity"][activityKeys[i]][perkeys[j]];
                          }
                          this.activityList[cactivity.ActivityID] = perTemkeys;
                        } else {
                          let actvitykeysdummy = { R: false, C: false, D: false, U: false }
                          this.activityList[cactivity.ActivityID] = actvitykeysdummy;
                        }

                      }
                    }
                  }
                }
              });

              ListProcess[cprocess.ProcessID] = activityIds;

              if (this.rowID) {
                if (savePermissions['Process']) {
                  let processKeys = Object.keys(savePermissions["Process"]);
                  if (processKeys.indexOf(cprocess.ProcessID) > -1) {
                  }
                  else {
                    this.processList[cprocess.ProcessID] = [];
                  }
                  for (let i = 0; i < processKeys.length; i++) {
                    if (Number(cprocess.ProcessID) === Number(processKeys[i])) {
                      let perkeys = Object.keys(savePermissions["Process"][processKeys[i]]);
                      let perTemkeys = {};
                      for (let j = 0; j < perkeys.length; j++) {
                        perTemkeys[perkeys[j]] = savePermissions["Process"][processKeys[i]][perkeys[j]];
                      }
                      this.processList[cprocess.ProcessID] = perTemkeys;
                    }
                    else {
                      let processkeysdummy = { R: false, C: false, D: false, U: false }
                      this.processList[cprocess.ProcessID] = processkeysdummy;
                    }
                  }
                }
              }
            }
            subJson[cprocess.ProcessName + "æææ" + cprocess.ProcessID] = ActivityList;
          }
        });


        if (this.rowID) {
          if (!(Object.keys(savePermissions).indexOf('Team') < -1)) {
            //   this.showGranual=false;
            // return;
          }
          if (savePermissions["Team"]) {
            let temKeys = Object.keys(savePermissions["Team"]);


            for (let i = 0; i < temKeys.length; i++) {

              if (Number(cteam.TeamID) === Number(temKeys[i])) {
                let perkeys = Object.keys(savePermissions["Team"][temKeys[i]]);
                let perTemkeys = {};
                for (let j = 0; j < perkeys.length; j++) {

                  perTemkeys[perkeys[j]] = savePermissions["Team"][temKeys[i]][perkeys[j]];

                }

                this.teamList[cteam.TeamID] = perTemkeys;
              } else {
                let teamkeysdummy = { R: false, C: false, D: false, U: false }
                this.teamList[cteam.TeamID] = teamkeysdummy;
              }
            }
          }
        }
        this.MainJosnIds[cteam.TeamID] = ListProcess;
        this.MainJson[cteam.TeamName + "æææ" + cteam.TeamID] = subJson;
      });

    });
  }


  close(): void {
    this.dialogRef.close();
  }
  onCheckboxChange(MapType, key, permissonType, event, type) {
    if (MapType === 'Map') {
      this.mapGranualKeys[permissonType] = event.checked;
      this.MapPermission[key] = this.mapGranualKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {

        this.mapGranualKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.mapGranualKeys['C'] = false;
        this.mapGranualKeys['U'] = false;
        this.mapGranualKeys['D'] = false;
      }

      if (type == 'edit') {
        let prePermisn = {};
        prePermisn = this.MenuJson['Map']['ProcessMap'];
        prePermisn[permissonType] = event.checked;
        this.MenuJson['Map']['ProcessMap'] = prePermisn;
      } else {
        this.granual[MapType] = this.MapPermission;
      }

    }

    else if (MapType === 'Team') {
      this.teamGranualKeys[permissonType] = event.checked;
      this.TeamPermisson[key] = this.teamGranualKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {
        this.teamGranualKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.teamGranualKeys['C'] = false;
        this.teamGranualKeys['U'] = false;
        this.teamGranualKeys['D'] = false;
      }

      if (type == 'edit') {
        let prePermisn = {};
        let keysList = Object.keys(this.MenuJson['Team']);
        if (keysList.indexOf(key) > -1) {
          prePermisn = this.MenuJson['Team'][key];
        } else {
          prePermisn = { R: false, C: false, U: false, D: false };
          this.MenuJson['Team'][key] = prePermisn;
        }
        prePermisn[permissonType] = event.checked;
        this.MenuJson['Team'][key] = prePermisn;
      } else {
        this.granual[MapType] = this.TeamPermisson;
      }

    }

    else if (MapType === 'Process') {
      this.processGranualkeys[permissonType] = event.checked;
      this.ProcessPermissons[key] = this.processGranualkeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {

        this.processGranualkeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.processGranualkeys['C'] = false;
        this.processGranualkeys['U'] = false;
        this.processGranualkeys['D'] = false;
      }
      if (type == 'edit') {
        let prePermisn = {};
        let keysList = Object.keys(this.MenuJson['Process']);
        if (keysList.indexOf(key) > -1) {
          prePermisn = this.MenuJson['Process'][key];
        } else {
          prePermisn = { R: false, C: false, U: false, D: false };
          this.MenuJson['Process'][key] = prePermisn;
        }
        prePermisn[permissonType] = event.checked;
        this.MenuJson['Process'][key] = prePermisn;
      } else {

        this.granual[MapType] = this.ProcessPermissons;
      }

    }

    else if (MapType === 'Activity') {
      this.activitiesGranualKeys[permissonType] = event.checked;
      this.ActivityPermissions[key] = this.activitiesGranualKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {

        this.activitiesGranualKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.activitiesGranualKeys['C'] = false;
        this.activitiesGranualKeys['U'] = false;
        this.activitiesGranualKeys['D'] = false;
      }
      if (type == 'edit') {
        let prePermisn = {};
        let keysList = Object.keys(this.MenuJson['Activity']);
        if (keysList.indexOf(key) > -1) {
          prePermisn = this.MenuJson['Activity'][key];
        } else {
          prePermisn = { R: false, C: false, U: false, D: false };
          this.MenuJson['Activity'][key] = prePermisn;
        }
        prePermisn[permissonType] = event.checked;
        this.MenuJson['Activity'][key] = prePermisn;
      } else {

        this.granual[MapType] = this.ActivityPermissions;
      }

    }
  }

  onCheckboxMenuChange(MapType, key, permissonType, event, type) {
    if (permissonType == 'C') {
      this.checkedCB['C'] = false;
    }
    if (permissonType == 'U') {
      this.checkedCB['U'] = false;
    }
    if (permissonType == 'D') {
      this.checkedCB['D'] = false;
    }
    if (MapType === 'Company') {
      this.companyMenuKeys[permissonType] = event.checked;
      this.companyMenuPermissions[key] = this.companyMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {
        this.companyMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.companyMenuKeys['C'] = false;
        this.companyMenuKeys['U'] = false;
        this.companyMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['Company'] = this.companyMenuPermissions;
      } else {
        this.newPermissions[MapType] = this.companyMenuPermissions;
      }
    } else if (MapType === 'Team') {
      this.teamMenuKeys[permissonType] = event.checked;
      this.teamMenuPermissions[key] = this.teamMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {
        this.teamMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.teamMenuKeys['C'] = false;
        this.teamMenuKeys['U'] = false;
        this.teamMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['Team'] = this.teamMenuPermissions;
      } else {
        this.newPermissions[MapType] = this.teamMenuPermissions;
      }
    } else if (MapType === 'Employee') {
      this.EmployeeMenuKeys[permissonType] = event.checked;
      this.EmployeeMenuPermissions[key] = this.EmployeeMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {
        this.EmployeeMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.EmployeeMenuKeys['C'] = false;
        this.EmployeeMenuKeys['U'] = false;
        this.EmployeeMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['Employee'] = this.EmployeeMenuPermissions;
      } else {
        this.newPermissions[MapType] = this.EmployeeMenuPermissions;
      }
    } else if (MapType === 'ProcessMap') {
      this.ProcessMapMenuKeys[permissonType] = event.checked;
      this.ProcessMapMenuPermissions[key] = this.ProcessMapMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {
        this.ProcessMapMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.ProcessMapMenuKeys['C'] = false;
        this.ProcessMapMenuKeys['U'] = false;
        this.ProcessMapMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['ProcessMap'] = this.ProcessMapMenuPermissions;
      } else {
        this.newPermissions[MapType] = this.ProcessMapMenuPermissions;
      }
    } else if (MapType === 'Process') {
      this.processMenuKeys[permissonType] = event.checked;
      this.processMenuPermissions[key] = this.processMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {

        this.processMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.processMenuKeys['C'] = false;
        this.processMenuKeys['U'] = false;
        this.processMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['Process'] = this.processMenuPermissions;
      } else {
        this.newPermissions[MapType] = this.processMenuPermissions;
      }
    } else if (MapType === 'Activity') {
      this.activityMenuKeys[permissonType] = event.checked;
      this.activityMenuPermissions[key] = this.activityMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {
        this.activityMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.activityMenuKeys['C'] = false;
        this.activityMenuKeys['U'] = false;
        this.activityMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['Activity'] = this.activityMenuPermissions;
      } else {
        this.newPermissions[MapType] = this.activityMenuPermissions;
      }
    } else if (MapType === 'Metric') {
      this.metricMenuKeys[permissonType] = event.checked;
      this.metricMenuPermissions[key] = this.metricMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {
        this.metricMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.metricMenuKeys['C'] = false;
        this.metricMenuKeys['U'] = false;
        this.metricMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['Metric'] = this.metricMenuPermissions;
      } else {
        this.newPermissions[MapType] = this.metricMenuPermissions;
      }
    }
    else if (MapType === 'Frustration') {
      this.frustrationMenuKeys[permissonType] = event.checked;
      this.frustrationMenuPermissions[key] = this.frustrationMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {

        this.frustrationMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.frustrationMenuKeys['C'] = false;
        this.frustrationMenuKeys['U'] = false;
        this.frustrationMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['Frustration'] = this.frustrationMenuPermissions;
      } else {
        this.newPermissions[MapType] = this.frustrationMenuPermissions;
      }
    } else if (MapType === 'Projects') {
      this.projectsMenuKeys[permissonType] = event.checked;
      this.projectsMenuPermissions[key] = this.projectsMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {

        this.projectsMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.projectsMenuKeys['C'] = false;
        this.projectsMenuKeys['U'] = false;
        this.projectsMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }

      if (type == 'edit') {
        this.PermissonJosn['Projects'] = this.projectsMenuPermissions;
      } else {
        this.newPermissions[MapType] = this.projectsMenuPermissions;
      }
    } else if (MapType === 'Frustrations') {
      this.casesMenuKeys[permissonType] = event.checked;
      this.casesMenuPermissions[key] = this.casesMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {

        this.casesMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.casesMenuKeys['C'] = false;
        this.casesMenuKeys['U'] = false;
        this.casesMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['Frustrations'] = this.casesMenuPermissions;
      } else {
        this.newPermissions[MapType] = this.casesMenuPermissions;
      }
    }
    else if (MapType === 'Reports') {
      this.ReportsMenuKeys[permissonType] = event.checked;
      this.ReportsMenuPermissions[key] = this.ReportsMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {
        this.ReportsMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.ReportsMenuKeys['C'] = false;
        this.ReportsMenuKeys['U'] = false;
        this.ReportsMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['Reports'] = this.ReportsMenuPermissions;
      } else {
        this.newPermissions[MapType] = this.ReportsMenuPermissions;
      }
    } else if (MapType === 'Roles') {
      this.rolesMenuKeys[permissonType] = event.checked;
      this.rolesMenuPermissions[key] = this.rolesMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {
        this.rolesMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.rolesMenuKeys['C'] = false;
        this.rolesMenuKeys['U'] = false;
        this.rolesMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['Roles'] = this.rolesMenuPermissions;
      } else {
        this.newPermissions[MapType] = this.rolesMenuPermissions;
      }
    } else if (MapType === 'FrustrationsType') {
      this.frustrationsTypeMenuKeys[permissonType] = event.checked;
      this.frustrationsTypeMenuPermissions[key] = this.frustrationsTypeMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {
        this.frustrationsTypeMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.frustrationsTypeMenuKeys['C'] = false;
        this.frustrationsTypeMenuKeys['U'] = false;
        this.frustrationsTypeMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['FrustrationsType'] = this.frustrationsTypeMenuPermissions;
      } else {
        this.newPermissions[MapType] = this.frustrationsTypeMenuPermissions;
      }
    }
    else if (MapType === 'Alerts') {
      this.alertTypeMenukeys[permissonType] = event.checked;
      this.alertTypeMenyPermissions[key] = this.alertTypeMenukeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {

        this.alertTypeMenukeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.alertTypeMenukeys['C'] = false;
        this.alertTypeMenukeys['U'] = false;
        this.alertTypeMenukeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['Alerts'] = this.alertTypeMenyPermissions;
      } else {
        this.newPermissions[MapType] = this.alertTypeMenyPermissions;
      }
    }
    else if (MapType === 'Metric Table') {
      this.metricTableMenuKeys[permissonType] = event.checked;
      this.metricTableMenuPermissions[key] = this.metricTableMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {
        this.metricTableMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.metricTableMenuKeys['C'] = false;
        this.metricTableMenuKeys['U'] = false;
        this.metricTableMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['Metric Table'] = this.metricTableMenuPermissions;
      } else {
        this.newPermissions[MapType] = this.metricTableMenuPermissions;
      }
    }
    else if (MapType === 'Departments') {
      this.departmentMenuKeys[permissonType] = event.checked;
      this.departmentTableMenuPermissions[key] = this.departmentMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {

        this.departmentMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.departmentMenuKeys['C'] = false;
        this.departmentMenuKeys['U'] = false;
        this.departmentMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['Departments'] = this.departmentTableMenuPermissions;
      } else {
        this.newPermissions[MapType] = this.departmentTableMenuPermissions;
      }
    }
    else if (MapType === 'Goals') {
      this.goalMenuKeys[permissonType] = event.checked;
      this.goalTableMenuPermissions[key] = this.goalMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {

        this.goalMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.goalMenuKeys['C'] = false;
        this.goalMenuKeys['U'] = false;
        this.goalMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['Goals'] = this.goalTableMenuPermissions;
      } else {
        this.newPermissions[MapType] = this.goalTableMenuPermissions;
      }
    }
    else if (MapType === 'Score Card') {
      this.scoreCardMenuKeys[permissonType] = event.checked;
      this.scoreCardTableMenuPermissions[key] = this.scoreCardMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {

        this.scoreCardMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.scoreCardMenuKeys['C'] = false;
        this.scoreCardMenuKeys['U'] = false;
        this.scoreCardMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['Score Card'] = this.scoreCardTableMenuPermissions;
      } else {
        this.newPermissions[MapType] = this.scoreCardTableMenuPermissions;
      }
    }
    else if (MapType === 'Quarter') {
      this.quarterMenuKeys[permissonType] = event.checked;
      this.quarterTableMenuPermissions[key] = this.quarterMenuKeys;
      if (permissonType == 'C' || permissonType == 'U' || permissonType == 'D') {

        this.quarterMenuKeys['R'] = true;
      }
      if (permissonType == 'R' && !event.checked) {
        this.quarterMenuKeys['C'] = false;
        this.quarterMenuKeys['U'] = false;
        this.quarterMenuKeys['D'] = false;
        this.checkedCB['R'] = false;
        this.checkedCB['C'] = false;
        this.checkedCB['U'] = false;
        this.checkedCB['D'] = false;
      }
      if (type == 'edit') {
        this.PermissonJosn['Quarter'] = this.quarterTableMenuPermissions;
      } else {


        this.newPermissions[MapType] = this.quarterTableMenuPermissions;
      }
    }
  }
  onClick(list, data) {
    if (this.checkedCB['R'] === true) {
    }
    if (list == 'R') {
      for (let i = 0; i < this.menuData.length; i++) {
        if (this.menuData[i].MenuName != 'UserManual')
          // && (this.menuData[i].MenuName!=='ProcessMap' || list=='C' ) 
          if ((this.menuData[i].MenuName !== 'Company'
            && this.menuData[i].MenuName !== 'ProcessMap'
            && this.menuData[i].MenuName !== 'Score Card')
            && (this.menuData[i].MenuName !== 'Reports' || list == 'R')
            && (this.menuData[i].MenuName !== 'Cases' || list == 'R' || list == 'U')
            && (this.menuData[i].MenuName !== 'FrustrationsType' || list == 'R')
            && (this.menuData[i].MenuName !== 'Alerts' || list == 'R')
            && (this.menuData[i].MenuName !== 'Metric Table' || list == 'R')
            && (this.menuData[i].MenuName !== 'Departments' || list == 'R')
            && (this.menuData[i].MenuName !== 'Goal' || list == 'R')

            && (this.menuData[i].MenuName !== 'Quarter' || list == 'R')
          )
            if (data === 'edit' && this.checkedCB['R'] == true) {
              this.PermissonJosn[this.menuData[i].MenuName][this.menuData[i].MenuID]['R'] = true;
            } else if (data === 'new' && this.checkedCB['R'] == true) {

              this.newPermissions[this.menuData[i].MenuName][this.menuData[i].MenuID]['R'] = true;
            } else if (data === 'new' && this.checkedCB['R'] == false) {
              this.checkedCB['C'] = false;
              this.checkedCB['U'] = false;
              this.checkedCB['D'] = false;
              this.newPermissions[this.menuData[i].MenuName][this.menuData[i].MenuID]['R'] = false;
              this.newPermissions[this.menuData[i].MenuName][this.menuData[i].MenuID]['C'] = false;
              this.newPermissions[this.menuData[i].MenuName][this.menuData[i].MenuID]['U'] = false;
              this.newPermissions[this.menuData[i].MenuName][this.menuData[i].MenuID]['D'] = false;
            } else if (data === 'edit' && this.checkedCB['R'] == false) {
              this.checkedCB['C'] = false;
              this.checkedCB['U'] = false;
              this.checkedCB['D'] = false;
              this.PermissonJosn[this.menuData[i].MenuName][this.menuData[i].MenuID]['R'] = false;
              this.PermissonJosn[this.menuData[i].MenuName][this.menuData[i].MenuID]['C'] = false;
              this.PermissonJosn[this.menuData[i].MenuName][this.menuData[i].MenuID]['U'] = false;
              this.PermissonJosn[this.menuData[i].MenuName][this.menuData[i].MenuID]['D'] = false;
            }

      }
    }
    if (list === 'C') {
      for (let i = 0; i < this.menuData.length; i++) {
        if (this.menuData[i].MenuName != 'UserManual')
          if ((this.menuData[i].MenuName !== 'Company' ||
            this.CurrentUser == 'admin')

          ) {
            if (data === 'edit' && this.checkedCB['C'] == true) {
              this.checkedCB['R'] = true;
              if ((this.menuData[i].MenuName !== 'Company')
                && (this.menuData[i].MenuName !== 'Reports')
                && (this.menuData[i].MenuName !== 'Cases')
                && (this.menuData[i].MenuName !== 'FrustrationsType')
                && (this.menuData[i].MenuName !== 'Alerts')
                && (this.menuData[i].MenuName !== 'Metric Table')
                && (this.menuData[i].MenuName !== 'Goals')
                && (this.menuData[i].MenuName !== 'Quarter')) {
                this.PermissonJosn[this.menuData[i].MenuName][this.menuData[i].MenuID]['C'] = true;
              }
              this.PermissonJosn[this.menuData[i].MenuName][this.menuData[i].MenuID]['R'] = true;
            } else if (data === 'new' && this.checkedCB['C'] == true) {
              this.checkedCB['R'] = true;
              if ((this.menuData[i].MenuName !== 'Company')
                && (this.menuData[i].MenuName !== 'Reports')
                && (this.menuData[i].MenuName !== 'Cases')
                && (this.menuData[i].MenuName !== 'FrustrationsType')
                && (this.menuData[i].MenuName !== 'Alerts')
                && (this.menuData[i].MenuName !== 'Metric Table')
                && (this.menuData[i].MenuName !== 'Goals')
                && (this.menuData[i].MenuName !== 'Quarter')) {
                this.newPermissions[this.menuData[i].MenuName][this.menuData[i].MenuID]['C'] = true;
              }
              this.newPermissions[this.menuData[i].MenuName][this.menuData[i].MenuID]['R'] = true;
            } else if (data === 'new' && this.checkedCB['C'] == false) {
              this.checkedCB['R'] = true;
              if ((this.menuData[i].MenuName !== 'Company')
                && (this.menuData[i].MenuName !== 'Reports')
                && (this.menuData[i].MenuName !== 'Cases')
                && (this.menuData[i].MenuName !== 'FrustrationsType')
                && (this.menuData[i].MenuName !== 'Alerts')
                && (this.menuData[i].MenuName !== 'Metric Table')
                && (this.menuData[i].MenuName !== 'Goals')
                && (this.menuData[i].MenuName !== 'Quarter')) {
                this.newPermissions[this.menuData[i].MenuName][this.menuData[i].MenuID]['C'] = false;
              }
            } else if (data === 'edit' && this.checkedCB['C'] == false) {
              this.checkedCB['R'] = true;
              if ((this.menuData[i].MenuName !== 'Company')
                && (this.menuData[i].MenuName !== 'Reports')
                && (this.menuData[i].MenuName !== 'Cases')
                && (this.menuData[i].MenuName !== 'FrustrationsType')
                && (this.menuData[i].MenuName !== 'Alerts')
                && (this.menuData[i].MenuName !== 'Metric Table')
                && (this.menuData[i].MenuName !== 'Goals')
                && (this.menuData[i].MenuName !== 'Quarter')) {
                this.PermissonJosn[this.menuData[i].MenuName][this.menuData[i].MenuID]['C'] = false;
              }
            }

          }
      }
    }
    if (list === 'U') {
      for (let i = 0; i < this.menuData.length; i++) {
        // && (this.menuData[i].MenuName!=='ProcessMap' || list=='C' )
        if (this.menuData[i].MenuName != 'UserManual')
          if ((this.menuData[i].MenuName !== 'Company'
            || this.CurrentUser == 'admin'))
            if (data === 'edit' && this.checkedCB['U'] == true) {
              this.checkedCB['R'] = true;
              if ((this.menuData[i].MenuName !== 'Company')
                && (this.menuData[i].MenuName !== 'Reports')
                && (this.menuData[i].MenuName !== 'Cases')
                && (this.menuData[i].MenuName !== 'FrustrationsType')
                && (this.menuData[i].MenuName !== 'Alerts')
                && (this.menuData[i].MenuName !== 'Metric Table')
                && (this.menuData[i].MenuName !== 'Goals')
                && (this.menuData[i].MenuName !== 'Quarter')) {
                this.PermissonJosn[this.menuData[i].MenuName][this.menuData[i].MenuID]['U'] = true;
              }
              this.PermissonJosn[this.menuData[i].MenuName][this.menuData[i].MenuID]['R'] = true;
            } else if (data === 'new' && this.checkedCB['U'] == true) {
              this.checkedCB['R'] = true;
              if ((this.menuData[i].MenuName !== 'Company')
                && (this.menuData[i].MenuName !== 'Reports')
                && (this.menuData[i].MenuName !== 'Cases')
                && (this.menuData[i].MenuName !== 'FrustrationsType')
                && (this.menuData[i].MenuName !== 'Alerts')
                && (this.menuData[i].MenuName !== 'Metric Table')
                && (this.menuData[i].MenuName !== 'Goals')
                && (this.menuData[i].MenuName !== 'Quarter')) {
                this.newPermissions[this.menuData[i].MenuName][this.menuData[i].MenuID]['U'] = true;
              }
              this.newPermissions[this.menuData[i].MenuName][this.menuData[i].MenuID]['R'] = true;
            } else if (data === 'new' && this.checkedCB['U'] == false) {
              this.checkedCB['R'] = true;
              if ((this.menuData[i].MenuName !== 'Company')
                && (this.menuData[i].MenuName !== 'Reports')
                && (this.menuData[i].MenuName !== 'Cases')
                && (this.menuData[i].MenuName !== 'FrustrationsType')
                && (this.menuData[i].MenuName !== 'Alerts')
                && (this.menuData[i].MenuName !== 'Metric Table')
                && (this.menuData[i].MenuName !== 'Goals')
                && (this.menuData[i].MenuName !== 'Quarter')) {
                this.newPermissions[this.menuData[i].MenuName][this.menuData[i].MenuID]['U'] = false;
              }

            } else if (data === 'edit' && this.checkedCB['U'] == false) {
              this.checkedCB['R'] = true;
              if ((this.menuData[i].MenuName !== 'Company')
                && (this.menuData[i].MenuName !== 'Reports')
                && (this.menuData[i].MenuName !== 'Cases')
                && (this.menuData[i].MenuName !== 'FrustrationsType')
                && (this.menuData[i].MenuName !== 'Alerts')
                && (this.menuData[i].MenuName !== 'Metric Table')
                && (this.menuData[i].MenuName !== 'Goals')
                && (this.menuData[i].MenuName !== 'Quarter')) {
                this.PermissonJosn[this.menuData[i].MenuName][this.menuData[i].MenuID]['U'] = false;
              }

            }
      }
    }
    if (list === 'D') {
      for (let i = 0; i < this.menuData.length; i++) {
        if (this.menuData[i].MenuName != 'UserManual')
          // && (this.menuData[i].MenuName!=='ProcessMap' || list=='C' ) 
          if ((this.menuData[i].MenuName !== 'Company' || this.CurrentUser == 'admin'))

            if (data === 'edit' && this.checkedCB['D'] == true) {
              this.checkedCB['R'] = true;
              if ((this.menuData[i].MenuName !== 'Company')
                && (this.menuData[i].MenuName !== 'Reports')
                && (this.menuData[i].MenuName !== 'Cases')
                && (this.menuData[i].MenuName !== 'FrustrationsType')
                && (this.menuData[i].MenuName !== 'Alerts')
                && (this.menuData[i].MenuName !== 'Metric Table')
                && (this.menuData[i].MenuName !== 'Goals')
                && (this.menuData[i].MenuName !== 'Quarter')) {
                this.PermissonJosn[this.menuData[i].MenuName][this.menuData[i].MenuID]['D'] = true;
              }
              this.PermissonJosn[this.menuData[i].MenuName][this.menuData[i].MenuID]['R'] = true;
            } else if (data === 'new' && this.checkedCB['D'] == true) {
              this.checkedCB['R'] = true;
              if ((this.menuData[i].MenuName !== 'Company')
                && (this.menuData[i].MenuName !== 'Reports')
                && (this.menuData[i].MenuName !== 'Cases')
                && (this.menuData[i].MenuName !== 'FrustrationsType')
                && (this.menuData[i].MenuName !== 'Alerts')
                && (this.menuData[i].MenuName !== 'Metric Table')
                && (this.menuData[i].MenuName !== 'Goals')
                && (this.menuData[i].MenuName !== 'Quarter')) {
                this.newPermissions[this.menuData[i].MenuName][this.menuData[i].MenuID]['D'] = true;
              }
              this.newPermissions[this.menuData[i].MenuName][this.menuData[i].MenuID]['R'] = true;
            } else if (data === 'new' && this.checkedCB['D'] == false) {
              this.checkedCB['R'] = true;
              if ((this.menuData[i].MenuName !== 'Company')
                && (this.menuData[i].MenuName !== 'Reports')
                && (this.menuData[i].MenuName !== 'Cases')
                && (this.menuData[i].MenuName !== 'FrustrationsType')
                && (this.menuData[i].MenuName !== 'Alerts')
                && (this.menuData[i].MenuName !== 'Metric Table')
                && (this.menuData[i].MenuName !== 'Goals')
                && (this.menuData[i].MenuName !== 'Quarter')) {
                this.newPermissions[this.menuData[i].MenuName][this.menuData[i].MenuID]['D'] = false;
              }

            } else if (data === 'edit' && this.checkedCB['D'] == false) {
              this.checkedCB['R'] = true;
              if ((this.menuData[i].MenuName !== 'Company')
                && (this.menuData[i].MenuName !== 'Reports')
                && (this.menuData[i].MenuName !== 'Cases')
                && (this.menuData[i].MenuName !== 'FrustrationsType')
                && (this.menuData[i].MenuName !== 'Alerts')
                && (this.menuData[i].MenuName !== 'Metric Table')
                && (this.menuData[i].MenuName !== 'Goals')
                && (this.menuData[i].MenuName !== 'Quarter')) {
                this.PermissonJosn[this.menuData[i].MenuName][this.menuData[i].MenuID]['D'] = false;
              }

            }
      }
    }
  }
  AddRole() {
    this.btnDisabled = true;

    setTimeout(() => {
      this.btnDisabled = false;
    }, 1000);

    if (this.RoleName === undefined || this.RoleName.trim() === '') {
      this.errorMessage = "Please enter mandatory(*) fields";
      return;
    } else {
      let savePermissions = {};
      let granualPermission = {};
      let roleID = "";
      if (!this.rowID) {
        savePermissions = this.newPermissions;
        granualPermission = this.granual;
        roleID = "";
      } else {
        savePermissions = this.PermissonJosn;
        granualPermission = this.MenuJson;
        roleID = this.roleID
      }
      let josn = {
        RoleName: this.RoleName,
        RoleDescription: this.RoleDescription,
        RowID: this.rowID,
        Permissions: granualPermission,
        MenuJson: savePermissions,
        CreatedBy: this.CurrentUser,
        RoleId: roleID,
        CompanyID: this.CompanyID
      }
      this.dorseyService.SaveRole(josn).subscribe(permisn => {

        if (permisn['_body'].toString() === 'true') {

          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Updated successfully';
        }
      });
    }


  }

}


