import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { IResponsePackage } from "../models/response-package.model";

@Injectable({
    providedIn: 'root'
})
export class DepartmentService {
    httpUrl = environment.API_URL;
    constructor(private http: HttpClient) { }

    getDepartments(companyId: number): Observable<IResponsePackage> {
        return this.http.get<IResponsePackage>(`${this.httpUrl}Dorsey/GetDepartmentsByCompanyId?companyId=${companyId}`)
            .pipe(
                catchError(this.handleError)
            );
    }

    saveDepartment(entity: any): Observable<any> {
        return this.http.post(`${this.httpUrl}Dorsey/SaveDepartment`, entity).pipe(
            catchError(this.handleError)
        );
    }

    deleteDepartment(departmentId: number): Observable<any> {
        return this.http.get(`${this.httpUrl}Dorsey/DeleteDepartment?departmentId=${departmentId}`).pipe(
            catchError(this.handleError)
        );
    }

    getTeamsByDepartmentId(companyId: number, departmentId: number): Observable<IResponsePackage> {
        if (departmentId) {
            return this.http.get<IResponsePackage>(`${this.httpUrl}Dorsey/GetTeamsByDepartmentId?companyId=${companyId}&departmentId=${departmentId}`)
                .pipe(
                    catchError(this.handleError)
                );
        }
    }

    getUsersByCompanyId(companyId: number): Observable<IResponsePackage> {
        return this.http.get<IResponsePackage>(`${this.httpUrl}Dorsey/GetUsersByCompanyId?companyId=${companyId}`)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occured: ${err.error.message}`;
        }
        else {
            errorMessage = `Server returned code: ${err.status}, error message is ${err.message}`
        }
        return throwError(errorMessage);
    }

}
