import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { PerformanceScoreCard } from "../add-score-card/add-score-card.model";
import { IResponsePackage } from "../models/response-package.model";
import { ScoreCard } from "./performance-score-card.model";

@Injectable({
    providedIn: 'root'
})
export class PerformanceScoreCardService {

    httpUrl = environment.API_URL;
    public scoreCard: BehaviorSubject<ScoreCard> = new BehaviorSubject<ScoreCard>(null);

    constructor(private http: HttpClient) { }

    GetScoreCardMasterByDepartmentId(departmentId: number, companyId: number): Observable<IResponsePackage> {
        return this.http.get<IResponsePackage>(`${this.httpUrl}Dorsey/GetScoreCardMasterByDepartmentId?departmentId=${departmentId}&companyId=${companyId}`)
            .pipe(
                catchError(this.handleError)
            );
    }

    GetPerformanceScoreCardDetails(companyId: number, departmentId: number, teamId: number, scoreCardMasterId: number): Observable<IResponsePackage> {
        return this.http.get<IResponsePackage>(`${this.httpUrl}Dorsey/GetPerformanceScoreCardDetails?departmentId=${departmentId}&teamId=${teamId}&companyId=${companyId}&pscMasterId=${scoreCardMasterId}`)
            .pipe(
                catchError(this.handleError)
            );
    }

    SavePerformanceScoreCardDetails(entity: PerformanceScoreCard): Observable<any> {
        return this.http.post(`${this.httpUrl}Dorsey/SavePerformanceScoreCardDetails`, entity).pipe(
            catchError(this.handleError)
        );
    }

    GetPSCDetailIdsByPSCMasterId(scoreCardMasterId: number): Observable<IResponsePackage> {
        return this.http.get<IResponsePackage>(`${this.httpUrl}Dorsey/GetPSCDetailIdsByPSCMasterId?pscMasterId=${scoreCardMasterId}`)
            .pipe(
                catchError(this.handleError)
            );
    }

    DeleteScoreCardMaster(scoreCardID: number): Observable<any> {
        return this.http.get(`${this.httpUrl}Dorsey/DeleteScoreCardMaster?scoreCardID=${scoreCardID}`).pipe(
            catchError(this.handleError)
        );
    }
    CheckMetricExistInPSCDetails(pointerId: number): Observable<any> {
        return this.http.get(`${this.httpUrl}Dorsey/CheckMetricExistInPSCDetails?pointerId=${pointerId}`).pipe(
            catchError(this.handleError)
        );
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occured: ${err.error.message}`;
        }
        else {
            errorMessage = `Server returned code: ${err.status}, error message is ${err.message}`
        }
        return throwError(errorMessage);
    }

}
