import { Component, OnInit, Inject } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { LocalStorageService } from 'ngx-webstorage';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as c3 from 'c3';
import * as d3 from 'd3';
import htmlToImage from 'html-to-image';
import { EmployeeLoginLevel } from '../common/models/login-level.model';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-report-hipriority',
  templateUrl: './report-hipriority.component.html',
  // styleUrls: ['./report-hipriority.component.scss'],
  providers: [DorseyService],
  inputs: ['dashboardSeverity', 'dashboardSev', 'dashboardTeamSeverity']
})

export class ReportHipriorityComponent implements OnInit {
  copyRight: string = copyRight;
  employeeDetails: any;
  wrng: any;
  searchtext: string = '';
  severityData: any;
  pointerData: any;
  CompanyID: string;
  totalCount = 0;
  frustData = [];
  frust = [];
  view = {};
  categories = [];
  metricList = [];
  warningMsg = '';
  isDisable = false;
  disableSearch = false;
  p: number = 1;
  rowsOnPage = 5;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 5;
  activityPaging = false;
  saveFilter = [];
  dashboardSeverity = true;
  dashboardSev = true;
  metricbykeyprocess = [];
  UserTeamID: string;
  teamData: any;
  TeamID: any;
  EmployeeID: string;
  dashboardTeamSeverity: any;
  tempTeam = [];
  companyName: string;
  headerDate = new Date();
  TeamName: string;
  employeeData: any;
  copyDate: number;
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;

  constructor(private dorsey: DorseyService, private storage: LocalStorageService, private dialog: MatDialog) {
    this.CompanyID = this.storage.retrieve('companyID');
    this.EmployeeID = this.storage.retrieve('EmployeeID');
    this.companyName = this.storage.retrieve('LoginCompanyName');
    this.employeeDetails = this.storage.retrieve('employeeDetails');
    if (this.EmployeeID.toString() === "0") {

    }
    else {
      this.TeamID = this.storage.retrieve('TeamID');
    }
  }

  ngOnInit() {
    this.GetSeverity();
    this.GetTeam();
    if (this.employeeDetails) {
      this.EmployeeID = this.employeeDetails.EmployeeID;
      this.TeamID = this.employeeDetails.TeamID;
    }
    else {
      this.EmployeeID = '0';
      this.TeamID = 0;
    }
    this.copyDate = (new Date()).getFullYear();
  }

  GetSeverity() {
    this.dorsey.GetSeverity().subscribe(severity => {
      this.severityData = JSON.parse(severity['_body']);
    });
  }
  sortByValue(jsObj) {
    var items = Object.keys(jsObj).map(function (key) {
      return [key, jsObj[key]];
    });

    // Sort the array based on the second element
    items.sort(function (first, second) {
      return second[1] - first[1];
    });
    return items;
  }

  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  GetSelectedReport(event) {
    this.searchtext = '';
    this.TeamID = event.value;
    let teamd = this.teamData.filter(x => x.TeamID === this.TeamID)
    if (teamd.length > 0) {
      this.TeamName = teamd[0].TeamName;
    }
    this.ConstructReport();
  }

  GetTeam() {
    this.TeamName = '';
    this.dorsey.GetTeam(this.CompanyID).subscribe(team => {
      team = JSON.parse(team['_body']);
      this.teamData = team;
      this.dorsey.GetPointer(this.CompanyID).subscribe(frustrations => {
        frustrations = JSON.parse(frustrations['_body']);
        this.pointerData = frustrations;
        if (this.employeeDetails) {
          if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) {
            this.teamData = this.teamData.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID);

          }
          else if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
            this.teamData = this.teamData.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID && ent.TeamID == this.employeeDetails.TeamID);
            this.TeamID = this.teamData[0].TeamID;
          }
        }
        if (this.dashboardTeamSeverity != undefined) {
          this.TeamID = this.dashboardTeamSeverity;
        }
        else if (this.employeeDetails && this.employeeDetails.IsEmployeeLoginLevel === EmployeeLoginLevel.Team) {
          this.TeamID = this.TeamID;
        }
        else {
          this.TeamID = 0;
        }
        if (this.TeamID == 0) {
          this.teamData = this.teamData.filter(entity1 => this.pointerData.find(entity2 => entity1.TeamID == entity2.TeamID && entity2.PointerTypeID == 1));
        }
        if (this.teamData.length > 0 && !this.TeamID) {
          this.teamData.splice(0, 0, { TeamID: 0, TeamName: 'Aggregate Report' });
        }
        this.ConstructReport();

      });
    });
  }
  ConstructReport() {
    this.metricList = [];
    this.frustData = [];
    this.categories = [];
    this.frust = [];
    this.rowsOnPageSet = [];
    this.rowsOnPage = 5;
    this.dynamicRows = 5;
    this.warningMsg = '';
    var sum = 0;
    this.dorsey.GetPointer(this.CompanyID).subscribe(pointer => {
      this.pointerData = JSON.parse(pointer['_body']);
      if (this.TeamID == 0)
        this.pointerData = this.pointerData.filter(entity1 => this.teamData.find(entity2 => entity1.TeamID == entity2.TeamID) && entity1.PointerTypeID == 1);
      else {
        this.pointerData = this.pointerData.filter(entity1 => entity1.TeamID == this.TeamID && entity1.PointerTypeID == 1);
      }
      this.totalCount = this.pointerData.length;
      if (this.totalCount) {
        for (let i = 0; i < this.severityData.length; i++) {
          let data = this.pointerData.filter(x => x.SeverityID === this.severityData[i].SeverityID);
          if (data.length > 0) {
            this.frustData.push(data);
            this.view['CategoryName'] = this.severityData[i].SeverityName;
            this.categories[this.severityData[i].SeverityName] = data.length;
            for (let li = 0; li < data.length; li++) {
              this.metricList.push(data[li]);
            }
            sum = sum + data.length;
            this.frust.push({
              data: data,
              label: this.severityData[i].SeverityName,
              value: data.length
            });
            this.frust.sort(function (obj1, obj2) {
              return obj2.value - obj1.value;
            });
          }
        }
      }
      else {
        this.warningMsg = 'No data available';
        if (!this.metricList.length) {
          this.isDisable = false;
          this.disableSearch = false;
        }
        else {
          this.isDisable = true;
          this.disableSearch = true;
        }
        return;
      }
      if (this.metricList.length === 0) {
        this.isDisable = false;
        this.disableSearch = false;
        return;
      }
      else {
        this.isDisable = true;
        this.disableSearch = true;
      }
      let sortOrder = [];
      sortOrder = this.sortByValue(this.categories);

      let chart = c3.generate({
        bindto: '#chatSeverity',//

        data: {
          json: this.categories,
          type: 'pie',

        },
        color: {
          pattern: ['#9f3e23', '#d55632', '#f4954f', '#f4c04f']
        },
        pie: {
          label: {
            format: function (value, ratio, id) {
              return value;
            }
          }
        },
      });

      setTimeout(function () {
        chart.data.colors({ Critical: '#9f3e23', High: '#d55632', Medium: '#f4954f', Low: '#f4c04f' });
      }, 0);

      this.rowCount = this.metricList.length;
      let tempmetricList = this.metricList;
      if (this.metricList.length === 0) {
        this.activityPaging = false;
      }
      else {
        this.saveFilter = JSON.parse(JSON.stringify(this.metricList));
        this.activityPaging = true;
        while (this.dynamicRows < this.rowCount) {
          this.rowsOnPageSet.push(this.dynamicRows);
          this.dynamicRows = this.dynamicRows + 10;
        }
        if (!this.rowsOnPageSet.includes(this.rowCount)) this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet.length > 0) {
          if (this.rowsOnPageSet[0] <= 10)
            this.rowsOnPage = this.rowsOnPageSet[0];
        }
      }
    });
  }
  Searchtext() {
    this.metricbykeyprocess = this.saveFilter;
    let tempmetricbykeyprocess = this.metricbykeyprocess.filter(
      (item) => {
        return (
          (item.ProcessName !== null && item.ProcessName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ActivityName !== null && item.ActivityName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.FrustrationsTypeName !== null && item.FrustrationsTypeName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.Subject !== null && item.Subject.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.Description !== null && item.Description.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.StatusName !== null && item.StatusName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.SeverityName !== null && item.SeverityName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ProjectName !== null && item.ProjectName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.TeamName !== null && item.TeamName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1))
      }
    );
    this.metricList = tempmetricbykeyprocess;
    if (this.metricList.length == 0) {
      setTimeout(() => {
        this.wrng = "No data available";
      }, 100);
      this.isDisable = false;
      return;
    } else {
      this.isDisable = true;
    }
    if (this.searchtext === '') {
      this.metricbykeyprocess = this.saveFilter;
      this.dynamicRows = 5;
      this.rowsOnPageSet = [];
      this.rowCount = this.metricbykeyprocess.length;
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 5;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount))
        this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet) {
        this.rowsOnPage = this.rowsOnPageSet[0];
      }
      this.isDisable = true;
    } else {
      this.rowCount = tempmetricbykeyprocess.length;
      this.dynamicRows = 5;
      this.rowsOnPageSet = [];
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 5;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount)) {
        this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 5)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }
  exportAsXLSX(): void {
    let allData = JSON.parse(JSON.stringify(this.metricList));
    for (let i = 0; i < allData.length; i++) {
      allData[i][" No. "] = i + 1;
      allData[i].Team = this.metricList[i].TeamName;
      allData[i].Process = this.metricList[i].ProcessName;
      allData[i].Activity = this.metricList[i].ActivityName;
      allData[i][" Type "] = this.metricList[i].FrustrationsTypeName;
      allData[i][" Frustration "] = this.metricList[i].Subject;
      allData[i][" Description "] = this.metricList[i].Description;
      allData[i].Status = this.metricList[i].StatusName;
      allData[i].Severity = this.metricList[i].SeverityName;
      allData[i].Project = this.metricList[i].ProjectName;

      delete (allData[i].DepartmentName);
      delete (allData[i].EmployeeName);
      delete (allData[i].VersionID);
      delete (allData[i].GoalID);
      delete (allData[i].GoalName);

      delete (allData[i].GreenRange);
      delete (allData[i].RedRange);
      delete (allData[i].Source);
      delete (allData[i].YellowRange);
      delete (allData[i].ProcessName);
      delete (allData[i].ActivityName);
      delete (allData[i].TeamName);
      delete (allData[i].FrustrationsTypeName);
      delete (allData[i].Subject);
      delete (allData[i].Description);
      delete (allData[i].StatusName);
      delete (allData[i].SeverityName);
      delete (allData[i].ProjectName);
      delete (allData[i].PointerID);
      delete (allData[i].ActivityID);
      delete (allData[i].PointerTypeID);
      delete (allData[i].CompanyID);
      delete (allData[i].EmployeeID);
      delete (allData[i].UOM);
      delete (allData[i].Target);
      delete (allData[i].Type);
      delete (allData[i].StatusID);
      delete (allData[i].SeverityID);
      delete (allData[i].ProcessID);
      delete (allData[i].DepartmentID);
      delete (allData[i].TeamID);
      delete (allData[i].FrustrationsTypeID);
      delete (allData[i].Date);
      delete (allData[i].IsActive);
      delete (allData[i].CreatedDate);

      delete (allData[i].CreatedBy);
      delete (allData[i].UpdatedDate);
      delete (allData[i].UpdatedBy);
      delete (allData[i].ProjectID);
    }

    let allData1 = JSON.parse(JSON.stringify(this.frust));
    for (let i = 0; i < allData1.length; i++) {
      allData1[i].label = this.frust[i].label;
      allData1[i].value = this.frust[i].value;
      delete (allData1[i].data);
    }
    var node = document.getElementById('chatSeverity');
    let image
    htmlToImage.toPng(node)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        image = dataUrl;
      });
    setTimeout(() => {

    }, 500);
    let name: any;
    if (this.TeamName) {
      name = this.TeamName;
    }
    else {
      name = "Aggregate Report";
    }
    setTimeout(() => {
      this.dorsey.generateReportExcel(allData1, allData, image, this.companyName + '_Frustrations severity' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Frustrations severity', "Company: " + this.companyName, "Team: " + name, "Frustrations severity Report", copyRight, 'FFFFFF00', 'FF0000FF');
    }, 2000);

  }

  getData(data) {
    const dialogRef = this.dialog.open(ReportSeverityDialogComponent, {
      width: '650px',
      data: { data: data }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.html',
  // styleUrls: ['dialog.css']
})
export class ReportSeverityDialogComponent implements OnInit {

  data: any;
  constructor(
    public dialogRef: MatDialogRef<ReportSeverityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) { }
  ngOnInit() {
    this.data = this.dialogData.data;
  }
  close(): void {
    this.dialogRef.close();
  }
}
