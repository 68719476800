import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { IResponsePackage } from "../models/response-package.model";
import { Month, WeekDay } from "../quarter/quarter.model";

const Moment = require('moment');
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);
@Injectable({
    providedIn: 'root'
})
export class QuarterService {

    private httpUrl: string = environment.API_URL;

    constructor(private http: HttpClient) {
    }

    getMonths(): Array<Month> {
        return [
            { MonthId: 1, MonthName: 'January' },
            { MonthId: 2, MonthName: 'February' },
            { MonthId: 3, MonthName: 'March' },
            { MonthId: 4, MonthName: 'April' },
            { MonthId: 5, MonthName: 'May' },
            { MonthId: 6, MonthName: 'June' },
            { MonthId: 7, MonthName: 'July' },
            { MonthId: 8, MonthName: 'August' },
            { MonthId: 9, MonthName: 'September' },
            { MonthId: 10, MonthName: 'October' },
            { MonthId: 11, MonthName: 'November' },
            { MonthId: 12, MonthName: 'December' },
        ];
    }

    getQuarters(companyId: number): Observable<IResponsePackage> {
        return this.http.get<IResponsePackage>(`${this.httpUrl}Dorsey/GetQuartersByCompanyId?companyId=${companyId}`).pipe(
            catchError(this.handleError)
        );
    }

    saveQuarters(entity: any): Observable<any> {
        return this.http.post(`${this.httpUrl}Dorsey/SaveQuarter`, entity).pipe(
            catchError(this.handleError)
        );
    }

    generateQuarters(selectedMonthId, companyId, currentUser, quarterWeekDay, quarterWeekDate) {
        let months = this.getMonths();
        var startMonths = months.splice(selectedMonthId - 1);
        var concatMonths = startMonths.concat(months).map((val, index) => {
            return {
                MonthId: index + 1,
                MonthName: val.MonthName
            }
        });
        return this.splitArrayIntoQuarters(concatMonths, 3).map((val, index) => {
            return {
                QuarterId: index + 1,
                QuarterName: 'Quarter ' + (index + 1),
                QuarterMonths: val,
                CompanyId: companyId,
                CreatedBy: currentUser,
                QuarterWeekDay: quarterWeekDay,
                QuarterWeekDate: quarterWeekDate
            }
        });
    }

    splitArrayIntoQuarters(months, noOfMonths) {
        var quarters = [], i = 0, n = months.length;
        while (i < n) {
            quarters.push(months.slice(i, i += noOfMonths));
        }
        return quarters;
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occured: ${err.error.message}`;
        }
        else {
            errorMessage = `Server returned code: ${err.status}, error message is ${err.message}`
        }
        return throwError(errorMessage);
    }

    getQuarterWeekDays(): Array<WeekDay> {
        return [

            { WeekDayId: 0, WeekDay: 'Sunday' },
            { WeekDayId: 1, WeekDay: 'Monday' },
            { WeekDayId: 2, WeekDay: 'Tuesday' },
            { WeekDayId: 3, WeekDay: 'Wednensday' },
            { WeekDayId: 4, WeekDay: 'Thursday' },
            { WeekDayId: 5, WeekDay: 'Friday' },
            { WeekDayId: 6, WeekDay: 'Saturday' },


        ];
    }

    getWeekDates(startDate, endDate, weekDay) {

        let weekData = [];
        var weeks = []
        var indexOf = [].indexOf;
        var monthRange = moment.range(startDate, endDate);

        for (let mday of monthRange.by('days')) {

            if (weeks.indexOf(mday.week()) === -1) {
                weeks.push(mday.week());
            }
        }

        while (startDate.isSameOrBefore(endDate)) {
            if (weekData.length > 0) {
                this.getWeeksStartEndDate(weekData, startDate, weekDay);

                startDate.add(1, 'days');
            }

            if (!startDate.isAfter(endDate)) {
                weekData.push({ startDate: moment(startDate).format('MM/DD') });
                startDate.add(6, 'days');

                if (weeks.length == weekData.length) {
                    this.getWeeksStartEndDate(weekData, startDate, weekDay);

                }
            }
        }


        let lastObj = weekData[weekData.length - 1];
        if (lastObj.startDate == endDate.format('MM/DD')) {
            this.getWeeksStartEndDate(weekData, startDate, weekDay);
        }

        return weekData;
    }

    getWeeksStartEndDate(weekData, startDate, weekDay): void {
        let lastObj = weekData[weekData.length - 1];
        lastObj['endDate'] = moment(startDate).format('MM/DD');
        lastObj['WeekNo'] = `(week${weekData.length})`
        if (weekDay == 'Start Date') {
            lastObj['label'] = `${lastObj.startDate}`
        }
        else {
            lastObj['label'] = `${lastObj.endDate}`
        }
    }
    getWeekMonths(months: any, year, quarterWeekday, quarterWeekDate) {
        let WeekDateList = [];

        var selectedMonthId = this.getMonths().find(val => val.MonthName == months[0].MonthName).MonthId; var startDate = moment([year, selectedMonthId - 1]);

        var firstDay = moment(startDate).startOf('month');

        var chosenWeekday = Number(quarterWeekday)
        const todayNum = moment(firstDay).isoWeekday();

        var firstDayOfWeek;
        if ((todayNum <= chosenWeekday))
            firstDayOfWeek = chosenWeekday < moment(firstDay).weekday() ? moment(firstDay).weekday(chosenWeekday + 7) : moment(firstDay).weekday(chosenWeekday)
        else
            firstDayOfWeek = moment(firstDay).startOf('isoWeek').add(1, 'week').day(quarterWeekday);
        var endDay;
        let weekData = [];
        endDay = moment(startDate).add(3, 'months').subtract(1, 'days');
        WeekDateList = this.getWeekDates(firstDayOfWeek, endDay, quarterWeekDate);
        return WeekDateList;
    }
}
