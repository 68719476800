import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DorseyService } from '../dorsey.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  // styleUrls: ['./reports.component.scss'],
  providers: [DorseyService]
})
export class ReportsComponent implements OnInit {
  index = 0;
  reportData: any;
  ReportID;
  constructor(private router: Router, private dorseyService: DorseyService) { }

  ngOnInit() {
    this.GetReport();
    this.ReportID = 1;
  }
  onLinkClick(event) {
    this.index = event.index;
  }
  GetReport() {
    this.dorseyService.GetReport().subscribe(report => {
      this.reportData = JSON.parse(report["_body"]);

    });

  }
  report(event) {
    this.ReportID = event.value;
  }
}
