import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { ValidationDialogComponent } from '../add-score-card/validation-dialog';
import { Department } from '../departments/department.model';
import { DorseyService } from '../dorsey.service';
import { DepartmentService } from '../services/department.service';
import { ScoreCard, ScoreCardTypeEnum } from './performance-score-card.model';
import { PerformanceScoreCardService } from './performance-score-card.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ScoreCardMaster, PSCMetricDetails } from '../add-score-card/add-score-card.model';
import { ResponseDialogComponent } from '../add-score-card/response-dialog';
import { QuarterService } from '../services/quarter.service';
const Moment = require('moment');
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import { EmployeeLoginLevel } from '../common/models/login-level.model';
@Component({
  selector: 'app-performance-score-card',
  templateUrl: './performance-score-card.component.html',
  styleUrls: ['./performance-score-card.component.scss']
})
export class PerformanceScoreCardComponent implements OnInit {

  employeeDetails: any;
  isViewScoreCard: boolean = false;
  pscMetricDetails: Array<PSCMetricDetails>;
  selectedMetrics: Array<PSCMetricDetails> = [];
  departmentList: Array<Department> = [];
  companyId: number;
  selectedDepartmentId: number;
  selectedTeamId: number;
  teamList: any = [];
  scoreCardTypes: string[] = [ScoreCardTypeEnum.Department, ScoreCardTypeEnum.Team];
  toggleTeam: boolean = false;
  selectedScoreCardType: string;
  scoreCard: ScoreCard;
  YearPickerConfig: Partial<BsDatepickerConfig>;
  masterScoreCards: Array<ScoreCardMaster> = [];

  MenuPermissions: any;
  menuData: any;
  menuID: string;
  menuName: string;
  column = "";
  direction: number;
  arrow = true;
  isDesc: boolean = false;
  isDisable = false;
  disableSearch = false;
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  scoreCardMasterId: number;
  masterScoreCard: ScoreCardMaster;
  WeekDateList = [];
  latestScoreCardId: number;
  isScoreCardEnable: boolean = false;
  constructor(private departmentService: DepartmentService,
    private dorseyService: DorseyService,
    private storage: LocalStorageService,
    private scoreCardService: PerformanceScoreCardService,
    private quarterService: QuarterService,
    private router: Router,
    public dialog: MatDialog) {
    this.menuData = this.storage.retrieve('menuData');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
    this.employeeDetails = this.storage.retrieve('employeeDetails');
    this.isScoreCardEnable = this.storage.retrieve('isScoreCardEnable');

    this.YearPickerConfig = Object.assign({},
      { containerClass: 'theme-dark-blue', showWeekNumbers: false, dateInputFormat: 'YYYY' });
  }

  ngOnInit(): void {
    this.companyId = this.storage.retrieve('companyID');
    this.menuData = this.menuData.filter(x => x.MenuName.toString().toLowerCase() === 'score card');
    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }
    if (this.employeeDetails) {
      if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
        this.selectedScoreCardType = EmployeeLoginLevel.Team;
        this.scoreCardTypeChange(this.selectedScoreCardType);
      }
    }
  }

  /*Set Pagination for score cards */

  scoreCardPagination(RowCount): void {
    this.dynamicRows = 10;
    this.rowsOnPageSet = [];
    this.rowsOnPage = 10;
    this.rowCount = RowCount;
    while (this.dynamicRows < this.rowCount) {
      this.rowsOnPageSet.push(this.dynamicRows);
      this.dynamicRows = this.dynamicRows + 10;
    }
    if (!this.rowsOnPageSet.includes(this.rowCount)) {
      this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }

  scoreCardTypeChange(scoreCardType: string): void {
    this.masterScoreCards = [];
    this.toggleTeam = scoreCardType === ScoreCardTypeEnum.Department ? false : true;
    this.getDepartments();
    this.getTeamsByDepartmentId(this.selectedDepartmentId);
  }

  departmentChange(selectedDepartmentId): void {
    this.masterScoreCards = [];
    if (this.selectedScoreCardType === ScoreCardTypeEnum.Team) {
      this.getTeamsByDepartmentId(selectedDepartmentId);
    }
  }

  teamChange(teamId: number): void {
    this.masterScoreCards = [];
  }

  showMasterScoreCards(): void {
    if (!this.validateFields()) {
      return;
    }
    this.masterScoreCards = [];
    this.scoreCardService.GetScoreCardMasterByDepartmentId(this.selectedDepartmentId, this.companyId).subscribe(resp => {
      this.masterScoreCards = resp.Data;
      if (this.selectedScoreCardType === ScoreCardTypeEnum.Team) {
        this.masterScoreCards = this.masterScoreCards.filter(ent => ent.TeamID === this.selectedTeamId);
      } else {
        this.masterScoreCards = this.masterScoreCards.filter(ent => ent.TeamID === 0);
      }

      this.rowCount = this.masterScoreCards.length;
      if (this.masterScoreCards.length === 0) {
        this.isDisable = true;
        this.disableSearch = true;
      }
      else {
        this.latestScoreCardId = this.masterScoreCards[0].Id;
        this.isDisable = false;
        this.disableSearch = false;
        this.scoreCardPagination(this.rowCount);
      }
      this.masterScoreCards = this.masterScoreCards.map(ele => {
        return {
          Id: ele.Id,
          CompanyID: ele.CompanyID,
          ScoreCardType: ele.ScoreCardType,
          DepartmentID: ele.DepartmentID,
          TeamID: ele.TeamID,
          QuarterId: ele.QuarterId,
          Year: ele.Year,
          ScoreCardName: ele.ScoreCardName,
          CreatedBy: ele.CreatedBy,
          UpdatedBy: ele.UpdatedBy,
          QuarterName: 'Quarter ' + ele.QuarterId,//ele.ScoreCardName.split('_')[1],
          CompanyName: ele.CompanyName,
          DepartmentName: ele.DepartmentName,
          TeamName: ele.TeamName
        }
      });
    });
  }

  openScoreCard(): void {
    if (!this.validateFields()) {
      return;
    }
    this.assignScoreCardValues();
    this.router.navigate(['add-score-card', 0, 0]);
  }

  editScoreCard(scoreCard): void {
    this.assignScoreCardValues();
    this.router.navigate(['add-score-card', scoreCard.Id, 0]);
  }

  deleteScoreCard(scoreCard): void {
    const dialogRef = this.dialog.open(PerformanceScoreCardDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'Are you sure you want to delete score card : ' + scoreCard.ScoreCardName + '?æææ' + true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.scoreCardService.DeleteScoreCardMaster(scoreCard.Id).subscribe(resp => {
          if (resp.Data == true) {
            this.dialog.open(ResponseDialogComponent, {
              data: {
                message: 'Delete successful'
              }
            });
            this.showMasterScoreCards();
          }
        });
      }
    });
  }

  ///private methods
  private validateFields(): boolean {
    var errorMessages = [];
    if (!this.selectedScoreCardType) {
      errorMessages.push("Please select Type");
    }
    if (!this.selectedDepartmentId) {
      errorMessages.push("Please select Department");
    }
    if (this.selectedScoreCardType === ScoreCardTypeEnum.Team) {
      if (!this.selectedTeamId) {
        errorMessages.push("Please select Team");
      }
    }

    if (errorMessages.length != 0) {
      this.dialog.open(ValidationDialogComponent, {
        data: {
          errorMessages: errorMessages
        }
      });
      return false;
    }
    return true;
  }

  private getDepartments(): void {
    this.departmentService.getDepartments(this.companyId).subscribe(resp => {
      this.departmentList = resp.Data;
      if (this.employeeDetails) {

        if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) {
          this.departmentList = this.departmentList.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID);
        }
        else if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
          this.departmentList = this.departmentList.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID);
          this.getTeamsByDepartmentId(this.employeeDetails.DepartmentID);

        }
      }
    });
  }

  private getTeamsByDepartmentId(selectedDepartmentId): void {
    this.dorseyService.GetTeam(this.companyId.toString()).subscribe(resp => {
      var companyTeamList = JSON.parse(resp['_body']);
      this.teamList = companyTeamList;
      if (this.employeeDetails && companyTeamList) {
        if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) {
          this.teamList = this.teamList.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID);
        }
        else if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
          this.teamList = this.teamList.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID && ent.TeamID == this.employeeDetails.TeamID);
          this.selectedDepartmentId = this.teamList[0].DepartmentID;
          this.selectedTeamId = this.teamList[0].TeamID;
        }
      }
      else {
        this.teamList = companyTeamList.filter(ent => ent.DepartmentID === selectedDepartmentId);
      }
    });
  }

  private assignScoreCardValues() {
    var department = this.departmentList.find(ent => ent.DepartmentID === this.selectedDepartmentId);
    var team = this.teamList.find(ent => ent.TeamID === this.selectedTeamId);
    this.scoreCard = {
      ScoreCardType: this.selectedScoreCardType,
      DepartmentId: this.selectedDepartmentId,
      DepartmentName: department ? department.DepartmentName : '',
      TeamId: (this.selectedScoreCardType == ScoreCardTypeEnum.Team && this.selectedTeamId) ? this.selectedTeamId : 0,
      TeamName: (this.selectedScoreCardType == ScoreCardTypeEnum.Team && team) ? team.TeamName : ''
    };
    this.scoreCardService.scoreCard.next(this.scoreCard);
  }

  viewScoreCard(scoreCard): void {
    this.scoreCardMasterId = scoreCard.Id;
    this.scoreCardService.GetPerformanceScoreCardDetails(scoreCard.CompanyID, scoreCard.DepartmentID, scoreCard.TeamID, scoreCard.Id).subscribe(resp => {
      this.pscMetricDetails = resp.AdditionalData.PSCDetails;
      this.selectedMetrics = this.pscMetricDetails.filter(ent => ent.IsSelected === true);
      this.processData();
      var quarter = resp.AdditionalData.QuarterDetails;
      var months = quarter.filter(val => val.QuarterID == scoreCard.QuarterId);
      if (months.length > 0)
        this.WeekDateList = this.quarterService.getWeekMonths(months, Number(scoreCard.Year), quarter[0].QuarterWeekDay, quarter[0].QuarterWeekDate);
      this.scoreCard = scoreCard;
      this.isViewScoreCard = true;
    });
  }

  private processData(): void {
    const goalSeen = {};
    this.selectedMetrics = this.selectedMetrics.sort((a, b) => {
      return a.GoalName.localeCompare(b.GoalName);
    }).map(x => {
      const goalSpan = goalSeen[x.GoalName] ? 0 :
        this.selectedMetrics.filter(y => y.GoalName === x.GoalName).length;
      goalSeen[x.GoalName] = true;
      return { ...x, goalSpan };
    });
  }

  viewScoreCardChangedHandler(value: boolean): void {
    this.isViewScoreCard = value;
  }

  goToAddScoreCardChangedHandler(event): void {
    this.assignScoreCardValues();
    this.router.navigate(['add-score-card', this.scoreCardMasterId, 3]);
  }

  sort(property): void {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

}


@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
})
export class PerformanceScoreCardDialogComponent implements OnInit {

  condition = false;

  constructor(
    public dialogRef: MatDialogRef<PerformanceScoreCardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.data = this.data.split('æææ');
    if (this.data[1] === 'true') {
      this.condition = true;
    } else {
      this.condition = false;
    }
    this.data = this.data[0];
  }

  ngOnInit(): void { }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
