import { Component, OnInit, Inject } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BreakpointObserver } from '@angular/cdk/layout';
import { OrganizationService } from '../services/organization.service';
import { copyRight } from '../copyRight.model';
import { MsalUserService } from './msal-user.service';
import { CompanyService } from '../services/company.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  copyRight: string = copyRight;
  userName = '';
  password = '';
  warningMsg = '';
  usersData: any;
  userRoleAssignmentData: any;
  filtercompany = [];
  filterEmployee = [];
  //filterUserRole = [];
  adminDetails = [];
  login = false;
  rolesData: any;
  organizationDetails: any;
  menuData: any;
  MenuPermissions: any;
  PermissionJson: any;
  companyID: string;
  menuOpenStatus = true;
  count = 0;
  menuOpen = false;
  TeamID: string;
  employeeData: any;
  employeeID = 0;
  organizationID: number;
  RoleID: string;
  CompanyName: string = '';
  companyData: any;
  currentUser: any;
  GlobalAdminLogin = false;
  loginUsername: string;
  teamData: any;
  panelOpenState = false;
  step = 0;
  menuVisible = false;
  setupList = false;
  processList = false;
  projects = false;
  reports = false;
  companySetup = false;
  type = false;
  loginCompanyName: string = '';
  copyDate: number;
  opened: any;
  sidenavMode: any;
  backdrop = false;
  smallScrn = false;
  isSuperAdmin: boolean = false;
  companyNavigate: boolean;
  employeeLoginLevel: string;
  isScoreCardEnable: boolean = false;
  showAzureBtn: boolean = false;

  constructor(private dorsey: DorseyService,
    private router: Router,
    private storage: LocalStorageService,
    private organizationService: OrganizationService,
    private dialog: MatDialog,
    breakpointObserver: BreakpointObserver,
    private companyService: CompanyService,
    private msalService: MsalUserService) {
    const windowSize = breakpointObserver.observe([
      '(max-width: 1200px)'
    ]).subscribe(result => {
      if (result.matches) {
        this.opened = false;
        this.sidenavMode = "over";
        this.smallScrn = true;
      } else {
        this.opened = true;
        this.sidenavMode = "side";
        this.smallScrn = false;
      }
    });
    if (window.location.href.split('#')[1] === '/' || !window.location.href.split('#')[1]) {
      this.storage.store('Login', false);
      this.login = false;
    }

  }
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  collapseSidenav() {
    if (this.opened && this.smallScrn) {
      this.opened = !this.opened;
    }
  }

  menuClick(url, obj) {
    var activeclasses = document.querySelectorAll('.liactive');
    for (let i = 0; i < activeclasses.length; i++) {
      activeclasses[i].setAttribute("class", "liactive1");
      activeclasses[i].parentElement.setAttribute("class", "has-module1");
    }
    obj.parentNode.setAttribute("class", "liactive");
    obj.parentNode.parentNode.setAttribute("class", "has-module");
    this.router.navigateByUrl(url);
  }

  ngOnInit() {
  
    if(window.location.hostname.includes('sugarcreek')){
      this.showAzureBtn = true;
    }
    if(sessionStorage.getItem('msal.urlHash')){
      var token = sessionStorage.getItem('msal.urlHash').split('#')[1].split('&')[0].split('=')[1];
      var azureObject = JSON.parse(atob(token.split('.')[1]));
      //preferred_username
      this.companyService.getUserDetailsByEmailId(azureObject.email).subscribe(resp => {
        if (resp.Data.length == 0) {
          this.warningMsg = "No user found";
          this.storage.clear();
          return;
        }
        this.userName = resp.Data[0].UserName;
        this.password = resp.Data[0].Password;
        this.storage.store('Login', true);
        this.loginProcess();
      })
    }else{
     this.loginProcess();
    }
    this.copyDate = (new Date()).getFullYear();
  }

  loginProcess(): void{
    if (this.storage.retrieve('Login')) {
      this.GetTeamLogin();
      this.GetUserRoleAssignment();
      this.GetMenuMaster();
      this.Login();
      this.MenuPermissions = this.storage.retrieve('MenuPermissions');
      this.companyNavigate = this.storage.retrieve('companyNavigate');
      this.menuData = this.storage.retrieve('menuData');
      this.loginUsername = this.storage.retrieve('loginUsername');
      this.companyID = this.storage.retrieve('companyID');
      this.GlobalAdminLogin = this.storage.retrieve('GlobalAdminLogin');
      this.CompanyName = this.storage.retrieve('CompanyName');
      this.organizationID = this.storage.retrieve('organizationId');
      this.isScoreCardEnable = this.storage.retrieve('isScoreCardEnable');
      this.menuVisible = this.storage.retrieve('menuVisible');
      if (this.GlobalAdminLogin) {
        this.GetUsers();
        this.GetUserRoleAssignment();
        if (this.companyID != '0') {
          this.dorsey.GetCompanyData().subscribe(data => {
            this.companyData = JSON.parse(data['_body']);
            let dataCompany = this.companyData.filter(x => x.CompanyID === this.companyID);
            if (dataCompany.length > 0) {
              this.CompanyName = dataCompany[0].CompanyName;
            }
          });
          this.storage.store('CompanyName', this.CompanyName);
        }
      }
      this.login = true;
    } else {
      this.GetUserRoleAssignment();
      this.GetMenuMaster();
    }
  }

  loginWithAzure(): void{
    this.msalService.login();
  }

  GetMenuMaster() {
    this.dorsey.GetMenuMaster().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.menuData = subData;
    });
  }

  GetUsers() {
    this.dorsey.GetUsersList().subscribe(users => {
      this.usersData = JSON.parse(users['_body']);
    });
  }
  GetUserRoleAssignment() {
    this.dorsey.GetUserRoleAssignment().subscribe(userRoles => {
      this.userRoleAssignmentData = JSON.parse(userRoles['_body']);
    });
  }

  GetTeamLogin() {
    if (this.companyID) {
      this.dorsey.GetTeam(this.companyID).subscribe(subData => {
        subData = JSON.parse(subData['_body']);
        this.teamData = subData;

      });
    }

  }

  GetTeam(companyID) {
    if (companyID) {
      this.dorsey.GetTeam(companyID).subscribe(subData => {
        subData = JSON.parse(subData['_body']);
        this.teamData = subData;
      });
    }
  }

  GetRoles(companyID) {
    if (companyID) {
      this.dorsey.GetRoles(companyID).subscribe(users => {
        this.rolesData = JSON.parse(users['_body']);
      });
    }
  }

  GetEmployee(companyID: string) {
    if (companyID) {
      this.dorsey.GetEmployee(companyID).subscribe(team => {
        this.employeeData = JSON.parse(team['_body']);
      });
    }
  }

  LoginWithCompany(userName, Password, cID) {
    this.CompanyName = '';
    this.companyID = cID;
    if (cID == 0) {
      this.menuVisible = true;
    }
    else {
      this.menuVisible = false;
    }
    let uName = this.storage.retrieve("adminEmail");
    let Pas = this.storage.retrieve("adminPassword");
    let UserName = this.storage.retrieve('adminUserName');
    this.storage.store("adminEmail", uName);
    this.storage.store("adminPassword", Pas);
    this.storage.store("adminUserName", UserName);
    this.userName = userName;
    this.password = Password;
    this.GlobalAdminLogin = true;
    this.GetUsers();
    this.GetUserRoleAssignment();
    this.Login();

  }
  backToOrganization() {
    this.Login();
    //this.router.navigate(['/organization']);
  }

  BackToAdmin() {
    // this.storage.clear("id");
    // this.storage.clear("TeamID");
    // this.GetUsers();
    // this.GetUserRoleAssignment();
    // this.companyID = '';
    // this.menuVisible = true;
    this.CompanyName = '';
    this.GlobalAdminLogin = false;
    this.userName = this.storage.retrieve("loginUsername");
    this.login = this.storage.retrieve("Login");
    this.loginLevel = this.storage.retrieve("loginLevel");
    if (this.loginLevel == 'superadmin') {
      this.password = this.storage.retrieve("admintoken");
    } else if (this.loginLevel == 'organization') {
      this.password = this.storage.retrieve("orgtoken");
    } else if (this.loginLevel == 'entity') {
      this.password = this.storage.retrieve("entitytoken");
    }

    let userId = this.storage.retrieve('UserID');
    sessionStorage.clear();
    sessionStorage.removeItem("loginCompanyName");
    sessionStorage.removeItem("CompanyName");
    this.storage.clear();
    this.Login();
  }

  loginLevel: string;

  Login() {
    let parentId: number;
    if (this.GlobalAdminLogin) {
      this.usersData = this.storage.retrieve('userData');
    }
    this.dorsey.GetUsersList().subscribe(users => {
      this.usersData = JSON.parse(users['_body']);
      let data = this.storage.retrieve('UserID');

      if (data !== '') {
        let filteruser = this.usersData.filter(x => x.UserID === data);
        if (filteruser.length > 0) {
          this.currentUser = filteruser;
          this.storage.store('CurrentUser', this.currentUser);
        }
      }
      this.warningMsg = '';
      if (!this.userName && !this.password) {
        this.warningMsg = 'Please enter username and password';
        return;
      } else if (!this.userName) {
        this.warningMsg = 'Please enter username';
        return;
      } else if (!this.password) {
        this.warningMsg = 'Please enter password';
        return;
      } else if (this.userName != '' && this.password != '') {

        let filteruser = this.usersData.filter(x => (x.UserName.toLowerCase() === this.userName.toLowerCase() && x.Password === this.password || (x.AdminUname === this.userName && x.AdminPwd === this.password)));
        if (filteruser.length === 0) {
          this.warningMsg = 'Please enter valid credentials';
          return;
        }
        this.currentUser = filteruser;
        this.dorsey.GetCompanyRoleID(filteruser[0].CompanyID).subscribe(roleData => {
          let compRole = JSON.parse(roleData['_body'])[0]['RoleID'];
          localStorage.setItem('companyRoleID', (compRole));
          let userRoleName = JSON.parse(roleData['_body'])[0]['RoleName'];
          this.storage.store('userRoleName', userRoleName);
        });

        this.storage.store('CurrentUser', this.currentUser);
        if (filteruser.length > 0) {
          this.userName = filteruser[0].UserName;
          this.storage.store("UserID", filteruser[0].UserID);

          if (this.GlobalAdminLogin) {
            this.loginUsername = this.storage.retrieve('loginUsername');
            this.companyID = filteruser[0].CompanyID;
            let userName = this.storage.retrieve('adminUserName');
            this.storage.store('user', userName);
          }
          else {
            this.loginUsername = filteruser[0].UserName;
            this.companyID = filteruser[0].CompanyID;
            this.storage.store('user', filteruser[0].UserName);
          }
          this.organizationID = filteruser[0].OrganizationID;
          this.dorsey.GetRoles(this.companyID).subscribe(users => {
            this.rolesData = JSON.parse(users['_body']);
            this.dorsey.GetUserRoleAssignment().subscribe(data => {
              this.userRoleAssignmentData = JSON.parse(data['_body']);
              this.adminDetails = this.userRoleAssignmentData.filter(x => x.UserID === filteruser[0].UserID && x.RoleID === 1);
              this.organizationDetails = this.userRoleAssignmentData.filter(x => x.UserID === filteruser[0].UserID && filteruser[0].CompanyID === 0 && filteruser[0].EmployeeID === 0);
              // this.companyLogin = this.userRoleAssignmentData.filter(x => x.UserID === filteruser[0].UserID && filteruser[0].EmployeeID === 0);
              this.filtercompany = this.userRoleAssignmentData.filter(x => x.UserID === filteruser[0].UserID && filteruser[0].EmployeeID === 0);
              if (this.adminDetails.length > 0) {
                if (!this.storage.retrieve('loginLevel')) {
                  this.loginLevel = 'superadmin';
                  this.storage.store("admintoken", this.password);
                  this.storage.store("loginLevel", this.loginLevel);
                }
                this.menuVisible = true;
                this.storage.store('isCompanyLogin', false);
                this.organizationID = 0;
                let filterPermissons = this.rolesData.filter(x => x.RoleID === this.adminDetails[0].RoleID && x.OrganizationID == 0);
                this.MenuPermissions = JSON.parse(filterPermissons[0].MenuJson);
                this.storage.store("username", this.userName);

                this.storage.store("token", this.password);
                this.storage.store("organizationId", this.organizationID);
                this.storage.store("adminUname", filteruser[0].UserName);
                this.storage.store("GlobalAdminLogin", this.GlobalAdminLogin);
                this.storage.store('MenuPermissions', this.MenuPermissions);
                this.storage.store('menuData', this.menuData);
                this.storage.store('companyID', filteruser[0].CompanyID);
                this.storage.store('loginUsername', this.loginUsername);
                this.storage.store('Login', true);
                this.isScoreCardEnable = false;
                this.router.navigate(['/organization'],);
                this.login = true;
                return;
              }
              else if (this.organizationDetails.length > 0) {
                if (!this.storage.retrieve('loginLevel')) {
                  this.loginLevel = 'organization';
                  this.storage.store("orgtoken", this.password);
                  this.storage.store("loginLevel", this.loginLevel);
                }
                this.companyNavigate = false;
                this.storage.store("companyNavigate", this.companyNavigate);
                this.isSuperAdmin = false;
                this.organizationID = filteruser[0].OrganizationID;
                this.storage.store("organizationId", this.organizationID);
                var orgDetails;
                this.organizationService.getOrganizations().subscribe(resp => {
                  let organization = resp.Data.find(ent => ent.OrganizationID === this.organizationID);
                  orgDetails = {
                    OrganizationId: organization.OrganizationID,
                    IsSuperAdmin: this.isSuperAdmin,
                    OrganizationName: organization.OrganizationName,
                    NoOfLicenses: organization.NoOfLicenses
                  }
                  this.menuVisible = true;
                  let filterPermissons = this.rolesData.filter(x => x.RoleID === this.filtercompany[0].RoleID);
                  this.MenuPermissions = JSON.parse(filterPermissons[0].MenuJson);

                  this.storage.store("username", this.userName);
                  this.storage.store("token", this.password);
                  this.storage.store("adminUname", filteruser[0].UserName);
                  this.storage.store("GlobalAdminLogin", this.GlobalAdminLogin);
                  this.storage.store('MenuPermissions', this.MenuPermissions);
                  this.storage.store('menuData', this.menuData);
                  this.storage.store('companyID', filteruser[0].CompanyID);
                  this.storage.store('loginUsername', this.loginUsername);
                  this.storage.store('Login', true);
                  this.isScoreCardEnable = false;
                  this.storage.store('OrganizationDetails', orgDetails);
                  this.router.navigate(['/entity']);
                  this.login = true;
                });
                return;
              }
              else if (this.filtercompany.length > 0) {
                if (!this.storage.retrieve('loginLevel')) {
                  this.loginLevel = 'entity';
                  this.storage.store("entitytoken", this.password);
                  this.storage.store("loginLevel", this.loginLevel);
                }

                this.companyNavigate = this.storage.retrieve("companyNavigate");
                let role = this.userRoleAssignmentData.filter(x => x.UserID === this.filtercompany[0].UserID)
                let filterPermissons = this.rolesData.filter(x => x.RoleID === role[0].RoleID);
                this.MenuPermissions = JSON.parse(filterPermissons[0].MenuJson);
                this.organizationID = filteruser[0].OrganizationID;
                var orgDetails;
                this.organizationService.getOrganizations().subscribe(resp => {
                  let organization = resp.Data.find(ent => ent.OrganizationID === this.organizationID);
                  orgDetails = {
                    OrganizationId: organization.OrganizationID,
                    IsSuperAdmin: this.isSuperAdmin,
                    OrganizationName: organization.OrganizationName,
                    NoOfLicenses: organization.NoOfLicenses
                  }
                  this.GetTeam(this.companyID);
                  if (this.teamData !== undefined) {
                    for (let i = 0; i < this.teamData.length; i++) {
                      if (this.teamData[i].TeamName === 'Leadership Team' && this.teamData[i].CompanyID === filteruser[0].CompanyID) {
                        this.storage.store('TeamIDLeader', this.teamData[i].TeamID);
                      }
                    }
                  }
                  this.companyID = filteruser[0].CompanyID;
                  let readOnly = this.rolesData.filter(x => x.CompanyID === this.companyID && x.RoleName.toLowerCase() === "read only");
                  if (readOnly.length > 0) {
                    this.storage.store("ReadOnlyRoleID", readOnly[0].RoleID);
                  }
                  let TeamAdmin = this.rolesData.filter(x => x.CompanyID === this.companyID && x.RoleName.toLowerCase() === "team admin");
                  if (TeamAdmin.length > 0) {
                    this.storage.store("TeamAdminRoleID", TeamAdmin[0].RoleID);
                  }

                  let teamMember = this.rolesData.filter(x => x.CompanyID === this.companyID && x.RoleName.toLowerCase() === "team member");
                  if (teamMember.length > 0) {
                    this.storage.store("TeamMemberRoleID", teamMember[0].RoleID);
                  }
                  if (this.companyID != '0') {
                    this.dorsey.GetCompanyData().subscribe(data => {
                      this.companyData = JSON.parse(data['_body']);
                      let dataCompany = this.companyData.filter(x => x.CompanyID === this.companyID);
                      if (dataCompany.length > 0) {
                        this.CompanyName = dataCompany[0].CompanyName;
                        parentId = dataCompany[0].ParentID;
                        this.isScoreCardEnable = dataCompany[0].EnableScoreCard;
                      }
                      this.storage.store('CompanyName', this.CompanyName);
                      this.storage.store('isScoreCardEnable', this.isScoreCardEnable);

                      this.storage.store('loginCompanyName', this.CompanyName);
                      sessionStorage.setItem('loginCompanyName', this.CompanyName);

                    });
                  }
                  this.storage.store("GlobalAdminLogin", this.GlobalAdminLogin);
                  this.storage.store("username", this.userName);
                  this.storage.store("token", this.password);
                  this.RoleID = filterPermissons[0].RoleID;
                  this.storage.store('RoleID', this.RoleID);
                  this.storage.store('loginUsername', this.loginUsername);
                  this.storage.store('EmpRoleID', this.RoleID);

                  this.storage.store('companyID', filteruser[0].CompanyID);
                  this.storage.store('EmployeeID', filteruser[0].EmployeeID);
                  this.login = true;
                  this.storage.store('Login', true);
                  this.storage.store('MenuPermissions', this.MenuPermissions);
                  this.storage.store('menuData', this.menuData);
                  this.storage.store('isCompanyLogin', true);

                  if (!parentId) {
                    this.storage.store('isSubCompanyLogin', true);
                  }

                  this.storage.store('OrganizationDetails', orgDetails);
                  if (!this.companyNavigate) {
                    this.menuVisible = true;
                    this.companyNavigate = false;
                    this.storage.store("companyNavigate", this.companyNavigate);
                    this.router.navigateByUrl('/entity');
                  } else {
                    this.menuVisible = false;
                    this.companyNavigate = true;
                    this.storage.store("companyNavigate", this.companyNavigate);
                    this.router.navigateByUrl('/processMap');
                  }
                });
              }
              else {
                this.menuVisible = false;
                this.companyNavigate = true;
                this.storage.store("menuVisible", this.menuVisible);
                this.storage.store("companyNavigate", this.companyNavigate);
                this.filterEmployee = this.userRoleAssignmentData.filter(x => x.UserID === filteruser[0].UserID && filteruser[0].EmployeeID !== 0);
                if (this.filterEmployee.length > 0) {
                  let filterPermissons = this.rolesData.filter(x => x.RoleID === this.filterEmployee[0].RoleID);
                  this.MenuPermissions = JSON.parse(filterPermissons[0].MenuJson);
                  this.companyID = filteruser[0].CompanyID;
                  this.dorsey.GetEmployee(filteruser[0].CompanyID).subscribe(team => {
                    this.employeeData = JSON.parse(team['_body']);
                    let filterEm = this.employeeData.filter(x => x.EmployeeID === filteruser[0].EmployeeID && filteruser[0].CompanyID === this.companyID);
                    this.employeeID = filteruser[0].EmployeeID;
                    if (filterEm.length > 0) {
                      if (this.teamData !== undefined) {
                        for (let i = 0; i < this.teamData.length; i++) {
                          if (this.teamData[i].TeamID === filterEm[0].TeamID && this.teamData[i].CompanyID === filteruser[0].CompanyID) {
                            this.storage.store('TeamIDLeader', this.teamData[i].TeamID);
                          }
                          else if (this.teamData[i].TeamName === 'Leadership Team' && this.teamData[i].CompanyID === filteruser[0].CompanyID) {
                            this.storage.store('TeamIDLeader', this.teamData[i].TeamID);
                          }
                        }
                      }
                      this.storage.store('TeamID', filterEm[0].TeamID);
                    }
                    this.storage.store("employeeDetails", filterEm[0]);
                    this.RoleID = filterEm[0].RoleID;
                    this.storage.store("GlobalAdminLogin", this.GlobalAdminLogin);
                    this.storage.store("username", this.userName);
                    this.storage.store("token", this.password);
                    this.storage.store('loginUsername', this.loginUsername);
                    let readOnly = this.rolesData.filter(x => x.CompanyID === this.companyID && x.RoleName.toLowerCase() === "read only");
                    if (readOnly.length > 0) {
                      this.storage.store("ReadOnlyRoleID", readOnly[0].RoleID);
                    }
                    let TeamAdmin = this.rolesData.filter(x => x.CompanyID === this.companyID && x.RoleName.toLowerCase() === "team admin");
                    if (TeamAdmin.length > 0) {
                      this.storage.store("TeamAdminRoleID", TeamAdmin[0].RoleID);
                      this.storage.store("TeamMemberRoleName", TeamAdmin[0].RoleName);
                    }
                    let teamMember = this.rolesData.filter(x => x.CompanyID === this.companyID && x.RoleName.toLowerCase() === "team member");
                    if (teamMember.length > 0) {
                      this.storage.store("TeamMemberRoleID", teamMember[0].RoleID);
                    }
                    if (this.companyID != '0') {
                      this.dorsey.GetCompanyData().subscribe(data => {
                        this.companyData = JSON.parse(data['_body']);
                        let dataCompany = this.companyData.filter(x => x.CompanyID === this.companyID);
                        if (dataCompany.length > 0) {
                          this.CompanyName = dataCompany[0].CompanyName;
                          parentId = dataCompany[0].ParentID;
                          this.isScoreCardEnable = dataCompany[0].EnableScoreCard;
                        }
                        this.storage.store('isScoreCardEnable', this.isScoreCardEnable);
                        this.storage.store('CompanyName', this.CompanyName);
                        this.storage.store('loginCompanyName', this.CompanyName);
                        sessionStorage.setItem('loginCompanyName', this.CompanyName);
                      });
                    }
                    this.storage.store('RoleID', "0");
                    this.storage.store('EmpRoleID', this.RoleID);
                    this.storage.store('companyID', filteruser[0].CompanyID);
                    this.storage.store('user', filteruser[0].UserName);
                    this.storage.store('EmployeeID', filteruser[0].EmployeeID);
                    this.login = true;
                    this.storage.store('Login', true);
                    this.storage.store('MenuPermissions', this.MenuPermissions);
                    this.storage.store('menuData', this.menuData);
                    this.menuVisible = false;
                    this.router.navigateByUrl('/processMap');
                  });
                }
              }
            })
          });
        }
        else {
          this.warningMsg = 'Please enter valid Credentials';
        }
      }
      else {
        this.warningMsg = 'Please enter valid Credentials';
        return;
      }
    });
    if (this.companyID != '0') {
      this.dorsey.GetCompanyData().subscribe(data => {
        this.companyData = JSON.parse(data['_body']);

        // let dataCompany = this.companyData.filter(x => x.CompanyID === this.companyID);
        // if (dataCompany.length > 0) {
        //   this.CompanyName = dataCompany[0].CompanyName;
        // }

        this.storage.store('CompanyName', this.CompanyName);
        this.storage.store('loginCompanyName', this.CompanyName);
        sessionStorage.setItem('loginCompanyName', this.CompanyName);

      });
    }

  }

  GetCompany() {

  }
  LogOut() {
    this.loginUsername = "";
    this.CompanyName = "";
    this.menuVisible = false;
    this.userName = '';
    this.password = '';
    this.login = false;
    this.employeeID = 0;
    this.companyID = ''
    this.GlobalAdminLogin = false;
    this.storage.clear('Login');
    this.storage.clear('MenuPermissions');
    this.storage.clear('menuData');
    this.storage.clear('companyID');
    this.storage.clear('TeamID');
    this.storage.clear('EmpRoleID');
    this.storage.clear('id');
    this.storage.clear('userData');
    this.storage.clear('employeeDetails');
    this.storage.clear('CompanyName');
    this.storage.clear('ReadOnlyRoleID');
    this.storage.clear('TeamAdminRoleID');
    this.storage.clear('TeamMemberRoleName');
    this.storage.clear('TeamMemberRoleID');
    this.storage.clear('TeamIDLeader');
    this.storage.clear('username');
    this.storage.clear('token');
    this.storage.clear('adminEmail');
    this.storage.clear('adminPassoword');
    this.storage.clear('adminUserName');
    this.storage.clear('loginUsername');
    this.storage.clear('loginCompanyName');
    this.storage.clear('GlobalAdminLogin');
    this.storage.clear('RoleID');
    this.router.navigateByUrl('/');
    this.filterEmployee = [];
    this.adminDetails = [];
    this.filtercompany = [];
    this.MenuPermissions = null;
    sessionStorage.clear();
    sessionStorage.removeItem("loginCompanyName");
    sessionStorage.removeItem("CompanyName");
    this.storage.clear();
  }

  CheckEnter(event) {
    event.preventDefault();
    if (event.keyCode === 13) {
      document.getElementById('login').click();
    }
  }

  onClick(event) {
    if (this.count === 0) {
      this.menuOpen = true;
      this.menuOpenStatus = false;
      this.count++;
    } else {
      this.menuOpen = false;
      this.menuOpenStatus = true;
      this.count = 0;
    }

  }
  changePassword() {
    this.GetUsers();
    this.Login();
    const dialogRef = this.dialog.open(LoginMetricDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'ChangePwd' + 'æææ' + false,
    });

    dialogRef.componentInstance.userName = this.userName;
    dialogRef.componentInstance.password = this.password;
    dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.componentInstance.usersData = this.usersData;

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        const dialogRefchid = this.dialog.open(LoginMetricDialogComponent, {
          data: 'Updated succesfully' + 'æææ' + false,
        });
        dialogRefchid.afterClosed().subscribe(data => {
          this.GetUsers();
        });
      }
    });
  }
}

@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
  styleUrls: ['dialog.css'],
  providers: [DorseyService],
})
export class LoginMetricDialogComponent implements OnInit {
  currentUser: any;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  dataSelf: string;
  usersData: any;
  userName: string;
  password: string;
  constructor(
    public dialogRef: MatDialogRef<LoginMetricDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dorseyService: DorseyService, private storage: LocalStorageService) {
    this.data = this.data.split('æææ');
    this.data = this.data[0];
  }
  ngOnInit() {
    this.currentUser = this.storage.retrieve('CurrentUser');
  }

  Close() {
    this.dialogRef.close(false);
  }
  ChangePassword() {

    this.dataSelf = '';
    if (!this.oldPassword || !this.newPassword || !this.confirmPassword) {
      this.dataSelf = 'Please enter mandatory fields(*)';
      return;
    } else if (this.oldPassword !== this.currentUser[0].Password) {
      this.dataSelf = 'Please enter the correct current password';
      return;
    } else if (this.newPassword !== this.confirmPassword) {
      this.dataSelf = 'New password and confirm password mismatch';
      return;
    } else if (this.oldPassword === this.confirmPassword) {
      this.dataSelf = 'Current password and new password should not match';
      return;
    }
    else {
      const json = {
        Password: this.confirmPassword,
        UserID: this.currentUser[0].UserID
      };
      this.dorseyService.ChangePassword(json).subscribe(data => {

        if (data['_body'].toString() !== 'false') {

          this.dialogRef.close(true);

        } else {
          this.dataSelf = 'Save failed';
        }

      });
    }
  }
}
