export interface Month {
    MonthId: number,
    MonthName: string
}

export interface Quater {
    QuarterId: number,
    month: Month
}

export interface WeekDay {
    WeekDayId: number;
    WeekDay: string,

}

export enum QuarterStartDay {
    SelectStartDate = "Select Quarter Start Date",
    StartDate = "Start Date",
    EndDate = "End Date"
}
