import { Component, OnInit, Inject } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepickerInputEvent, MatRadioChange, MatRadioButton } from '@angular/material';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';
import { EmployeeLoginLevel } from '../common/models/login-level.model';
import { DepartmentService } from '../services/department.service';
import { Department } from '../departments/department.model';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  providers: [DorseyService]
})
export class EmployeeComponent implements OnInit {
  tempEmpDataFilter: any;
  teamData: any;
  employeeData: any;
  condition = false;
  EmployeeID: string;
  rolesData: any;
  companyData: any;
  roleID: string;
  designationID: string;
  departmentId: number;
  companyID: string;
  companyName: string;
  EmployeeName: string;
  Gender: string;
  DOB: string;
  DOJ: string;
  Email: string;
  Phone: string;
  status = false;
  employeeLoginLevel: string = null;
  employeeDetails: any;
  date = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());
  serializedDate1 = new FormControl((new Date()).toISOString());
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;
  searchtext: string;
  employeePaging = false;
  saveFilter = [];
  TeamID: string;
  userVisible = false;
  UserName: string;
  Password: string;
  isDisable = false;
  disableSearch = false;
  userData: any;
  UserID: string;
  Designation: string;
  currentUser: string;
  MenuPermissions: any;
  menuData: any;
  menuID: string;
  menuName: string;
  userRoleAssign: any;
  companyRole: any;
  CompanyRoleID: string;
  loginEmployeeID: string;
  FirstName: string;
  LastName: string;
  userTeamID: any;
  loginCompanyName: any;
  TeamAdminRoleID: string;
  TeamMemberRoleID: string;
  TeamMemberRoleName: string;
  EmpRoleID: string;
  EmpTeamName: string;
  EmpCompanyID: any;
  UserIDEMp: any;
  userRoleAssignmentData: any;
  rolesDataUser: any;
  isTeamAssigned: boolean = false;

  constructor(private dorseyService: DorseyService,
    public dialog: MatDialog,
    private departmentService: DepartmentService,
    private storage: LocalStorageService) {
    this.currentUser = this.storage.retrieve('user');
    this.EmpCompanyID = this.storage.retrieve('companyID');
    this.userTeamID = this.storage.retrieve('TeamID');
    this.menuData = this.storage.retrieve('menuData');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
    this.loginEmployeeID = this.storage.retrieve('EmployeeID');
    this.loginCompanyName = this.storage.retrieve('loginCompanyName');
    this.TeamAdminRoleID = this.storage.retrieve('TeamAdminRoleID');
    this.TeamMemberRoleID = this.storage.retrieve('TeamMemberRoleID');
    this.TeamMemberRoleName = this.storage.retrieve('TeamMemberRoleName');
    this.EmpRoleID = this.storage.retrieve('EmpRoleID');
    this.UserIDEMp = this.storage.retrieve('UserID');
    this.employeeDetails = this.storage.retrieve('employeeDetails');
    this.companyID = this.storage.retrieve('companyID');
  }

  ngOnInit() {
    this.GetUserRoleAssignment();
    if (this.loginEmployeeID.toString() !== '0') {
      this.EmployeeID = this.loginEmployeeID;
    }
    else {
      //this.EmployeeID = "0";
      this.EmployeeID = this.loginEmployeeID = "0";
    }
    let tempdata = this.menuData;
    this.menuData = tempdata.filter(x => x.MenuName.toString().toLowerCase() === 'employee');
    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }
    this.GetUserROleAssignment();
    this.GetUsers();
    this.GetEmployee();
    this.GetRoles();
    this.GetCompanyDetails();
    this.GetTeam();
    this.sort('EmployeeName');
  }

  OpenEmployee() {
    this.FirstName = "";
    this.LastName = "";
    this.roleID = '';
    this.Designation = '';
    this.UserName = '';
    this.Password = '';
    this.employeeLoginLevel = '';
    this.companyID = this.storage.retrieve('companyID');
    this.dialogCall('new');
  }

  GetCompanyDetails() {
    this.dorseyService.GetCompanyData().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.companyData = subData;
    });
  }

  GetUserRoleAssignment() {
    this.dorseyService.GetUserRoleAssignment().subscribe(userRoles => {
      this.userRoleAssignmentData = JSON.parse(userRoles['_body']);
      this.dorseyService.GetRoles(this.companyID).subscribe(users => {
        this.rolesDataUser = JSON.parse(users['_body']);
        if (this.loginEmployeeID.toString() !== '0') {
          let roles = this.userRoleAssignmentData.filter(x => x.UserID === this.UserIDEMp);
          if (roles.length > 0) {
            this.EmpRoleID = roles[0].RoleID;
            let roleData = this.rolesDataUser.filter(x => x.RoleID === this.EmpRoleID);
            if (roleData.length > 0) {
              this.MenuPermissions = JSON.parse(roleData[0].MenuJson);
            }

          }
        }
      });
    });
  }

  GetTeam() {
    this.dorseyService.GetTeam(this.companyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.teamData = subData;
      let tempData = this.teamData.filter(x => x.TeamID === this.userTeamID);
      if (tempData.length > 0)
        this.EmpTeamName = tempData[0].TeamName;

      if (this.EmployeeID.toString() !== "0" && this.userTeamID.toString() !== "0") {
        let data = this.teamData.filter(x => x.TeamID === this.userTeamID);
        this.teamData = data;
        this.TeamID = data[0].TeamID;
        //this.isTeamAssigned = this.employeeDetails.IsEmployeeLoginLevel = EmployeeLoginLevel.Team ? true : false;
        this.TeamID ? this.isTeamAssigned = true : this.isTeamAssigned = false
      } else {
        this.TeamID = '0';
        this.teamData = subData;
      }
    });
  }

  Searchtext() {
    this.employeeData = this.saveFilter;
    let tempEmployee = this.employeeData.filter(
      (item) => {
        return (
          (item.CompanyName !== null && this.searchtext && item.CompanyName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.IsEmployeeLoginLevel !== null && this.searchtext && item.IsEmployeeLoginLevel.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.EmployeeName !== null && this.searchtext && item.EmployeeName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.EmailID !== null && this.searchtext && item.EmailID.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.TeamName !== null && this.searchtext && item.TeamName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.DepartmentName !== null && this.searchtext && item.DepartmentName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.RoleName !== null && this.searchtext && item.RoleName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
        );
      }

    );
    this.employeeData = tempEmployee;
    if (this.employeeData.length == 0) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.employeeData = this.saveFilter;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      this.rowCount = this.employeeData.length;
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount))
        this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet) {

        this.rowsOnPage = this.rowsOnPageSet[0];
      }
      this.isDisable = false;
    } else {
      this.rowCount = tempEmployee.length;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount))
        this.rowsOnPageSet.push(this.rowCount);
      this.rowsOnPage = this.rowsOnPageSet[0];
      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }

  GetEmployee() {
    this.dorseyService.GetEmployee(this.companyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.employeeData = subData;
      if (this.userTeamID === null || this.userTeamID === undefined) {
        this.userTeamID = "0";
      }
      if (this.employeeData.length === 0) {
        this.employeePaging = false;
        this.isDisable = true;
        this.disableSearch = true;
      }
      else {
        this.employeePaging = true;
        this.isDisable = false;
        this.disableSearch = false;
        //if (this.userTeamID.toString() !== "0" && this.userTeamID !== null && this.companyID.toString() !== "0") {
        if (this.employeeDetails) {
          if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) {
            this.employeeData = [...this.employeeData.filter(x => x.DepartmentID === this.employeeDetails.DepartmentID)];
          }
          else if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
            this.employeeData = [...this.employeeData.filter(x => x.TeamID === this.employeeDetails.TeamID)];
          }
        }
        // let dtaa = this.employeeData.filter(x => x.TeamID === this.userTeamID && x.CompanyID === this.companyID);
        // this.employeeData = dtaa;
        //}
        // else {
        //   let dtaa = this.employeeData.filter(x => x.CompanyID === this.companyID);
        //   this.employeeData = dtaa;
        // }
        this.saveFilter = JSON.parse(JSON.stringify(this.employeeData));
        this.rowsOnPageSet = [];
        this.dynamicRows = 10;
        this.rowCount = this.employeeData.length;
        while (this.dynamicRows < this.rowCount) {
          this.rowsOnPageSet.push(this.dynamicRows);
          this.dynamicRows = this.dynamicRows + 10;
        }
        if (!this.rowsOnPageSet.includes(this.rowCount)) this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet.length === 1) {
          if (this.rowsOnPageSet[0] <= 10)
            this.rowsOnPage = this.rowsOnPageSet[0];
        }
      }
    });
  }

  exportAsXLSX() {
    let allData = JSON.parse(JSON.stringify(this.employeeData));
    for (let i = 0; i < allData.length; i++) {
      allData[i].Employee = allData[i].EmployeeName;
      allData[i][" Email "] = allData[i].EmailID;
      allData[i]["Employee Level"] = allData[i].IsEmployeeLoginLevel;
      allData[i]["Department"] = allData[i].DepartmentName;
      allData[i].Team = allData[i].TeamName;
      allData[i].Role = allData[i].RoleName;

      delete (allData[i].DepartmentName);
      delete (allData[i].IsEmployeeLoginLevel);
      delete (allData[i].UpdatedDate3);
      delete (allData[i].UpdatedBy3);
      delete (allData[i].CompanyID3);
      delete (allData[i].IsActive3);
      delete (allData[i].CreatedDate3);
      delete (allData[i].CreatedBy3);
      delete (allData[i].RowID);
      delete (allData[i].RoleID1);
      delete (allData[i].RoleName1);
      delete (allData[i].RoleDescription);
      delete (allData[i].PermissionJson);
      delete (allData[i].MenuJson);
      delete (allData[i].TeamID1);
      delete (allData[i].ProcessID);
      delete (allData[i].TeamName1);
      delete (allData[i].CompanyID2);
      delete (allData[i].IsActive2);
      delete (allData[i].CreatedDate2);
      delete (allData[i].CreatedBy2);
      delete (allData[i].UpdatedDate2);
      delete (allData[i].UpdatedBy2);
      delete (allData[i].TeamDescription);
      delete (allData[i].TeamName);
      delete (allData[i].RoleName);
      delete (allData[i].CompanyName);
      delete (allData[i].EmployeeName);
      delete (allData[i].Gender);
      delete (allData[i].EmailID);
      delete (allData[i].DateOfBirth);
      delete (allData[i].DateOfJoin);
      delete (allData[i].CompanyName);
      delete (allData[i].EmployeeName);
      delete (allData[i].Email);
      delete (allData[i].TeamID);
      delete (allData[i].DesignationID);
      delete (allData[i].RoleID);
      delete (allData[i].DepartmentID);
      delete (allData[i].CreatedBy);
      delete (allData[i].CreatedDate);
      delete (allData[i].UpdatedBy);
      delete (allData[i].UpdatedDate);
      delete (allData[i].ParentID);
      delete (allData[i].CompanyOwner);
      delete (allData[i].Description);
      delete (allData[i].AnnualRevenue);
      delete (allData[i].EmployeeID);
      delete (allData[i].IsActive);
      delete (allData[i].CompanyID1);
      delete (allData[i].CompanyNumber);
      delete (allData[i].CompanyCurrency);
      delete (allData[i].CompanySource);
      delete (allData[i].Active);
      delete (allData[i].CustomerPriority);
      delete (allData[i].Industry);
      delete (allData[i].NAICSCode);
      delete (allData[i].NAICSDescription);
      delete (allData[i].NumberOfLocations);
      delete (allData[i].OperatingHours);
      delete (allData[i].Ownership);
      delete (allData[i].ParentCompany);
      delete (allData[i].SICCode);
      delete (allData[i].SICCode);
      delete (allData[i].SICDescription);
      delete (allData[i].D_U_N_SNumber);
      delete (allData[i].Address1);
      delete (allData[i].Address2);
      delete (allData[i].Phone);
      delete (allData[i].CIty);
      delete (allData[i].Pincode);
      delete (allData[i].State);
      delete (allData[i].Country);
      delete (allData[i].Phone1);
      delete (allData[i].Phone2);
      delete (allData[i].Fax);
      delete (allData[i].Website);
      delete (allData[i].YearStarted);
      delete (allData[i].IsActive1);
      delete (allData[i].CreatedDate1);
      delete (allData[i].CreatedBy1);
      delete (allData[i].UpdatedDate1);
      delete (allData[i].UpdatedBy1);
      delete (allData[i].CompanyName1);
      delete (allData[i].CompanyID);
    }

    this.dorseyService.generateExcel(allData, this.loginCompanyName + '_employee' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Employee', "Company: " + this.loginCompanyName, '', "Employee Report", copyRight, 'FFFFFF00', 'FF0000FF');
  }

  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  GetRoles() {
    this.dorseyService.GetRoles(this.companyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.rolesData = subData;
      let temproles = this.rolesData;
      this.departmentService.getUsersByCompanyId(Number(this.companyID)).subscribe(resp => {
        this.userData = resp.Data;
        let data = [...this.userData];
        this.companyRole = data;
        if (data.length > 0) {
          this.dorseyService.GetUserRoleAssignment().subscribe(subData => {
            subData = JSON.parse(subData['_body']);
            this.userRoleAssign = subData;
            let assignROle = this.userRoleAssign.filter(x => x.UserID === data[0].UserID);
            if (assignROle.length > 0) {
              let roles = temproles.filter(x => x.RoleID != assignROle[0].RoleID);
              this.rolesData = roles;
              if (this.loginEmployeeID.toString() !== "0" && (this.employeeDetails && this.employeeDetails.IsEmployeeLoginLevel != EmployeeLoginLevel.Company)) {
                let adminRole = roles.filter(x => x.RoleID !== this.TeamAdminRoleID);
                this.rolesData = adminRole;
                this.rolesData = adminRole.filter(x => x.RoleID !== this.EmpRoleID);
              }
            }
          });
        }
      });
    });
  }

  GetUserROleAssignment() {
    this.dorseyService.GetUserRoleAssignment().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.userRoleAssign = subData;
    });
  }

  // GetDesignation() {
  //   this.dorseyService.GetDesignation().subscribe(subData => {
  //     subData = JSON.parse(subData['_body']);
  //     this.designationData = subData;
  //   });
  // }

  // GetDepartment() {
  //   this.dorseyService.GetDepartment().subscribe(subData => {
  //     subData = JSON.parse(subData['_body']);
  //     this.departmentData = subData;
  //   });
  // }

  dialogCall(msg: string) {
    this.GetRoles();
    const dialogRef = this.dialog.open(EmployeeDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: msg + 'æææ' + false,
    });

    dialogRef.componentInstance.EmployeeID = this.loginEmployeeID;
    dialogRef.componentInstance.loginEmployeeID = this.loginEmployeeID;
    dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.componentInstance.userVisible = this.userVisible;
    dialogRef.componentInstance.roleID = this.roleID;
    dialogRef.componentInstance.Designation = this.Designation;
    dialogRef.componentInstance.companyRole = this.companyRole;
    dialogRef.componentInstance.userData = this.userData;
    dialogRef.componentInstance.userRoleAssign = this.userRoleAssign;
    dialogRef.componentInstance.companyRole = this.companyRole;
    dialogRef.componentInstance.selectedLoginLevel = this.employeeLoginLevel;
    if (msg === 'user') {
      this.userVisible = true;
      dialogRef.componentInstance.companyID = this.companyID;
      dialogRef.componentInstance.EmployeeName = this.EmployeeName;
      dialogRef.componentInstance.Email = this.Email;
      dialogRef.componentInstance.Phone = this.Phone;
      dialogRef.componentInstance.UserName = this.UserName;
      dialogRef.componentInstance.Password = this.Password;
      dialogRef.componentInstance.EmployeeID = this.EmployeeID;
    }
    if (msg === 'new') {
      this.userVisible = false;
      this.searchtext = '';
      this.GetEmployee();
    }
    dialogRef.componentInstance.companyData = this.companyData;
    dialogRef.componentInstance.rolesData = this.rolesData;
    //dialogRef.componentInstance.designationData = this.designationData;
    //dialogRef.componentInstance.departmentData = this.departmentData;
    dialogRef.componentInstance.companyID = this.companyID;
    dialogRef.componentInstance.TeamID = this.TeamID;
    dialogRef.componentInstance.DOB = null;
    dialogRef.componentInstance.isTeamAssigned = this.isTeamAssigned;
    dialogRef.componentInstance.DOJ = null;
    dialogRef.componentInstance.teamData = this.teamData;
    dialogRef.componentInstance.selectedLoginLevel = this.employeeLoginLevel;

    if (msg === 'edit') {
      this.userVisible = false;
      this.status = true;
      dialogRef.componentInstance.TeamID = this.TeamID;
      dialogRef.componentInstance.FirstName = this.FirstName;
      dialogRef.componentInstance.LastName = this.LastName;
      dialogRef.componentInstance.Password = this.Password;
      dialogRef.componentInstance.UserName = this.UserName;
      dialogRef.componentInstance.UserID = this.UserID;
      dialogRef.componentInstance.companyName = this.companyName;
      dialogRef.componentInstance.EmployeeName = this.EmployeeName;
      dialogRef.componentInstance.Phone = this.Phone;
      dialogRef.componentInstance.Gender = this.Gender;
      dialogRef.componentInstance.roleID = this.roleID;
      dialogRef.componentInstance.designationID = this.designationID;
      dialogRef.componentInstance.departmentId = this.departmentId;
      dialogRef.componentInstance.Email = this.Email;
      dialogRef.componentInstance.selectedLoginLevel = this.employeeLoginLevel;
      // this.departmentService.getTeamsByDepartmentId(Number(this.companyID), this.departmentId).subscribe(resp => {
      //   dialogRef.componentInstance.teamList = resp.Data;
      // });

      if (this.DOB)
        dialogRef.componentInstance.DOB = new Date(this.DOB);
      else dialogRef.componentInstance.DOB = null;
      if (this.DOJ)
        dialogRef.componentInstance.DOJ = new Date(this.DOJ);
      else dialogRef.componentInstance.DOJ = null;
      dialogRef.componentInstance.EmployeeID = this.EmployeeID;
      dialogRef.componentInstance.companyID = this.companyID;
    }

    dialogRef.afterClosed().subscribe(result => {
      if (this.searchtext !== '' && this.searchtext !== undefined) {
        this.Searchtext();
      }
      else {

        this.GetEmployee();
      }
      if (result && result.toString() === 'true') {
        let statusMsg = '';
        if (msg === 'new') {
          statusMsg = 'Saved successfully';
        } else {
          statusMsg = 'Updated successfully';
        }

        this.dynamicRows = 10;
        this.rowsOnPageSet = [];
        const dialogRefsub = this.dialog.open(EmployeeDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: statusMsg + 'æææ' + false,
        });

        setTimeout(() => {
          this.GetEmployee();
          this.GetUsers();
        }, 500);
        dialogRefsub.afterClosed().subscribe(result1 => {
          this.employeeData = [];
          if (this.searchtext !== '' && this.searchtext !== undefined) {
            this.Searchtext();
          }
          else {
            this.GetEmployee();
            this.GetUsers();
          }
        });
      }
    });
  }

  GetUsers() {
    this.departmentService.getUsersByCompanyId(Number(this.companyID)).subscribe(resp => {
      this.userData = resp.Data;
    });
  }

  AddUser(element) {
    this.EmployeeID = "";
    this.UserID = "";
    this.UserName = "";
    this.Password = "";
    this.EmployeeID = element.EmployeeID;
    this.companyID = element.CompanyID;
    this.companyName = element.EmployeeName;
    this.Email = element.EmailID;
    this.Phone = element.Phone;
    this.roleID = element.RoleID;
    this.userVisible = true;
    let filterUser = this.userData.filter(x => x.EmployeeID === this.EmployeeID);
    if (filterUser.length > 0 && filterUser.length === 1) {
      this.UserID = filterUser[0].UserID;
      this.UserName = filterUser[0].UserName;
      this.Password = filterUser[0].Password;
    } else {
      this.UserName = "";
      this.Password = "";
    }
    this.dialogCall('user');

  }
  EditEmployee(data) {
    this.EmployeeID = data.EmployeeID;
    this.companyName = data.CompanyID;
    this.roleID = data.RoleID;
    this.designationID = data.DesignationID;
    this.departmentId = data.DepartmentID;
    this.Phone = data.Phone;
    this.Email = data.EmailID;
    this.EmployeeName = data.EmployeeName;
    this.companyID = data.CompanyID;
    this.TeamID = data.TeamID;
    this.Designation = data.DesignationID;
    this.employeeLoginLevel = data.IsEmployeeLoginLevel;
    let tempUsers = this.userData.filter(x => x.CompanyID === this.companyID && x.EmployeeID === this.EmployeeID && x.Email === this.Email);
    if (tempUsers.length > 0 && tempUsers.length === 1) {
      this.UserName = tempUsers[0].UserName;
      this.FirstName = tempUsers[0].FirstName;
      this.LastName = tempUsers[0].LastName;
      this.Password = tempUsers[0].Password;
      this.UserID = tempUsers[0].UserID;
    }
    else {
      this.FirstName = '';
      this.LastName = '';
      this.Password = '';
      this.UserID = '';
    }
    this.storage.store("Uname", this.UserName);
    this.dialogCall('edit');
  }

  ifMaxRow() {
    if (this.rowsOnPage == this.employeeData.length) {
      this.rowsOnPage = this.rowsOnPage - 1;
    }
  }

  DeleteEmployee(element) {
    this.EmployeeID = element.EmployeeID;
    const dialogRef = this.dialog.open(EmployeeDialogComponent, {
      data: 'Are you sure you want to delete employee: ' + element.CompanyName + '?æææ' + true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dorseyService.DeleteEmployee(this.EmployeeID).subscribe(data => {
          if (data.ok) {
            this.ifMaxRow();
            this.condition = false;
            this.dialogCall('Deleted successfully');
          }
          this.dynamicRows = 10;
          this.rowsOnPageSet = [];
          this.GetEmployee();
        });
      }
    });
  }
}

@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
  // styleUrls: ['dialog.css'],
  providers: [DorseyService, DatePipe],
})
export class EmployeeDialogComponent implements OnInit {
  companyRole: any;
  userRoleAssign: any;
  UserName: string;
  Password: string;
  userVisible = false;
  companyName: string;
  roleID: string;
  designationID: string;
  companyID: string;
  EmployeeName: string;
  teamData: any;
  Gender: string;
  DOB = new Date();
  DOJ = new Date();
  Email: string;
  currentUser: string;
  Phone: string;
  EmployeeID: string;
  TeamID: string;
  dataSelf = '';
  condition: boolean;
  addCompany: string;
  companyData: any;
  rolesData: any;
  //designationData: any;
  //departmentData: any;
  startDate = new Date();
  validDate = new Date();
  minDate = new Date();
  UserID: string;
  Designation: string;
  loginEmployeeID: string;
  userData: any;
  FirstName: string;
  LastName: string;
  userListData: any;
  isTeamAssigned: boolean;
  btnDisabled = false;
  employeeLoginLevelList: string[] = [EmployeeLoginLevel.Company, EmployeeLoginLevel.Department, EmployeeLoginLevel.Team];
  selectedLoginLevel: string = null;
  departmentList: Array<Department> = [];
  departmentId: number = 0;
  employeeLoginLevel = EmployeeLoginLevel;
  teamList: Array<any> = [];
  employeeDetails: any;

  constructor(
    public dialogRef: MatDialogRef<EmployeeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dorseyService: DorseyService,
    private departmentService: DepartmentService,
    private datepipe: DatePipe,
    private storage: LocalStorageService) {
    this.employeeDetails = this.storage.retrieve('employeeDetails');
    this.data = this.data.split('æææ');
    if (this.data[1] === 'true') {
      this.condition = true;
    } else {
      this.condition = false;
    }
    this.data = this.data[0];
    if (this.data == 'msg') {
      this.DOB = null;
      this.DOJ = null;
    }
  }

  ngOnInit() {
    this.GetUserListData();
    if (this.companyID) {
      this.departmentService.getDepartments(Number(this.companyID)).subscribe(resp => {
        if (resp.Data.length > 0) {
          this.getTeamsByDepartmentId();
          this.departmentList = resp.Data;
          if (this.employeeDetails) {
            //this.isTeamAssigned = this.employeeDetails.IsEmployeeLoginLevel = EmployeeLoginLevel.Team ? true : false;
            if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) {
              this.departmentList = this.departmentList.filter(x => x.DepartmentID === this.employeeDetails.DepartmentID);
              this.employeeLoginLevelList = [EmployeeLoginLevel.Team];
            }
            else if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
              this.teamList = this.teamList.filter(x => x.TeamID === this.employeeDetails.TeamID);
              this.employeeLoginLevelList = [EmployeeLoginLevel.Team];
            }
            else {
              this.employeeLoginLevelList = [EmployeeLoginLevel.Department, EmployeeLoginLevel.Team];


            }
          }
        }
      });
    }
  }

  loginLevelChange(): void {
    if (this.data === 'new') {
      this.TeamID = "0";
      this.departmentId = 0;
    }
    if (this.selectedLoginLevel == EmployeeLoginLevel.Company) {
      this.departmentId = 0;
      this.TeamID = '0';
    }
    else if (this.selectedLoginLevel == EmployeeLoginLevel.Department) {
      this.TeamID = '0';
    }
  }

  departmentChange(): void {
    this.getTeamsByDepartmentId();
  }

  getTeamsByDepartmentId(): void {
    if (this.departmentId && this.companyID) {
      this.departmentService.getTeamsByDepartmentId(Number(this.companyID), this.departmentId).subscribe(resp => {
        if (resp.Data.length > 0) {
          this.teamList = resp.Data;
        }
      });
    }
  }

  onYesClick() {
    this.dialogRef.close(true);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  SaveUser() {
    let count = 0;
    const Editcount = 0;
    if (this.UserName === undefined || this.Password === undefined || this.UserName.trim() === '' || this.Password.trim() === '') {
      this.dataSelf = 'Please enter mandatory(*) fields';
      if (this.data === 'new') {

      }
      count++;
    }

    if (count === 0 && Editcount === 0) {
      this.dataSelf = '';
      if (this.data === 'new') {


        this.SavingUserDetails(this.data);
      } else {
        this.SavingUserDetails(this.data);
      }
    }
  }


  SavingUserDetails(msg: string) {
    const json = {
      CompanyID: this.companyID,
      RoleID: this.roleID,
      roleCreatedBy: this.currentUser,
      name: this.EmployeeName,
      email: this.Email,
      phone1: this.Phone,
      UserName: this.UserName,
      Password: this.Password,
      EmployeeID: this.EmployeeID,
      userCreatedBy: this.currentUser,
      UserID: this.UserID
    }


    if (msg === 'new') {
      this.dorseyService.SaveUser(json).subscribe(data => {

        if (data['_body'].toString() !== '0') {

          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Save failed';
        }

      });
    } else {

      this.dorseyService.SaveUser(json).subscribe(data => {

        if (data['_body'].toString() !== '0') {

          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Updated successfully';
        }
      });
    }

  }

  AddEmployee() {
    this.btnDisabled = true;
    setTimeout(() => {
      this.btnDisabled = false;
    }, 1000);
    let count = 0;
    const Editcount = 0;
    if (this.FirstName == undefined ||
      this.LastName === undefined ||
      this.Password === undefined ||
      this.roleID === undefined ||
      !this.selectedLoginLevel ||
      this.Email === undefined ||
      this.UserName === undefined ||
      this.roleID === '' ||
      this.FirstName.trim() === '' ||
      this.LastName.trim() === '' ||
      this.Password.trim() === '' ||
      this.Email.trim() === '' ||
      this.UserName.trim() === '') {
      this.dataSelf = 'Please enter mandatory(*) fields';
      count++;
    }
    if (this.selectedLoginLevel === this.employeeLoginLevel.Department && !this.departmentId) {
      this.dataSelf = 'Please enter mandatory(*) fields';
      return;
    }

    if (this.selectedLoginLevel === this.employeeLoginLevel.Team && (this.TeamID === "0" || !this.TeamID)) {
      this.dataSelf = 'Please enter mandatory(*) fields';
      return;
    }
    if (this.Email) {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!this.Email.match(mailformat)) {
        this.dataSelf = 'Please enter vaild email';
        return;
      }
    }

    if (count === 0 && Editcount === 0) {
      this.dataSelf = '';
      if (this.data === 'new') {
        this.Saving(this.data);
      } else {
        this.Saving(this.data);
      }
    }
  }

  GetUserListData() {
    this.dorseyService.GetUsersList().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.userListData = subData;
    });
  }

  Saving(msg: string) {
    if (!this.TeamID) {
      this.TeamID = "0";
    }
    if (msg === 'new') {
      this.UserID = '';
      this.EmployeeID = '';
    }
    const json = {
      EmployeeName: this.FirstName + " " + this.LastName,
      CompanyID: this.companyID,
      EmailID: this.Email,
      DesignationID: this.Designation,
      RoleID: this.roleID,
      DepartmentID: this.departmentId,
      Phone: this.Phone,
      CreatedBy: this.currentUser,
      EmployeeID: this.EmployeeID,
      UpdatedBy: this.currentUser,
      TeamID: this.TeamID,
      UserName: this.UserName,
      Password: this.Password,
      FirstName: this.FirstName,
      LastName: this.LastName,
      userCreatedBy: this.currentUser,
      roleCreatedBy: this.currentUser,
      UserID: this.UserID,
      EmployeeLoginLevel: this.selectedLoginLevel
    };


    if (msg === 'new') {
      this.UserID = '';
      this.EmployeeID = '';
      let checkUser = this.userListData.filter(x => x.UserName.toString().toLowerCase().trim() === this.UserName.toString().toLowerCase().trim());
      if (checkUser.length > 0) {
        this.dataSelf = "Username already exist";
        return;
      }
      this.dorseyService.SaveEmployee(json).subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Save failed';
        }
      });
    } else {
      if (this.UserName !== this.storage.retrieve('Uname')) {
        let checkUser = this.userListData.filter(x => x.UserName.toString().toLowerCase().trim() === this.UserName.toString().toLowerCase().trim());
        if (checkUser.length > 0) {
          this.dataSelf = "Username already exist";
          return;
        }
      }
      this.dorseyService.SaveEmployee(json).subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Updated successfully';

        }
      });
    }
  }

  Clear() {
    this.dialogRef.close(false);
  }
  company(event) {
    this.companyID = event.value;
  }

  role(event) {
    this.roleID = event.value;
  }

  designation(event) {
    this.designationID = event.value;
  }

  doj(type: string, event: MatDatepickerInputEvent<Date>) {
    const date = event.value;
    this.DOJ = date;
    let latest_date = this.datepipe.transform(this.DOJ, 'MM/dd/yyyy');
    this.DOJ = new Date(latest_date);
  }

  dob(type: string, event: MatDatepickerInputEvent<Date>) {
    const date = event.value;
    this.DOB = date;
    let latest_date = this.datepipe.transform(this.DOB, 'MM/dd/yyyy');
    this.DOB = new Date(latest_date);
    var year = this.DOB.getFullYear();
    var month = this.DOB.getMonth();
    var day = this.DOB.getDate();
    this.startDate = new Date(year, month, day + 1);
    this.minDate = this.startDate;
  }

  IsAlpha(evt) {
    var keyCode = (evt.which) ? evt.which : evt.keyCode;
    if ((keyCode < 65 || keyCode > 90) && (keyCode < 97 || keyCode > 123) && keyCode != 32)
      return false;
    return true;
  }

  ValidatePh(event: any) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter
      [46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
      (event.keyCode === 65 && event.ctrlKey === true) || // Allow: Ctrl+A
      (event.keyCode === 67 && event.ctrlKey === true) || // Allow: Ctrl+C
      (event.keyCode === 86 && event.ctrlKey === true) || // Allow: Ctrl+V
      (event.keyCode === 88 && event.ctrlKey === true) || // Allow: Ctrl+X
      (event.keyCode === 65 && event.metaKey === true) || // Cmd+A (Mac)
      (event.keyCode === 67 && event.metaKey === true) || // Cmd+C (Mac)
      (event.keyCode === 86 && event.metaKey === true) || // Cmd+V (Mac)
      (event.keyCode === 88 && event.metaKey === true) || // Cmd+X (Mac)
      (event.keyCode >= 35 && event.keyCode <= 39)  // Home, End, Left, Right
    ) {
      return;
    }

    if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
      event.preventDefault();
    }
  }

  ValidateEmail(event: any) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.Email === undefined || this.Email === "") {
      if (event.keyCode == 32) {
        event.preventDefault();
      }
    }
  }

  onChange(mrChange: MatRadioChange) {
    let mrButton: MatRadioButton = mrChange.source;
  }


  team(event) {
    this.TeamID = event.value;
  }

  UniqueEmail(event: any) {
    this.dorseyService.GetUsersList().subscribe(subData => {
      // tslint:disable-next-line:no-string-literal
      subData = JSON.parse(subData['_body']);
      this.userListData = subData;
    });
    let checkUser = this.userListData.filter(x => x.UserName.toString().toLowerCase().trim() === this.UserName.toString().toLowerCase().trim());
    if (checkUser.length > 0) {
      this.dataSelf = "Username already exist";
      this.Email = "";
      setTimeout(() => {
        this.dataSelf = '';
      }, 2000);
    }
  }

  valZero() {
    var re = /^(?=.*\d)(?=.*[1-9]).{1,16}$/;
    if (this.Phone) {
      if (!this.Phone.match(re)) {
        this.dataSelf = "Phone number invalid";
        this.Phone = "";
        setTimeout(() => {
          this.dataSelf = "";
        }, 2000);
      }
    }
  }
}
