import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DorseyService } from '../dorsey.service';
import { LocalStorageService } from 'ngx-webstorage';
import { DepartmentService } from '../services/department.service';
import { EmployeeLoginLevel } from '../common/models/login-level.model';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
})
export class TeamsComponent implements OnInit {
  employeeLevelLogin = EmployeeLoginLevel;
  Permissons: any;
  processData: any;
  status = false;
  TeamName: string;
  TeamID: string;
  condition = false;
  teamData: any;
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  isDesc: boolean = false;
  column = "";
  direction: number;
  searchtext: string;
  teamPaging = false;
  saveFilter = [];
  CompanyID: string;
  isDisable = false;
  disableSearch = false;
  currentUser: string;
  MenuPermissions: any;
  menuData: any;
  menuID: string;
  menuName: string;
  processIDs = [];
  keyProcessTeam: any;
  TeamDescription: string;

  granualActivity = [];
  empID: string;
  arrow = true;
  loginCompanyName: any;
  employeeData: any;
  userRoleAssignmentData: any;
  rolesData: any;
  UserID: any;
  processName = [];
  DepartmentList: any;
  DepartmentID: number;
  employeeLoginLevel = EmployeeLoginLevel;
  employeeDetails: any;
  constructor(public dialog: MatDialog,
    public dorseyService: DorseyService,
    private storage: LocalStorageService,
    private departmentService: DepartmentService) {
    this.CompanyID = this.storage.retrieve('companyID');
    this.currentUser = this.storage.retrieve('user');
    this.menuData = this.storage.retrieve('menuData');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');

    this.empID = this.storage.retrieve("EmployeeID");

    this.loginCompanyName = this.storage.retrieve('loginCompanyName');
    this.UserID = this.storage.retrieve('UserID');
    this.employeeDetails = this.storage.retrieve('employeeDetails');
  }

  ngOnInit() {
    this.getTeam();
    this.menuData = this.menuData.filter(x => x.MenuName.toString().toLowerCase() === 'team');
    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }
    this.getProcess();
    this.getDepartments();

  }

  getDepartments(): void {
    this.DepartmentList = [];
    this.departmentService.getDepartments(parseInt(this.CompanyID)).subscribe(resp => {
      this.DepartmentList = resp.Data;
      if (this.employeeDetails) {
        if (this.employeeDetails.IsEmployeeLoginLevel == this.employeeLevelLogin.Department) {
          this.DepartmentList = this.DepartmentList.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID)
        }
        // else if (this.employeeDetails.IsEmployeeLoginLevel == this.employeeLevelLogin.Team) {
        //   this.DepartmentList = this.DepartmentList.filter(ent => ent.TeamID == this.employeeDetails.TeamID)
        // }
      }
    });
  }

  exportAsXLSX() {
    let allData = JSON.parse(JSON.stringify(this.teamData));
    for (let i = 0; i < allData.length; i++) {
      allData[i]["Department Name"] = allData[i].DepartmentName;
      allData[i].Team = this.teamData[i].TeamName;
      allData[i].Description = this.teamData[i].TeamDescription;
      delete (allData[i].DepartmentID);
      delete (allData[i].DepartmentName);
      delete (allData[i].TeamName);
      delete (allData[i].TeamDescription);
      delete (allData[i].CompanyID);
      delete (allData[i].ProcessID);
      delete (allData[i].TeamID);
      delete (allData[i].CreatedDate);
      delete (allData[i].CreatedBy);
      delete (allData[i].IsActive);
      delete (allData[i].UpdatedBy);
      delete (allData[i].UpdatedDate);

    }
    this.dorseyService.generateExcel(allData, this.loginCompanyName + '_team' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Team', "Company: " + this.loginCompanyName, '', "Team Report", copyRight, 'FFFFFF00', 'FF0000FF');
  }

  searchText(): void {
    this.teamData = this.saveFilter;
    let tempTeam = this.teamData.filter(
      (item) => {
        return (
          (item.TeamName !== null && item.TeamName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.DepartmentName !== null && item.DepartmentName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.TeamDescription !== null && item.TeamDescription.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)

        );
      }
    );
    this.teamData = tempTeam;
    if (this.teamData.length == 0) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.teamData = this.saveFilter;
      this.rowCount = this.teamData.length;
      this.isDisable = false;
      this.getTeamPagination(this.rowCount);
    }
    else {
      this.rowCount = tempTeam.length;
      this.getTeamPagination(this.rowCount);
      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }

  getTeam(): void {
    this.teamData = [];
    this.dorseyService.GetTeam(this.CompanyID).subscribe(processDa => {
      processDa = JSON.parse(processDa['_body']);
      this.teamData = processDa;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      if (this.teamData.length === 0) {
        this.teamPaging = false;
        this.isDisable = true;
        this.disableSearch = true;
      }
      else {
        this.teamPaging = true;
        this.isDisable = false;
        this.disableSearch = false;
        if (this.employeeDetails) {
          if (this.employeeDetails.IsEmployeeLoginLevel == this.employeeLevelLogin.Department) {
            this.teamData = this.teamData.filter(ent => ent.DepartmentID == this.employeeDetails.DepartmentID)
          }
          else if (this.employeeDetails.IsEmployeeLoginLevel == this.employeeLevelLogin.Team) {
            this.teamData = this.teamData.filter(ent => ent.TeamID == this.employeeDetails.TeamID)
          }
          else {
            let data = this.teamData.filter(x => x.CompanyID === this.CompanyID);
            this.teamData = data;
          }
          let tempTeam = this.teamData;
          if (!this.employeeDetails.PermissionJson) {
            this.rowCount = this.teamData.length;
            this.saveFilter = JSON.parse(JSON.stringify(this.teamData));
            this.getTeamPagination(this.rowCount)
            return;
          }

          this.Permissons = JSON.parse(this.employeeDetails.PermissionJson);
          this.Permissons = this.Permissons["Team"];
          if (this.Permissons != undefined) {
            let teamKeys = Object.keys(this.Permissons);
            this.teamData = tempTeam.filter(x => x.CompanyID === this.CompanyID);
            if (teamKeys != undefined) {
              for (let i = 0; i < teamKeys.length; i++) {
                let actData = this.teamData.filter(x => x.TeamID.toString() === teamKeys[i].toString());
                if (actData.length > 0) {
                  let permissonKeys = Object.keys(this.Permissons[teamKeys[i]]);
                  if (permissonKeys.indexOf('R') > -1)
                    actData[0]['R'] = this.Permissons[teamKeys[i]]['R'];
                  else
                    actData[0]['R'] = false;
                  if (permissonKeys.indexOf('C') > -1)
                    actData[0]['C'] = this.Permissons[teamKeys[i]]['C'];
                  else
                    actData[0]['C'] = false;
                  if (permissonKeys.indexOf('U') > -1)
                    actData[0]['U'] = this.Permissons[teamKeys[i]]['U'];
                  else
                    actData[0]['U'] = false;
                  if (permissonKeys.indexOf('D') > -1)
                    actData[0]['D'] = this.Permissons[teamKeys[i]]['D'];
                  else
                    actData[0]['D'] = false;

                  this.granualActivity.push(actData[0]);
                }
              }
              this.teamData = this.granualActivity;
              if (this.teamData.length > 0) {
                this.rowCount = this.teamData.length;
                this.teamPaging = true;
                this.isDisable = false;
                this.disableSearch = false;
                this.saveFilter = JSON.parse(JSON.stringify(this.teamData));
                this.getTeamPagination(this.rowCount)
              }
              else if (this.teamData.length === 0) {
                this.teamPaging = false;
                this.isDisable = true;
                this.disableSearch = true;
              }
            }
          } else {
            if (this.teamData.length > 0) {
              this.rowCount = this.teamData.length;
              this.teamPaging = true;
              this.isDisable = false;
              this.disableSearch = false;
              this.saveFilter = JSON.parse(JSON.stringify(this.teamData));
              this.getTeamPagination(this.rowCount)
            }
            else if (this.teamData.length === 0) {
              this.teamPaging = false;
              this.isDisable = true;
              this.disableSearch = true;
            }
          }
        }
        else {
          this.teamData = processDa;
          this.saveFilter = JSON.parse(JSON.stringify(this.teamData));
          this.rowCount = this.teamData.length;
          this.getTeamPagination(this.rowCount)
        }
      }
    });
  }
  getTeamPagination(RowCount): void {
    this.dynamicRows = 10;
    this.rowsOnPageSet = [];
    this.rowsOnPage = 10;
    this.rowCount = RowCount;
    while (this.dynamicRows < this.rowCount) {
      this.rowsOnPageSet.push(this.dynamicRows);
      this.dynamicRows = this.dynamicRows + 10;
    }
    if (!this.rowsOnPageSet.includes(this.rowCount)) {
      this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }

  sort(property): void {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  OpenTeam(): void {
    this.TeamDescription = '';
    this.DepartmentID = 0;
    this.TeamName = '';
    this.dialogCall('new');
  }

  dialogCall(msg: string) {
    const dialogRef = this.dialog.open(TeamsDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: msg + 'æææ' + false,
    });
    dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.componentInstance.teamData = this.teamData;
    dialogRef.componentInstance.TeamDescription = this.TeamDescription;
    dialogRef.componentInstance.CompanyID = this.CompanyID;
    dialogRef.componentInstance.DepartmentList = this.DepartmentList;
    dialogRef.componentInstance.DepartmentID = this.DepartmentID;
    if (msg === 'new') {
      this.searchtext = '';
      this.getTeam();
    }
    if (msg === 'edit') {
      dialogRef.componentInstance.TeamName = this.TeamName;
      dialogRef.componentInstance.TeamID = this.TeamID;
    }
    dialogRef.afterClosed().subscribe(result => {

      this.TeamID = "";
      if (this.searchtext !== '' && this.searchtext !== undefined) {
        this.searchText();
      }
      else {
        this.getTeam();
      }
      if (result.toString() === 'true') {
        let statusMsg = '';
        if (msg === 'new') {
          statusMsg = 'Saved successfully';
        }
        else {
          statusMsg = 'Updated successfully';
        }
        this.getTeam();
        const dialogRefsub = this.dialog.open(TeamsDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: statusMsg + 'æææ' + false,
        });
        setTimeout(() => {
          this.getTeam();
        }, 500);
        dialogRefsub.afterClosed().subscribe(result1 => {
          this.dynamicRows = 10;
          if (this.searchtext !== '' && this.searchtext !== undefined) {
            this.searchText();
          }
          else {
            this.getTeam();
          }
        });
      }
    });
  }

  getProcess() {
    this.dorseyService.GetProcess(this.CompanyID).subscribe(processDa => {
      processDa = JSON.parse(processDa['_body']);
      this.processData = processDa;
      this.processData.sort(function (a, b) {
        return b.squence - a.squence;
      });
    });

  }

  getEmployee() {
    this.dorseyService.GetEmployee(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.employeeData = subData;
    });
  }
  EditTeam(element): void {
    this.TeamDescription = element.TeamDescription;
    this.DepartmentID = element.DepartmentID;
    this.TeamID = element.TeamID;
    this.TeamName = element.TeamName;
    this.storage.store('TName', this.TeamName);
    this.dialogCall('edit');
  }

  ifMaxRow(): void {
    if (this.rowsOnPage == this.teamData.length) {
      this.rowsOnPage = this.rowsOnPage - 1;
    }
  }

  DeleteTeam(element): void {
    this.TeamID = element.TeamID;
    const dialogRef = this.dialog.open(TeamsDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'Are you sure you want to delete team: ' + element.TeamName + '?æææ' + true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getEmployee();
        let issueCount = 0;
        let issueCount1 = 0;
        setTimeout(() => {
          for (let i = 0; i < this.employeeData.length; i++) {
            if (this.TeamID == this.employeeData[i].TeamID) {
              issueCount++;
            }
          }

          for (let i = 0; i < this.processData.length; i++) {
            if (this.TeamID == this.processData[i].TeamID) {
              issueCount1++;
            }
          }
          if (!issueCount && !issueCount1) {
            this.dorseyService.DeleteTeam(this.TeamID).subscribe(data => {
              if (data.ok) {
                this.ifMaxRow();
                this.condition = true;
                this.dialogCall('Deleted successfully');
              }
              this.dynamicRows = 10;
              this.rowsOnPageSet = [];
              this.getTeam();
            });
          } else {
            this.dialogCall("This team have an employee or process");
          }
        }, 500);
      }
    });
  }
}


@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
  providers: [DorseyService]
})
export class TeamsDialogComponent implements OnInit {
  condition = false;
  processData: any;
  dataSelf: string;
  TeamName: string;
  TeamID: string;
  teamData: any;
  CompanyID: string;
  currentUser: string;
  TeamDescription: string;
  processIDs = [];
  processName: any;
  btnDisabled = false;
  DepartmentID: number;
  DepartmentList: string;
  constructor(
    public dialogRef: MatDialogRef<TeamsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dorseyService: DorseyService, private storage: LocalStorageService) {
    this.data = this.data.split('æææ');
    if (this.data[1] === 'true') {
      this.condition = true;
    } else {
      this.condition = false;
    }
    this.data = this.data[0];
  }

  ngOnInit() {
  }

  onYesClick() {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  process(event) {
    this.processIDs = event.value;
  }

  departmentChange(event) {
    this.DepartmentID = event.value;
  }

  AddTeam() {
    this.btnDisabled = true;
    setTimeout(() => {
      this.btnDisabled = false;
    }, 1000);
    this.dataSelf = '';

    if (this.DepartmentID === 0 || !this.TeamName.trim()) {
      this.dataSelf = 'Please enter mandatory(*) fields';
      return;
    }
    this.saveTeam(this.data);
  }

  saveTeam(msg: string) {
    const json = {
      TeamName: this.TeamName,
      CreatedBy: this.currentUser,
      UpdatedBy: this.currentUser,
      TeamID: this.TeamID,
      CompanyID: this.CompanyID,
      ProcessID: JSON.stringify(this.processIDs),
      TeamDescription: this.TeamDescription,
      DepartmentID: this.DepartmentID
    };
    if (msg === 'new') {
      for (let i = 0; i < this.teamData.length; i++) {
        if (this.TeamName.toLowerCase().trim() === this.teamData[i].TeamName.toLowerCase().trim()) {
          this.dataSelf = "Team name already exists";
          return;
        }
      }
      this.dorseyService.SaveTeam(json).subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.dataSelf = 'Saved successfully';
          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Save failed';
        }
      });
    } else {
      for (let i = 0; i < this.teamData.length; i++) {
        if (this.TeamName.toLowerCase().trim() === this.teamData[i].TeamName.toLowerCase().trim() && this.TeamName.toLowerCase().trim() !== this.storage.retrieve('TName').toLowerCase().trim()) {
          this.dataSelf = "Team name already exists";
          return;
        }
      }
      this.dorseyService.SaveTeam(json).subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Updated successfully';
        }
      });
    }
  }

  Clear() {
    this.dialogRef.close(false);
  }

  IsAlpha(evt) {
    var keyCode = (evt.which) ? evt.which : evt.keyCode
    if ((keyCode < 65 || keyCode > 90) && (keyCode < 97 || keyCode > 123) && keyCode != 32)
      return false;
    return true;
  }

  validate() {
    for (let i = 0; i < this.teamData.length; i++) {
      if (this.TeamName && this.TeamName.toLowerCase().trim() === this.teamData[i].TeamName.toLowerCase().trim()) {
        this.dataSelf = "Team name already exists";
        setTimeout(() => {
          this.dataSelf = '';
        }, 2000);

      }
    }
  }
}
