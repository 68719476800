import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LocalStorageService } from 'ngx-webstorage';
import { DorseyService } from '../dorsey.service';
import { DepartmentService } from '../services/department.service';
import { Department } from './department.model';
import { EmployeeLoginLevel } from '../common/models/login-level.model';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {

  companyId: number;
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  MenuPermissions: any;
  menuData: any;
  menuID: string;
  menuName: string;
  DepartmentDescription: string;
  DepartmentName: string;
  DepartmentID: number;
  currentUser: string;
  departmentData: any;
  searchtext: string;
  DepartmentList: Array<Department>;
  isDisable = false;
  disableSearch = false;
  departmentPaging = false;
  editDepartmentName: string;
  saveFilter = [];
  column = "";
  direction: number;
  arrow = true;
  isDesc: boolean = false;
  loginCompanyName: any;
  condition: boolean;
  teamList: any;
  employeeLoginLevel = EmployeeLoginLevel;
  employeeDetails: any;
  constructor(public dialog: MatDialog,
    public departmentService: DepartmentService,
    private dorseyService: DorseyService,
    private storage: LocalStorageService) {
    this.currentUser = this.storage.retrieve('user');
    this.menuData = this.storage.retrieve('menuData');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
  }

  ngOnInit(): void {
    this.companyId = this.storage.retrieve('companyID');
    this.loginCompanyName = this.storage.retrieve('loginCompanyName');

    this.employeeDetails = this.storage.retrieve('employeeDetails');
    this.menuData = this.menuData.filter(x => x.MenuName.toString().toLowerCase() === 'departments');
    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }
    this.getDepartments();
    this.getTeams();
  }

  getTeams(): void {
    this.dorseyService.GetTeam(this.companyId.toString()).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.teamList = subData;
    });
  }
  getDepartments(): void {
    // this.DepartmentList = [];
    this.departmentService.getDepartments(this.companyId).subscribe(resp => {
      this.DepartmentList = resp.Data;
      if (this.DepartmentList.length === 0) {
        this.departmentPaging = false;
        this.isDisable = true;
        this.disableSearch = true;
      }
      else {
        if (this.employeeDetails) {
          if ((this.employeeDetails.IsEmployeeLoginLevel == this.employeeLoginLevel.Department) || (this.employeeDetails.IsEmployeeLoginLevel == this.employeeLoginLevel.Team)) {
            this.DepartmentList = this.DepartmentList.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID);
          }
          else if (this.employeeDetails.IsEmployeeLoginLevel === this.employeeLoginLevel.Company) {
            this.DepartmentList = this.DepartmentList;
          }
        }
        this.saveFilter = JSON.parse(JSON.stringify(this.DepartmentList));
        this.rowCount = this.DepartmentList.length;
        this.departmentPaging = true;
        this.isDisable = false;
        this.disableSearch = false;
        this.departmentPagination(this.rowCount);
      }
    });
  }
  /*Set Pagination for departments */

  departmentPagination(RowCount): void {
    this.dynamicRows = 10;
    this.rowsOnPageSet = [];
    this.rowsOnPage = 10;
    this.rowCount = RowCount;
    while (this.dynamicRows < this.rowCount) {
      this.rowsOnPageSet.push(this.dynamicRows);
      this.dynamicRows = this.dynamicRows + 10;
    }
    if (!this.rowsOnPageSet.includes(this.rowCount)) {
      this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }
  /*
  Search Departments data
  */
  searchText(): void {
    this.DepartmentList = this.saveFilter;
    let tempListDepartments = this.DepartmentList.filter(
      (item) => {
        return (
          (item.DepartmentName !== null && this.searchtext && item.DepartmentName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.DepartmentDescription !== null && this.searchtext && item.DepartmentDescription.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
        );
      }
    );
    this.DepartmentList = tempListDepartments;
    if (this.DepartmentList.length == 0) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.DepartmentList = this.saveFilter;
      this.rowCount = this.DepartmentList.length;
      this.isDisable = false;
      this.departmentPagination(this.rowCount);
    }
    else {
      this.rowCount = tempListDepartments.length;
      this.departmentPagination(this.rowCount);
      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }

  sort(property): void {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  newDepartment(): void {
    this.dialogCall('new');
  }

  /*
  Dialog open
  */
  dialogCall(msg: string): void {
    const dialogRef = this.dialog.open(DepartmentDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: msg + 'æææ' + false,
    });

    dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.componentInstance.DepartmentList = this.DepartmentList;
    dialogRef.componentInstance.companyId = this.companyId;
    if (msg === 'new') {
      this.searchtext = '';
      this.getDepartments();
    }
    if (msg === 'edit') {
      dialogRef.componentInstance.DepartmentName = this.DepartmentName;
      dialogRef.componentInstance.DepartmentDescription = this.DepartmentDescription;
      dialogRef.componentInstance.DepartmentID = this.DepartmentID;
      dialogRef.componentInstance.editDepartmentName = this.editDepartmentName;
      //dialogRef.componentInstance.inUse = this.inUse;
    }
    dialogRef.afterClosed().subscribe(result => {
      if (this.searchtext !== '' && this.searchtext !== undefined) {
        this.searchText();
      }
      else {
        this.getDepartments();
      }
      if (result.toString() === 'true') {
        let statusMsg = '';
        if (msg === 'new') {
          statusMsg = 'Saved successfully';
        }
        else {
          statusMsg = 'Updated successfully';
        }
        this.getDepartments();

        const dialogRefsub = this.dialog.open(DepartmentDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: statusMsg + 'æææ' + false,
          //  width: '30%'
        });
        setTimeout(() => {
          this.getDepartments();
        }, 500);
        dialogRefsub.afterClosed().subscribe(result1 => {
          this.dynamicRows = 10;
          if (this.searchtext !== '' && this.searchtext !== undefined) {
            this.searchText();
          }
          else {
            this.getDepartments();
          }
        });
      }
    });
  }

  /*
  Edit department
  */
  editDepartment(departmentRow): void {
    this.DepartmentID = departmentRow.DepartmentID;
    this.DepartmentName = departmentRow.DepartmentName;
    this.editDepartmentName = departmentRow.DepartmentName;
    this.DepartmentDescription = departmentRow.DepartmentDescription;
    this.companyId = departmentRow.CompanyID;
    this.dialogCall('edit');
  }

  ifMaxRow(): void {
    if (this.rowsOnPage == this.DepartmentList.length) {
      this.rowsOnPage = this.rowsOnPage - 1;
    }
  }

  /*
  Delete Department
  */
  deleteDepartment(departmentRow: any): void {
    this.DepartmentID = departmentRow.DepartmentID;
    const dialogRef = this.dialog.open(DepartmentDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'Are you sure you want to delete department: ' + departmentRow.DepartmentName + '?æææ' + true,

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (!this.teamList.find(ent => ent.DepartmentID == this.DepartmentID)) {
          this.departmentService.deleteDepartment(this.DepartmentID).subscribe(resp => {
            if (resp.Data) {
              this.ifMaxRow();
              this.condition = true;
              this.dialogCall('Deleted successfully');
              this.DepartmentList = [];
              this.getDepartments();
            } else {
              this.dialogCall('Delete Failed');
            }
            this.dynamicRows = 10;
            this.rowsOnPageSet = [];
          });
        } else {
          this.dialogCall("Delete Failed. This department is associated with Teams");
        }

      }
    });
  }

  exportAsXLSX(): void {
    let allData = JSON.parse(JSON.stringify(this.DepartmentList));
    for (let i = 0; i < allData.length; i++) {
      allData[i]["Department Name"] = allData[i].DepartmentName;
      allData[i]["Description"] = allData[i].DepartmentDescription;
      delete (allData[i].DepartmentID);
      delete (allData[i].DepartmentName);
      delete (allData[i].DepartmentDescription);
      delete (allData[i].CreatedDate);
      delete (allData[i].CreatedBy);
      delete (allData[i].UpdatedDate);
      delete (allData[i].UpdatedBy);
      delete (allData[i].IsActive);
      delete (allData[i].CompanyID);
    }
    this.dorseyService.generateExcel(allData, this.loginCompanyName + '_Departments' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Departments', "Company: " + this.loginCompanyName, '', "Departments Report", copyRight, 'FFFFFF00', 'FF0000FF');
  }
}

@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html'
})
export class DepartmentDialogComponent implements OnInit {
  condition = false;
  dataSelf: string;
  DepartmentName: string;
  DepartmentID: number;
  companyId: number;
  currentUser: string;
  DepartmentDescription: string;
  btnDisabled = false;
  DepartmentList: Array<Department>;
  editDepartmentName: string;
  constructor(
    public dialogRef: MatDialogRef<DepartmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public departmentService: DepartmentService) {
    this.data = this.data.split('æææ');
    if (this.data[1] === 'true') {
      this.condition = true;
    } else {
      this.condition = false;
    }
    this.data = this.data[0];
  }

  ngOnInit(): void {
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  validateDepartment(): void {
    this.dataSelf = '';
    if (!this.DepartmentName.trim()) {
      this.dataSelf = 'Please enter mandatory(*) fields';
      return;
    }
    this.saveDepartment(this.data);
  }

  saveDepartment(msg: string): void {
    const entity = {
      DepartmentName: this.DepartmentName,
      CreatedBy: this.currentUser,
      DepartmentID: this.DepartmentID,
      CompanyID: this.companyId,
      UpdatedBy: this.currentUser,
      DepartmentDescription: this.DepartmentDescription
    };
    if (msg === 'new') {

      if (this.DepartmentList.find(ent => ent.DepartmentName.toLowerCase().trim() == this.DepartmentName.toLowerCase().trim())) {
        this.dataSelf = "Department name already exists";
        return;
      }
      this.departmentService.saveDepartment(entity).subscribe(resp => {
        if (resp.Data === true) {
          this.dataSelf = 'Saved successfully';
          this.DepartmentName = '';
          this.DepartmentDescription = '';
          this.DepartmentList.push(entity);
          setTimeout(() => {
            this.dataSelf = '';
          }, 2000);
        } else {
          this.dataSelf = 'Save failed';
        }
      });
    } else {
      if (this.editDepartmentName != this.DepartmentName)
        if (this.DepartmentList.find(ent => ent.DepartmentName.toLowerCase().trim() == this.DepartmentName.toLowerCase().trim())) {
          this.dataSelf = "Department name already exists";
          return;
        }
      this.departmentService.saveDepartment(entity).subscribe(resp => {
        if (resp.Data === true) {
          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Updated successfully';
        }
      });
    }
  }


  Clear() {
    this.dialogRef.close(false);
  }

}
