import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(companyData: Array<any>, args?: any): any {
    //  if(args.direction)
    if (companyData) {
      return companyData.sort(function (a, b) {
        if (a[args.property] < b[args.property]) {
          return -1 * args.direction;
        }
        else if (a[args.property] > b[args.property]) {
          return 1 * args.direction;
        }
        else {
          return 0;
        }
      });
    }

    // else return -1;
  };

}
