import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DorseyService } from '../dorsey.service';
import { LocalStorageService } from 'ngx-webstorage';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-frustrationstype',
  templateUrl: './frustrationstype.component.html',
})
export class FrustrationstypeComponent implements OnInit {
  FrustrationsDescription: string;
  frustTypeData: any;
  isDisable = false;
  disableSearch = false;
  frustPaging = false;
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  isDesc: boolean = false;
  searchtext: string = '';
  saveFilter = [];
  FrustrationsTypeName: any;
  FrustrationsTypeID: any;
  condition = false;
  currentUser: string;
  CompanyID: string;
  pointerData: any;
  dataSelf: string;
  column = "";
  direction: number;
  arrow = true;
  menuData: any;
  menuID: string;
  menuName: string;
  empID: string;
  MenuPermissions: any;
  loginCompanyName: any;
  userTeamID: any;
  TeamName: string;
  teamData: any;
  userRoleAssignmentData: any;
  rolesData: any;
  UserID: any;
  EmpROleID: any;
  inUse = false;
  constructor(private dorseyService: DorseyService, public dialog: MatDialog, public storage: LocalStorageService) {
    this.currentUser = this.storage.retrieve('user');
    this.menuData = this.storage.retrieve('menuData');
    this.empID = this.storage.retrieve('EmployeeID');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
    this.loginCompanyName = this.storage.retrieve('loginCompanyName');
    this.userTeamID = this.storage.retrieve("TeamID");
    this.UserID = this.storage.retrieve('UserID');
    this.EmpROleID = this.storage.retrieve('EmpRoleID');

  }

  ngOnInit() {
    this.CompanyID = this.storage.retrieve('companyID');
    this.GetFrustrationType();
    this.GetUserRoleAssignment();
    let tempdata = this.menuData;
    this.menuData = tempdata.filter(x => x.MenuName.toString() === 'FrustrationsType');
    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }
    this.getTeam();

  }
  GetUserRoleAssignment() {
    this.dorseyService.GetUserRoleAssignment().subscribe(userRoles => {
      this.userRoleAssignmentData = JSON.parse(userRoles['_body']);
      this.dorseyService.GetRoles(this.CompanyID).subscribe(users => {
        this.rolesData = JSON.parse(users['_body']);
        if (this.empID.toString() !== '0') {
          let roles = this.userRoleAssignmentData.filter(x => x.UserID === this.UserID);
          if (roles.length > 0) {
            this.EmpROleID = roles[0].RoleID;
            let roleData = this.rolesData.filter(x => x.RoleID === this.EmpROleID);
            if (roleData.length > 0) {
              this.MenuPermissions = JSON.parse(roleData[0].MenuJson);
            }

          }
        }
      });
    });
  }

  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  GetFrustrationType() {
    this.frustTypeData = [];
    this.dorseyService.GetFrustrationType("1", this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.frustTypeData = subData;
      this.rowCount = this.frustTypeData.length;
      if (this.frustTypeData.length === 0) {
        this.frustPaging = false;
        this.isDisable = true;
        this.disableSearch = true;
      }
      else {
        this.saveFilter = JSON.parse(JSON.stringify(this.frustTypeData));
        this.frustPaging = true;
        this.isDisable = false;
        this.disableSearch = false;

        this.dynamicRows = 10;
        this.rowsOnPageSet = [];

        while (this.dynamicRows < this.rowCount) {
          this.rowsOnPageSet.push(this.dynamicRows);
          this.dynamicRows = this.dynamicRows + 10;
        }
        if (!this.rowsOnPageSet.includes(this.rowCount))
          this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet.length === 1) {
          if (this.rowsOnPageSet[0] <= 10)
            this.rowsOnPage = this.rowsOnPageSet[0];
        }
      }
    });


  }

  Searchtext() {
    this.frustTypeData = this.saveFilter;
    let tempFrustType = this.frustTypeData.filter(
      (item) => {
        return (
          (item.FrustrationsTypeName !== null && item.FrustrationsTypeName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.FrustrationsDescription !== null && item.FrustrationsDescription.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)

        );
      }
    );
    this.frustTypeData = tempFrustType;
    if (this.frustTypeData.length == 0) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.frustTypeData = this.saveFilter;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      this.rowCount = this.frustTypeData.length;
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount))
        this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet) {

        this.rowsOnPage = this.rowsOnPageSet[0];
      }
      this.isDisable = false;
    } else {

      this.rowCount = tempFrustType.length;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount)) {
        this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }

      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }



  exportAsXLSX() {
    let allData = JSON.parse(JSON.stringify(this.frustTypeData));
    for (let i = 0; i < allData.length; i++) {
      allData[i]["Frustration Types"] = allData[i].FrustrationsTypeName;
      allData[i]["Description"] = allData[i].FrustrationsDescription;
      delete (allData[i].FrustrationsTypeID);
      delete (allData[i].FrustrationsTypeName
      );
      delete (allData[i].FrustrationsDescription);
      delete (allData[i].CreatedDate);

      delete (allData[i].CreatedBy);
      delete (allData[i].UpdatedDate);
      delete (allData[i].UpdatedBy);
      delete (allData[i].IsActive);

      delete (allData[i].Process);
      delete (allData[i].PointerTypeID);
      delete (allData[i].CompanyID);
      delete (allData[i].VersionID);
    }

    this.dorseyService.generateExcel(allData, this.loginCompanyName + '_frustration Types' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Frustration Types', "Company: " + this.loginCompanyName, '', "Frustration Types Report", copyRight, 'FFFFFF00', 'FF0000FF');
  }

  getTeam() {
    this.TeamName = '';
    this.dorseyService.GetTeam(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.teamData = subData;
      let tempData = this.teamData.filter(x => x.TeamID === this.userTeamID);
      if (tempData.length > 0) {
        this.TeamName = tempData[0].TeamName;
      }
    });
  }

  OpenFrustType() {
    this.dialogCall('new');
  }

  dialogCall(msg: string) {
    const dialogRef = this.dialog.open(FrustTypeDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: msg + 'æææ' + false,
    });
    dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.componentInstance.frustTypeData = this.frustTypeData;
    dialogRef.componentInstance.CompanyID = this.CompanyID;
    if (msg === 'new') {
      this.searchtext = '';
      this.GetFrustrationType();
    }
    if (msg === 'edit') {

      dialogRef.componentInstance.FrustrationsTypeName = this.FrustrationsTypeName;
      dialogRef.componentInstance.FrustrationsTypeID = this.FrustrationsTypeID;
      dialogRef.componentInstance.FrustrationsDescription = this.FrustrationsDescription;
      dialogRef.componentInstance.inUse = this.inUse;

    }

    dialogRef.afterClosed().subscribe(result => {

      if (this.searchtext !== '' && this.searchtext !== undefined) {
        this.Searchtext();
      }
      else {
        this.GetFrustrationType();
      }

      if (result.toString() === 'true') {

        let statusMsg = '';
        if (msg === 'new') {
          statusMsg = 'Saved successfully';
        }
        else {

          statusMsg = 'Updated successfully';
        }
        this.GetFrustrationType();

        const dialogRefsub = this.dialog.open(FrustTypeDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: statusMsg + 'æææ' + false,
          //  width: '30%'
        });

        setTimeout(() => {
          this.GetFrustrationType();
        }, 500);
        dialogRefsub.afterClosed().subscribe(result1 => {
          this.dynamicRows = 10;
          //  this.rowsOnPageSet=[];

          if (this.searchtext !== '') {
            this.Searchtext();
          }
          else {
            this.GetFrustrationType();
          }


        });



      }

    });
  }

  EditFrustType(element) {

    this.FrustrationsTypeID = element.FrustrationsTypeID;

    this.FrustrationsTypeName = element.FrustrationsTypeName;
    this.storage.store('FTName', this.FrustrationsTypeName);
    this.FrustrationsDescription = element.FrustrationsDescription;

    this.GetPointer();
    let issueCount = 0;
    setTimeout(() => {

      for (let i = 0; i < this.pointerData.length; i++) {

        if (this.FrustrationsTypeID == this.pointerData[i].FrustrationsTypeID) {
          issueCount++;
        }
      }
      if (!issueCount) {
        this.inUse = false;
        this.dialogCall('edit');

      } else {
        this.inUse = true;
        this.dialogCall("edit");
      }
    }, 500);
    //this.dialogCall('edit');
  }

  GetPointer() {
    this.pointerData = [];
    this.dorseyService.GetPointer(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.pointerData = subData;
    });
  }

  ifMaxRow() {

    if (this.rowsOnPage == this.frustTypeData.length) {
      this.rowsOnPage = this.rowsOnPage - 1;
    }
  }

  DeleteFrustType(element) {
    this.FrustrationsTypeID = element.FrustrationsTypeID;
    this.GetPointer();
    let issueCount = 0;
    setTimeout(() => {

      for (let i = 0; i < this.pointerData.length; i++) {

        if (this.FrustrationsTypeID == this.pointerData[i].FrustrationsTypeID) {
          issueCount++;
        }
      }

      if (issueCount) {
        this.dialogCall("This frustration type is in use");
      }
      else {


        const dialogRef = this.dialog.open(FrustTypeDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: 'Are you sure you want to delete frustration type: ' + element.FrustrationsTypeName + '?æææ' + true,
          // width: '40%'
        });
        //   this.condition = true;

        dialogRef.afterClosed().subscribe(result => {
          if (result) {

            /// this.condition = true;

            if (!issueCount) {
              this.dorseyService.DeleteType(this.FrustrationsTypeID).subscribe(data => {

                if (data.ok) {
                  this.ifMaxRow();
                  this.condition = true;
                  this.dialogCall('Deleted successfully');
                  this.frustTypeData = [];
                  this.GetFrustrationType();
                }
                this.dynamicRows = 10;
                this.rowsOnPageSet = [];


              });
            } else {
              //this.dialogCall("This frustration type is in use");
            }



          }
        });
      }
    }, 1000);

  }

}


@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
  // styleUrls: ['dialog.css'],
  providers: [DorseyService]
})
export class FrustTypeDialogComponent implements OnInit {
  FrustrationsDescription: string;
  condition = false;
  dataSelf: string;
  FrustrationsTypeName: string;
  FrustrationsTypeID: string;
  frustTypeData: any;
  CompanyID: string;
  currentUser: string;
  TeamDescription: string;
  processIDs = [];
  inUse: boolean;
  btnDisabled = false;
  constructor(
    public dialogRef: MatDialogRef<FrustTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dorseyService: DorseyService, private storage: LocalStorageService) {
    //  dialogRef.disableClose=true;
    this.data = this.data.split('æææ');
    if (this.data[1] === 'true') {
      this.condition = true;
    } else {
      this.condition = false;
    }
    this.data = this.data[0];
  }
  ngOnInit() {



  }
  onYesClick() {
    this.dialogRef.close(true);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  process(event) {
    this.processIDs = event.value;
  }
  AddFrustType() {
    this.btnDisabled = true;

    setTimeout(() => {
      this.btnDisabled = false;
    }, 1000);
    let count = 0;
    const Editcount = 0;

    if (this.FrustrationsTypeName == undefined ||
      this.FrustrationsTypeName.trim() === '') {
      this.dataSelf = 'Please enter mandatory(*) fields';
      if (this.data === 'new') {
      }
      count++;
    }

    if (count === 0 && Editcount === 0) {
      this.dataSelf = '';
      if (this.data === 'new') {
        this.Saving(this.data);
      } else {
        this.Saving(this.data);
      }
    } else {
    }
  }


  GetFrustrationType() {
    this.frustTypeData = [];
    this.dorseyService.GetFrustrationType("1", this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.frustTypeData = subData;

    });
  }
  Saving(msg: string) {



    const json = {

      FrustrationsTypeName: this.FrustrationsTypeName,
      CreatedBy: this.currentUser,
      FrustrationsTypeID: this.FrustrationsTypeID,
      CompanyID: this.CompanyID,
      PointerTypeID: 1,
      FrustrationsDescription: this.FrustrationsDescription

    };
    if (msg === 'new') {

      for (let i = 0; i < this.frustTypeData.length; i++) {
        if (this.FrustrationsTypeName.toLowerCase().trim() === this.frustTypeData[i].FrustrationsTypeName.toLowerCase().trim()) {

          this.dataSelf = "Frustration type already exists";
          return;
          setTimeout(() => {
            this.dataSelf = '';
            return;
          }, 2000);
          //this.companyName = "";
        }
      }

      this.dorseyService.SaveTypes(json, 'No').subscribe(data => {



        if (data['_body'].toString() === 'true') {
          this.dataSelf = 'Saved successfully';
          this.GetFrustrationType();
          //this.dialogRef.close(true);
          this.FrustrationsTypeName = '';
          setTimeout(() => {
            this.dataSelf = '';
          }, 2000);
        } else {
          this.dataSelf = 'Save failed';
        }

      });
    } else {

      for (let i = 0; i < this.frustTypeData.length; i++) {
        if (this.FrustrationsTypeName.toLowerCase().trim() === this.frustTypeData[i].FrustrationsTypeName.toLowerCase().trim() && this.FrustrationsTypeName.toLowerCase().trim() !== this.storage.retrieve('FTName').toLowerCase().trim()) {

          this.dataSelf = "Frustration type already exists";
          return;
          setTimeout(() => {
            this.dataSelf = '';
            return;
          }, 2000);
          //this.companyName = "";
        }
      }

      this.dorseyService.SaveTypes(json, 'No').subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Updated successfully';
        }
      });
    }
  }
  Clear() {
    this.dialogRef.close(false);
  }
}
