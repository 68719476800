import { Component, Input, OnInit } from '@angular/core';
import { CompanyService } from '../services/company.service';
import { CompanyDetails, OrganizationLicenseDetails } from '../company/company.model';
import { CompanyDialogComponent } from '../company/company.component';
import { MatDialog, MatSlideToggleChange, MatTableDataSource } from '@angular/material';
import { LocalStorageService } from 'ngx-webstorage';
import { DorseyService } from '../dorsey.service';
import { ValidationDialogComponent } from '../add-score-card/validation-dialog';
import { ResponseDialogComponent } from '../add-score-card/response-dialog';
import { LoginComponent } from '../login/login.component';
@Component({
  selector: 'app-sub-company',
  templateUrl: './sub-company.component.html',
  styleUrls: ['./sub-company.component.scss']
})
export class SubCompanyComponent implements OnInit {
  @Input() organizationId: number;
  @Input() companyId: number;
  @Input() isSuperAdmin: boolean;
  @Input() entityName: string;
  @Input() entityLicenses: number;
  @Input() subCompanyCount: number;
  @Input() subEntityLicenses: number;
  @Input() remainingEntityLicenses: number;
  errorMessages: string[] = [];
  currentUser: string;
  subCompanyDetails: Array<CompanyDetails> = [];
  subCompany: CompanyDetails;
  userData: any;
  showSubEntities: boolean = false;
  parentCompanyId: number;
  subEntityLevel: number = 0;
  currentRow: number;
  adminEmail: string;
  adminPassword: string;
  adminUserName: string;
  loginUsername: string;
  searchtext: string = '';
  // p: number = 1;
  // rowsOnPage = 10;
  // rowsOnPageSet = [];
  // rowCount: any;
  // dynamicRows = 10;
  isDesc: boolean = false;
  column = '';
  direction: number;
  arrow = true;
  saveFilter: Array<CompanyDetails> = [];
  UserName: string;
  Password: string;
  isDisable = false;
  isSearchDisable = false;
  condition: boolean;
  organizationDetails: any;
  // companyPaging = false;
  dataSource = new MatTableDataSource();
  isCompanyLogin: boolean;

  constructor(
    private companyService: CompanyService,
    private dialog: MatDialog,
    private storage: LocalStorageService,
    private login: LoginComponent,
    private dorseyService: DorseyService) {
    this.currentUser = this.storage.retrieve('user');
    this.adminUserName = this.storage.retrieve('adminUname');
    this.adminEmail = this.storage.retrieve('username');
    this.adminPassword = this.storage.retrieve('password');
    this.loginUsername = this.storage.retrieve('loginUsername');
    this.organizationDetails = this.storage.retrieve("OrganizationDetails");
    this.organizationId = this.organizationDetails.OrganizationId;
    this.isSuperAdmin = this.organizationDetails.IsSuperAdmin;
    this.isCompanyLogin = this.storage.retrieve("isCompanyLogin");
  }

  ngOnInit(): void {
    this.getSubCompanies(this.companyId);
    this.getUsers();
  }

  subCompaniesAlreadyCreated: number;
  SubCompaniesLicensesAlreadyProvided: number;

  getSubCompanies(companyId: number): void {
    this.companyService.getSubCompanyDetailsByCompanyId(companyId).subscribe(resp => {
      this.subCompanyDetails = resp.Data;
      this.subCompaniesAlreadyCreated = this.subCompanyDetails.length;
      this.SubCompaniesLicensesAlreadyProvided = this.subCompanyDetails.reduce((sum, current) => sum + current.NoOfSubCompanies, 0);
      // this.rowsOnPageSet = [];
      // this.dynamicRows = 10;
      if (this.subCompanyDetails.length === 0) {
        // this.companyPaging = false;
        this.isDisable = true;
        this.isSearchDisable = true;
      }
      else {
        this.saveFilter = JSON.parse(JSON.stringify(this.subCompanyDetails));
        // this.companyPaging = true;
        this.isDisable = false;
        this.isSearchDisable = false;
        // this.rowCount = this.subCompanyDetails.length;
        // this.dataSource = new MatTableDataSource(this.subCompanyDetails);
        // while (this.dynamicRows < this.rowCount) {
        //   this.rowsOnPageSet.push(this.dynamicRows);
        //   this.dynamicRows = this.dynamicRows + 10;
        // }
        // if (!this.rowsOnPageSet.includes(this.rowCount))
        //   this.rowsOnPageSet.push(this.rowCount);
        // if (this.rowsOnPageSet.length === 1) {
        //   if (this.rowsOnPageSet[0] <= 10)
        //     this.rowsOnPage = this.rowsOnPageSet[0];
        // }
      }
    })
  }

  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  viewSubEntities(element): void {
    this.currentRow = element.CompanyID;
    this.parentCompanyId = element.CompanyID;
    this.subEntityLevel = this.subEntityLevel + 1;

    this.companyService.getSubCompaniesCountByCompanyId(element.CompanyID).subscribe(resp => {
      this.subCompanyCount = resp.Data;
      this.entityLicenses = resp.AdditionalData.EntityLicenses;;
      if (this.subEntityLevel > 1) {
        this.showSubEntities = false;
        this.entityName = '';
        setTimeout(() => {
          this.showSubEntities = true;
          this.entityName = element.CompanyName;
        }, 200);
      } else {
        this.showSubEntities = true;
        this.entityName = element.CompanyName;
      }
    });

  }

  enableScoreCardChange(event: MatSlideToggleChange, row): void {
    const dialogRef = this.dialog.open(CompanyDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'Are you sure you want to enable score card for this entity: ' + row.CompanyName + '?æææ' + true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.subCompany = row;
        this.subCompany.EnableScoreCard = event.checked;
        this.companyService.UpdateEnableScoreCard(this.subCompany).subscribe(resp => {
          if (resp.Data) {
            this.dialog.open(ResponseDialogComponent, {
              data: {
                message: 'Updated successfully'
              }
            });
          }
        });
      } else {
        this.subCompanyDetails.find(ele => ele.CompanyID === row.CompanyID).EnableScoreCard = false;
      }

    });
  }

  newSubCompany(): void {
    this.searchtext = '';
    this.Searchtext();
    this.errorMessages = [];
    this.remainingEntityLicenses = 0;
    var subEntitiesAlreadyAssigned = 0;
    this.entityLicenses = 0;
    this.getSubCompanies(this.companyId);
    this.companyService.getSubCompaniesCountByCompanyId(this.companyId).subscribe(resp => {
      this.companyService.getSubCompanyDetailsByCompanyId(this.companyId).subscribe(resp1 => {

        if (resp1.Data) {
          resp1.Data.forEach(ent => {
            subEntitiesAlreadyAssigned = subEntitiesAlreadyAssigned + ent.NoOfSubCompanies;
          });
        }
        this.entityLicenses = resp.AdditionalData.EntityLicenses;
        this.subCompanyCount = resp.Data + subEntitiesAlreadyAssigned;
        this.remainingEntityLicenses = this.entityLicenses - this.subCompanyCount;
        if (this.entityLicenses <= this.subCompanyCount) {
          this.errorMessages.push('Cannot create new sub entity as there are no licenses');
          this.dialog.open(ValidationDialogComponent, {
            data: {
              errorMessages: this.errorMessages
            }
          });
          return;
        }
        this.remainingEntityLicenses = this.remainingEntityLicenses - 1;
        this.subCompany = {
          CompanyID: 0,
          CompanyName: '',
          ParentID: this.companyId,
          Address1: '',
          Address2: '',
          Country: '',
          State: '',
          City: '',
          Pincode: '',
          Email: '',
          Phone1: '',
          Phone2: '',
          EnableScoreCard: false,
          EnableSubCompanies: false,
          NoOfSubCompanies: 0,
          OrganizationID: this.organizationId,
          UserID: 0,
          UserName: '',
          Password: '',
          FirstName: '',
          LastName: '',
          EmployeeID: 0,
          CreatedBy: this.currentUser,
          UpdatedBy: this.currentUser,
          RowID: 0,
          RoleID: 0

        }
        const subDialogref = this.dialog.open(CompanyDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: 'new' + 'æææ' + true,
        });
        subDialogref.componentInstance.companyDetails = this.subCompany;
        subDialogref.componentInstance.userListData = this.userData;
        subDialogref.componentInstance.companyData = this.subCompanyDetails;
        subDialogref.componentInstance.CompanyID = this.companyId;
        subDialogref.componentInstance.entityLicenses = this.entityLicenses;
        subDialogref.componentInstance.remainingEntityLicenses = this.remainingEntityLicenses;
        subDialogref.afterClosed().subscribe(result => {
          this.getSubCompanies(this.companyId);
        });
      });
    });







  }

  getUsers() {
    this.dorseyService.GetUsersList().subscribe(users => {
      this.userData = JSON.parse(users['_body']);
    });
  }


  EditCompany(companyRow) {
    this.subCompany = companyRow;
    this.subEntityLicenses = this.subCompany.NoOfSubCompanies;
    this.remainingEntityLicenses = 0;
    this.entityLicenses = 0;
    this.storage.store('CName', companyRow.CompanyName);
    this.companyService.getSubCompaniesCountByCompanyId(this.subCompany.ParentID).subscribe(resp => {
      this.companyService.getSubCompanyDetailsByCompanyId(this.subCompany.ParentID).subscribe(resp1 => {
        var subEntitiesAlreadyAssigned = 0;
        if (resp1.Data) {
          resp1.Data.forEach(ent => {
            subEntitiesAlreadyAssigned = subEntitiesAlreadyAssigned + ent.NoOfSubCompanies;
          });
        }
        this.entityLicenses = resp.AdditionalData.EntityLicenses;
        this.subCompanyCount = resp.Data + subEntitiesAlreadyAssigned;
        this.remainingEntityLicenses = this.entityLicenses - this.subCompanyCount;
        this.remainingEntityLicenses = this.subEntityLicenses + this.remainingEntityLicenses;
        this.dialogCall('edit');
      });
    });
  }

  // ifMaxRow() {
  //   if (this.rowsOnPage == this.subCompanyDetails.length) {
  //     this.rowsOnPage = this.rowsOnPage - 1;
  //   }
  // }

  DeleteCompany(element) {
    var subCompanyCount = 0;
    this.entityLicenses = 0;
    this.companyService.getSubCompaniesCountByCompanyId(element.CompanyID).subscribe(resp => {
      subCompanyCount = resp.Data;
      this.entityLicenses = resp.AdditionalData.EntityLicenses;
      if (subCompanyCount > 0) {
        this.dialogCall("Delete Failed. This entity has sub entities");
        return;
      }
      else {
        const dialogRef = this.dialog.open(CompanyDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: 'Are you sure you want to delete entity: ' + element.CompanyName + '?æææ' + true,
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            /// this.condition = true;
            this.companyService.deleteCompany(element.CompanyID).subscribe(resp => {
              if (resp.Data) {
                // this.ifMaxRow();
                this.condition = false;
                this.dialogCall('Deleted successfully');
                this.showSubEntities = false;
              }
              // this.dynamicRows = 10;
              // this.rowsOnPageSet = [];
              this.getSubCompanies(this.companyId);
            });
          }
        });
      }
    });

  }

  Navigate(element) {
    this.login.GetTeam(element.CompanyID);
    this.dorseyService.GetUsersList().subscribe(users => {
      this.userData = JSON.parse(users['_body']);
      let tempUser = this.userData.filter(x => x.CompanyID.toString() === element.CompanyID.toString() && x.EmployeeID.toString() === '0');
      this.login.GetUserRoleAssignment();
      this.storage.store("adminEmail", this.adminEmail);
      this.storage.store("adminPassword", this.adminPassword);
      this.storage.store("adminUserName", this.adminUserName);
      this.storage.store("userData", this.userData);
      this.storage.store("companyNavigate", true);
      if (tempUser.length > 0) {
        this.login.LoginWithCompany(tempUser[0].UserName, tempUser[0].Password, element.CompanyID);
      }
    });
  }

  Searchtext() {
    // this.getSubCompanies(this.companyId)
    this.subCompanyDetails = this.saveFilter;
    let tempCompany = this.subCompanyDetails.filter(
      (item) => {
        return (
          (item.CompanyName !== null && item.CompanyName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.Email !== null && item.Email.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.Phone1 !== null && item.Phone1.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
        );
      }
    );
    this.subCompanyDetails = tempCompany;
    if (this.subCompanyDetails.length == 0) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.subCompanyDetails = this.saveFilter;
      // this.dynamicRows = 10;
      // this.rowsOnPageSet = [];
      // this.rowCount = this.subCompanyDetails.length;
      // while (this.dynamicRows < this.rowCount) {
      //   this.rowsOnPageSet.push(this.dynamicRows);
      //   this.dynamicRows = this.dynamicRows + 10;
      // }
      // if (!this.rowsOnPageSet.includes(this.rowCount))
      //   this.rowsOnPageSet.push(this.rowCount);
      // if (this.rowsOnPageSet) {

      //   this.rowsOnPage = this.rowsOnPageSet[0];
      // }
      this.isDisable = false;
    }
    // else {

    //   this.rowCount = tempCompany.length;
    //   this.dynamicRows = 10;
    //   this.rowsOnPageSet = [];
    //   while (this.dynamicRows < this.rowCount) {
    //     this.rowsOnPageSet.push(this.dynamicRows);
    //     this.dynamicRows = this.dynamicRows + 10;
    //   }
    //   if (!this.rowsOnPageSet.includes(this.rowCount)) {
    //     this.rowsOnPageSet.push(this.rowCount);
    //     if (this.rowsOnPageSet)
    //       this.rowsOnPage = this.rowsOnPageSet[0];
    //   }

    //   if (this.rowsOnPageSet.length === 1) {
    //     if (this.rowsOnPageSet[0] <= 10)
    //       this.rowsOnPage = this.rowsOnPageSet[0];
    //   }
    //}
  }

  GetUsers() {
    this.dorseyService.GetUsersList().subscribe(users => {
      this.userData = JSON.parse(users['_body']);
    });
  }

  dialogCall(msg: string) {
    const dialogRef = this.dialog.open(CompanyDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: msg + 'æææ' + false,
    });
    dialogRef.componentInstance.companyData = this.subCompanyDetails;
    dialogRef.componentInstance.companyDetails = this.subCompany;
    dialogRef.componentInstance.userListData = this.userData;
    dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.componentInstance.isSuperAdmin = this.isSuperAdmin;
    dialogRef.componentInstance.entityLicenses = this.entityLicenses;
    dialogRef.componentInstance.remainingEntityLicenses = this.remainingEntityLicenses;
    dialogRef.componentInstance.subEntityLicenses = this.subEntityLicenses;
    if (msg == 'new') {
      this.searchtext = '';
      this.getSubCompanies(this.companyId);
    }
    if (msg == 'edit') {
      this.searchtext = '';
    }
    dialogRef.afterClosed().subscribe(result => {
      if (this.searchtext !== '' && this.searchtext !== undefined) {
        this.Searchtext();

      }
      else {
        this.getSubCompanies(this.companyId);
      }

      if (result && result.toString() === 'true') {
        let statusMsg = '';
        if (msg === 'new') {
          statusMsg = 'Saved successfully';
        }
        else {
          statusMsg = 'Updated successfully';
        }
        // this.dynamicRows = 10;
        const dialogRefsub = this.dialog.open(CompanyDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: statusMsg + 'æææ' + false,
        });

        setTimeout(() => {
          this.getSubCompanies(this.companyId);
          this.GetUsers();
        }, 500);

        dialogRefsub.afterClosed().subscribe(result1 => {
          if (this.searchtext !== '') {
            this.Searchtext();
          }
          else {
            this.getSubCompanies(this.companyId);
            this.GetUsers();
          }
        });
      }
    });
  }


}
