import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { CompanyComponent, CompanyDialogComponent } from './company/company.component'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPrintModule } from 'ngx-print';

import {
  MatTabsModule, MatIconModule, MatMenuModule, MatFormFieldModule, MatTableModule,
  MatDialogModule, MatInputModule, MatOptionModule, MatSelectModule,
  MatRadioModule, MatDatepickerModule, MatNativeDateModule, MatButtonModule,
  MatSlideToggleModule, MatPaginatorModule, MatTooltipModule, MatCheckboxModule,
  MatExpansionModule, MatListModule, MatSidenavModule, MatSliderModule, MatCardModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProcessComponent, ProcessDialogComponent } from './process/process.component';
import { ActivityComponent, ActivityDialogComponent } from './activity/activity.component';
import { TeamsComponent, TeamsDialogComponent } from './teams/teams.component';
import { EmployeeComponent, EmployeeDialogComponent } from './employee/employee.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PointerComponent, PointerDialogComponent, PointerDialogComponent2 } from './pointer/pointer.component';
import { ProcessMapComponent, ProcessMapDialogComponent, ProcessMapDialogComponent2 } from './process-map/process-map.component';
import { CaseComponent, CaseDialogComponent } from './case/case.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MetricComponent, MetricDialogComponent } from './metric/metric.component';
import { ProjectComponent, ProjectDialogComponent } from './project/project.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SortPipe } from './sort.pipe';

// import  from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ReportCategoryComponent, DialogComponent } from './report-category/report-category.component';
import { ReportProcessComponent, ReportProcessDialogComponent } from './report-process/report-process.component';
import { ReportProjectComponent, ReportProjectDialogComponent } from './report-project/report-project.component';
import { ReportMetricProcessComponent, ReportMetricDialogComponent } from './report-metric-process/report-metric-process.component';
import { FusionChartsModule } from "angular-fusioncharts";
import { ChartsModule } from 'ng2-charts';
// Import FusionCharts library and chart modules
import * as FusionCharts from "fusioncharts";
import * as Charts from "fusioncharts/fusioncharts.charts";

import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { FrustrationReportsComponent } from './frustration-reports/frustration-reports.component';
import { ProjectsNoactionsComponent } from './projects-noactions/projects-noactions.component';
import { OtherFrustrationComponent } from './other-frustration/other-frustration.component';
import { FrustrationCommentsComponent } from './frustration-comments/frustration-comments.component';
import { NometricProcessComponent } from './nometric-process/nometric-process.component';
import { ReportsComponent } from './reports/reports.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent, LoginMetricDialogComponent } from './login/login.component';
import { RolesComponent, RolePermissionsDiaglogComponent } from './roles/roles.component';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { HipriorityComponent } from './hipriority/hipriority.component';
import { FrustrationstypeComponent, FrustTypeDialogComponent } from './frustrationstype/frustrationstype.component';
import { ReportHipriorityComponent, ReportSeverityDialogComponent } from './report-hipriority/report-hipriority.component';

import { AlertsComponent } from './alerts/alerts.component';
import { MetricdataComponent } from './metricdata/metricdata.component';
import { MetricDataDialogComponent } from './metricdata/metricdata.component';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LongPress } from '../app/long-press';
import { UsermanualComponent } from './usermanual/usermanual.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DepartmentDialogComponent, DepartmentsComponent } from './departments/departments.component';
import { GoalDialogComponent, GoalsComponent } from './goals/goals.component';
import { PerformanceScoreCardComponent, PerformanceScoreCardDialogComponent } from './performance-score-card/performance-score-card.component';
import { QuarterComponent } from './quarter/quarter.component';
import { MessageDialogComponent } from './common/dialog';
import { AddScoreCardComponent } from './add-score-card/add-score-card.component';
import { ValidationDialogComponent } from './add-score-card/validation-dialog';
import { ViewScoreCardComponent } from './view-score-card/view-score-card.component';
import { ResponseDialogComponent } from './add-score-card/response-dialog';
import { ReportActivityIssuesComponent } from './report-activity-issues/report-activity-issues.component';
import { UserPermisionsComponent } from './user-permisions/user-permisions.component';
import { OrganizationComponent, OrganizationDialogComponent } from './organization/organization.component';
import { SubCompanyComponent } from './sub-company/sub-company.component';
import { NumberDirective } from './common/directives/numbers-only.directive';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { AzureDetails } from './login/azure-details.model';
import { MsalUserService } from './login/msal-user.service';
// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, Charts, FusionTheme);

const appRoutes: Routes = [
  { path: 'appcomponent', component: AppComponent },
  { path: 'entity', component: CompanyComponent },
  { path: 'process', component: ProcessComponent },
  { path: 'activity', component: ActivityComponent },
  { path: 'pointer', component: PointerComponent },
  { path: 'employee', component: EmployeeComponent },
  { path: 'team', component: TeamsComponent },
  { path: 'processMap', component: ProcessMapComponent },
  { path: 'case', component: CaseComponent },
  { path: 'metric', component: MetricComponent },
  { path: 'project', component: ProjectComponent },
  { path: 'report', component: ReportCategoryComponent },
  { path: 'reportProcess', component: ReportProcessComponent },
  { path: 'reportProject', component: ReportProjectComponent },
  { path: 'reportMetric', component: ReportMetricProcessComponent },
  { path: 'reportFrustration', component: FrustrationReportsComponent },
  { path: 'noactionProject', component: ProjectsNoactionsComponent },
  { path: 'otherFrustration', component: OtherFrustrationComponent },
  { path: 'frustrationComments', component: FrustrationCommentsComponent },
  { path: 'nometricProcess', component: NometricProcessComponent },
  //{ path: 'dashboard', component: DashboardComponent },
  { path: 'dashboard/:TeamID', component: DashboardComponent },
  { path: 'reports', component: ReportsComponent },
  { path: 'roles', component: RolesComponent },
  { path: 'frustrationsType', component: FrustrationstypeComponent },
  { path: 'alerts', component: AlertsComponent },
  { path: 'metricData', component: MetricdataComponent },
  { path: 'userManual', component: UsermanualComponent },
  { path: 'departments', component: DepartmentsComponent },
  { path: 'goals', component: GoalsComponent },
  { path: 'quarter', component: QuarterComponent },
  { path: 'performance-score-card', component: PerformanceScoreCardComponent },
  { path: 'add-score-card/:id/:stepId', component: AddScoreCardComponent },
  { path: 'organization', component: OrganizationComponent },
  { path: 'sub-company', component: SubCompanyComponent }

];

export const protectedResourceMap: any =  
  [  
    [AzureDetails.scopeUri]  
  ];


@NgModule({
  declarations: [
    AppComponent,
    ProcessComponent,
    ActivityComponent,
    TeamsComponent,
    EmployeeComponent,
    CompanyComponent,
    CompanyDialogComponent,
    ProcessDialogComponent,
    EmployeeDialogComponent,
    ActivityDialogComponent,
    TeamsDialogComponent,
    PointerComponent,
    PointerDialogComponent,
    PointerDialogComponent2,
    ProcessMapComponent,
    ProcessMapDialogComponent,
    ProcessMapDialogComponent2,
    CaseComponent,
    CaseDialogComponent,
    MetricComponent, MetricDialogComponent, ProjectComponent,
    ProjectComponent, ProjectDialogComponent,
    SortPipe,
    ReportCategoryComponent,
    DialogComponent,
    ReportProcessComponent,
    ReportProjectComponent,
    ReportProcessDialogComponent,
    ReportProjectDialogComponent,
    ReportMetricProcessComponent,
    ReportMetricDialogComponent,
    FrustrationReportsComponent,
    ProjectsNoactionsComponent,
    OtherFrustrationComponent,
    FrustrationCommentsComponent,
    NometricProcessComponent,
    DashboardComponent,
    ReportsComponent,
    LoginComponent,
    LoginMetricDialogComponent,
    UserPermisionsComponent,
    RolesComponent,
    RolePermissionsDiaglogComponent,
    HipriorityComponent,
    LoginMetricDialogComponent,
    FrustrationstypeComponent,
    FrustTypeDialogComponent,
    ReportHipriorityComponent,
    ReportSeverityDialogComponent, AlertsComponent,
    MetricdataComponent,
    MetricDataDialogComponent,
    LongPress,
    UsermanualComponent,
    DepartmentsComponent,
    DepartmentDialogComponent,
    GoalsComponent,
    GoalDialogComponent,
    PerformanceScoreCardComponent,
    QuarterComponent,
    MessageDialogComponent,
    AddScoreCardComponent,
    ValidationDialogComponent,
    ViewScoreCardComponent,
    ResponseDialogComponent,
    ReportActivityIssuesComponent,
    OrganizationComponent,
    OrganizationDialogComponent,
    SubCompanyComponent,
    PerformanceScoreCardDialogComponent,
    NumberDirective
  ],
  imports: [
    MsalModule.forRoot({  
      clientID: AzureDetails.ClientId,  
      authority: 'https://login.microsoftonline.com/' + AzureDetails.TenantId,  
      protectedResourceMap: protectedResourceMap,  
      redirectUri: AzureDetails.RedirectURL  
    }),
    BsDatepickerModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    RouterModule.forRoot(appRoutes), HttpModule,
    PdfViewerModule,
    MatTabsModule, BrowserAnimationsModule, MatIconModule, MatMenuModule,
    FormsModule, ReactiveFormsModule, MatFormFieldModule, MatDialogModule,
    MatInputModule, FlexLayoutModule, MatSelectModule, MatOptionModule,
    MatRadioModule, MatDatepickerModule, MatNativeDateModule, MatButtonModule, MatSlideToggleModule,
    MatTableModule, MatPaginatorModule, DragDropModule, MatTooltipModule, MatCheckboxModule, MatExpansionModule,
    NgxPaginationModule, FusionChartsModule, ChartsModule, NgxWebstorageModule.forRoot(),
    MatListModule, NgxPrintModule, MatSidenavModule, LayoutModule, HttpClientModule,
    MatSliderModule, MatCardModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, 
    HttpClient,
    MsalUserService,
    {  
      provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true  
    }],
  bootstrap: [LoginComponent],
  entryComponents: [
    CompanyDialogComponent,
    ProcessDialogComponent,
    EmployeeDialogComponent,
    ActivityDialogComponent,
    TeamsDialogComponent,
    PointerDialogComponent,
    PointerDialogComponent2,
    ProcessMapDialogComponent,
    ProcessMapDialogComponent2,
    CaseDialogComponent,
    MetricDialogComponent,
    ProjectDialogComponent,
    DialogComponent,
    ReportProcessDialogComponent,
    ReportProjectDialogComponent,
    ReportMetricDialogComponent,
    RolePermissionsDiaglogComponent,
    LoginMetricDialogComponent,
    FrustTypeDialogComponent,
    ReportSeverityDialogComponent,
    MetricDataDialogComponent,
    DepartmentDialogComponent,
    GoalDialogComponent,
    MessageDialogComponent,
    ValidationDialogComponent,
    ResponseDialogComponent,
    OrganizationDialogComponent,
    PerformanceScoreCardDialogComponent
  ]
})
export class AppModule { }
