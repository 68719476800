import { Component, OnInit, Inject, OnChanges } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { GoalService } from '../services/goal.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PointerDialogComponent } from '../pointer/pointer.component';
import { MetricDialogComponent } from '../metric/metric.component';
import { LocalStorageService } from 'ngx-webstorage';
import { ProcessDialogComponent } from '../process/process.component';
import { ActivityDialogComponent } from '../activity/activity.component';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { EmployeeLoginLevel } from '../common/models/login-level.model';
import { PerformanceScoreCardService } from '../performance-score-card/performance-score-card.service';
import { HostListener } from '@angular/core';
import { LoginComponent } from '../login/login.component';
import { LocationStrategy } from '@angular/common'
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-process-map',
  templateUrl: './process-map.component.html',
  styleUrls: ['./process-map.component.scss'],
  providers: [DorseyService, ProcessMapComponent],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({
        width: '100%',
        height: '100%',
      })),
      state('final', style({
        // width: '50%',
        height: '100%'
      })),
      transition('initial=>final', animate('1500ms')),
      transition('final=>initial', animate('1000ms'))
    ]),

    trigger('changeTextSize', [
      state('initial', style({
        fontSize: '13px'
      })),
      state('final', style({
        fontSize: '6px'
      })),
      transition('initial=>final', animate('1500ms')),
      transition('final=>initial', animate('1000ms'))
    ]),
    trigger('changeBoxSize', [
      state('initial', style({
        fontSize: '17px',
        padding: '0px 6px 0px 6px'
      })),
      state('final', style({
        fontSize: '10px',
        padding: '0px 2px 0px 2px'
      })),
      transition('initial=>final', animate('1500ms')),
      transition('final=>initial', animate('1000ms'))
    ]),
    trigger('changeActBoxSize', [
      state('initial', style({
        fontSize: '17px',
        height: '26px',
        width: '26px'
      })),
      state('final', style({
        fontSize: '6px',
        height: '11px',
        width: '11px'
      })),
      transition('initial=>final', animate('1500ms')),
      transition('final=>initial', animate('1000ms'))
    ]),
    trigger('changeActBoxSize1', [
      state('initial', style({
        fontSize: '17px',
        height: '26px',
        width: '26px'
      })),
      state('final', style({
        fontSize: '8px',
        height: '11px',
        width: '11px'
      })),
      transition('initial=>final', animate('1500ms')),
      transition('final=>initial', animate('1000ms'))
    ]),
    trigger('changeMFDivSize', [
      state('initial', style({
        // width: '100%',
        // height: '100%',
      })),
      state('final', style({
        // width: '90%',
        // height: '50%'
      })),
      transition('initial=>final', animate('1500ms')),
      transition('final=>initial', animate('1000ms'))
    ]),
    trigger('changeAlertBoxSize', [
      state('initial', style({
        // fontSize:'12px',
        // height:'40%px',
        width: '20px'
      })),
      state('final', style({
        //  fontSize:'6px',
        //  height:'20%',
        width: '11px'
      })),
      transition('initial=>final', animate('1500ms')),
      transition('final=>initial', animate('1000ms'))
    ])
  ]
})
export class ProcessMapComponent implements OnInit, OnChanges {
  copyRight: string = copyRight;
  isDisabledTeamId: boolean = false
  menuCCheck = false;
  menuReadCheck = false;
  menuCreateCheck = false;
  activityTemp: any;
  menuUpdateCheck = false;
  menuDeleteCheck = false;
  RoleData: any;
  readOnly = false;
  TeamName: string;
  empID: number;
  granualActivity = [];
  granualProcess = [];
  granualTeam = [];
  versions = [];
  currentVersion = '';
  showPrintData: boolean = false;

  filterDropProcess: any;
  filterDropActivity: any;
  MapType: string;
  CompanyID: string;

  processLists = [];
  pointerList = [];
  activityLists: any;
  ActiveActivities = {};
  tempSubList = [];
  ProcessFinalList = [];
  IssuesLists = {

  };

  granulProcessLists = {

  };

  granualTempProcess = [];
  employeeData: any;
  processData: any;
  connectedTo = [];
  activityData: any;
  pointerTypeData: any;
  frustrationtypeData: any;
  departmentData: any;
  teamData: any;
  severityData: any;
  statusData: any;
  ProcessType: string;
  EmployeeID: string;
  ProcessID: string;
  ActivityID: string;
  PointerTypeID: string;
  FrustrationTypeID: string;
  DepartmentID: string;
  TeamID: any;
  StatusID: string;
  SeverityID: string;
  Subject: string;
  Description: string;
  PointerID: string;
  pointerData: any;
  tempActivity = [];
  tempFrustrationType = [];
  CaseData = [];
  processIDs = [];
  expandMap = false;
  cased: any;
  IsVersion = false;
  condition = false;
  pocessSepecficActivity = [];
  subProcessMap = {};
  headerDate = new Date();
  metricData: any;
  expandProcess = false;
  selcetdProcessId = [];
  errorMessage: string;
  Permissons: any;
  showDiv = true;
  companyData: any;
  CompanyName: string;
  subTeam = false;
  MenuPermissions: any;
  menuData: any;
  menuID: string;
  activityTypeData: any;
  menuName: string;
  ParentID: string;
  VersionNumber: number;
  childTableName: string;
  childTableNameType: string;
  currentVersionFileds = true;
  currentUser: string;
  EmpROleID: string;
  hideButton = true;
  dashboardTeamID: any;
  typeName: any;
  alertData: any;
  alertPTypeID: any;
  alertMTypeID: any;
  alertValue: any;

  alertMetricValue: any;
  alertMetricPointer: any;
  ifMetricAlert = false;
  //showMetricAlert=false;
  showMetricAlert = {};
  AlertMetricStatus: any;
  copyDate: number;
  childTablePointers: any;
  squence: any;
  parentID: any;

  currentZoomLevel: number;
  width = 100;
  height = 100;
  currentState = 'initial';
  changeSize = false;
  previousVersionID: string;
  sequenceList = [];
  lastSequence: any;
  GoalsList: any;
  employeeDetails: any;
  employeeLoginLevel = EmployeeLoginLevel;
  constructor(private dorseyService: DorseyService, private location: LocationStrategy,
    private dialog: MatDialog, private storage: LocalStorageService,
    private goalService: GoalService
  ) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });

    this.currentUser = this.storage.retrieve('user');
    this.menuData = this.storage.retrieve('menuData');
    this.CompanyID = this.storage.retrieve('companyID');
    this.EmployeeID = this.storage.retrieve('EmployeeID');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
    this.EmpROleID = this.storage.retrieve("EmpRoleID");
    this.empID = this.storage.retrieve("EmployeeID");
    this.dashboardTeamID = this.storage.retrieve("id");
    this.CompanyName = this.storage.retrieve('CompanyName');
    if (this.dashboardTeamID !== null) {
      this.TeamID = Number(this.dashboardTeamID);
    }
    else if (this.EmployeeID.toString() === "0") {
      this.TeamID = Number(this.storage.retrieve('TeamIDLeader'));
    }
    else {
      this.TeamID = (this.storage.retrieve('TeamID'));
    }
    this.employeeDetails = this.storage.retrieve("employeeDetails");
    this.GetEmployee();
    this.MapType = "3";
    this.typeName = 'Both';
    this.GetTeam();
  }

  ngOnChanges() {

  }

  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   console.log('Back button pressed');
  //   var loginLevel = this.storage.retrieve("loginLevel");
  //   if (loginLevel === 'organization' || loginLevel === 'superadmin' || loginLevel === 'entity') {
  //     this.login.BackToAdmin();
  //   }
  // }

  ngOnInit() {
    this.GetRoles();
    this.GetGoals();
    let tempdata = this.menuData;
    this.menuData = tempdata.filter(x => x.MenuName.toString().toLowerCase() === 'processmap');
    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }

    if ((this.MenuPermissions[this.menuName][this.menuID]['C'] && this.MenuPermissions[this.menuName][this.menuID]['R']) || (this.MenuPermissions[this.menuName][this.menuID]['U'] && this.MenuPermissions[this.menuName][this.menuID]['R']) || (this.MenuPermissions[this.menuName][this.menuID]['D'] && this.MenuPermissions[this.menuName][this.menuID]['R'])) {
      this.readOnly = false;
    }
    else {
      this.readOnly = true;
    }
    this.GetActivityType();
    this.GetCompany();
    this.GetPointer();
    this.GetActivities();
    this.GetDepartment();
    this.GetPointerType();
    this.GetSeverity();
    this.GetStatus();
    this.GetFrustrationType();
    this.GetAlertValues();
    this.GetMetricAlert();
    setTimeout(() => {
      this.checkForMetricAlert();
    }, 500);

    this.copyDate = (new Date()).getFullYear();
  }


  GetGoals() {
    this.GoalsList = [];
    this.goalService.getGoals(parseInt(this.CompanyID)).subscribe(resp => {

      this.GoalsList = resp.Data;
    });
  }
  changeState() {
    this.changeSize = !this.changeSize;
    this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  }
  GetCompany() {
    this.dorseyService.GetCompanyData().subscribe(company => {
      this.companyData = JSON.parse(company['_body']);
      let filterCompanyName = this.companyData.filter(x => x.CompanyID === this.CompanyID);
      if (filterCompanyName.length === 1) {
        this.CompanyName = filterCompanyName[0].CompanyName;

      }
    });

  }
  isOverflown(element) {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }

  AutoFit() {
    let el = document.getElementsByClassName('squareInActvty');

    for (let index = 0; index < el.length; ++index) {
      var div = el[index];


    }
  }
  GetVersions() {

    this.errorMessage = "";

    this.dorseyService.GetVersions(Number(this.CompanyID), this.TeamID).subscribe(versions => {
      this.versions = JSON.parse(versions['_body']);
      if (this.versions.length > 0) {
        if (this.TeamID) {
          this.currentVersion = this.versions[this.versions.length - 1];
          this.DepartmentID = this.versions[this.versions.length - 1]['VersionID'];
        }
        this.ConstructMap();
      }
      else {
        this.ProcessFinalList = [];
        this.granualActivity = [];
        this.granualProcess = [];
        this.pointerList = [];

        this.processLists = [];
        this.TeamID = this.TeamID;
      }

    });
  }
  GetSelectedVersion(event) {
    this.processLists = [];
    this.activityLists = [];
    this.pointerList = [];
    this.previousVersionID = event.value;
    for (let i = 0; i < this.versions.length; i++) {
      if (this.versions[i].VersionID === event.value) {
        this.currentVersion = this.versions[i];
        if (this.versions[i].VersionName.split('_').length !== 1) {
          this.headerDate = new Date(this.versions[i].VersionName.split('_')[1]);
          this.currentVersionFileds = false;
        }
        else {
          this.headerDate = new Date();
          this.currentVersionFileds = false;
        }
      }
    }
    this.ConstructMap();
  }
  GetSelectedProcessMap(event) {

    this.selcetdProcessId = [];
    this.activityLists = [];
    this.ActiveActivities = [];
    this.TeamID = event.value;

    this.subTeam = true;

    this.GetVersions();
    for (let i = 0; i < this.teamData.length; i++) {

      if (this.teamData[i].TeamID === event.value) {

      }
    }
  }

  GetRoles() {
    this.dorseyService.GetRoles(this.CompanyID).subscribe(data => {

      let roledata = JSON.parse(data["_body"]);
      this.RoleData = roledata;

      let tempData = roledata.filter(x => x.RoleID === this.EmpROleID);
      if (tempData.length == 0) {

        return;
      }

      else {
        if (!tempData[0].PermissionJson) {
          return;
        }
        else {
          this.Permissons = JSON.parse(tempData[0].PermissionJson);
        }
      }

    });
  }


  ConstructMap() {
    if (!this.TeamID)
      return;
    this.connectedTo = [];
    this.IssuesLists = [];
    this.ActiveActivities = [];
    this.ProcessFinalList = [];

    this.dorseyService.GetVersions(Number(this.CompanyID), this.TeamID).subscribe(versions => {
      this.versions = JSON.parse(versions['_body']);
      let CurrentVersionNumber;
      this.processLists = [];
      this.processIDs = [];
      this.granualProcess = [];
      this.granualActivity = [];
      this.hideButton = true;

      let tableName;
      let tableAct;
      let tablePointers;
      let tableKeyProcessTeam;
      let tableActivitiesTeam;
      let tablefrustrationType;
      this.CompanyID = this.CompanyID;
      if (this.TeamID !== '0') {
        let data = this.teamData.filter(x => x.TeamID === this.TeamID);
        if (data.length > 0) {
          this.TeamName = data[0].TeamName;
        }


      }
      CurrentVersionNumber = this.currentVersion['VersionID'];
      this.VersionNumber = CurrentVersionNumber;

      if (this.currentVersion['VersionName'] != undefined)
        if (this.currentVersion['VersionName'].split('_').length === 1) {
          this.IsVersion = false;
        }


      if (this.currentVersion['VersionName'] != undefined)
        if (!(this.currentVersion['VersionName'].split('_').length === 1)) {

          this.IsVersion = true;
          tableName = 'Ver_KeyProcess';
          tableAct = 'Ver_Activities';
          tablePointers = 'Ver_Pointers';
          tablefrustrationType = 'Ver_FrustrationsType';

        }
        else {
          this.IsVersion = false;
          tableName = 'KeyProcess';
          tableAct = 'Activities';
          tablePointers = 'Pointers';

          tablefrustrationType = 'FrustrationsType';
        }
      this.childTableName = tablePointers;
      this.childTablePointers = tablePointers;
      this.childTableNameType = tableAct;
      this.dorseyService.GetSelectedVersionData(Number(CurrentVersionNumber), Number(this.CompanyID), Number(this.TeamID), tableName).subscribe(process => {

        process = JSON.parse(process['_body']);

        let Prcesses: any = process;

        this.dorseyService.GetSelectedVersionPointerData(CurrentVersionNumber, Number(this.CompanyID), Number(this.TeamID), tablePointers).subscribe(pointer => {
          this.pointerList = JSON.parse(pointer['_body']);

          for (let i = 0; i < Prcesses.length; i++) {

            let PmCount = 0; let PfCount = 0;
            let smCount = 0, sfCount = 0;

            let subActiCount = 0;
            if (Prcesses[i]['CompanyID'].toString() === this.CompanyID.toString() || this.CompanyID.toString() == "0") {


              this.processLists.push(Prcesses[i]);


              if (!this.Permissons) {
                this.granualProcess = this.processLists;

              }
              else {


                if (this.Permissons["Process"] !== undefined) {
                  let ProcessPer = this.Permissons["Process"];

                  let processKeys = Object.keys(ProcessPer);

                  this.granualProcess = [];

                  for (let i = 0; i < processKeys.length; i++) {

                    let prsData = this.processLists.filter(x => x.ProcessID.toString() === processKeys[i].toString());

                    if (prsData.length > 0) {
                      let permissonKeys = Object.keys(ProcessPer[processKeys[i]]);

                      if (permissonKeys.indexOf('R') > -1)
                        prsData[0]['R'] = ProcessPer[processKeys[i]]['R'];
                      else
                        prsData[0]['R'] = false;


                      if (permissonKeys.indexOf('C') > -1)
                        prsData[0]['C'] = ProcessPer[processKeys[i]]['C'];
                      else
                        prsData[0]['C'] = false;


                      if (permissonKeys.indexOf('U') > -1)
                        prsData[0]['U'] = ProcessPer[processKeys[i]]['U'];
                      else
                        prsData[0]['U'] = false;


                      if (permissonKeys.indexOf('D') > -1)
                        prsData[0]['D'] = ProcessPer[processKeys[i]]['D'];
                      else
                        prsData[0]['D'] = false;

                      this.granualProcess.push(prsData[0]);
                    }
                    else {

                    }



                  }

                  if (this.granualProcess.length === 0) {
                    this.granualProcess = this.processLists;
                  }
                }
                else {

                  if (this.TeamID != "0" && this.CompanyID != "0") {
                    let data = this.processLists.filter(x => x.TeamID === this.TeamID && x.CompanyID === this.CompanyID);
                    this.processLists = data;
                    this.granualProcess = this.processLists;

                  }
                  else {
                    let data = this.processLists.filter(x => x.CompanyID === this.CompanyID);
                    this.processLists = data;
                    this.granualProcess = this.processLists;
                  }


                }
              }


              this.dorseyService.GetSelectedVersionActivityData(CurrentVersionNumber, Number(this.CompanyID), Number(this.TeamID), tableAct).subscribe(activity => {

                activity = JSON.parse(activity['_body']);

                this.activityLists = activity;





                // let filter=this.activityLists.filter(x=>x.ProcessID=== this.granualProcess[i].ProcessID);
                // if(filter.length>0){
                //   this.activityTemp=filter;
                // }

                // else{
                //   this.activityTemp=[];
                // }



                if (!this.Permissons) {
                  this.granualActivity = this.activityLists;

                }
                else {

                  if (this.Permissons["Activity"] !== undefined) {


                    let ActPer = this.Permissons["Activity"];

                    let activityKeys = Object.keys(ActPer);

                    this.granualActivity = [];
                    for (let i = 0; i < activityKeys.length; i++) {


                      let actData = this.activityLists.filter(x => x.ActivityID.toString() === activityKeys[i].toString());
                      if (actData.length > 0) {
                        let permissonKeys = Object.keys(ActPer[activityKeys[i]]);

                        if (permissonKeys.indexOf('R') > -1)
                          actData[0]['R'] = ActPer[activityKeys[i]]['R'];
                        else
                          actData[0]['R'] = false;


                        if (permissonKeys.indexOf('C') > -1)
                          actData[0]['C'] = ActPer[activityKeys[i]]['C'];
                        else
                          actData[0]['C'] = false;


                        if (permissonKeys.indexOf('U') > -1)
                          actData[0]['U'] = ActPer[activityKeys[i]]['U'];
                        else
                          actData[0]['U'] = false;


                        if (permissonKeys.indexOf('D') > -1)
                          actData[0]['D'] = ActPer[activityKeys[i]]['D'];
                        else
                          actData[0]['D'] = false;

                        this.granualActivity.push(actData[0]);
                      }

                    }
                    if (this.granualActivity.length === 0) {
                      this.granualActivity = this.activityLists;
                    }

                  }
                  else {

                    if (this.TeamID != "0" && this.CompanyID != "0") {
                      let data = this.activityLists.filter(x => x.TeamID === this.TeamID && x.CompanyID === this.CompanyID);
                      this.activityLists = data;
                      this.granualActivity = this.activityLists;

                    }
                    else {
                      let data = this.activityLists.filter(x => x.CompanyID === this.CompanyID);
                      this.activityLists = data;
                      this.granualActivity = this.activityLists;
                    }

                  }
                }

                const ActivityJson = [];
                let ActiDummyM = {}; const ActiDummyI = {}; let procesDummy = {};
                let issueDummy = {}; let metricDummy = {};
                let issueProcess = {}; let metricProcess = {};
                let metricMoreThanDummy = {};
                let issueProbool = {};
                let metricProbool = {};

                for (let j = 0; j < this.granualActivity.length; j++) {
                  let frustationCount = []; let fCount = 0;
                  let metricCount = []; let mCount = 0;


                  if (this.granualActivity[j]['ProcessID'].toString() === this.granualProcess[i].ProcessID.toString() && this.granualActivity[j].ParentID === 0) {


                    this.metricData = this.pointerList.filter(x => x.PointerTypeID != null && x.PointerTypeID.toString() === '2');
                    this.pointerList.forEach(element => {


                      if (element['ProcessID'] === this.granualProcess[i].ProcessID && element['ActivityID'] === this.granualActivity[j]['ActivityID']) {
                        if (element['PointerTypeID'] === 2) {
                          mCount++;
                          this.expandProcess = false;
                          let metric = this.metricData.filter(x => x.ProcessID === this.granualProcess[i].ProcessID && x.ActivityID === this.granualActivity[j].ActivityID)
                          metricDummy[this.granualActivity[j]['ActivityID']] = metric;
                        } else {

                          fCount++;
                          this.expandProcess = false;
                          this.dorseyService.GetCaseData(this.granualProcess[i].ProcessID, this.granualActivity[j].ActivityID, 1, CurrentVersionNumber, this.childTableName, this.childTableNameType).subscribe(cases => {
                            cases = JSON.parse(cases['_body']);
                            this.cased = cases;
                            issueDummy[this.granualActivity[j]['ActivityID']] = this.cased;
                          });
                        }
                      }
                      else {
                        if (element['PointerTypeID'] === 2) {
                          let metric = this.metricData.filter(x => x.ProcessID === this.granualProcess[i].ProcessID && x.ActivityID === this.granualActivity[j].ActivityID)

                          metricMoreThanDummy[this.granualActivity[j]['ActivityID']] = metric.length;
                        }
                      }
                    });


                    let subActData = this.granualActivity.filter(x => x.ProcessID === this.granualProcess[i].ProcessID && x.ParentID !== 0);

                    for (let n = 0; n < subActData.length; n++) {

                      if (subActData[n]['ProcessID'].toString() === this.granualProcess[i].ProcessID.toString()) {

                        for (let a = 0; a < this.pointerList.length; a++) {
                          if (subActData[n].ActivityID === this.pointerList[a].ActivityID && this.pointerList[a].PointerTypeID === 2) {

                            smCount++;
                          } else if (subActData[n].ActivityID === this.pointerList[a].ActivityID && this.pointerList[a].PointerTypeID === 1) {

                            sfCount++;
                          }
                        }

                      }
                    }

                    this.granualProcess[i].SubMetricCount = smCount;
                    this.granualProcess[i].SubIssueCount = sfCount;

                    if (this.granualProcess[i].MetricCount) {
                      this.granualProcess[i].MetricCount = +this.granualProcess[i].MetricCount + mCount;
                    }
                    else { this.granualProcess[i].MetricCount = mCount; }
                    if (this.granualProcess[i].IssueCount) {
                      this.granualProcess[i].IssueCount = +this.granualProcess[i].IssueCount + fCount;
                    }
                    else { this.granualProcess[i].IssueCount = fCount; }

                    if (mCount !== 0) {
                      ActiDummyM[this.granualActivity[j]['ActivityID']] = mCount;
                    }


                    if (fCount !== 0) {
                      ActiDummyI[this.granualActivity[j]['ActivityID']] = fCount;
                    }

                    let element = document.getElementById('metric');

                    procesDummy['Metric'] = ActiDummyM;
                    procesDummy['MetricCount'] = this.granualProcess[i].MetricCount;
                    procesDummy['Issue'] = ActiDummyI;
                    procesDummy['IssueCount'] = this.granualProcess[i].IssueCount;

                    ActivityJson.push(this.granualActivity[j]);
                    ActivityJson.sort(function (a, b) {
                      return a.squence - b.squence;
                    });

                    this.ActiveActivities[this.granualProcess[i].ProcessID] = ActivityJson;

                    this.tempSubList[this.granualActivity[j]];


                  } if (this.granualActivity[j]['ProcessID'].toString() === this.granualProcess[i].ProcessID.toString() && this.granualActivity[j].ParentID !== 0) {

                    subActiCount++;
                  }
                  procesDummy['SubActivity'] = subActiCount;

                }

                this.pointerList.forEach(element => {
                  if (element['ProcessID'] === this.granualProcess[i].ProcessID && element['ActivityID'] === 0) {
                    if (this.processIDs.indexOf(this.granualProcess[i].ProcessID) > -1) {
                    } else {
                      if (element['StatusID'] !== 4 || element['StatusID'] !== 5) {
                        if (element['PointerTypeID'] === 2) {
                          PmCount++;
                          this.metricData = this.pointerList.filter(x => x.PointerTypeID != null && x.PointerTypeID.toString() === '2');
                          let metricd = this.metricData.filter(x => x.ProcessID === this.granualProcess[i].ProcessID && x.ActivityID === 0)
                          metricProcess[Prcesses[i]['ProcessID']] = metricd;
                          metricProbool[Prcesses[i]['ProcessID']] = true;
                          this.expandProcess = true;

                        } else {
                          PfCount++;
                          this.dorseyService.GetCaseData(this.granualProcess[i].ProcessID, 0, 1, CurrentVersionNumber, this.childTableName, this.childTableNameType).subscribe(cases => {
                            cases = JSON.parse(cases['_body']);

                            issueProcess[Prcesses[i]['ProcessID']] = cases;
                            this.expandProcess = true;
                            issueProbool[Prcesses[i]['ProcessID']] = true;

                          });
                        }
                      }
                    }
                  }
                });

                this.granualProcess.forEach(elementProcess => {
                  this.granualTempProcess = [];
                  this.granulProcessLists = [];
                  this.granualActivity.forEach(elementActivity => {


                    if (elementProcess.ProcessID == elementActivity.ProcessID && elementActivity.ParentID == 0) {
                      this.granualTempProcess.push(elementActivity);
                    }

                  });


                  this.granualTempProcess.sort(function (a, b) {
                    return a.squence - b.squence;
                  });
                  // this.granulProcessLists['weeklist'] = this.granualTempProcess;
                  if (this.granualProcess.length == this.ProcessFinalList.length) {
                    return;
                  }
                  else {
                    this.ProcessFinalList.push({
                      'ProcessID': elementProcess.ProcessName,
                      'ActivityList': this.granualTempProcess,
                      'Process': elementProcess
                    });

                    this.connectedTo.push(elementProcess.ProcessName);
                  }
                })

                if (this.processIDs.indexOf(this.granualProcess[i].ProcessID) > -1) { }
                else { this.processIDs.push(this.granualProcess[i].ProcessID); }
                procesDummy['IssueProcess'] = issueProcess;
                procesDummy['MetricProcess'] = metricProcess;
                Prcesses[i].pMetricCount = PmCount;
                Prcesses[i].pIssueCount = PfCount;
                Prcesses[i].SubMetricCount = smCount;
                Prcesses[i].SubIssueCount = sfCount;
                procesDummy['pMetricCount'] = PmCount;
                procesDummy['pIssueCount'] = PfCount;
                procesDummy['SubMetricCount'] = smCount;
                procesDummy['SubIssueCount'] = sfCount;
                procesDummy['IssueList'] = issueDummy;
                procesDummy['MetricList'] = metricDummy;
                procesDummy['MetricMoreThan'] = metricMoreThanDummy;
                procesDummy['TeamID'] = Prcesses[i].TeamID;
                if (Object.keys(procesDummy).length > 0) {
                  this.IssuesLists[this.granualProcess[i].ProcessID] = procesDummy;
                }


                let datatemp = this.chunk(this.tempSubList, 3);
                this.checkForMetricAlert();


              });
            }
          }
        });
      });
    });
  }
  chunk(arr, size) {
    var newArr = [];
    for (var i = 0; i < arr.length; i += size) {
      newArr.push(arr.slice(i, i + size));
    }
    return newArr;
  }
  Print1() {
    var id = document.getElementById('Map');
    var prt = document.getElementById('printMap');
    // prt.style.height ='1000px';
    //  id.classList.remove('donotprintthis');
    var printContents = document.getElementById('printMap').innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();

    //prt.style.height ='440px';
    // document.body.innerHTML = originalContents;
  }

  Print() {
    //  var id = document.getElementById('Map');
    //  var prt = document.getElementById('printMap');
    //  prt.style.height ='1000px';
    //id.classList.remove('donotprintthis');
    var printContents = document.getElementById('printMap').innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    //prt.style.height ='440px';
    // document.body.innerHTML = originalContents;
  }


  Versioning(menuPer) {
    if (!menuPer) {
      return;
    }
    const json = {
      CompanyID: this.CompanyID,
      TeamID: this.TeamID,
      createdby: this.currentUser
    };
    const dialogRef = this.dialog.open(ProcessMapDialogComponent, {
      data: 'Are you sure you want to version it ' + '?æææ' + true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dorseyService.SaveProcess(json, 'Yes').subscribe(data => {
          if (data.ok) {
            this.condition = true;
            this.dialogCall('Version saved successfully');
          }
          // this.GetProcess();
          //  this.GetActivities();
          //  this.GetPointer();
          this.GetVersions();
        });
      }
    });
  }

  ExpandMap() {
    this.processLists = [];
    this.IssuesLists = {};
    this.processIDs = [];
    this.pointerList = [];
    this.ConstructMap()
    this.expandMap = true;

  }
  HideMap() {
    this.expandMap = false;
  }

  GetPointer() {
    this.dorseyService.GetPointer(this.CompanyID).subscribe(subData => {

      subData = JSON.parse(subData['_body']);
      this.pointerData = subData;
      this.metricData = this.pointerData.filter(x => x.PointerTypeID != null && x.PointerTypeID.toString() === '2');

    });
  }
  GetEmployee() {
    this.dorseyService.GetEmployee(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.employeeData = subData;
      let temmEmployee = this.employeeData;

      if (this.TeamID != '0') {
        this.employeeData = temmEmployee.filter(x => x.TeamID === this.TeamID || x.CompanyID == this.CompanyID);
      } else {
        this.employeeData = temmEmployee.filter(x => x.CompanyID === this.CompanyID);
      }



      if (this.empID.toString() !== '0') {
        let filterEmp = this.employeeData.filter(x => x.EmployeeID === this.empID);
        if (filterEmp.length > 0) {
          if (filterEmp[0].TeamID !== '0') {
            this.TeamID = filterEmp[0].TeamID;
          }

        }


      }
    });
  }


  GetDepartment() {
    this.dorseyService.GetDepartment().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.departmentData = subData;
    });
  }

  GetPointerType() {
    this.dorseyService.GetPointerType().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.pointerTypeData = subData;
    });
  }
  GetStatus() {
    this.dorseyService.GetStatus().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.statusData = subData;
    });
  }
  GetSeverity() {
    this.dorseyService.GetSeverity().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.severityData = subData;
    });
  }

  GetTeam() {
    this.GetRoles();
    this.ProcessFinalList = []; ``
    this.granualProcess = [];
    this.granualActivity = [];
    this.IssuesLists = [];
    this.processLists = [];
    this.pointerList = [];
    this.activityLists = [];

    this.dorseyService.GetTeam(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.teamData = subData;
      if (!this.Permissons) {

        if (this.EmployeeID.toString() !== "0") {
          if (this.employeeLoginLevel.Department == this.employeeDetails.IsEmployeeLoginLevel) {
            this.teamData = this.teamData.filter(ent => ent.DepartmentID == this.employeeDetails.DepartmentID);
          }
          else if (this.employeeLoginLevel.Team == this.employeeDetails.IsEmployeeLoginLevel) {
            this.teamData = this.teamData.filter(ent => ent.DepartmentID == this.employeeDetails.DepartmentID && ent.TeamID == this.employeeDetails.TeamID);
          }
          else {
            this.teamData = subData;
          }
          // if (this.TeamID.toString() !== "0" && this.CompanyID.toString() !== "0") {
          //   let data = this.teamData.filter(x => x.TeamID === this.TeamID && x.CompanyID === this.CompanyID);

          //   this.teamData = data;
          //   this.TeamID = this.teamData[0].TeamID;
          // }
          // else {
          //   let data = this.teamData.filter(x => x.CompanyID === this.CompanyID);
          //   this.teamData = data;
          // }
        }
        else {
          this.teamData = subData;
        }

      }
      else if (this.Permissons["Team"] !== undefined) {

        let TeamPer = this.Permissons["Team"];
        let TeamKeys = Object.keys(TeamPer);
        let teamTemp = TeamKeys;

        if (!TeamKeys) {
          this.teamData = subData;
        }
        else {

          let tempList = [];
          for (let k = 0; k < TeamKeys.length; k++) {
            let data = this.teamData.filter(x => x.TeamID.toString() === TeamKeys[k].toString());
            if (data.length > 0) {
              tempList[k] = (data[0]);
            }
          }
          this.teamData = tempList;
        }
      }
      else {
        if (this.employeeLoginLevel.Department == this.employeeDetails.IsEmployeeLoginLevel) {
          this.teamData = this.teamData.filter(ent => ent.DepartmentID == this.employeeDetails.DepartmentID);
        }
        else if (this.employeeLoginLevel.Team == this.employeeDetails.IsEmployeeLoginLevel) {
          this.teamData = this.teamData.filter(ent => ent.DepartmentID == this.employeeDetails.DepartmentID && ent.TeamID == this.employeeDetails.TeamID);
        }
        else {
          this.teamData = subData;
        }
        // if (this.TeamID.toString() !== "0" && this.CompanyID.toString() !== "0") {
        //   let data = this.teamData.filter(x => x.TeamID === this.TeamID && x.CompanyID === this.CompanyID);

        //   this.teamData = data;
        // }
        // else {
        //   let data = this.teamData.filter(x => x.CompanyID === this.CompanyID);
        //   this.teamData = data;
        //   this.TeamID = this.teamData[0].TeamID;
        // }
      }

      if (this.TeamID.toString() === '0') {
        if (this.teamData !== undefined) {
          for (let i = 0; i < this.teamData.length; i++) {
            if (this.teamData[i].TeamName === 'Leadership Team') {
              this.TeamID = this.teamData[i].TeamID;
            }
          }
        }
      }
      this.GetVersions();
    });

  }

  //drag drop activity
  dropActivity(event: CdkDragDrop<string[]>, ProcessData: any) {
    this.sequenceList = [];
    this.filterDropActivity = [];

    if (event.previousContainer === event.container) {
      // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

      this.filterDropActivity = event.container.data;
      let filterArrayActivity = this.filterDropActivity.filter(x => x.squence == event.previousIndex + 1);

      if (filterArrayActivity.length > 0 && filterArrayActivity.length == 1) {
        this.SaveActivity(filterArrayActivity, event.currentIndex + 1, filterArrayActivity[0].ProcessID, 'move');
      }
    }
    else {

      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);


      this.filterDropActivity = event.container.data;
      for (let i = 0; i < this.filterDropActivity.length; i++) {

        if (this.filterDropActivity[i].ProcessID === ProcessData.ProcessID && this.filterDropActivity[i].ParentID === 0 && this.TeamID === this.filterDropActivity[i].TeamID) {
          this.sequenceList.push(this.filterDropActivity[i].squence);
        }


      }

      let filterArrayActivity = this.filterDropActivity.filter(x => x.squence == event.previousIndex + 1 && x.ProcessID !== ProcessData.ProcessID);

      this.SaveActivity(filterArrayActivity, event.currentIndex + 1, ProcessData.ProcessID, 'transfer');
    }


  }
  //drag drop process
  dropProcess(event: CdkDragDrop<string[]>) {

    if (event.previousContainer === event.container) {
      this.filterDropProcess = event.container.data;
      let filterArrayProcess = this.filterDropProcess.filter(x => x['Process'].squence == event.previousIndex + 1);

      if (filterArrayProcess.length > 0 && filterArrayProcess.length == 1) {
        this.SaveProcess(filterArrayProcess[0]['Process'], event.currentIndex + 1);
      }
    }
    // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }
  SaveProcess(processData: any, currentIndex) {

    const json = {
      CompanyID: processData.CompanyID,

      processName: processData.ProcessName,
      createdby: processData.CreatedBy,
      processID: processData.ProcessID,
      updatedby: processData.CreatedBy,
      ProcessDescription: processData.ProcessDescription,

      DepartmentID: "0",
      EmployeeID: processData.EmployeeID,

      type: 'edit',
      TeamID: processData.TeamID,
      squence: currentIndex,
      lastSequence: processData.squence
    };

    this.dorseyService.SaveProcess(json, 'No').subscribe(data => {
      if (data['_body'].toString() === 'true') {

      } else {

      }
      this.ConstructMap();
    });


  }

  SaveActivity(currentAct: any, currentIndex, ProcessID, typeOf) {
    this.lastSequence = [];


    if (typeOf.toString() == 'transfer') {
      if (ProcessID != currentAct[0].ProcessID) {
        let seq = this.sequenceList.length + 1;

        if (this.sequenceList.length !== 0) {
          this.sequenceList.push(seq);
        }
        else this.sequenceList.push(1);
      }

      if (currentAct[0].ParentID === 0) {
        this.squence = this.sequenceList[this.sequenceList.length - 1];
        this.lastSequence = this.sequenceList.length;
      }
    }

    for (let i = 0; i < currentAct.length; i++) {

      if (typeOf.toString() == 'move') {
        this.lastSequence = currentAct[i].squence;

      }
      const json = {
        CompanyID: this.CompanyID,
        ActivityID: currentAct[i].ActivityID,
        ParentID: currentAct[i].ParentID,
        ActivityName: currentAct[i].ActivityName,
        ProcessID: ProcessID,
        ActivityTypeID: currentAct[i].ActivityTypeID,
        DepartmentID: currentAct[i].DepartmentID,
        EmployeeID: currentAct[i].EmployeeID,
        CreatedBy: this.currentUser,
        UpdatedBy: this.currentUser,
        TeamIDs: this.TeamID,
        squence: currentIndex,
        type: 'edit',
        lastSequence: this.lastSequence
      };

      // return;
      this.dorseyService.SaveActivity(json, 'No').subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.ConstructMap();

        } else {

        }
        this.GetActivities();

      });
    }
  }


  GetActivities() {
    this.dorseyService.GetActivity(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.activityData = subData;

    });
  }
  GetProcess() {
    this.dorseyService.GetProcess(this.CompanyID).subscribe(subData => {
      this.processLists = JSON.parse(subData['_body']);
      if (this.processLists.length === 0) {
        this.showDiv = true;

      }

    });
  }
  GetActivity() {

    this.dorseyService.GetActivity(this.CompanyID).subscribe(activity => {
      activity = JSON.parse(activity['_body']);
      this.activityLists = activity;
      this.activityData = this.activityLists;
    });
  }

  GetPointers() {

    this.dorseyService.GetPointer(this.CompanyID).subscribe(pointer => {
      this.pointerList = JSON.parse(pointer['_body']);

    });

  }
  getCase(processID, activityID, pointerID, menuUpdate, menuDelete) {

    this.menuUpdateCheck = menuUpdate;
    this.menuDeleteCheck = menuDelete;

    if (this.expandMap === true) {
      this.expandMap = true;
    }

    let activityName = '';

    if (activityID !== 0) {
      activityName = activityID.ActivityName;
      activityID = activityID.ActivityID;

    }

    this.dorseyService.GetCaseData(processID.ProcessID, activityID, pointerID, this.currentVersion['VersionID'], this.childTableName, this.childTableNameType).subscribe(cases => {
      cases = JSON.parse(cases['_body']);

      const dialogRef = this.dialog.open(ProcessMapDialogComponent, {
        panelClass: 'custom-dialog-container',
        data: 'new' + 'æææ' + true + 'æææ' + pointerID,
        width: '800px',
      });

      let filterROle = this.RoleData.filter(x => x.RoleName === "Read Only" && x.RoleID === this.EmpROleID);

      if (filterROle.length > 0) {
        this.readOnly = true;
      }
      else {
        this.readOnly = false;

      }





      dialogRef.componentInstance.IsVersion = this.IsVersion;
      dialogRef.componentInstance.readOnly = this.readOnly;
      dialogRef.componentInstance.GoalsList = this.GoalsList;

      dialogRef.componentInstance.menuDeleteCheck = this.menuDeleteCheck;
      dialogRef.componentInstance.menuUpdateCheck = this.menuUpdateCheck;

      dialogRef.componentInstance.childTablePointers = this.childTablePointers;
      dialogRef.componentInstance.currentVersion = this.currentVersion;
      dialogRef.componentInstance.versionID = this.currentVersion['VersionID'];
      dialogRef.componentInstance.childTableNameType = this.childTableNameType;
      dialogRef.componentInstance.CaseData = cases;
      dialogRef.componentInstance.Process = processID.ProcessName;
      dialogRef.componentInstance.Activity = activityName;
      dialogRef.componentInstance.pointerList = this.pointerList;
      dialogRef.componentInstance.processData = this.processData;

      dialogRef.componentInstance.childTableName = this.childTableName;

      dialogRef.componentInstance.processData = this.processLists;
      dialogRef.componentInstance.activityData = this.activityData;
      dialogRef.componentInstance.departmentData = this.departmentData;
      dialogRef.componentInstance.pointerTypeData = this.pointerTypeData;
      dialogRef.componentInstance.severityData = this.severityData;
      //  dialogRef.componentInstance.statusData = this.statusData;
      dialogRef.componentInstance.teamData = this.teamData;
      dialogRef.componentInstance.employeeData = this.employeeData;
      dialogRef.componentInstance.frustrationtypeData = this.frustrationtypeData;
      dialogRef.componentInstance.tempActivity = this.tempActivity;
      dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
      dialogRef.componentInstance.statusData = this.statusData;
      dialogRef.componentInstance.ActivityID = activityID;
      dialogRef.componentInstance.ProcessID = processID;
      dialogRef.componentInstance.IssuesLists = this.IssuesLists;
      dialogRef.componentInstance.ProcessFinalList = this.ProcessFinalList;
      dialogRef.afterClosed().subscribe(result => {
        this.ConstructMap();
      })


    });


  }

  getCaseData(processID, activityID, pointerID, versionID) {
    this.dorseyService.GetCaseData(processID.ProcessID, activityID.ActivityID, pointerID, versionID, this.childTableName, this.childTableNameType).subscribe(cases => {
      cases = JSON.parse(cases['_body']);
      this.cased = cases;

    });


  }


  GetFrustrationType() {
    this.dorseyService.GetFrustrationType('1', this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.frustrationtypeData = subData;

    });


  }
  ChooseMatricOrIssue(processID, activityID, menuPer, isver, read, menuUpdate, menuDelete) {

    this.ActivityID = activityID;




    //  if(isver){
    //   let filter=this.activityData.filter(x=>x.ProcessID===processID && x.VersionID===this.currentVersion['VersionID']);
    //   if(filter.length>0){
    //     this.activityTemp=filter;
    //   }

    //   else{
    //     this.activityTemp=[];
    //   }

    //  }
    // else{

    // }



    let filter = this.granualActivity.filter(x => x.ProcessID === processID);
    if (filter.length > 0) {
      this.activityTemp = filter;
    }

    else {
      this.activityTemp = [];
    }





    let filterROle = this.RoleData.filter(x => x.RoleName === "Read Only" && x.RoleID === this.EmpROleID);

    if (filterROle.length > 0) {
      this.readOnly = true;
    }
    else {
      this.readOnly = false;

    }


    if (!this.TeamID) {
      return;
    }


    this.menuReadCheck = read;

    this.menuUpdateCheck = menuUpdate;

    this.menuDeleteCheck = menuDelete;
    //   if(!this.readOnly){
    // this.readOnly=read;
    //   }
    this.menuCreateCheck = menuPer;


    if (this.menuCreateCheck && this.menuReadCheck) {
      this.menuCreateCheck = this.menuCreateCheck;
      this.menuUpdateCheck = this.menuUpdateCheck;
      this.menuDeleteCheck = this.menuDeleteCheck;
    }
    else if (this.menuDeleteCheck && this.menuReadCheck) {
      this.menuDeleteCheck = this.menuDeleteCheck;
      this.menuUpdateCheck = this.menuUpdateCheck;

    }
    else if (this.menuUpdateCheck && this.menuReadCheck) {
      this.menuUpdateCheck = this.menuUpdateCheck;
      this.menuDeleteCheck = this.menuDeleteCheck;
    }
    else if (!this.menuCreateCheck && this.menuReadCheck) {
      this.menuCCheck = this.menuReadCheck;
    }


    if (activityID != 0 && (this.readOnly || this.IsVersion || this.menuCCheck)) {
      return;
    }



    //     if(processID!=0 && activityID!=0 && this.readOnly || this.IsVersion)
    //     {
    // return;
    //     }

    this.subProcessMap = {};

    const dialogRef = this.dialog.open(ProcessMapDialogComponent, {
      data: 'choose' + 'æææ' + true,

    });
    dialogRef.componentInstance.isSub = activityID;
    dialogRef.componentInstance.ActivityID = activityID;
    dialogRef.componentInstance.ProcessID = processID;
    dialogRef.componentInstance.isProcess = processID;
    dialogRef.componentInstance.IsVersion = isver;
    dialogRef.componentInstance.readOnly = this.readOnly;
    dialogRef.componentInstance.menuDeleteCheck = this.menuDeleteCheck;
    dialogRef.componentInstance.menuUpdateCheck = this.menuUpdateCheck;
    dialogRef.componentInstance.menuCreateCheck = this.menuCreateCheck;
    dialogRef.componentInstance.menuReadCheck = this.menuReadCheck;
    dialogRef.componentInstance.menuCCheck = this.menuCCheck;
    dialogRef.componentInstance.activityTemp = this.activityTemp;

    dialogRef.componentInstance.GoalsList = this.GoalsList;

    dialogRef.afterClosed().subscribe(pointerType => {

      dialogRef.componentInstance.isSub = activityID;
      if (pointerType === 'Issue') {

        this.AddIssue(processID, activityID);
      } else if (pointerType === 'Metric') {
        this.AddMetric(processID, activityID);
      } else if (pointerType === 'processView') {

        this.DisplaySubMap(processID);

      }
      else if (pointerType === 'Activity') {
        dialogRef.componentInstance.isActivity = activityID;
        this.AddActivity(processID, activityID);
      }
      else if (pointerType === 'SubActivity') {
        this.AddActivity(processID, activityID);
      }

      else if (pointerType === 'subAct') {

        this.DisplaySubActivityMap(processID, activityID);


      }
      else if (pointerType === 'changSquence' && activityID != 0) {

        const subDialogref = this.dialog.open(ActivityDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: 'rearrange' + 'æææ' + true,
        });

        let activityFilter = this.activityData.filter(x =>
          x.ProcessID.toString() == processID.toString() && x.ActivityID.toString() == activityID.toString()
        );
        subDialogref.componentInstance.ProcessID = processID;
        subDialogref.componentInstance.ActivityID = activityID;
        subDialogref.componentInstance.ActivityName = activityFilter[0].ActivityName;
        subDialogref.componentInstance.ParentActivityID = activityFilter[0].ParentID;
        subDialogref.componentInstance.DepartmentID = activityFilter[0].DepartmentID;
        subDialogref.componentInstance.EmployeeID = activityFilter[0].EmployeeID;
        subDialogref.componentInstance.ActivityTypeID = activityFilter[0].ActivityTypeID;
        subDialogref.componentInstance.tempActivity = this.activityData;
        subDialogref.componentInstance.squence = activityFilter[0].squence;
        subDialogref.componentInstance.processData = this.processLists;
        subDialogref.componentInstance.TeamID = activityFilter[0].TeamID;

        subDialogref.componentInstance.teamData = this.teamData;
        subDialogref.componentInstance.ActivityTypeID = activityFilter[0].ActivityTypeID;
        subDialogref.componentInstance.activityTypeData = this.activityTypeData;
        subDialogref.componentInstance.ParentActivityID = activityFilter[0].ParentID;
        subDialogref.componentInstance.activityData = this.activityData;
        subDialogref.componentInstance.CompanyID = this.CompanyID;
        subDialogref.afterClosed().subscribe(result => {
          // this.GetActivity();
          this.dorseyService.GetActivity(this.CompanyID).subscribe(subData => {
            this.activityData = JSON.parse(subData['_body']);

            this.processLists = [];
            this.IssuesLists = {};
            this.processIDs = [];
            this.pointerList = [];
            this.granualProcess = [];
            this.ProcessFinalList = [];

            this.GetActivities();
            //  this.ngOnInit();
            // this.dialogCall('Updated Successfully');
            this.ConstructMap();

            this.checkForMetricAlert();

          });
        });
      } else if (pointerType === 'changSquence' && processID != 0) {

        this.dorseyService.GetProcess(this.CompanyID).subscribe(subData => {
          this.processData = JSON.parse(subData['_body']);
          const processDialogref = this.dialog.open(ProcessDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: 'rearrange' + 'æææ' + true,
          });

          let processFilter = this.processData.filter(x =>
            x.ProcessID.toString() == processID.toString()
          );
          processDialogref.componentInstance.processID = processID;
          processDialogref.componentInstance.TeamID = this.TeamID;
          processDialogref.componentInstance.teamData = this.teamData;
          processDialogref.componentInstance.CompanyID = this.CompanyID;
          processDialogref.componentInstance.ProcessName = processFilter[0].ProcessName;
          processDialogref.componentInstance.TeamID = processFilter[0].TeamID;
          processDialogref.componentInstance.EmployeeID = processFilter[0].EmployeeID;
          processDialogref.componentInstance.DepartmentID = processFilter[0].DepartmentID;
          processDialogref.componentInstance.ProcessDescription = processFilter[0].ProcessDescription;
          processDialogref.componentInstance.squence = processFilter[0].squence;
          processDialogref.componentInstance.processData = this.processData;

          processDialogref.afterClosed().subscribe(res => {
            dialogRef.close(false);
            this.ConstructMap();
          });
        });

      }

      else if (pointerType === 'edit' && activityID != 0) {

        let activityFilter = this.activityData.filter(x => x.ProcessID === processID && x.ActivityID === activityID);
        if (activityFilter.length > 0) {
          const dialogRef = this.dialog.open(ActivityDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: 'edit' + 'æææ' + true,
          });

          if (activityFilter[0].ActivityID === 0) {
            dialogRef.componentInstance.ParentActivityID = 0;
          }
          else {
            dialogRef.componentInstance.ParentActivityID = activityFilter[0].ActivityID;
          }

          this.activityData.sort(function (a, b) {
            return a.squence - b.squence;
          });


          let tempProcessData = this.processLists.filter(x => x.TeamID === activityFilter[0].TeamID);

          dialogRef.componentInstance.processData = tempProcessData;

          dialogRef.componentInstance.ActivityID = activityFilter[0].ActivityID;

          dialogRef.componentInstance.ParentActivityID = activityFilter[0].ParentID;
          dialogRef.componentInstance.DepartmentID = activityFilter[0].DepartmentID;
          dialogRef.componentInstance.EmployeeID = activityFilter[0].EmployeeID;
          dialogRef.componentInstance.tempActivity = this.activityData;

          dialogRef.componentInstance.oldProcessID = activityFilter[0].ProcessID;

          dialogRef.componentInstance.oldActivityID = this.ParentID;

          dialogRef.componentInstance.ActivityTypeID = activityFilter[0].ActivityTypeID;
          dialogRef.componentInstance.ActivityName = activityFilter[0].ActivityName;
          dialogRef.componentInstance.squence = activityFilter[0].squence;
          dialogRef.componentInstance.activityData = this.activityData;
          dialogRef.componentInstance.processData = this.processLists;
          dialogRef.componentInstance.TeamID = activityFilter[0].TeamID;
          dialogRef.componentInstance.departmentData = this.departmentData;
          dialogRef.componentInstance.teamData = this.teamData;
          dialogRef.componentInstance.employeeData = this.employeeData;

          dialogRef.componentInstance.actData = this.activityData;

          dialogRef.componentInstance.ProcessID = activityFilter[0].ProcessID;
          dialogRef.componentInstance.CompanyID = activityFilter[0].CompanyID;
          dialogRef.componentInstance.activityTypeData = this.activityTypeData;

          dialogRef.componentInstance.currentUser = this.currentUser;
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.dialogCall("Updated successfully");
              this.processLists = [];
              this.IssuesLists = {};
              this.processIDs = [];
              //this.pointerList=[];

              this.GetActivities();
              //  this.ngOnInit();
              this.ConstructMap()
            }


          });
        }

      }

      else if (pointerType === 'edit' && processID != 0) {


        const dialogRef = this.dialog.open(ProcessDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: 'edit' + 'æææ' + true,
        });

        let processFilter = this.processLists.filter(x => x.ProcessID === processID);
        if (processFilter.length > 0) {
          dialogRef.componentInstance.EmployeeID = processFilter[0].EmployeeID;
          dialogRef.componentInstance.TeamID = processFilter[0].TeamID;
          dialogRef.componentInstance.teamData = this.teamData;
          dialogRef.componentInstance.CompanyID = processFilter[0].CompanyID;
          dialogRef.componentInstance.processData = this.processLists;
          dialogRef.componentInstance.procData = this.processLists;

          dialogRef.componentInstance.processID = processFilter[0].ProcessID;
          dialogRef.componentInstance.ProcessName = processFilter[0].ProcessName;
          dialogRef.componentInstance.ProcessDescription = processFilter[0].ProcessDescription;
          dialogRef.componentInstance.squence = processFilter[0].squence;
          dialogRef.afterClosed().subscribe(result => {

            if (result)
              this.dialogCall("Updated successfully");

            this.processLists = [];
            this.IssuesLists = {};
            this.processIDs = [];
            this.pointerList = [];
            this.GetTeam();

          });
        }

      }
      else if (pointerType === 'delete' && activityID != 0) {
        let activityFilter = this.activityData.filter(x => x.ProcessID === processID && x.ActivityID === activityID);
        if (activityFilter.length > 0) {

          this.ActivityID = activityFilter[0].ActivityID;
          this.CompanyID = activityFilter[0].CompanyID;
          this.TeamID = activityFilter[0].TeamID;
          this.squence = activityFilter[0].squence;
          this.ProcessID = activityFilter[0].ProcessID;
          this.parentID = activityFilter[0].ParentID;

          const dialogRef = this.dialog.open(ActivityDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: 'Are you sure you want to delete activity: ' + activityFilter[0].ActivityName + '?æææ' + true,

          });

          dialogRef.afterClosed().subscribe(result => {
            if (result) {


              this.dorseyService.DeleteActivity(this.ActivityID, this.CompanyID, this.TeamID, this.squence, this.ProcessID, this.parentID).subscribe(data => {

                if (data.ok) {
                  this.condition = true;
                  this.dialogCall('Deleted successfully');
                }
                this.GetActivities();
                this.ConstructMap();
              });

            }
          });

        }
      }

      else if (pointerType === 'delete' && processID != 0) {
        let processFilter = this.processLists.filter(x => x.ProcessID === processID);
        if (processFilter.length > 0) {

          this.ProcessID = processFilter[0].ProcessID;
          this.CompanyID = processFilter[0].CompanyID;
          this.TeamID = processFilter[0].TeamID;
          this.squence = processFilter[0].squence;

          const dialogRef = this.dialog.open(ProcessDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: 'Are you sure you want to delete process: ' + processFilter[0].ProcessName + '?æææ' + true,
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result) {


              this.dorseyService.DeleteProcess(this.ProcessID, this.CompanyID, this.TeamID, this.squence).subscribe(data => {
                if (data.ok) {
                  this.condition = true;
                  this.dialogCall('Deleted successfully');

                  this.GetProcess();
                  setTimeout(() => {
                    this.ConstructMap();
                  }, 100);
                }
              });

            }
          });
        }
      }
    });

  }

  onChange(event) {
    this.MapType = event.value.toString();

    if (this.MapType === '0') {
      this.typeName = "Only Frustrations";
    } else if (this.MapType === '1') {
      this.typeName = "Only Metrics";
    } else if (this.MapType === '2') {
      this.typeName = "No Frustrations & Metrics";
    } else {
      this.typeName = "Both";
    }
  }
  DisplaySubActivityMap(processID, activityID) {

    this.granualActivity = [];
    this.activityLists = [];
    let CurrentVersionNumber;
    CurrentVersionNumber = this.currentVersion['VersionID'];
    this.dorseyService.GetSelectedVersionActivityData(CurrentVersionNumber, Number(this.CompanyID), Number(this.TeamID), this.childTableNameType).subscribe(activity => {

      activity = JSON.parse(activity['_body']);
      this.activityLists = activity;
      this.granualActivity = this.activityLists;

      this.subProcessMap = {};
      var procssName = '';
      let activitys = [];
      let subActivities = [];
      let subactTemp = {};
      if (this.TeamID === 0) {
        for (let i = 0; i < this.granualActivity.length; i++) {
          if (this.granualActivity[i].ProcessID === processID && this.granualActivity[i].ParentID === 0) {
            activitys.push(this.granualActivity[i]);
          }
        }
      }
      else {
        for (let i = 0; i < this.granualActivity.length; i++) {
          if (this.granualActivity[i].ProcessID === processID && this.granualActivity[i].TeamID === this.TeamID && this.granualActivity[i].ParentID === 0) {
            activitys.push(this.granualActivity[i]);
          }
        }
      }

      for (let i = 0; i < this.processLists.length; i++) {

        if (this.processLists[i].ProcessID === processID) {
          procssName = this.processLists[i].ProcessName;

        }
      }
      let tempSub;


      let subact = this.granualActivity.filter(x => x.ParentID != 0);
      subActivities = [];
      let metricDummy = {};
      let issueDummy = {};
      let SubFsCounts = {};
      activitys.forEach(actElement => {
        tempSub = subact.filter(x => x.ParentID.toString() === actElement['ActivityID'].toString() && x.ProcessID.toString() === processID.toString());
        if (tempSub.length > 0) {
          subActivities = tempSub;
          subactTemp[actElement['ActivityID']] = subActivities;
        }
        subActivities.sort(function (a, b) {
          return a.squence - b.squence;
        });
        this.granualActivity = this.activityLists;


        tempSub.forEach(subAct => {
          this.dorseyService.GetSelectedVersionPointerData(CurrentVersionNumber, Number(this.CompanyID), Number(this.TeamID), this.childTableName).subscribe(pointer => {
            this.pointerData = JSON.parse(pointer['_body']);
            let fSCount = 0;
            let mSCount = 0;
            let procesDummy = {};
            let ActiDummyM = {}; const ActiDummyI = {};
            this.metricData = this.pointerData.filter(x => x.PointerTypeID != null && x.PointerTypeID.toString() === '2');
            this.pointerData.forEach(element => {

              if (element['ProcessID'] === processID && element['ActivityID'] === subAct['ActivityID']) {

                if (element['PointerTypeID'] === 2) {
                  mSCount++;
                  this.expandProcess = false;
                  let metric = this.metricData.filter(x => x.ProcessID === processID && x.ActivityID === element['ActivityID'])
                  metricDummy[subAct['ActivityID']] = metric;

                } else {
                  fSCount++;
                  this.expandProcess = false;
                  this.dorseyService.GetCaseData(processID, element['ActivityID'], 1, CurrentVersionNumber, this.childTableName, this.childTableNameType).subscribe(cases => {
                    cases = JSON.parse(cases['_body']);
                    this.cased = cases;
                    issueDummy[subAct['ActivityID']] = this.cased;
                  });
                }
              }
            });
            if (mSCount !== 0) {
              ActiDummyM[subAct['ActivityID']] = mSCount;
            }
            if (fSCount !== 0) {
              ActiDummyI[subAct['ActivityID']] = fSCount;
            }
            procesDummy['SubMetric'] = ActiDummyM;
            procesDummy['SubIssue'] = ActiDummyI;
            SubFsCounts[subAct['ActivityID']] = procesDummy;
          });
        });

      });

      this.IssuesLists[processID]['SubMCount'] = SubFsCounts;
      let data = [];
      const ActivityJson = [];
      for (let i = 0; i < activitys.length; i++) {
        if (activitys[i].ParentID === 0) {
          data.push(activitys[i]);
        }
      }
      activitys.sort(function (a, b) {
        return a.squence - b.squence;
      });
      this.IssuesLists[processID]['Activity'] = activitys;
      this.IssuesLists[processID]['SubActivity'] = subactTemp;
      this.IssuesLists[processID]['SubActivityMetric'] = metricDummy;
      this.IssuesLists[processID]['SubActivityIssues'] = issueDummy;

      this.subProcessMap[processID] = this.IssuesLists[processID];
      const dialogRef = this.dialog.open(ProcessMapDialogComponent, {
        panelClass: ['custom-dialog-containerSub'],
        data: 'subAct' + 'æææ',
        maxWidth: '90vw'
      });
      dialogRef.componentInstance.copyRight = this.copyRight;
      dialogRef.componentInstance.currentVersion = this.currentVersion;
      dialogRef.componentInstance.IsVersion = this.IsVersion;
      dialogRef.componentInstance.readOnly = this.readOnly;
      dialogRef.componentInstance.GoalsList = this.GoalsList;
      dialogRef.componentInstance.menuDeleteCheck = this.menuDeleteCheck;
      dialogRef.componentInstance.menuUpdateCheck = this.menuUpdateCheck;
      dialogRef.componentInstance.menuCreateCheck = this.menuCreateCheck;
      dialogRef.componentInstance.menuReadCheck = this.menuReadCheck;
      dialogRef.componentInstance.menuCCheck = this.menuCCheck;
      dialogRef.componentInstance.IssuesLists = this.subProcessMap;
      dialogRef.componentInstance.MapType = this.MapType;
      dialogRef.componentInstance.isProcess = processID;
      dialogRef.componentInstance.isActivity = activityID;
      dialogRef.componentInstance.ProcessName = procssName;
      dialogRef.componentInstance.TeamID = this.TeamID
      dialogRef.componentInstance.tempProcess = this.processLists;
      dialogRef.componentInstance.activityData = this.activityData;
      dialogRef.componentInstance.departmentData = this.departmentData;
      dialogRef.componentInstance.pointerTypeData = this.pointerTypeData;
      dialogRef.componentInstance.severityData = this.severityData;
      dialogRef.componentInstance.statusData = this.statusData;
      dialogRef.componentInstance.teamData = this.teamData;
      dialogRef.componentInstance.employeeData = this.employeeData;
      dialogRef.componentInstance.frustrationtypeData = this.frustrationtypeData;
      dialogRef.componentInstance.tempActivity = this.activityData;
      dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
      dialogRef.componentInstance.ProcessID = processID;
      dialogRef.componentInstance.CompanyID = this.CompanyID;
      dialogRef.componentInstance.VersionNumber = this.VersionNumber;
      dialogRef.componentInstance.childTable = this.childTableName;
      dialogRef.componentInstance.childFrustType = this.childTableNameType;
      dialogRef.componentInstance.pointerList = this.pointerList;
      dialogRef.componentInstance.CompanyName = this.CompanyName;
      dialogRef.componentInstance.TeamName = this.TeamName;
      dialogRef.componentInstance.alertValue = this.alertValue;
      dialogRef.componentInstance.alertMetricValue = this.alertMetricValue;
      dialogRef.componentInstance.alertPTypeID = this.alertPTypeID;
      dialogRef.componentInstance.alertMTypeID = this.alertMTypeID;
      dialogRef.componentInstance.alertMetricPointer = this.alertMetricPointer;
      dialogRef.componentInstance.TeamID = this.TeamID
      dialogRef.componentInstance.processData = this.processLists;
      dialogRef.componentInstance.activityData = this.activityData;
      dialogRef.componentInstance.departmentData = this.departmentData;
      dialogRef.componentInstance.pointerTypeData = this.pointerTypeData;
      dialogRef.componentInstance.severityData = this.severityData;
      dialogRef.componentInstance.statusData = this.statusData;
      dialogRef.componentInstance.teamData = this.teamData;
      dialogRef.componentInstance.employeeData = this.employeeData;
      dialogRef.componentInstance.frustrationtypeData = this.frustrationtypeData;
      dialogRef.componentInstance.tempActivity = this.activityData;
      dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
      dialogRef.componentInstance.ProcessID = processID;
      dialogRef.componentInstance.ActivityID = activityID;
      dialogRef.componentInstance.CompanyID = this.CompanyID;
      this.StatusID = this.statusData[0].StatusID;
      dialogRef.componentInstance.StatusID = this.StatusID;
      dialogRef.componentInstance.currentUser = this.currentUser;
      dialogRef.componentInstance.childTablePointers = this.childTablePointers;
      dialogRef.componentInstance.childTableName = this.childTableName;
      dialogRef.componentInstance.activityTypeData = this.activityTypeData;
      dialogRef.componentInstance.RoleData = this.RoleData;
      dialogRef.componentInstance.EmpROleID = this.EmpROleID;
      dialogRef.componentInstance.CurrentVersionNumber = CurrentVersionNumber;
      dialogRef.componentInstance.childTableNameType = this.childTableNameType;
      dialogRef.componentInstance.alertMetricPointer = this.alertMetricPointer;
      dialogRef.afterClosed().subscribe(res => {
        this.GetActivity();
        this.GetFrustrationType();
        this.ConstructMap();
      });
    });

  }
  DisplaySubMap(processID) {
    this.subProcessMap = {};
    var procssName = '';
    let process = "";
    let activitys = [];
    if (this.TeamID === 0) {
      for (let i = 0; i < this.granualActivity.length; i++) {
        if (this.granualActivity[i].ProcessID === processID && this.granualActivity[i].ParentID === 0) {
          activitys.push(this.granualActivity[i]);
        }
      }
    }
    else {
      for (let i = 0; i < this.granualActivity.length; i++) {
        if (this.granualActivity[i].ProcessID === processID && this.granualActivity[i].TeamID === this.TeamID && this.granualActivity[i].ParentID === 0) {
          activitys.push(this.granualActivity[i]);
        }
      }
    }
    activitys.sort(function (a, b) {
      return a.squence - b.squence;
    });
    for (let i = 0; i < this.processLists.length; i++) {

      if (this.processLists[i].ProcessID === processID) {
        procssName = this.processLists[i].ProcessID;
        process = this.processLists[i].ProcessName;
      }
    }
    let data = [];
    for (let i = 0; i < activitys.length; i++) {

      if (activitys[i].ParentID === 0) {
        data.push(activitys[i]);
      }
    }
    this.IssuesLists[procssName]['Activity'] = activitys;
    this.subProcessMap[procssName] = this.IssuesLists[procssName];
    const dialogRef = this.dialog.open(ProcessMapDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'subMap' + 'æææ',
      width: '800px'
    });
    dialogRef.componentInstance.currentVersion = this.currentVersion;
    dialogRef.componentInstance.IssuesLists = this.subProcessMap;
    dialogRef.componentInstance.MapType = this.MapType;
    dialogRef.componentInstance.ProcessName = process;
    dialogRef.componentInstance.pointerList = this.pointerList;
    dialogRef.componentInstance.CompanyName = this.CompanyName;
    dialogRef.componentInstance.TeamName = this.TeamName;
    dialogRef.componentInstance.alertValue = this.alertValue;
    dialogRef.componentInstance.alertMetricValue = this.alertMetricValue;
    dialogRef.componentInstance.alertPTypeID = this.alertPTypeID;
    dialogRef.componentInstance.GoalsList = this.GoalsList;
    dialogRef.componentInstance.alertMTypeID = this.alertMTypeID;
    dialogRef.componentInstance.alertMetricPointer = this.alertMetricPointer;
    dialogRef.afterClosed().subscribe(res => {
      this.GetActivity();
    });
  }


  AddIssue(processID, activityID) {
    const dialogRef = this.dialog.open(PointerDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'new' + 'æææ' + activityID,
    });
    dialogRef.componentInstance.TeamID = this.TeamID
    dialogRef.componentInstance.processData = this.processLists;
    dialogRef.componentInstance.activityData = this.activityData;
    dialogRef.componentInstance.departmentData = this.departmentData;
    dialogRef.componentInstance.pointerTypeData = this.pointerTypeData;
    dialogRef.componentInstance.severityData = this.severityData;
    dialogRef.componentInstance.statusData = this.statusData;
    dialogRef.componentInstance.teamData = this.teamData;
    dialogRef.componentInstance.employeeData = this.employeeData;
    dialogRef.componentInstance.frustrationtypeData = this.frustrationtypeData;
    dialogRef.componentInstance.tempActivity = this.activityData;
    dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
    dialogRef.componentInstance.ProcessID = processID;
    dialogRef.componentInstance.ActivityID = activityID;
    dialogRef.componentInstance.CompanyID = this.CompanyID;
    this.StatusID = this.statusData[0].StatusID;
    dialogRef.componentInstance.StatusID = this.StatusID;
    dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.afterClosed().subscribe(result => {
      this.processLists = [];
      this.GetFrustrationType();
      this.IssuesLists = {};
      this.processIDs = [];
      this.pointerList = [];
      this.ConstructMap()
    });

  }
  AddProcess(menuPer, vers) {
    if (!menuPer || vers) {
      return;
    }
    const dialogRef = this.dialog.open(ProcessDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'new' + 'æææ' + 'process',
    });
    dialogRef.componentInstance.EmployeeID = this.empID;
    dialogRef.componentInstance.TeamID = this.TeamID;
    dialogRef.componentInstance.teamData = this.teamData;
    dialogRef.componentInstance.CompanyID = this.CompanyID;
    dialogRef.componentInstance.processData = this.processLists;
    dialogRef.afterClosed().subscribe(result => {
      this.processLists = [];
      this.IssuesLists = {};
      this.processIDs = [];
      this.pointerList = [];
      this.GetTeam();
    });
  }
  GetActivityType() {
    this.dorseyService.GetActivityType().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.activityTypeData = subData;
    });
  }

  AddActivity(processID, activityID) {
    const dialogRef = this.dialog.open(ActivityDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'new' + 'æææ' + 'process',
    });

    if (activityID === 0) {
      dialogRef.componentInstance.ParentActivityID = 0;
    }
    else {
      dialogRef.componentInstance.ParentActivityID = activityID;
    }
    this.activityData.sort(function (a, b) {
      return a.squence - b.squence;
    });
    dialogRef.componentInstance.activityData = this.activityData;
    dialogRef.componentInstance.processData = this.processLists;
    dialogRef.componentInstance.TeamID = this.TeamID;
    dialogRef.componentInstance.tempActivity = this.activityData;
    dialogRef.componentInstance.departmentData = this.departmentData;
    dialogRef.componentInstance.teamData = this.teamData;
    dialogRef.componentInstance.employeeData = this.employeeData;
    dialogRef.componentInstance.tempActivity = this.activityData;
    dialogRef.componentInstance.ProcessID = processID;
    dialogRef.componentInstance.CompanyID = this.CompanyID;
    dialogRef.componentInstance.activityTypeData = this.activityTypeData;

    dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.afterClosed().subscribe(result => {
      this.processLists = [];
      this.IssuesLists = {};
      this.processIDs = [];
      this.pointerList = [];
      this.GetActivities();
      this.ConstructMap()

    });

  }

  AddMetric(processID, activityID) {
    const dialogRef = this.dialog.open(MetricDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'new' + 'æææ' + activityID,
    });

    dialogRef.componentInstance.processData = this.processLists;
    dialogRef.componentInstance.TeamID = this.TeamID;
    dialogRef.componentInstance.GoalsList = this.GoalsList;
    dialogRef.componentInstance.tempActivity = this.activityData;
    dialogRef.componentInstance.departmentData = this.departmentData;
    dialogRef.componentInstance.pointerTypeData = this.pointerTypeData;
    dialogRef.componentInstance.severityData = this.severityData;
    dialogRef.componentInstance.statusData = this.statusData;
    dialogRef.componentInstance.teamData = this.teamData;
    dialogRef.componentInstance.employeeData = this.employeeData;
    dialogRef.componentInstance.frustrationtypeData = this.frustrationtypeData;
    dialogRef.componentInstance.tempActivity = this.activityData;
    dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
    dialogRef.componentInstance.ProcessID = processID;
    dialogRef.componentInstance.ActivityID = activityID;
    dialogRef.componentInstance.CompanyID = this.CompanyID;
    dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.afterClosed().subscribe(result => {
      this.processLists = [];
      this.IssuesLists = {};
      this.processIDs = [];
      this.pointerList = [];
      this.GetFrustrationType();

      this.ConstructMap()
      this.checkForMetricAlert();
    });

  }
  dialogCall(msg: string) {

    const dialogRef = this.dialog.open(ProcessMapDialogComponent, {
      data: msg + 'æææ' + false,
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result.toString() === 'true') {

        let statusMsg = '';
        if (msg === 'new') {
          statusMsg = 'Saved successfully';
        }
      }
    });
  }

  GetAlertValues() {
    this.dorseyService.GetAlertValue(this.CompanyID, 1).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.alertData = subData;

      for (let i = 0; i < this.alertData.length; i++) {

        if (this.alertData[i].AlertStatus === true) {
          this.alertPTypeID = this.alertData[i].PointerTypeID;
          this.alertValue = this.alertData[i].AlertValue;
        }
      }
    });

  }

  GetMetricAlert() {
    this.dorseyService.GetAlertValue(this.CompanyID, 2).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.alertData = subData;

      for (let i = 0; i < this.alertData.length; i++) {

        if (this.alertData[i].AlertStatus === true) {
          this.alertMetricPointer = this.alertData[i].PointerTypeID;
          this.alertMetricValue = this.alertData[i].AlertValue;
          this.AlertMetricStatus = this.alertData[i].AlertStatus;
          this.alertMTypeID = this.alertData[i].PointerTypeID;

        }

      }
    });

  }

  checkForMetricAlert() {
    this.showMetricAlert = [];
    if (this.AlertMetricStatus) {
      for (let i = 0; i < this.granualProcess.length; i++) {

        if (this.ActiveActivities[this.granualProcess[i].ProcessID] !== undefined) {
          for (let j = 0; j < this.ActiveActivities[this.granualProcess[i].ProcessID].length; j++) {
            let id = this.ActiveActivities[this.granualProcess[i].ProcessID][j].ActivityID;

            this.showMetricAlert[id] = false;

            if (this.IssuesLists[this.granualProcess[i].ProcessID]['MetricList'][this.ActiveActivities[this.granualProcess[i].ProcessID][j].ActivityID.toString()] != undefined) {

              j = j + this.alertMetricValue - (j % this.alertMetricValue) - 1;

            } else {

              if ((j + 1) % this.alertMetricValue == 0) {
                this.showMetricAlert[id] = true;

              } else {

                this.showMetricAlert[id] = false;

              }
            }

          }
        }



      }
    }
  }

}
@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
  styleUrls: ['dialog.css'],
  providers: [DorseyService, ProcessMapComponent]
})
export class ProcessMapDialogComponent implements OnInit {
  readOnly = false;
  copyRight: string;
  IsVersion = false;
  ActivityTypeID: string;
  condition = false;
  isActivity: any;
  dataSelf: string;
  processID: string;
  headerText: string;
  PointerType: string;
  CaseData: any;
  Process = '';
  Activity = '';
  toggle = '';
  IssuesLists = {};
  ProcessFinalList = [];
  subKey = '';
  MapType: string;
  TeamID: any;
  tempProcess = [];
  activityData: any;
  departmentData: any;
  pointerTypeData: any
  severityData: any;
  statusData: any;
  teamData: any;
  employeeData: any;
  frustrationtypeData: any;
  tempActivity: any;
  tempFrustrationType: any;
  ProcessID: string
  ActivityID: string
  CompanyID: string;
  ParentID: string;
  SubActivityID: string;
  tempSubActivity = [];
  subActivityStatus = false;
  ProcessName = '';
  GoalsList: any;
  VersionNumber: number;
  childTable: string;

  childFrustType: string;
  pointerList = [];
  ActivityName: string;
  activityTypeData: any;
  SubactivityData: any;
  isSub: any;
  isProcess: any;
  processData: any;
  expandMap = false;
  parentActivityName: string;
  CompanyName: string;
  TeamName: string;
  headerDate = new Date();
  versionID: any;
  childTableName: any;
  currentVersion = '';
  StatusID: string;
  alertPTypeID: any;
  alertValue: any;
  alertMetricValue: any;
  alertMetricPointer: any;
  showMetricAlert = {};
  alertMTypeID: any;
  copyDate: number;
  currentUser: string;
  childTablePointers: any;
  RoleData: any;
  EmpROleID: string;
  CurrentVersionNumber: any;
  childTableNameType: string;

  redLow: string;
  redHigh: string;
  yellowLow: string;
  yellowHigh: any;
  PointerID: any;
  //title=false;
  editSubActiibiyt = false;
  parentId: any;
  menuDeleteCheck: boolean;
  menuUpdateCheck: boolean;
  menuCreateCheck: boolean;
  menuReadCheck: boolean;
  menuCCheck: boolean;
  EmployeeID: any;
  subActivity: any;
  FrustrationTypeID: any;
  SeverityID: any;
  Subject: any;
  Description: any;
  Source: any;
  DepartmentID: string;
  conditionActivity: any;
  activityTemp: any;
  constructor(private parent: ProcessMapComponent,
    public dialogRef: MatDialogRef<ProcessMapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dorseyService: DorseyService,
    private dialog: MatDialog, private storage: LocalStorageService,
    private scoreCardService: PerformanceScoreCardService) {
    //    dialogRef.disableClose=true;

    this.data = this.data.split('æææ');

    if (this.data[1] === 'true') {
      this.condition = true;
    } else {
      this.condition = false;
    }
    this.toggle = this.data[2];
    this.data = this.data[0];

  }
  ngOnInit() {

    this.currentUser = this.storage.retrieve('user');
    this.CompanyID = this.storage.retrieve('companyID');

    this.subKey = Object.keys(this.IssuesLists)[0];
    this.checkForMetricAlert();

    this.copyDate = (new Date()).getFullYear();

  }

  ExpandMap() {
    let open = document.getElementById('open');
    ;
    if (open !== null) {
      open.style.marginTop = '8px'
    }
    this.expandMap = true;

  }
  HideMap() {
    let open = document.getElementById('open');
    if (open !== null) {
      open.style.marginTop = '30px';
    }

    this.expandMap = false;
  }

  Print() {
    window.print();
    var printContents = document.getElementById('Map').innerHTML;
    var popupWin = window.open('', '_blank', 'width=300,height=300');
    popupWin.document.open();
    popupWin.document.write(printContents);

    popupWin.window.print();
    popupWin.document.close();
    return true;

  }
  closeChoose() {
    this.dialogRef.close(this.PointerType);
  }
  Clear() {
    this.dialogRef.close(false);
  }
  onYesClick() {

    this.dialogRef.close(true);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  PrintMap() {
    var id = document.getElementById('Map');
    id.className = 'donotprintthis';
    window.print();
    //this.title=true;
  }
  GetPointerData() {

  }
  GetActivityType() {
    this.dorseyService.GetActivityType().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.activityTypeData = subData;
    });

  }


  dialogCall(msg: string) {

    const dialogRef = this.dialog.open(ProcessMapDialogComponent, {
      data: msg + 'æææ' + false,
    });




















    dialogRef.afterClosed().subscribe(result => {

      if (result.toString() === 'true') {

        let statusMsg = '';
        if (msg === 'new') {
          statusMsg = 'Saved successfully';
        }
        // else {

        //   statusMsg = 'Updated successfully';
        // }

      }

    });
  }
  AddMetricOrIssue(activityID, parentID) {

    if (this.IsVersion && this.readOnly) {
      return;
    }
    else if (this.readOnly) {
      return;
    }
    else if (this.IsVersion && !this.readOnly) {
      return;
    }
    else if (this.menuCCheck) {
      return;
    }


    this.GetActivityType();
    this.GetProcess();
    const dialogRef = this.dialog.open(ProcessMapDialogComponent2, {
      data: 'chooseChild' + 'æææ' + true,
    });


    let processTemp = this.activityData.filter(x => x.ActivityID === activityID);
    if (processTemp.length > 0) {
      this.processID = processTemp[0].ProcessID;

      this.ActivityName = processTemp[0].ActivityName;
      this.ActivityID = processTemp[0].ActivityID;
    }

    dialogRef.componentInstance.isProcess = 0;
    dialogRef.componentInstance.IsVersion = this.IsVersion;
    dialogRef.componentInstance.readOnly = this.readOnly;


    dialogRef.componentInstance.menuDeleteCheck = this.menuDeleteCheck;
    dialogRef.componentInstance.menuUpdateCheck = this.menuUpdateCheck;

    dialogRef.componentInstance.GoalsList = this.GoalsList;
    dialogRef.componentInstance.menuCreateCheck = this.menuCreateCheck;
    dialogRef.componentInstance.menuReadCheck = this.menuReadCheck;

    dialogRef.componentInstance.menuCCheck = this.menuCCheck;

    let activityFilter1 = this.activityData.filter(x =>
      x.ProcessID.toString() === this.processID.toString() && x.ActivityID.toString() === activityID.toString());

    dialogRef.componentInstance.isSub = activityFilter1[0].ParentID;
    dialogRef.componentInstance.activityProcess = activityFilter1[0].ParentID;
    dialogRef.componentInstance.ActivityTypeID = activityFilter1[0].ActivityTypeID;

    dialogRef.componentInstance.isActivity = activityFilter1[0].Act;

    dialogRef.afterClosed().subscribe(pointerType => {

      if (pointerType === 'Issue') {
        this.AddIssue(activityID);
      }
      else if (pointerType === 'SubActivity') {

        this.AddSubActivity(activityID, this.processID);

      }
      else if (pointerType === 'Metric') {

        this.AddMetric(activityID);

      } else if (pointerType === 'changSquence') {

        const subDialogref = this.dialog.open(ActivityDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: 'rearrange' + 'æææ' + true,
        });

        this.ActivityID = activityID;
        let processTemp = this.activityData.filter(x => x.ActivityID === activityID);
        if (processTemp.length > 0) {
          this.processID = processTemp[0].ProcessID;
          this.ActivityName = processTemp[0].ActivityName;
        }
        let activityFilter = this.activityData.filter(x =>
          x.ProcessID.toString() === this.processID.toString() && x.ActivityID.toString() === activityID.toString()
        );

        subDialogref.componentInstance.ProcessID = this.processID;
        subDialogref.componentInstance.ActivityID = activityID;
        subDialogref.componentInstance.ActivityName = activityFilter[0].ActivityName;
        subDialogref.componentInstance.ParentActivityID = activityFilter[0].ParentID;
        subDialogref.componentInstance.DepartmentID = activityFilter[0].DepartmentID;
        subDialogref.componentInstance.EmployeeID = activityFilter[0].EmployeeID;
        subDialogref.componentInstance.ActivityTypeID = activityFilter[0].ActivityTypeID;
        subDialogref.componentInstance.tempActivity = this.activityData;
        subDialogref.componentInstance.squence = activityFilter[0].squence;
        subDialogref.componentInstance.processData = this.tempProcess;
        subDialogref.componentInstance.TeamID = activityFilter[0].TeamID;
        subDialogref.componentInstance.teamData = this.teamData;
        subDialogref.componentInstance.ActivityTypeID = activityFilter[0].ActivityTypeID;
        subDialogref.componentInstance.activityTypeData = this.activityTypeData;
        subDialogref.componentInstance.ParentActivityID = activityFilter[0].ParentID;
        subDialogref.componentInstance.activityData = this.activityData;
        subDialogref.componentInstance.CompanyID = this.CompanyID;
        subDialogref.afterClosed().subscribe(result => {

          this.dorseyService.GetActivity(this.CompanyID).subscribe(subData => {
            this.activityData = JSON.parse(subData['_body']);

            subData = JSON.parse(subData['_body']);

            this.SubactivityData = subData;

            this.SubactivityData = this.SubactivityData.filter(x => x.ParentID === activityFilter[0].ParentID && x.ProcessID === this.processID);
            let sQuen = this.SubactivityData.filter(x => x.ActivityID === activityID);

            let ParentName = this.activityData.filter(x => x.ActivityID === activityFilter[0].ParentID);
            this.SubactivityData.sort(function (a, b) {
              return a.squence - b.squence;
            });

            if (ParentName.length)
              this.IssuesLists[this.processID]['SubActivity'][ParentName[0].ActivityID] = this.SubactivityData;
            else this.IssuesLists[this.processID]['Activity'] = this.SubactivityData;

          });


        });
      }

      else if (pointerType === 'edit' && parentID === 0) {
        this.editSubActiibiyt = true;
        let activityFilter = this.activityData.filter(x => x.ActivityID === activityID);

        if (activityFilter.length > 0) {

          const dialogRef = this.dialog.open(ActivityDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: 'edit' + 'æææ' + true,
          });

          if (activityFilter[0].ActivityID === 0) {
            dialogRef.componentInstance.ParentActivityID = 0;
          }
          else {
            dialogRef.componentInstance.ParentActivityID = activityFilter[0].ActivityID;
          }

          this.activityData.sort(function (a, b) {
            return a.squence - b.squence;
          });

          let tempProcessData = this.tempProcess.filter(x => x.TeamID === activityFilter[0].TeamID);

          dialogRef.componentInstance.processData = tempProcessData;

          dialogRef.componentInstance.ActivityID = activityFilter[0].ActivityID;

          dialogRef.componentInstance.ProcessID = activityFilter[0].ProcessID;
          dialogRef.componentInstance.ParentActivityID = activityFilter[0].ParentID;
          dialogRef.componentInstance.DepartmentID = activityFilter[0].DepartmentID;
          dialogRef.componentInstance.EmployeeID = activityFilter[0].EmployeeID;

          dialogRef.componentInstance.tempActivity = this.activityData;


          dialogRef.componentInstance.oldProcessID = activityFilter[0].ProcessID;

          dialogRef.componentInstance.oldActivityID = activityFilter[0].ParentID;

          dialogRef.componentInstance.ActivityID = activityFilter[0].ActivityID;
          dialogRef.componentInstance.ActivityTypeID = activityFilter[0].ActivityTypeID;
          dialogRef.componentInstance.ActivityName = activityFilter[0].ActivityName;
          dialogRef.componentInstance.squence = activityFilter[0].squence;
          dialogRef.componentInstance.activityData = this.activityData;
          // dialogRef.componentInstance.processData = this.processLists;
          dialogRef.componentInstance.TeamID = activityFilter[0].TeamID;
          dialogRef.componentInstance.tempActivity = this.activityData;
          dialogRef.componentInstance.departmentData = this.departmentData;
          dialogRef.componentInstance.teamData = this.teamData;
          dialogRef.componentInstance.employeeData = this.employeeData;

          dialogRef.componentInstance.actData = this.activityData;
          dialogRef.componentInstance.tempActivity = this.activityData;

          dialogRef.componentInstance.ProcessID = activityFilter[0].ProcessID;
          dialogRef.componentInstance.CompanyID = activityFilter[0].CompanyID;
          dialogRef.componentInstance.activityTypeData = this.activityTypeData;

          dialogRef.componentInstance.currentUser = this.currentUser;
          dialogRef.afterClosed().subscribe(result => {
            if (result)
              this.dialogCall("Updated successfully");

            this.processData = [];
            // this.pointerList=[];
            //   this.GetFrustrations();

            let filAct = this.activityData;
            this.dorseyService.GetActivity(this.CompanyID).subscribe(subData => {
              this.activityData = JSON.parse(subData['_body']);

              subData = JSON.parse(subData['_body']);

              this.SubactivityData = subData;



              this.SubactivityData = this.SubactivityData.filter(x => x.ParentID === activityFilter[0].ParentID && x.ProcessID === activityFilter[0].ProcessID);
              let sQuen = this.SubactivityData.filter(x => x.ActivityID === activityFilter[0].ActivityID);


              let data = this.activityData.filter(x => x.ParentID === activityFilter[0].ActivityID);
              this.SubactivityData.sort(function (a, b) {
                return a.squence - b.squence;
              });


              let tempAct = this.activityData.filter(x => x.ActivityID === activityFilter[0].ActivityID);

              let temfilAct = filAct.filter(x => x.ActivityID === activityFilter[0].ActivityID);



              this.IssuesLists[activityFilter[0].ProcessID]['Activity'] = this.SubactivityData;
              this.ParentID = parentID.toString();;

              if (!(Object.keys(this.IssuesLists[this.processID]['SubActivity']).indexOf(this.SubactivityData[0].ActivityID.toString()) > -1)) {

                this.IssuesLists[activityFilter[0].ProcessID]['SubActivity'][tempAct[0].ActivityID] = data;
                this.ActivityName = this.SubactivityData[0].ActivityName;
                this.ActivityID = this.SubactivityData[0].ActivityID;
                this.ProcessID = this.SubactivityData[0].ProcessID;

                //this.GetFrustrations();

              }

              else {
                this.ActivityName = activityFilter[0].ActivityName;
                this.ActivityID = activityFilter[0].ActivityID;
                this.ProcessID = activityFilter[0].ProcessID;
                //  this.GetFrustrations();
              }

              //this.pointerList=[];
            });


          });
        }
      }
      else if (pointerType === 'edit' && parentID != 0) {
        this.editSubActiibiyt = true;
        let filterAct = this.activityData.filter(x => x.ParentID === parentID && x.ActivityID === activityID);

        if (filterAct.length > 0) {


          const dialogRef = this.dialog.open(ActivityDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: 'edit' + 'æææ' + true,
          });

          this.activityData.sort(function (a, b) {
            return a.squence - b.squence;
          });


          let tempProcessData = this.tempProcess.filter(x => x.TeamID === this.TeamID);

          dialogRef.componentInstance.processData = tempProcessData;


          dialogRef.componentInstance.ParentActivityID = filterAct[0].ParentID;
          dialogRef.componentInstance.DepartmentID = filterAct[0].DepartmentID;
          dialogRef.componentInstance.EmployeeID = filterAct[0].EmployeeID;


          dialogRef.componentInstance.tempActivity = this.activityData;



          dialogRef.componentInstance.oldProcessID = this.ProcessID;

          dialogRef.componentInstance.oldActivityID = this.ParentID;

          dialogRef.componentInstance.ActivityID = filterAct[0].ActivityID;
          dialogRef.componentInstance.ActivityTypeID = filterAct[0].ActivityTypeID;
          dialogRef.componentInstance.ActivityName = filterAct[0].ActivityName;

          dialogRef.componentInstance.squence = filterAct[0].squence;
          dialogRef.componentInstance.activityData = this.activityData;
          // dialogRef.componentInstance.processData = this.processLists;
          dialogRef.componentInstance.TeamID = filterAct[0].TeamID;
          dialogRef.componentInstance.tempActivity = this.activityData;
          dialogRef.componentInstance.departmentData = this.departmentData;
          dialogRef.componentInstance.teamData = this.teamData;
          dialogRef.componentInstance.employeeData = this.employeeData;

          dialogRef.componentInstance.actData = this.activityData;
          dialogRef.componentInstance.tempActivity = this.activityData;

          dialogRef.componentInstance.ProcessID = filterAct[0].ProcessID;
          dialogRef.componentInstance.CompanyID = filterAct[0].CompanyID;
          dialogRef.componentInstance.activityTypeData = this.activityTypeData;

          dialogRef.componentInstance.currentUser = this.currentUser;
          dialogRef.afterClosed().subscribe(result => {
            if (result)

              this.dialogCall("Updated successfully");
            this.processData = [];
            let activityFilter = this.activityData.filter(x =>
              x.ProcessID.toString() === filterAct[0].ProcessID.toString() && x.ActivityID.toString() === filterAct[0].ActivityID.toString()
            );

            this.dorseyService.GetActivity(this.CompanyID).subscribe(subData => {
              this.activityData = JSON.parse(subData['_body']);
              subData = JSON.parse(subData['_body']);
              this.SubactivityData = subData;
              this.SubactivityData = this.SubactivityData.filter(x => x.ParentID === activityFilter[0].ParentID && x.ProcessID === filterAct[0].ProcessID);
              let sQuen = this.SubactivityData.filter(x => x.ActivityID === filterAct[0].ActivityID);
              let ParentName = this.activityData.filter(x => x.ActivityID === activityFilter[0].ParentID);
              this.SubactivityData.sort(function (a, b) {
                return a.squence - b.squence;
              });
              if (ParentName.length)
                this.IssuesLists[filterAct[0].ProcessID]['SubActivity'][ParentName[0].ActivityID] = this.SubactivityData;
              else this.IssuesLists[filterAct[0].ProcessID]['Activity'] = this.SubactivityData;
            });
          });
        }
      }
      else if (pointerType === 'delete' && parentID === 0) {
        let activityFilter = this.activityData.filter(x => x.ProcessID === this.processID && x.ActivityID === activityID);
        if (activityFilter.length > 0) {
          this.ActivityID = activityFilter[0].ActivityID;
          this.CompanyID = activityFilter[0].CompanyID;
          this.TeamID = activityFilter[0].TeamID;
          this.ProcessID = activityFilter[0].ProcessID;
          const dialogRef = this.dialog.open(ActivityDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: 'Are you sure you want to delete activity: ' + activityFilter[0].ActivityName + '?æææ' + true,
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.dorseyService.DeleteActivity(this.ActivityID, this.CompanyID, this.TeamID, activityFilter[0].squence, activityFilter[0].ProcessID, activityFilter[0].ParentID).subscribe(data => {
                if (data.ok) {
                  this.condition = true;
                  this.dialogCall('Deleted successfully');
                }
                this.processData = [];
                let filAct = this.activityData;
                this.dorseyService.GetActivity(this.CompanyID).subscribe(subData => {
                  this.activityData = JSON.parse(subData['_body']);
                  subData = JSON.parse(subData['_body']);
                  this.SubactivityData = subData;
                  this.SubactivityData = this.SubactivityData.filter(x => x.ParentID === activityFilter[0].ParentID && x.ProcessID === activityFilter[0].ProcessID);
                  let sQuen = this.SubactivityData.filter(x => x.ActivityID === activityFilter[0].ActivityID);
                  let data = this.activityData.filter(x => x.ParentID === activityFilter[0].ActivityID);
                  this.SubactivityData.sort(function (a, b) {
                    return a.squence - b.squence;
                  });
                  let tempAct = this.activityData.filter(x => x.ActivityID === activityFilter[0].ActivityID);
                  let temfilAct = filAct.filter(x => x.ActivityID === activityFilter[0].ActivityID);
                  this.IssuesLists[activityFilter[0].ProcessID]['Activity'] = this.SubactivityData;
                  this.ParentID = parentID.toString();;
                  if (!(Object.keys(this.IssuesLists[this.processID]['SubActivity']).indexOf(this.SubactivityData[0].ActivityID.toString()) > -1)) {
                    this.IssuesLists[activityFilter[0].ProcessID]['SubActivity'][tempAct[0].ActivityID] = data;
                    this.ActivityName = this.SubactivityData[0].ActivityName;
                    this.ActivityID = this.SubactivityData[0].ActivityID;
                    this.ProcessID = this.SubactivityData[0].ProcessID;
                  }
                  else {
                    this.ActivityName = activityFilter[0].ActivityName;
                    this.ActivityID = activityFilter[0].ActivityID;
                    this.ProcessID = activityFilter[0].ProcessID;
                  }
                });
              });
            }
          });
        }
      }

      else if (pointerType === 'delete' && parentID !== 0) {
        let filterAct = this.activityData.filter(x => x.ParentID === parentID && x.ActivityID === activityID);
        if (filterAct.length > 0) {
          this.ActivityID = filterAct[0].ActivityID;
          this.CompanyID = filterAct[0].CompanyID;
          this.TeamID = filterAct[0].TeamID;
          this.ProcessID = filterAct[0].ProcessID;
          const dialogRef = this.dialog.open(ActivityDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: 'Are you sure you want to delete activity: ' + filterAct[0].ActivityName + '?æææ' + true,
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.dorseyService.DeleteActivity(this.ActivityID, this.CompanyID, this.TeamID, filterAct[0].squence, filterAct[0].ProcessID, filterAct[0].ParentID).subscribe(data => {
                if (data.ok) {
                  this.condition = true;
                  this.dialogCall('Deleted successfully');
                }
                let tempFilter = this.activityData.filter(x =>
                  x.ProcessID.toString() === filterAct[0].ProcessID.toString() && x.ActivityID.toString() === filterAct[0].ActivityID.toString()
                );
                this.dorseyService.GetActivity(this.CompanyID).subscribe(subData => {
                  this.activityData = JSON.parse(subData['_body']);
                  subData = JSON.parse(subData['_body']);
                  this.SubactivityData = subData;
                  this.SubactivityData = this.SubactivityData.filter(x => x.ParentID === filterAct[0].ParentID && x.ProcessID === filterAct[0].ProcessID);
                  let sQuen = this.SubactivityData.filter(x => x.ActivityID === filterAct[0].ActivityID);
                  let ParentName = this.activityData.filter(x => x.ActivityID === filterAct[0].ParentID);
                  this.SubactivityData.sort(function (a, b) {
                    return a.squence - b.squence;
                  });
                  if (ParentName.length)
                    this.IssuesLists[filterAct[0].ProcessID]['SubActivity'][ParentName[0].ActivityID] = this.SubactivityData;
                  else this.IssuesLists[filterAct[0].ProcessID]['Activity'] = this.SubactivityData;
                });
              });
            }
          });
        }
      }
    });
  }

  EditPointer(element) {
    let tempP = this.pointerList.filter(x => x.PointerID === element.PointerID);
    if (tempP.length > 0) {
      this.TeamID = tempP[0].TeamID;
    }

    this.dorseyService.GetSelectedVersionPointerData(this.versionID, Number(this.CompanyID), Number(this.TeamID), this.childTablePointers).subscribe(pointer => {
      this.pointerList = JSON.parse(pointer['_body']);

      let filterPointer = this.pointerList.filter(x => x.PointerID === element.PointerID);
      if (filterPointer.length > 0) {
        if (filterPointer[0].PointerTypeID === 1) {
          const dialogRef = this.dialog.open(PointerDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: 'edit' + 'æææ' + 'process',
          });




          //   this.GetTempActivity(filterPointer[0].ProcessID);
          dialogRef.componentInstance.processData = this.processData;
          dialogRef.componentInstance.activityData = this.activityData;
          dialogRef.componentInstance.departmentData = this.departmentData;
          dialogRef.componentInstance.pointerTypeData = this.pointerTypeData;
          dialogRef.componentInstance.severityData = this.severityData;
          dialogRef.componentInstance.statusData = this.statusData;


          dialogRef.componentInstance.teamData = this.teamData;
          dialogRef.componentInstance.employeeData = this.employeeData;
          dialogRef.componentInstance.frustrationtypeData = this.frustrationtypeData;
          dialogRef.componentInstance.tempActivity = this.tempActivity;
          dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
          dialogRef.componentInstance.EmployeeID = filterPointer[0].EmployeeID;
          dialogRef.componentInstance.ProcessID = filterPointer[0].ProcessID;
          dialogRef.componentInstance.ActivityID = filterPointer[0].ActivityID;
          dialogRef.componentInstance.PointerTypeID = filterPointer[0].PointerTypeID;
          dialogRef.componentInstance.PointerID = filterPointer[0].PointerID;
          dialogRef.componentInstance.DepartmentID = filterPointer[0].DepartmentID;
          dialogRef.componentInstance.FrustrationTypeIDs = filterPointer[0].FrustrationsTypeID;
          dialogRef.componentInstance.FrustrationTypeID = filterPointer[0].FrustrationsTypeID;
          dialogRef.componentInstance.TeamID = filterPointer[0].TeamID;
          dialogRef.componentInstance.StatusID = filterPointer[0].StatusID;
          dialogRef.componentInstance.SeverityID = filterPointer[0].SeverityID;
          dialogRef.componentInstance.Subject = filterPointer[0].Subject;
          dialogRef.componentInstance.Description = filterPointer[0].Description;
          //  dialogRef.componentInstance.tempFrustrationType = element.tempFrustrationType;

          dialogRef.componentInstance.ProjectID = filterPointer[0].ProjectID;
          dialogRef.componentInstance.CompanyID = filterPointer[0].CompanyID;
          dialogRef.componentInstance.updatedBy = filterPointer[0].UpdatedBy;
          dialogRef.componentInstance.updatedDate = filterPointer[0].UpdatedDate;
          dialogRef.componentInstance.currentUser = this.currentUser;
          this.tempActivity = [];
          for (let i = 0; i < this.activityData.length; i++) {
            if (this.activityData[i].ProcessID === filterPointer[0].ProcessID && this.activityData[i].ParentID == 0) {
              this.tempActivity.push(this.activityData[i]);
            }
          }
          dialogRef.componentInstance.tempActivity = this.tempActivity;

          dialogRef.afterClosed().subscribe(result => {
            if (result)
              this.dialogCall("Updated successfully");
            this.dorseyService.GetCaseData(filterPointer[0].ProcessID, filterPointer[0].ActivityID, filterPointer[0].PointerTypeID, this.versionID, this.childTableName, this.childTableNameType).subscribe(cases => {
              cases = JSON.parse(cases['_body']);
              this.CaseData = cases;
            })
          });
        } else {
          const dialogRef = this.dialog.open(MetricDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: 'edit' + 'æææ' + 'process',
          });


          let tempProcessData = this.processData.filter(x => x.TeamID === filterPointer[0].TeamID);

          dialogRef.componentInstance.processData = tempProcessData;
          dialogRef.componentInstance.GoalsList = this.GoalsList;

          dialogRef.componentInstance.activityData = this.activityData;
          dialogRef.componentInstance.departmentData = this.departmentData;
          dialogRef.componentInstance.pointerTypeData = this.pointerTypeData;
          dialogRef.componentInstance.severityData = this.severityData;
          dialogRef.componentInstance.statusData = this.statusData;
          dialogRef.componentInstance.GoalID = filterPointer[0].GoalID

          dialogRef.componentInstance.teamData = this.teamData;
          dialogRef.componentInstance.employeeData = this.employeeData;
          dialogRef.componentInstance.frustrationtypeData = this.frustrationtypeData;

          dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
          //  dialogRef.componentInstance.tempProcess = this.tempProcess;
          dialogRef.componentInstance.EmployeeID = filterPointer[0].EmployeeID;
          dialogRef.componentInstance.ProcessID = filterPointer[0].ProcessID;


          dialogRef.componentInstance.ActivityID = filterPointer[0].ActivityID;
          dialogRef.componentInstance.PointerTypeID = filterPointer[0].PointerTypeID;
          dialogRef.componentInstance.PointerID = filterPointer[0].PointerID;
          dialogRef.componentInstance.DepartmentID = filterPointer[0].DepartmentID;
          dialogRef.componentInstance.FrustrationTypeID = filterPointer[0].FrustrationTypeID;
          dialogRef.componentInstance.TeamID = filterPointer[0].TeamID;
          dialogRef.componentInstance.StatusID = filterPointer[0].StatusID;
          dialogRef.componentInstance.SeverityID = filterPointer[0].SeverityID;
          dialogRef.componentInstance.Subject = filterPointer[0].Subject;
          dialogRef.componentInstance.Description = filterPointer[0].Description;

          dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;

          dialogRef.componentInstance.Source = element.Source;
          dialogRef.componentInstance.CompanyID = filterPointer[0].CompanyID;
          dialogRef.componentInstance.subActivityStatus = this.subActivityStatus;
          dialogRef.componentInstance.SubActivityID = this.SubActivityID;

          this.tempActivity = [];
          for (let i = 0; i < this.activityData.length; i++) {
            if (this.activityData[i].ProcessID === filterPointer[0].ProcessID && this.activityData[i].ParentID == 0) {
              this.tempActivity.push(this.activityData[i]);
            }
          }
          dialogRef.componentInstance.tempActivity = this.tempActivity;

          dialogRef.afterClosed().subscribe(result => {
            if (result)
              this.dialogCall("Updated successfully");

            this.dorseyService.GetCaseData(filterPointer[0].ProcessID, filterPointer[0].ActivityID, filterPointer[0].PointerTypeID, this.versionID, this.childTableName, this.childTableNameType).subscribe(cases => {
              cases = JSON.parse(cases['_body']);
              this.CaseData = cases;

              let filter = this.CaseData.filter(x => x.PointerID === element.PointerID);


            })

          });

        }


      }
    });
  }

  DeletePointer(element) {
    let filterPointer = this.pointerList.filter(x => x.PointerID === element.PointerID);
    if (filterPointer.length > 0) {
      if (filterPointer[0].PointerTypeID === 2) {
        this.PointerID = filterPointer[0].PointerID;
        let status: boolean;
        this.scoreCardService.CheckMetricExistInPSCDetails(this.PointerID).subscribe(resp => {
          status = resp.Data;
        })
        const dialogRef = this.dialog.open(MetricDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: 'Are you sure you want to delete metric: ' + element.Description + '?æææ' + true,
          width: '400px',
        });
        dialogRef.componentInstance.CompanyID = element.CompanyID;
        dialogRef.componentInstance.frustrationtypeData = this.frustrationtypeData;
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            if (!status) {
              this.dorseyService.DeletePointer(this.PointerID).subscribe(data => {
                if (data.ok) {
                  this.condition = true;
                  const dialogRef = this.dialog.open(MetricDialogComponent, {
                    panelClass: 'custom-dialog-container',
                    data: 'Deleted Successfully '
                  });
                  dialogRef.afterClosed().subscribe(result => {
                    this.dorseyService.GetCaseData(filterPointer[0].ProcessID, filterPointer[0].ActivityID, filterPointer[0].PointerTypeID, this.versionID, this.childTableName, this.childTableNameType).subscribe(cases => {
                      cases = JSON.parse(cases['_body']);
                      this.CaseData = cases;
                    })
                  });
                }
              });
            }
            else {
              this.dialogCall("Delete Failed. This metric is associated with scorecard");
            }
          }
        });
      } else {
        this.PointerID = filterPointer[0].PointerID;

        const dialogRef = this.dialog.open(PointerDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: 'Are you sure you want to delete frustration: ' + element.Subject + '?æææ' + true,
          width: '400px',

          // width: '40%'
        });

        dialogRef.componentInstance.CompanyID = element.CompanyID;
        dialogRef.componentInstance.frustrationtypeData = this.frustrationtypeData;
        //   this.condition = true;

        dialogRef.afterClosed().subscribe(result => {
          if (result) {

            /// this.condition = true;
            this.dorseyService.DeletePointer(this.PointerID).subscribe(data => {

              if (data.ok) {
                this.condition = true;
                const dialogRef = this.dialog.open(PointerDialogComponent, {
                  panelClass: 'custom-dialog-container',
                  data: 'Deleted Successfully '


                  // width: '40%'
                });
                dialogRef.afterClosed().subscribe(result => {


                  this.dorseyService.GetCaseData(filterPointer[0].ProcessID, filterPointer[0].ActivityID, filterPointer[0].PointerTypeID, this.versionID, this.childTableName, this.childTableNameType).subscribe(cases => {
                    cases = JSON.parse(cases['_body']);
                    this.CaseData = cases;

                  })

                });
              }

            });
          }
        });
      }
    }
  }
  GetSubActivities(activityID) {
    this.dorseyService.GetActivity(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);

      this.SubactivityData = subData;

      this.SubactivityData = this.SubactivityData.filter(x => x.ParentID === activityID);

    });
  }

  GetProcess() {
    this.dorseyService.GetProcess(this.CompanyID).subscribe(subData => {
      this.processData = JSON.parse(subData['_body']);

    });
  }

  AddSubActivity(activityID, processID) {

    this.GetActivityType();
    this.GetProcess();
    this.ActivityID = activityID;
    let processTemp = this.activityData.filter(x => x.ActivityID === activityID);
    if (processTemp.length > 0) {
      this.processID = processTemp[0].ProcessID;
      this.ActivityName = processTemp[0].ActivityName;
      this.ActivityID = processTemp[0].ActivityID;
    }
    let activityFilter = this.activityData.filter(x =>
      x.ProcessID.toString() === this.processID.toString() && x.ActivityID.toString() === activityID.toString()
    );

    let tempAct = this.activityData;

    this.activityData.sort(function (a, b) {
      return a.squence - b.squence;
    });


    const dialogRef = this.dialog.open(ActivityDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'new' + 'æææ' + 'process',
    });

    if (activityID === 0) {
      dialogRef.componentInstance.ParentActivityID = 0;
    }
    else {
      dialogRef.componentInstance.ParentActivityID = activityID;
    }
    dialogRef.componentInstance.activityData = this.activityData;
    dialogRef.componentInstance.processData = this.processData;
    dialogRef.componentInstance.ProcessID = this.processID;
    dialogRef.componentInstance.TeamID = this.TeamID;
    dialogRef.componentInstance.tempActivity = this.activityData;
    dialogRef.componentInstance.departmentData = this.departmentData;
    dialogRef.componentInstance.teamData = this.teamData;
    dialogRef.componentInstance.employeeData = this.employeeData;

    dialogRef.componentInstance.tempActivity = this.activityData;
    //  dialogRef.componentInstance.ActivityID = activityID;
    // dialogRef.componentInstance.ActivityTypeID = activityFilter[0].ActivityTypeID;
    dialogRef.componentInstance.CompanyID = this.CompanyID;
    dialogRef.componentInstance.activityTypeData = this.activityTypeData;

    //dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.afterClosed().subscribe(result => {

      let actLen = this.activityData.filter(x => x.ParentID === activityID);;

      this.dorseyService.GetActivity(this.CompanyID).subscribe(subData => {
        this.activityData = JSON.parse(subData['_body']);

        let tempAct = this.activityData.filter(x => x.ParentID === activityID);

        if (tempAct.length > 0 && tempAct.length > actLen.length) {
          for (let k = actLen.length; k < tempAct.length; k++) {
            if (!(Object.keys(this.IssuesLists[this.processID]['SubActivity']).indexOf(activityFilter[0].ActivityID.toString()) > -1)) {
              let st = {};
              st[activityFilter[0].ActivityID] = [];
              st[activityFilter[0].ActivityID].push(tempAct[k]);

              this.IssuesLists[this.processID]['SubActivity'][activityFilter[0].ActivityID] = st[activityFilter[0].ActivityID];

              this.IssuesLists[this.processID]['SubMCount'][tempAct[k].ActivityID] = {};
              this.IssuesLists[this.processID]['SubMCount'][tempAct[k].ActivityID]['SubMetric'] = {};
              this.IssuesLists[this.processID]['SubMCount'][tempAct[k].ActivityID] = {};
              this.IssuesLists[this.processID]['SubMCount'][tempAct[k].ActivityID]['SubIssue'] = {}
            }
            else {
              this.IssuesLists[this.processID]['SubActivity'][activityFilter[0].ActivityID].push(tempAct[k]);
              this.IssuesLists[this.processID]['SubMCount'][tempAct[k].ActivityID] = {};
              this.IssuesLists[this.processID]['SubMCount'][tempAct[k].ActivityID]['SubMetric'] = {};
              this.IssuesLists[this.processID]['SubMCount'][tempAct[k].ActivityID] = {};
              this.IssuesLists[this.processID]['SubMCount'][tempAct[k].ActivityID]['SubIssue'] = {}

            }
          }

        }

        this.GetFrustrations();

      });

    });

  }


  GetFrustrationType() {
    this.dorseyService.GetFrustrationType('1', this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);

      let tempType: any;
      tempType = subData;

      if (tempType.length === 0) {

        this.dorseyService.GetFrustrationType('1', "0").subscribe(subData => {
          subData = JSON.parse(subData['_body']);
          this.frustrationtypeData = subData;

        });
      }
      else {
        this.frustrationtypeData = tempType;
      }

    });


  }




  GetFrustrations() {
    this.editSubActiibiyt = true;
    let tempData = this.pointerList.filter(x => x.ProcessID === this.ProcessID && x.ActivityID === this.ActivityID);

    let lengthptns = this.pointerList.length;

    this.dorseyService.GetSelectedVersionPointerData(this.VersionNumber, Number(this.CompanyID), Number(this.TeamID), this.childTable).subscribe(pointer => {
      this.pointerList = JSON.parse(pointer['_body']);

      if (this.pointerList.length === lengthptns) {

        return;
      }

      this.pointerList = this.pointerList.filter(x => x.ProcessID === this.ProcessID && x.ActivityID === this.ActivityID);

      let metricFilter = this.pointerList.filter(x => x.PointerTypeID === 2);
      let issueFilter = this.pointerList.filter(x => x.PointerTypeID === 1);


      let filterSubMetric = metricFilter.filter(x => x.ActivityID === this.ActivityID);

      let filterSubIssue = issueFilter.filter(x => x.ActivityID === this.ActivityID);

      let putjson;
      if (this.pointerList.length > 0 && this.pointerList.length > tempData.length) {
        for (let k = tempData.length; k < this.pointerList.length; k++) {
          putjson = {
            PointerID: this.pointerList[k].PointerID,
            PointerType: this.pointerList[k].PointerType,
            SeverityName: this.pointerList[k].SeverityName,
            StatusID: this.pointerList[k].StatusID,
            StatusName: this.pointerList[k].StatusName,
            Description: this.pointerList[k].Description,
            Subject: this.pointerList[k].Subject
          };

          if (this.pointerList[k].PointerTypeID === 2) {
            if (this.ParentID !== '0') {

              if (!(Object.keys(this.IssuesLists[this.processID]['SubActivityMetric']).indexOf(this.ActivityID.toString()) > -1)) {
                let st = {};
                st[this.ActivityID] = [];
                st[this.ActivityID].push(putjson);

                if (!this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]) {
                  this.IssuesLists[this.processID]['SubMCount'][this.ActivityID] = {};

                }
                if (!this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubMetric']) {
                  this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubMetric'] = {};
                }
                if (!this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubMetric'][this.ActivityID]) {

                  this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubMetric'][this.ActivityID] = 0;
                }

                this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubMetric'][this.ActivityID] = Number(this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubMetric'][this.ActivityID]) + 1;

                this.IssuesLists[this.processID]['SubActivityMetric'][this.ActivityID] = st[this.ActivityID];
              }
              else {
                if (this.editSubActiibiyt) {
                  this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubMetric'][this.ActivityID] = filterSubMetric.length;
                  this.IssuesLists[this.processID]['SubActivityMetric'][this.ActivityID] = filterSubMetric;
                }
                else
                //    if(this.pointerList[k].PointerType!==undefined)
                {
                  this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubMetric'][this.ActivityID] = Number(this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubMetric'][this.ActivityID]) + 1;

                  this.IssuesLists[this.processID]['SubActivityMetric'][this.ActivityID].push(putjson);
                }

              }

            } else {

              if (!(Object.keys(this.IssuesLists[this.processID]['MetricList']).indexOf(this.ActivityID.toString()) > -1)) {
                let st = {};
                st[this.ActivityID] = [];
                st[this.ActivityID].push(putjson);

                if (!this.IssuesLists[this.processID]['Metric'][this.ActivityID]) {

                  this.IssuesLists[this.processID]['Metric'][this.ActivityID] = 0;
                }
                this.IssuesLists[this.processID]['Metric'][this.ActivityID] = Number(this.IssuesLists[this.processID]['Metric'][this.ActivityID]) + 1;
                this.IssuesLists[this.processID]['MetricList'][this.ActivityID] = st[this.ActivityID];
              }
              else {

                if (this.editSubActiibiyt) {
                  this.IssuesLists[this.processID]['Metric'][this.ActivityID] = metricFilter.length;
                  this.IssuesLists[this.processID]['MetricList'][this.ActivityID] = metricFilter;

                }
                else {
                  this.IssuesLists[this.processID]['MetricList'][this.ActivityID].push(putjson);
                  this.IssuesLists[this.processID]['Metric'][this.ActivityID] = Number(this.IssuesLists[this.processID]['Metric'][this.ActivityID]) + 1;

                }

              }

            }
          }
          else {

            if (this.ParentID === '0') {


              if (!(Object.keys(this.IssuesLists[this.processID]['IssueList']).indexOf(this.ActivityID.toString()) > -1)) {
                let st = {};
                st[this.ActivityID] = [];
                st[this.ActivityID].push(putjson);

                if (!this.IssuesLists[this.processID]['Issue'][this.ActivityID]) {

                  this.IssuesLists[this.processID]['Issue'][this.ActivityID] = 0;
                }

                this.IssuesLists[this.processID]['Issue'][this.ActivityID] = Number(this.IssuesLists[this.processID]['Issue'][this.ActivityID]) + 1;

                this.IssuesLists[this.processID]['IssueList'][this.ActivityID] = st[this.ActivityID];
              }
              else {
                if (this.editSubActiibiyt) {
                  this.IssuesLists[this.processID]['Issue'][this.ActivityID] = issueFilter.length;
                  this.IssuesLists[this.processID]['IssueList'][this.ActivityID] = issueFilter;
                }
                else {
                  this.IssuesLists[this.processID]['Issue'][this.ActivityID] = Number(this.IssuesLists[this.processID]['Issue'][this.ActivityID]) + 1;
                  this.IssuesLists[this.processID]['IssueList'][this.ActivityID].push(putjson);
                }


              }

            }
            else {

              if (!(Object.keys(this.IssuesLists[this.processID]['SubActivityIssues']).indexOf(this.ActivityID.toString()) > -1)) {

                let st = {};
                st[this.ActivityID] = [];
                st[this.ActivityID].push(putjson);

                if (!this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubIssue'][this.ActivityID]) {
                  this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubIssue'][this.ActivityID] = 0;
                }

                this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubIssue'][this.ActivityID] = Number(this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubIssue'][this.ActivityID]) + 1;


                this.IssuesLists[this.processID]['SubActivityIssues'][this.ActivityID] = st[this.ActivityID];
              }
              else {
                if (this.editSubActiibiyt) {
                  this.IssuesLists[this.processID]['SubActivityIssues'][this.ActivityID] = filterSubIssue;
                  this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubIssue'][this.ActivityID] = filterSubIssue.length;
                }
                else
                // if(this.pointerList[k].PointerType!==undefined)
                {
                  this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubIssue'][this.ActivityID] = Number(this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubIssue'][this.ActivityID]) + 1;

                  this.IssuesLists[this.processID]['SubActivityIssues'][this.ActivityID].push(putjson);
                }
              }

            }
          }
        }
      }
      if (this.IssuesLists[this.subKey]) {
        if (this.IssuesLists[this.subKey]['Activity']) {
          for (let j = 0; j < this.IssuesLists[this.subKey]['Activity'].length; j++) {
            let id = this.IssuesLists[this.subKey]['Activity'][j].ActivityID;
            this.showMetricAlert[id] = false;
            if (this.IssuesLists[this.subKey]['MetricList'][this.IssuesLists[this.subKey]['Activity'][j].ActivityID.toString()] != undefined) {
              j = j + this.alertMetricValue - (j % this.alertMetricValue) - 1;
            } else {
              if ((j + 1) % this.alertMetricValue == 0) {
                this.showMetricAlert[id] = true;
              } else {
                this.showMetricAlert[id] = false;
              }
            }
          }
        }

      }

    });
  }
  AddIssue(activityID) {
    this.ParentID = '0';

    const dialogRef = this.dialog.open(PointerDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'new' + 'æææ' + activityID,
    });
    this.ActivityID = activityID;
    let processTemp = this.activityData.filter(x => x.ActivityID === activityID);
    if (processTemp.length > 0) {
      this.processID = processTemp[0].ProcessID;
      this.ActivityName = processTemp[0].ActivityName;
      this.ActivityID = processTemp[0].ActivityID;
    }

    this.StatusID = this.statusData[0].StatusID;

    dialogRef.componentInstance.TeamID = this.TeamID
    dialogRef.componentInstance.processData = this.tempProcess;
    dialogRef.componentInstance.activityData = this.activityData;
    dialogRef.componentInstance.departmentData = this.departmentData;
    dialogRef.componentInstance.pointerTypeData = this.pointerTypeData;
    dialogRef.componentInstance.severityData = this.severityData;
    dialogRef.componentInstance.statusData = this.statusData;
    dialogRef.componentInstance.StatusID = this.StatusID;
    dialogRef.componentInstance.teamData = this.teamData;
    dialogRef.componentInstance.employeeData = this.employeeData;
    dialogRef.componentInstance.frustrationtypeData = this.frustrationtypeData;
    dialogRef.componentInstance.tempActivity = this.activityData;
    dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
    dialogRef.componentInstance.subActivityStatus = this.subActivityStatus;

    dialogRef.componentInstance.ProcessID = this.processID;

    dialogRef.componentInstance.ActivityID = activityID;
    dialogRef.componentInstance.CompanyID = this.CompanyID;


    let tempActivityes = this.activityData;
    let subAct = tempActivityes.filter(x => x.ParentID != 0);

    let tempAct = subAct.filter(x => x.ActivityID === activityID);

    if (tempAct.length === 1 && tempAct.length > 0) {

      this.ParentID = tempAct[0].ParentID;
    }

    if (this.ParentID.toString() === '0') {

      dialogRef.componentInstance.subActivityStatus = false;
      dialogRef.componentInstance.ActivityID = activityID;

    } else {

      dialogRef.componentInstance.subActivityStatus = true;
      dialogRef.componentInstance.SubActivityID = activityID;
      dialogRef.componentInstance.ActivityID = this.ParentID;

    }

    dialogRef.afterClosed().subscribe(result => {
      // this.parent.ConstructMap();
      this.GetFrustrations();
      this.GetFrustrationType();

      this.pointerList = [];

    });

  }


  AddMetric(activityID) {

    this.ParentID = '0';

    const dialogRef = this.dialog.open(MetricDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'new' + 'æææ' + activityID,
    });

    let processTemp = this.activityData.filter(x => x.ActivityID === activityID);
    if (processTemp.length > 0) {
      this.processID = processTemp[0].ProcessID;
      this.ActivityName = processTemp[0].ActivityName;
      this.ActivityID = processTemp[0].ActivityID;
    }
    this.ActivityID = activityID;

    dialogRef.componentInstance.GoalsList = this.GoalsList;
    dialogRef.componentInstance.processData = this.tempProcess;
    dialogRef.componentInstance.TeamID = this.TeamID;
    dialogRef.componentInstance.tempActivity = this.activityData;
    dialogRef.componentInstance.departmentData = this.departmentData;
    dialogRef.componentInstance.pointerTypeData = this.pointerTypeData;
    dialogRef.componentInstance.severityData = this.severityData;
    dialogRef.componentInstance.statusData = this.statusData;
    dialogRef.componentInstance.teamData = this.teamData;
    dialogRef.componentInstance.employeeData = this.employeeData;
    dialogRef.componentInstance.frustrationtypeData = this.frustrationtypeData;
    dialogRef.componentInstance.activityData = this.activityData;
    dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
    dialogRef.componentInstance.ProcessID = this.processID;
    // dialogRef.componentInstance.subActivityStatus = false;
    dialogRef.componentInstance.CompanyID = this.CompanyID;

    let tempActivityes = this.activityData;
    let subAct = tempActivityes.filter(x => x.ParentID != 0);

    let tempAct = subAct.filter(x => x.ActivityID === activityID);

    if (tempAct.length === 1 && tempAct.length > 0) {

      this.ParentID = tempAct[0].ParentID;
      this.parentActivityName = tempAct[0].ActivityName

    }


    if (this.ParentID.toString() === '0') {
      dialogRef.componentInstance.ActivityID = activityID;
      dialogRef.componentInstance.subActivityStatus = false;
    } else {

      dialogRef.componentInstance.subActivityStatus = true;
      dialogRef.componentInstance.SubActivityID = activityID;
      dialogRef.componentInstance.ActivityID = this.ParentID;
      // dialogRef.componentInstance.parentActivityName = this.parentActivityName;


    }

    dialogRef.afterClosed().subscribe(result => {
      // if(this.editSubActiibiyt){
      //   this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubMetric'][this.ActivityID]={};
      // }


      this.GetFrustrations();
      this.GetFrustrationType();



      this.checkForMetricAlert();

    });

  }


  getCase(activityID, pointerID) {

    let activityName = '';

    if (activityID !== 0) {
      activityName = activityID.ActivityName;

      this.parentId = activityID.ParentID;
    }

    this.dorseyService.GetCaseData(this.isProcess, activityID.ActivityID, pointerID, this.VersionNumber, this.childTable, this.childFrustType).subscribe(cases => {
      cases = JSON.parse(cases['_body']);

      const dialogRef = this.dialog.open(ProcessMapDialogComponent2, {
        data: 'new1' + 'æææ' + true + 'æææ' + pointerID,
        width: '800px',
      });

      let filterROle = this.RoleData.filter(x => x.RoleName === "Read Only" && x.RoleID === this.EmpROleID);

      if (filterROle.length > 0) {
        this.readOnly = true;
      }
      else {
        this.readOnly = false;

      }

      let activityFilter = this.activityData.filter(x => x.ActivityID === activityID.ActivityID);

      dialogRef.componentInstance.IsVersion = this.IsVersion;
      dialogRef.componentInstance.readOnly = this.readOnly;


      dialogRef.componentInstance.menuDeleteCheck = this.menuDeleteCheck;
      dialogRef.componentInstance.menuUpdateCheck = this.menuUpdateCheck;

      dialogRef.componentInstance.GoalsList = this.GoalsList;
      dialogRef.componentInstance.menuCreateCheck = this.menuCreateCheck;
      dialogRef.componentInstance.menuReadCheck = this.menuReadCheck;

      dialogRef.componentInstance.menuCCheck = this.menuCCheck;
      dialogRef.componentInstance.CaseData = cases;
      dialogRef.componentInstance.Process = this.isProcess.ProcessName;
      dialogRef.componentInstance.Activity = activityName;
      dialogRef.componentInstance.pointerList = this.pointerList;
      dialogRef.componentInstance.processData = this.processData;
      dialogRef.componentInstance.versionID = this.VersionNumber;
      dialogRef.componentInstance.childTableName = this.childTableName;
      dialogRef.componentInstance.childTablePointers = this.childTablePointers;


      dialogRef.componentInstance.TeamID = this.TeamID
      dialogRef.componentInstance.processData = this.processData;
      dialogRef.componentInstance.activityData = this.activityData;
      dialogRef.componentInstance.departmentData = this.departmentData;
      dialogRef.componentInstance.pointerTypeData = this.pointerTypeData;
      dialogRef.componentInstance.severityData = this.severityData;
      dialogRef.componentInstance.statusData = this.statusData;
      dialogRef.componentInstance.teamData = this.teamData;
      dialogRef.componentInstance.employeeData = this.employeeData;
      dialogRef.componentInstance.frustrationtypeData = this.frustrationtypeData;
      dialogRef.componentInstance.tempActivity = this.activityData;
      dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
      dialogRef.componentInstance.ProcessID = this.processID;
      dialogRef.componentInstance.ActivityID = activityID.ActivityID;
      dialogRef.componentInstance.CompanyID = this.CompanyID;
      this.StatusID = this.statusData[0].StatusID;
      dialogRef.componentInstance.StatusID = this.StatusID;
      dialogRef.componentInstance.currentUser = this.currentUser;
      dialogRef.componentInstance.childTablePointers = this.childTablePointers;

      dialogRef.afterClosed().subscribe(result => {
        // if(result !== undefined)
        this.dorseyService.GetSelectedVersionPointerData(this.CurrentVersionNumber, Number(this.CompanyID), Number(this.TeamID), this.childTablePointers).subscribe(pointer => {
          this.pointerList = JSON.parse(pointer['_body']);



          this.ActivityID = activityID.ActivityID;



          let filterSubMetric = this.pointerList.filter(x => x.PointerTypeID === 2 && x.ActivityID === activityID.ActivityID);
          let filterSubIssue = this.pointerList.filter(x => x.PointerTypeID === 1 && x.ActivityID === activityID.ActivityID);


          let filterMetric = this.pointerList.filter(x => x.PointerTypeID === 2 && x.ActivityID === activityFilter[0].ActivityID);
          let filterIssue = this.pointerList.filter(x => x.PointerTypeID === 1 && x.ActivityID === activityFilter[0].ActivityID);


          this.processID = activityFilter[0].ProcessID;

          if (pointerID === 2) {
            if (activityFilter[0].ParentID === 0) {
              this.IssuesLists[this.processID]['Metric'][activityFilter[0].ActivityID] = filterMetric.length;

              if (filterMetric.length > 0) {
                this.IssuesLists[this.processID]['MetricList'][activityFilter[0].ActivityID] = filterMetric;
              }
              else {

                delete this.IssuesLists[this.processID]['MetricList'][activityFilter[0].ActivityID];
              }

            }
            else if (this.ActivityID === activityFilter[0].ActivityID) {

              if (filterSubMetric.length > 0) {
                this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubMetric'][this.ActivityID] = filterSubMetric.length;
                this.IssuesLists[this.processID]['SubActivityMetric'][this.ActivityID] = filterSubMetric;
              }
              else {
                this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubMetric'] = {};
                delete this.IssuesLists[this.processID]['SubActivityMetric'][this.ActivityID];

              }

            }

          }
          else {
            if (activityFilter[0].ParentID === 0) {
              this.IssuesLists[this.processID]['Issue'][activityFilter[0].ActivityID] = filterIssue.length;
              if (filterIssue.length > 0) {
                this.IssuesLists[this.processID]['IssueList'][activityFilter[0].ActivityID] = filterIssue;
              }
              else {

                delete this.IssuesLists[this.processID]['IssueList'][activityFilter[0].ActivityID];
              }
            }
            else if (this.ActivityID === activityFilter[0].ActivityID) {
              {

                if (filterSubIssue.length > 0) {
                  this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubIssue'][this.ActivityID] = filterSubIssue.length;
                  this.IssuesLists[this.processID]['SubActivityIssues'][this.ActivityID] = filterSubIssue;

                }

                else {
                  this.IssuesLists[this.processID]['SubMCount'][this.ActivityID]['SubIssue'] = {};
                  delete this.IssuesLists[this.processID]['SubActivityIssues'][this.ActivityID];
                }

              }

            }

          }

          this.GetFrustrations();

          this.checkForMetricAlert();
        })
      });
    });
  }
  checkForMetricAlert() {
    if (this.IssuesLists[this.subKey]) {
      if (this.IssuesLists[this.subKey]['Activity']) {
        for (let j = 0; j < this.IssuesLists[this.subKey]['Activity'].length; j++) {
          let id = this.IssuesLists[this.subKey]['Activity'][j].ActivityID;
          this.showMetricAlert[id] = false;
          if (this.IssuesLists[this.subKey]['MetricList'][this.IssuesLists[this.subKey]['Activity'][j].ActivityID.toString()] != undefined) {

            j = j + this.alertMetricValue - (j % this.alertMetricValue) - 1;
          } else {
            if ((j + 1) % this.alertMetricValue == 0) {
              this.showMetricAlert[id] = true;
            } else {
              this.showMetricAlert[id] = false;
            }
          }

        }
      }

    }
  }

}

@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
  styleUrls: ['dialog.css'],
  providers: [DorseyService]
})
export class ProcessMapDialogComponent2 implements OnInit {
  readOnly = false;
  IsVersion = false;
  ActivityTypeID: string;
  isProcess: any;
  isSub: any;
  condition = false;
  isActivity: any;
  dataSelf: string;
  processID: string;
  headerText: string;
  GoalsList: any;
  PointerType: string;
  CaseData: any;
  Process = '';
  Activity = '';
  toggle = '';
  IssuesLists = {};
  subKey = '';
  MapType: string;
  show = true;
  SubActivityID: string;
  activityProcess: number;
  pointerList: any;
  processData: any;
  versionID: any;
  childTableName: any;
  childTablePointers: any;
  TeamID: any;
  activityData: any;
  departmentData: any;
  severityData: any;
  statusData: any;
  pointerTypeData: any;
  StatusID: string;
  frustrationtypeData: any;
  employeeData: any;
  teamData: any;
  tempFrustrationType: any;
  CompanyID: string;
  ActivityID: any;
  tempActivity: any;
  ProcessID: string;
  currentUser: string;
  greenLow: string;
  greenHigh: string;
  redLow: string;
  redHigh: string;
  yellowLow: string;
  yellowHigh: string;
  PointerID: any;
  childTableNameType: string;
  subActivityStatus: boolean;
  menuDeleteCheck: boolean;
  menuUpdateCheck: boolean;
  menuReadCheck: boolean;
  menuCreateCheck: boolean;
  menuCCheck: boolean;
  FrustrationTypeID: string;
  Subject: string;
  SeverityID: string;
  EmployeeID: string;
  Description: any;
  Source: any;
  DepartmentID: string;
  conditionActivity: any;
  tempSubActivity: any[];

  constructor(
    public dialogRef: MatDialogRef<ProcessMapDialogComponent2>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dorseyService: DorseyService,
    private dialog: MatDialog, private scoreCardService: PerformanceScoreCardService) {
    //    dialogRef.disableClose=true;
    this.data = this.data.split('æææ');

    if (this.data[1] === 'true') {
      this.condition = true;
    } else {
      this.condition = false;
    }
    this.toggle = this.data[2];
    this.data = this.data[0];

  }
  ngOnInit() {
    this.subKey = Object.keys(this.IssuesLists)[0];
  }

  closeChoose() {
    this.dialogRef.close(this.PointerType);
  }

  dialogCall(msg: string) {
    const dialogRef = this.dialog.open(ProcessMapDialogComponent, {
      data: msg + 'æææ' + false,
    });
  }

  Clear() {
    this.dialogRef.close(false);
  }
  onYesClick() {
    this.dialogRef.close(true);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }


  EditPointer(element) {

    if (!this.menuDeleteCheck && !this.menuUpdateCheck) {
      return;
    }

    let tempP = this.pointerList.filter(x => x.PointerID === element.PointerID);
    if (tempP.length > 0) {
      this.TeamID = tempP[0].TeamID;
    }

    this.dorseyService.GetSelectedVersionPointerData(this.versionID, Number(this.CompanyID), Number(this.TeamID), this.childTablePointers).subscribe(pointer => {
      this.pointerList = JSON.parse(pointer['_body']);

      let filterPointer = this.pointerList.filter(x => x.PointerID === element.PointerID);
      if (filterPointer.length > 0) {
        if (filterPointer[0].PointerTypeID === 1) {
          const dialogRef = this.dialog.open(PointerDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: 'edit' + 'æææ' + 'process',
          });

          dialogRef.componentInstance.processData = this.processData;
          dialogRef.componentInstance.activityData = this.activityData;
          dialogRef.componentInstance.departmentData = this.departmentData;
          dialogRef.componentInstance.pointerTypeData = this.pointerTypeData;
          dialogRef.componentInstance.severityData = this.severityData;
          dialogRef.componentInstance.statusData = this.statusData;
          dialogRef.componentInstance.teamData = this.teamData;
          dialogRef.componentInstance.employeeData = this.employeeData;
          dialogRef.componentInstance.frustrationtypeData = this.frustrationtypeData;
          dialogRef.componentInstance.tempActivity = this.tempActivity;
          dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
          dialogRef.componentInstance.EmployeeID = filterPointer[0].EmployeeID;
          dialogRef.componentInstance.ProcessID = filterPointer[0].ProcessID;
          dialogRef.componentInstance.ActivityID = filterPointer[0].ActivityID;
          dialogRef.componentInstance.PointerTypeID = filterPointer[0].PointerTypeID;
          dialogRef.componentInstance.PointerID = filterPointer[0].PointerID;
          dialogRef.componentInstance.DepartmentID = filterPointer[0].DepartmentID;
          dialogRef.componentInstance.FrustrationTypeIDs = filterPointer[0].FrustrationsTypeID;
          dialogRef.componentInstance.FrustrationTypeID = filterPointer[0].FrustrationsTypeID;
          dialogRef.componentInstance.TeamID = filterPointer[0].TeamID;
          dialogRef.componentInstance.StatusID = filterPointer[0].StatusID;
          dialogRef.componentInstance.SeverityID = filterPointer[0].SeverityID;
          dialogRef.componentInstance.Subject = filterPointer[0].Subject;
          dialogRef.componentInstance.Description = filterPointer[0].Description;
          dialogRef.componentInstance.ProjectID = filterPointer[0].ProjectID;
          dialogRef.componentInstance.CompanyID = filterPointer[0].CompanyID;
          dialogRef.componentInstance.updatedBy = filterPointer[0].UpdatedBy;
          dialogRef.componentInstance.updatedDate = filterPointer[0].UpdatedDate;
          dialogRef.componentInstance.currentUser = this.currentUser;

          let tempActivityes = this.activityData;
          let subAct = tempActivityes.filter(x => x.ParentID != 0);

          let tempAct = subAct.filter(x => x.ActivityID === filterPointer[0].ActivityID);

          let ParentID: string;
          let parentActivityName: string;
          if (tempAct.length === 1 && tempAct.length > 0) {

            ParentID = tempAct[0].ParentID;
            parentActivityName = tempAct[0].ActivityName
          }
          if (ParentID.toString() === '0') {
            dialogRef.componentInstance.ActivityID = filterPointer[0].ActivityID;
            dialogRef.componentInstance.subActivityStatus = false;
          } else {
            dialogRef.componentInstance.subActivityStatus = true;
            dialogRef.componentInstance.SubActivityID = filterPointer[0].ActivityID;
            dialogRef.componentInstance.ActivityID = ParentID;
          }
          dialogRef.afterClosed().subscribe(result => {
            if (result)
              this.dialogCall("Updated successfully");
            this.dorseyService.GetCaseData(filterPointer[0].ProcessID, filterPointer[0].ActivityID, filterPointer[0].PointerTypeID, this.versionID, this.childTableName, this.childTableNameType).subscribe(cases => {
              cases = JSON.parse(cases['_body']);
              this.CaseData = cases;
            })
          });
        } else {
          const dialogRef = this.dialog.open(MetricDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: 'edit' + 'æææ' + 'process',
          });

          let tempProcessData = this.processData.filter(x => x.TeamID === filterPointer[0].TeamID);
          dialogRef.componentInstance.processData = tempProcessData;
          dialogRef.componentInstance.activityData = this.activityData;
          dialogRef.componentInstance.departmentData = this.departmentData;
          dialogRef.componentInstance.pointerTypeData = this.pointerTypeData;
          dialogRef.componentInstance.severityData = this.severityData;
          dialogRef.componentInstance.statusData = this.statusData;
          dialogRef.componentInstance.GoalID = filterPointer[0].GoalID;
          dialogRef.componentInstance.GoalsList = this.GoalsList;
          dialogRef.componentInstance.teamData = this.teamData;
          dialogRef.componentInstance.employeeData = this.employeeData;
          dialogRef.componentInstance.frustrationtypeData = this.frustrationtypeData;
          dialogRef.componentInstance.tempActivity = this.tempActivity;
          dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
          dialogRef.componentInstance.EmployeeID = filterPointer[0].EmployeeID;
          dialogRef.componentInstance.ProcessID = filterPointer[0].ProcessID;
          dialogRef.componentInstance.ActivityID = filterPointer[0].ActivityID;
          dialogRef.componentInstance.PointerTypeID = filterPointer[0].PointerTypeID;
          dialogRef.componentInstance.PointerID = filterPointer[0].PointerID;
          dialogRef.componentInstance.DepartmentID = filterPointer[0].DepartmentID;
          dialogRef.componentInstance.FrustrationTypeID = filterPointer[0].FrustrationTypeID;
          dialogRef.componentInstance.TeamID = filterPointer[0].TeamID;
          dialogRef.componentInstance.StatusID = filterPointer[0].StatusID;
          dialogRef.componentInstance.SeverityID = filterPointer[0].SeverityID;
          dialogRef.componentInstance.Subject = filterPointer[0].Subject;
          dialogRef.componentInstance.Description = filterPointer[0].Description;
          dialogRef.componentInstance.tempActivity = this.tempActivity;
          dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
          dialogRef.componentInstance.Source = element.Source;
          dialogRef.componentInstance.CompanyID = filterPointer[0].CompanyID;
          let tempActivityes = this.activityData;
          let subAct = tempActivityes.filter(x => x.ParentID != 0);
          let tempAct = subAct.filter(x => x.ActivityID === filterPointer[0].ActivityID);

          let ParentID: string;
          let parentActivityName: string;
          if (tempAct.length === 1 && tempAct.length > 0) {

            ParentID = tempAct[0].ParentID;
            parentActivityName = tempAct[0].ActivityName
          }
          if (ParentID.toString() === '0') {
            dialogRef.componentInstance.ActivityID = filterPointer[0].ActivityID;
            dialogRef.componentInstance.subActivityStatus = false;
          } else {

            dialogRef.componentInstance.subActivityStatus = true;
            dialogRef.componentInstance.SubActivityID = filterPointer[0].ActivityID;
            dialogRef.componentInstance.ActivityID = ParentID;
          }
          dialogRef.afterClosed().subscribe(result => {
            if (result)
              this.dialogCall("Updated successfully");

            this.dorseyService.GetCaseData(filterPointer[0].ProcessID, filterPointer[0].ActivityID, filterPointer[0].PointerTypeID, this.versionID, this.childTableName, this.childTableNameType).subscribe(cases => {
              cases = JSON.parse(cases['_body']);
              this.CaseData = cases;
              let filter = this.CaseData.filter(x => x.PointerID === element.PointerID);
            })
          });
        }
      }
    });
  }

  DeletePointer(element) {

    let filterPointer = this.pointerList.filter(x => x.PointerID === element.PointerID);
    if (filterPointer.length > 0) {
      if (filterPointer[0].PointerTypeID === 2) {
        this.PointerID = filterPointer[0].PointerID;

        let status: boolean;
        this.scoreCardService.CheckMetricExistInPSCDetails(this.PointerID).subscribe(resp => {
          status = resp.Data;
        })
        const dialogRef = this.dialog.open(MetricDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: 'Are you sure you want to delete metric: ' + element.Description + '?æææ' + true,
          width: '400px',
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            if (!status) {
              this.dorseyService.DeletePointer(this.PointerID).subscribe(data => {
                if (data.ok) {
                  this.condition = true;
                  const dialogRef = this.dialog.open(MetricDialogComponent, {
                    panelClass: 'custom-dialog-container',
                    data: 'Deleted Successfully '
                  });
                  dialogRef.afterClosed().subscribe(result => {
                    this.dorseyService.GetCaseData(filterPointer[0].ProcessID, filterPointer[0].ActivityID, filterPointer[0].PointerTypeID, this.versionID, this.childTableName, this.childTableNameType).subscribe(cases => {
                      cases = JSON.parse(cases['_body']);
                      this.CaseData = cases;
                    })
                  });
                }
              });
            }
            else {
              this.dialogCall("Delete Failed. This metric is associated with scorecard");
            }
          }
        });
      } else {
        this.PointerID = filterPointer[0].PointerID;

        const dialogRef = this.dialog.open(PointerDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: 'Are you sure you want to delete frustration: ' + element.Subject + '?æææ' + true,
          width: '400px',

          // width: '40%'
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.dorseyService.DeletePointer(this.PointerID).subscribe(data => {

              if (data.ok) {
                this.condition = true;
                const dialogRef = this.dialog.open(PointerDialogComponent, {
                  panelClass: 'custom-dialog-container',
                  data: 'Deleted Successfully '
                });
                dialogRef.afterClosed().subscribe(result => {


                  this.dorseyService.GetCaseData(filterPointer[0].ProcessID, filterPointer[0].ActivityID, filterPointer[0].PointerTypeID, this.versionID, this.childTableName, this.childTableNameType).subscribe(cases => {
                    cases = JSON.parse(cases['_body']);
                    this.CaseData = cases;

                  })

                });
              }
            });
          }
        });
      }
    }

  }

}
