export enum Frequency {
    SelectFrequency = "Select Frequency",
    Monthly = "Monthly",
    Weekly = "Weekly"
}

export interface PerformanceScoreCard {
    scoreCardMaster: ScoreCardMaster;
    pscMetricDetails: Array<PSCMetricDetails>;
    metricIds: number[];
}

export interface ScoreCardMaster {
    Id: number;
    CompanyID: number;
    ScoreCardType: string;
    DepartmentID: number;
    TeamID: number;
    QuarterId: number;
    Year: number;
    ScoreCardName: string;
    CreatedBy: string;
    UpdatedBy: string;
    QuarterName: string;
    CompanyName: string;
    DepartmentName: string;
    TeamName: string;
}

export interface PSCMetricDetails {
    Id: number;
    PSCMasterId: number;
    PointerID: number;
    GoalID: number;
    GoalName: string;
    Description: string;
    TeamID: number;
    DepartmentID: number;
    CompanyID: number;
    GreenMinimum: number;
    GreenMaximum: number;
    YellowMinimum: number;
    YellowMaximum: number;
    RedMinimum: number;
    RedMaximum: number;
    Frequency: string;
    UOM: string;
    IsSelected: boolean;
    Week1: string;
    Week2: string;
    Week3: string;
    Week4: string;
    Week5: string;
    Week6: string;
    Week7: string;
    Week8: string;
    Week9: string;
    Week10: string;
    Week11: string;
    Week12: string;
    Week13: string;
    Week14: string;
    goalSpan: number;
    GreenTargetSymbol: string;
    YellowTargetSymbol: string;
    RedTargetSymbol: string;
}
