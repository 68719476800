import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'validation-dialog',
    templateUrl: 'validation-dialog.html',
})
export class ValidationDialogComponent implements OnInit {

    errorMessages: any = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        this.errorMessages = this.data.errorMessages;
    }
}