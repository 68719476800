import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { IResponsePackage } from "../models/response-package.model";
import { environment } from 'src/environments/environment';
import { OrganizationDetails } from '../organization/organization.model';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {

    private httpUrl: string = environment.API_URL;
    public totalLicenses = new BehaviorSubject<any>(0);
    public totalLicenses$: Observable<number> = this.totalLicenses.asObservable();
    constructor(private http: HttpClient) {
        this.totalLicenses$ = this.totalLicenses.asObservable();
    }

    getOrganizations(): Observable<IResponsePackage> {
        return this.http.get<IResponsePackage>(`${this.httpUrl}Dorsey/GetOrganizations`)
            .pipe(
                catchError(this.handleError)
            );
    }

    saveOrganization(entity: OrganizationDetails): Observable<any> {
        return this.http.post(`${this.httpUrl}Dorsey/SaveOrganization`, entity).pipe(
            catchError(this.handleError)
        );
    }

    deleteOrganization(organizationId: number): Observable<any> {
        return this.http.get(`${this.httpUrl}Dorsey/DeleteOrganization?organizationId=${organizationId}`).pipe(
            catchError(this.handleError)
        );
    }

    getCompanyDetailsByOrganizationId(organizationId: number): Observable<IResponsePackage> {
        return this.http.get<IResponsePackage>(`${this.httpUrl}Dorsey/GetCompanyDetailsByOrganizationId?organizationId=${organizationId}`)
            .pipe(
                catchError(this.handleError)
            );
    }

    getCompanyDetailsByCompanyId(companyId: number, organizationId: number): Observable<IResponsePackage> {
        return this.http.get<IResponsePackage>(`${this.httpUrl}Dorsey/GetCompanyDetailsByCompanyId?companyId=${companyId}&organizationId=${organizationId}`)
            .pipe(
                catchError(this.handleError)
            );
    }

    getCompaniesCountByOrganizationId(organizationId: number): Observable<IResponsePackage> {
        return this.http.get<IResponsePackage>(`${this.httpUrl}Dorsey/GetCompaniesCountByOrganizationId?organizationId=${organizationId}`)
            .pipe(
                catchError(this.handleError)
            );
    }


    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occured: ${err.error.message}`;
        }
        else {
            errorMessage = `Server returned code: ${err.status}, error message is ${err.message}`
        }
        return throwError(errorMessage);
    }
}
