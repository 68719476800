import { Component, OnInit } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { LocalStorageService } from 'ngx-webstorage';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-hipriority',
  templateUrl: './hipriority.component.html',
  //styleUrls: ['./hipriority.component.scss']
})
export class HipriorityComponent implements OnInit {
  copyRight: string = copyRight;
  CompanyID: string;
  projectData: any;
  isDisable = false;
  disableSearch = false;
  saveFilter = [];
  rowCount: any;
  projectPaging = false;
  searchtext: string;
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  dynamicRows = 10;
  companyName: string;
  headerDate = new Date();
  TeamName: string;
  copyDate: number;
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;
  EmployeeID: string;
  TeamID: string;
  constructor(private dorsey: DorseyService, private storage: LocalStorageService) {
    this.CompanyID = this.storage.retrieve('companyID');
    this.companyName = this.storage.retrieve('LoginCompanyName');
    this.EmployeeID = this.storage.retrieve('EmployeeID');
    if (this.EmployeeID.toString() === "0") {

    }
    else {
      this.TeamID = this.storage.retrieve('TeamID');
    }

  }

  ngOnInit() {
    this.GetProjects();
    this.copyDate = (new Date()).getFullYear();
  }
  GetProjects() {
    this.dorsey.GetProjects(this.CompanyID).subscribe(data => {
      this.projectData = JSON.parse(data["_body"]);
      let tempProjectsData = this.projectData;
      this.projectData = tempProjectsData.filter(x => x.StatusID === 2);
      if (this.projectData.length === 0) {
        this.projectPaging = false;
        this.isDisable = true;
        this.disableSearch = true;
      }
      else {
        this.saveFilter = JSON.parse(JSON.stringify(this.projectData));
        this.projectPaging = true;
        this.isDisable = false;
        this.disableSearch = false;
        this.rowCount = this.projectData.length;
        while (this.dynamicRows < this.rowCount) {
          this.rowsOnPageSet.push(this.dynamicRows);
          this.dynamicRows = this.dynamicRows + 10;
        }
        if (!this.rowsOnPageSet.includes(this.rowCount))
          this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet.length === 1) {
          if (this.rowsOnPageSet[0] <= 10)
            this.rowsOnPage = this.rowsOnPageSet[0];
        }

      }
    });
  }

  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  Searchtext() {
    this.projectData = this.saveFilter;
    let tempProjects = this.projectData.filter(
      (item) => {
        return (
          (item.ProjectName !== null && item.ProjectName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ProjectDescription !== null && item.ProjectDescription.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ProjectStatusName !== null && item.ProjectStatusName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.TeamName !== null && item.TeamName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
          || (item.ProjectOwner !== null && item.ProjectOwner.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
        );
      }
    );
    this.projectData = tempProjects;
    if (this.projectData.length == 0) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.projectData = this.saveFilter;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      this.rowCount = this.projectData.length;
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount))
        this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet) {

        this.rowsOnPage = this.rowsOnPageSet[0];
      }
      this.isDisable = false;
    } else {
      this.rowCount = tempProjects.length;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount)) {
        this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }

      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }


  exportAsXLSX() {

    let allData = JSON.parse(JSON.stringify(this.projectData));
    for (let i = 0; i < allData.length; i++) {
      allData[i][" No. "] = i + 1;
      allData[i].Team = allData[i].TeamName;
      allData[i].Project = allData[i].ProjectName;
      allData[i].Description = allData[i].ProjectDescription;
      allData[i].Status = allData[i].ProjectStatusName;
      allData[i]['Project Owner'] = allData[i].ProjectOwner;
      
      delete (allData[i].DepartmentID);
      delete (allData[i].ProjectName);
      delete (allData[i].ProjectStatusName);
      delete (allData[i].CompanyID);
      delete (allData[i].ProjectDescription);
      delete (allData[i].ProjectOwner);
      delete (allData[i].TeamName);

      delete (allData[i].ProjectID);
      delete (allData[i].StatusID);
      delete (allData[i].RowID);
      delete (allData[i].ProjectStatusID);

      delete (allData[i].IsActive);
      delete (allData[i].EmployeeID);
      delete (allData[i].CreatedDate);

      delete (allData[i].CreatedBy);
      delete (allData[i].UpdatedDate);
      delete (allData[i].UpdatedBy);

      delete (allData[i].StatusID1);
      delete (allData[i].IsActive1);
      delete (allData[i].CreatedDate1);

      delete (allData[i].CreatedBy1);
      delete (allData[i].UpdatedDate1);
      delete (allData[i].UpdatedBy1);
      delete (allData[i].ProjectStatusName1);
      delete (allData[i].ProjectDueDate);

      delete (allData[i].TeamID);
      delete (allData[i].TeamID1);
      delete (allData[i].ProcessID);
      delete (allData[i].CompanyID1);
      delete (allData[i].IsActive2);
      delete (allData[i].CreatedDate2);
      delete (allData[i].CreatedBy2);
      delete (allData[i].UpdatedBy2);
      delete (allData[i].UpdatedDate2);
      delete (allData[i].TeamDescription);
      delete (allData[i].TeamName1);
    }
    let team: any;
    team = '';
    /*if(this.EmployeeID.toString()==='0'){
      team='';
    }else{
    if(this.TeamID.toString()==='0'|| this.TeamID.toString()===null || this.TeamID.toString()===undefined){
      team='';
    }else{
      team= 'Team: '+this.TeamName;
    }
  }*/
    this.dorsey.generateExcel(allData, this.companyName + '_Prioritized Projects' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Prioritized Projects', "Company: " + this.companyName, team, "Prioritized Projects Report", copyRight, 'FFFFFF00', 'FF0000FF');
  }

}
