import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-usermanual',
  templateUrl: './usermanual.component.html',
  styleUrls: ['./usermanual.component.scss'],

})
export class UsermanualComponent implements OnInit {

  // PdfArry=[
  //   {pdfSrc:'../../assets/images/DMAPP_SampleUserManual.pdf'},
  //  ]
    
  constructor() { 
  }
  ngOnInit(): void {
    document.addEventListener("contextmenu", function (e) {
       e.preventDefault();
   }, false);
  document.onkeydown = function(e) {
      // Allow: Ctrl+A
   if (e.ctrlKey && e.keyCode === 65) {
     return false;
   }//Ctrl + C
    if (e.ctrlKey && e.keyCode === 67) {
     return false;
   }//Ctrl + V
    if (e.ctrlKey && e.keyCode === 86) {
     return false;
   }
   //ctrl p
   if(e.ctrlKey && e.keyCode == 80){
     return false;
    }
    //right click key code
     if(e.ctrlKey && e.keyCode == 93){
     return false;

     }
    }
  }

}
