import { Component, OnInit } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Department } from '../departments/department.model';
import { ScoreCardTypeEnum } from '../performance-score-card/performance-score-card.model';
import { DepartmentService } from '../services/department.service';
import { EmployeeLoginLevel } from '../common/models/login-level.model';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-nometric-process',
  templateUrl: './nometric-process.component.html',
  // styleUrls: ['./nometric-process.component.scss']
})
export class NometricProcessComponent implements OnInit {
  copyRight: string = copyRight;
  employeeDetails: any;
  NometricData: any;
  searchtext: string;
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;
  p: number = 1;
  rowsOnPage = 10;
  departmentList: Array<Department> = [];
  selectedDepartmentId: number;
  toggleTeam: boolean = false;
  scoreCardTypes: string[] = [ScoreCardTypeEnum.Department, ScoreCardTypeEnum.Team];
  rowsOnPageSet = [];
  rowCount: any;
  selectedReportType: string;
  departmentName: string;
  dynamicRows = 10;
  processPaging = false;
  saveFilter = [];
  CompanyID: string;
  isDisable = false;
  disableSearch = false;
  teamData: any;
  TeamID: any;
  filterData: any;
  EmployeeID: string;
  tempTeam = [];
  companyName: string;
  headerDate = new Date();
  TeamName: string;
  employeeData: any;
  copyDate: number;
  loginCompanyName: string;
  constructor(public dorseyService: DorseyService,
    private departmentService: DepartmentService, private storage: LocalStorageService) {

    this.loginCompanyName = this.storage.retrieve('loginCompanyName');
    this.EmployeeID == this.storage.retrieve('EmployeeID');
    this.EmployeeID = this.storage.retrieve('EmployeeID');
    this.companyName = this.storage.retrieve('LoginCompanyName');
    this.employeeDetails = this.storage.retrieve('employeeDetails');
    if (this.EmployeeID.toString() === "0") {
    }
    else {
      this.TeamID = this.storage.retrieve('TeamID');
    }

  }

  ngOnInit() {
    this.CompanyID = this.storage.retrieve('companyID');
    if (this.employeeDetails) {
      if ((this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) || (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team)) {
        this.toggleTeam = true;
        this.getTeamsByDepartmentId(this.employeeDetails.DepartmentID)
      }
      else {
        this.reportTypeChange(ScoreCardTypeEnum.Department)
      }
    }
    else {
      this.reportTypeChange(ScoreCardTypeEnum.Department)
    }
    this.copyDate = (new Date()).getFullYear();
  }

  teamChange(event): void {
    this.searchtext = '';
    this.selectedDepartmentId = 0;
    this.TeamID = event.value;
    let teamd = this.teamData.filter(x => x.TeamID === this.TeamID)
    if (teamd.length > 0) {
      this.TeamName = teamd[0].TeamName;
    }
    this.ConstructReport();
  }
  reportTypeChange(scoreCardType: string): void {
    this.searchtext = '';
    this.departmentList = [];
    this.teamData = [];
    this.filterData = [];
    this.selectedDepartmentId = 0;
    this.TeamID = 0;
    this.selectedReportType = scoreCardType;
    this.toggleTeam = scoreCardType === ScoreCardTypeEnum.Department ? false : true;
    this.getDepartments();
    this.getTeamsByDepartmentId(this.selectedDepartmentId);
  }

  departmentChange(selectedDepartmentId): void {
    this.searchtext = '';
    this.getTeamsByDepartmentId(selectedDepartmentId);
    this.departmentName = this.departmentList.find(ent => ent.DepartmentID === selectedDepartmentId).DepartmentName;
  }

  private getDepartments(): void {
    this.departmentService.getDepartments(Number(this.CompanyID)).subscribe(resp => {
      this.departmentList = resp.Data;
      if (this.employeeDetails) {
        if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) {
          this.departmentList = this.departmentList.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID);
        }
        else if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
          this.departmentList = this.departmentList.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID);
          this.getTeamsByDepartmentId(this.employeeDetails.DepartmentID);
        }
      }
      this.departmentList.splice(0, 0, { DepartmentID: 0, DepartmentName: 'Aggregate Report', DepartmentDescription: '0', CompanyID: Number(this.CompanyID), CreatedBy: '', UpdatedBy: '' });
    });
  }

  private getTeamsByDepartmentId(selectedDepartmentId): void {
    this.dorseyService.GetTeam(this.CompanyID.toString()).subscribe(resp => {
      var companyTeamList = JSON.parse(resp['_body']);
      this.teamData = companyTeamList;
      if (this.employeeDetails && companyTeamList) {
        if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) {
          this.teamData = this.teamData.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID)
        }
        else if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
          this.teamData = this.teamData.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID && ent.TeamID == this.employeeDetails.TeamID);
          this.selectedDepartmentId = this.teamData[0].DepartmentID;
          this.TeamID = this.teamData[0].TeamID;
          this.TeamName = this.teamData[0].TeamName;
        }
        else {
          if (!selectedDepartmentId) {
            this.selectedDepartmentId = 0;
            this.teamData = companyTeamList;
          }
          else if (selectedDepartmentId == 0) {
            this.teamData = companyTeamList;
          }
          else {
            this.teamData = this.teamData.filter(ent => ent.DepartmentID === selectedDepartmentId);
          }
        }
      }
      else {
        if (selectedDepartmentId == 0)
          this.teamData = companyTeamList;
        else
          this.teamData = companyTeamList.filter(ent => ent.DepartmentID === selectedDepartmentId);
      }
      this.dorseyService.GetNoMetricProcess(this.CompanyID).subscribe(pointer => {
        this.NometricData = JSON.parse(pointer['_body']);

        if (!this.TeamID)
          this.TeamID = 0;
        if (this.TeamID == 0) {
          this.teamData = this.teamData.filter(entity1 => this.NometricData.find(entity2 => entity1.TeamID == entity2.TeamID));
          if (this.teamData.length > 0)
            this.teamData.splice(0, 0, { TeamID: 0, TeamName: 'Aggregate Report' });
        }

        this.ConstructReport();

      });

    });
  }

  ConstructReport() {
    this.filterData = [];
    this.NometricData = [];
    this.dorseyService.GetNoMetricProcess(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.NometricData = subData;
      if (!this.TeamID)
        this.filterData = this.NometricData.filter(entity1 => this.teamData.find(entity2 => entity1.TeamID == entity2.TeamID));

      else {
        this.filterData = this.NometricData.filter(entity1 => entity1.TeamID == this.TeamID);
      }

      if (this.filterData.length === 0) {
        this.processPaging = false;
        this.isDisable = true;
        this.disableSearch = true;
      }
      else {
        this.processPaging = true;
        this.isDisable = false;
        this.disableSearch = false;
        this.saveFilter = JSON.parse(JSON.stringify(this.filterData));

        this.dynamicRows = 10;
        this.rowsOnPageSet = [];
        this.rowCount = this.filterData.length;

        while (this.dynamicRows < this.rowCount) {
          this.rowsOnPageSet.push(this.dynamicRows);
          this.dynamicRows = this.dynamicRows + 10;
        }
        if (!this.rowsOnPageSet.includes(this.rowCount))
          this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet.length > 0) {
          if (this.rowsOnPageSet[0] <= 10)
            this.rowsOnPage = this.rowsOnPageSet[0];
        }
      }
    });
  }

  Searchtext() {
    this.filterData = this.saveFilter;

    let tempNoMetric = this.filterData.filter(
      (item) => {
        return (
          (item.ProcessName !== null && item.ProcessName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.CompanyName !== null && item.CompanyName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.DepartmentName !== null && item.DepartmentName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ProcessDescription !== null && item.ProcessDescription.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
          || (item.TeamName !== null && item.TeamName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
        );
      }

    );
    this.filterData = tempNoMetric;
    if (this.filterData.length == 0) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.ConstructReport();
      this.isDisable = false;
    }
  }

  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }
  exportAsXLSX() {
    let allData = JSON.parse(JSON.stringify(this.filterData));
    for (let i = 0; i < this.filterData.length; i++) {
      allData[i]["No "] = i + 1;

      allData[i].Company = this.filterData[i].CompanyName;
      if (this.selectedReportType === ScoreCardTypeEnum.Department) {
        allData[i]["Department"] = this.filterData[i].DepartmentName;
      }
      allData[i].Team = this.filterData[i].TeamName;
      allData[i].Process = this.filterData[i].ProcessName;
      allData[i].Description = this.filterData[i].ProcessDescription;

      delete (allData[i].DepartmentName);
      delete (allData[i].ProcessID);
      delete (allData[i].CompanyName);
      delete (allData[i].ProcessName);
      delete (allData[i].ProcessDescription);
      delete (allData[i].TeamName);
      delete (allData[i].TeamID);
    }


    let name: any;
    if (this.selectedReportType == ScoreCardTypeEnum.Department && this.departmentName) {
      name = this.departmentName;
    } else if ((!this.selectedReportType || this.selectedReportType == ScoreCardTypeEnum.Team) && this.TeamName) {
      name = this.TeamName;
    } else {
      name = "Aggregate Report";
    }
    this.dorseyService.generateExcel(allData,
      this.loginCompanyName + '_Process with no metrics' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(),
      'Process with no metrics',
      "Company: " + this.loginCompanyName,
      this.selectedReportType ? this.selectedReportType + ": " + name : 'Team' + ": " + name,
      "Process with no metrics Report",
      copyRight,
      'FFFFFF00',
      'FF0000FF');
  }
}
