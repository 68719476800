import { Component, Inject, OnInit } from '@angular/core';
import { OrganizationDetails } from './organization.model';
import { OrganizationService } from '../services/organization.service'
import { LocalStorageService } from 'ngx-webstorage';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DorseyService } from '../dorsey.service';
import { Router } from '@angular/router';
import { OrganizationLicenseDetails } from '../company/company.model';
import { LocationStrategy } from '@angular/common';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
  [x: string]: any;
  organizationID: number;
  isSearchDisable = false;
  saveFilter: Array<OrganizationDetails> = [];
  isDisable = false;
  condition: boolean;
  searchtext: string = '';
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  isDesc: boolean = false;
  column = '';
  direction: number;
  arrow = true;
  licenseDetails: OrganizationLicenseDetails;
  companyPaging = false;
  MenuPermissions: any;
  organizationDetails: OrganizationDetails;
  currentUser: string;
  companyID: number;
  emailUnique: any;
  menuData: any;
  menuID: string;
  menuName: string;
  organization: Array<OrganizationDetails> = []; rolesData: any;
  userData: any;
  userRoleData: any;
  adminUserName: any;
  adminEmail: any;
  adminPassword: any;
  userRoleName: string;
  loginUsername: any;
  isSuperAdmin: boolean = true;
  loginLevel: string;
  constructor(
    private router: Router,
    private storage: LocalStorageService,
    public dialog: MatDialog,
    public organizationService: OrganizationService,
    private location: LocationStrategy,
    private dorseyService: DorseyService) {
    this.currentUser = this.storage.retrieve('user');
    this.menuData = this.storage.retrieve('menuData');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
    this.adminUserName = this.storage.retrieve('adminUname');
    this.adminEmail = this.storage.retrieve('username');
    this.adminPassword = this.storage.retrieve('password');
    this.loginUsername = this.storage.retrieve('loginUsername');
    this.loginLevel = this.storage.retrieve("loginLevel");

    //to disable back button
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit(): void {
    if (this.menuData) {
      this.menuData = this.menuData.find(x => x.MenuName.toString().toLowerCase() === 'organization');
      if (this.menuData) {
        this.menuName = this.menuData["MenuName"];
        this.menuID = this.menuData["MenuID"];
      }
    }
    this.getRoles(0);
    this.getUsers();
    this.getUserRoleAssignment();
    this.getOrganizations();
  }

  getRoles(companyID) {
    this.dorseyService.GetRoles(companyID).subscribe(users => {
      this.rolesData = JSON.parse(users['_body']);
    });

  }

  getUsers() {
    this.dorseyService.GetUsersList().subscribe(users => {
      this.userData = JSON.parse(users['_body']);
    });
  }

  getUserRoleAssignment() {
    this.dorseyService.GetUserRoleAssignment().subscribe(userRoles => {
      this.userRoleData = JSON.parse(userRoles['_body']);
    });
  }

  getOrganizations(): void {
    this.organizationService.getOrganizations().subscribe(resp => {
      this.organization = resp.Data;
      this.rowsOnPageSet = [];
      this.dynamicRows = 10;
      this.rowCount = this.organization.length;
      if (this.organization.length === 0) {
        this.companyPaging = false;
        this.isDisable = true;
        this.isSearchDisable = true;
      }
      else {
        this.saveFilter = JSON.parse(JSON.stringify(this.organization));
        this.companyPaging = true;
        this.isDisable = false;
        this.isSearchDisable = false;
        this.organizationPagination(this.rowCount);
      }
    });
  }

  newOrganiation(): void {
    this.organizationDetails = {
      OrganizationID: 0,
      OrganizationName: '',
      OrganizationDescription: '',
      Address1: '',
      Address2: '',
      Country: '',
      State: '',
      City: '',
      Pincode: '',
      Email: '',
      Phone: '',
      CreatedBy: this.currentUser,
      UpdatedBy: this.currentUser,
      NoOfLicenses: 0,
      UserID: 0,
      UserName: '',
      Password: '',
      FirstName: '',
      LastName: '',
      EmployeeID: 0,
      CompanyID: 0,
      RowID: 0,
      RoleID: 0,
    }
    this.dialogCall('new');
  }

  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }
  editOrganization(organization): void {
    this.organizationDetails = organization;
    this.storage.store('OName', this.organizationDetails.OrganizationName);
    this.storage.store('UName', this.organizationDetails.UserName);
    this.getCompaniesCountByOrganizationId();
  }

  getCompaniesCountByOrganizationId(): void {
    this.organizationService.getCompaniesCountByOrganizationId(this.organizationDetails.OrganizationID).subscribe(resp => {
      this.licenseDetails = resp.Data[0];
      this.dialogCall('edit');
    });
  }

  deleteOrganization(organizationROw: any): void {
    this.organizationID = organizationROw.OrganizationID;
    const dialogRef = this.dialog.open(OrganizationDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'Are you sure you want to delete organization: ' + organizationROw.OrganizationName + '?æææ' + true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.organizationService.deleteOrganization(this.organizationID).subscribe(resp => {
          if (resp.Data) {
            this.condition = true;
            this.dialogCall('Deleted successfully');
            this.organization = [];
            this.getOrganizations();
          } else {
            this.dialogCall('Delete Failed');
          }
          this.dynamicRows = 10;
          this.rowsOnPageSet = [];
        });
      }
    });

  }


  exportAsXLSX(): void {
    let allData = JSON.parse(JSON.stringify(this.organization));
    for (let i = 0; i < allData.length; i++) {
      allData[i]["Organization"] = allData[i].OrganizationName;
      allData[i]["Email "] = allData[i].Email;
      allData[i]["Phone "] = allData[i].Phone;
      allData[i]["No Of Licenses"] = allData[i].NoOfLicenses;

      delete (allData[i].Email);
      delete (allData[i].Phone);
      delete (allData[i].OrganizationID);
      delete (allData[i].CompanyID);
      delete (allData[i].UserID);
      delete (allData[i].UserName);
      delete (allData[i].FirstName);
      delete (allData[i].LastName);
      delete (allData[i].EmployeeID);
      delete (allData[i].Password);
      delete (allData[i].OrganizationID);
      delete (allData[i].OrganizationName);
      delete (allData[i].OrganizationDescription);
      delete (allData[i].Address1);
      delete (allData[i].Address2);
      delete (allData[i].Country);
      delete (allData[i].City);
      delete (allData[i].State);
      delete (allData[i].Pincode);
      delete (allData[i].NoOfLicenses);
      delete (allData[i].UpdatedBy);
      delete (allData[i].UpdatedDate);
      delete (allData[i].CreatedBy);
      delete (allData[i].CreatedDate);
      delete (allData[i].IsActive);
    }

    this.dorseyService.generateExcel(allData, 'Organization' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Organization', "Organization: " + this.loginUsername, '', "Organization Report", copyRight, 'FFFFFF00', 'FF0000FF');

  }
  searchText(): void {
    this.organization = this.saveFilter;
    let orgList = this.organization.filter(
      (item) => {
        return (
          (item.OrganizationName !== null && item.OrganizationName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.Email !== null && item.Email.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.Phone !== null && item.Phone.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)

        );
      }
    );
    this.organization = orgList;
    if (this.organization.length == 0) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.organization = this.saveFilter;
      this.rowCount = this.organization.length;
      this.isDisable = false;
      this.organizationPagination(this.rowCount);
    }
    else {
      this.rowCount = orgList.length;
      this.organizationPagination(this.rowCount);
      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)

          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }

  organizationPagination(RowCount) {
    this.dynamicRows = 10;
    this.rowsOnPageSet = [];
    this.rowsOnPage = 10;
    this.rowCount = RowCount;
    while (this.dynamicRows < this.rowCount) {
      this.rowsOnPageSet.push(this.dynamicRows);
      this.dynamicRows = this.dynamicRows + 10;
    }
    if (!this.rowsOnPageSet.includes(this.rowCount)) {
      this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }

  viewCompanies(element): void {
    let orgDetails = {
      OrganizationId: element.OrganizationID,
      OrganizationName: element.OrganizationName,
      NoOfLicenses: element.NoOfLicenses,
      IsSuperAdmin: this.isSuperAdmin
    }
    this.storage.store("OrganizationDetails", orgDetails);
    this.router.navigate(['/entity']);
  }

  dialogCall(msg: string) {
    this.getUsers();
    const dialogRef = this.dialog.open(OrganizationDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: msg + 'æææ' + false,
    });
    dialogRef.componentInstance.organizationDetails = this.organizationDetails;
    dialogRef.componentInstance.organizationID = this.organizationID;
    dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.componentInstance.organization = this.saveFilter;
    dialogRef.componentInstance.saveFilter = this.saveFilter;
    dialogRef.componentInstance.licenseDetails = this.licenseDetails;
    dialogRef.componentInstance.userListData = this.userData;

    if (msg == 'edit' || msg == 'new') {
      this.searchtext = '';
    }
    dialogRef.afterClosed().subscribe(result => {
      if (this.searchtext !== '' && this.searchtext !== undefined) {
        this.searchText();
      }
      else {
        this.getOrganizations();
      }


      if (result && result.toString() === 'true') {
        let statusMsg = '';
        if (msg === 'new') {
          statusMsg = 'Saved successfully';
        }
        else {
          statusMsg = 'Updated successfully';
        }
        const dialogRefsub = this.dialog.open(OrganizationDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: statusMsg + 'æææ' + false,
          //  width: '30%'
        });
        setTimeout(() => {
          this.getOrganizations();
        }, 500);
        dialogRefsub.afterClosed().subscribe(result1 => {
          this.dynamicRows = 10;
          if (this.searchtext !== '') {
            this.searchText();
          }
          else {
            this.getOrganizations();
          }
        });
      } else {
        this.getOrganizations();
      }
    });
  }
}
@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
  providers: [DorseyService],
})
export class OrganizationDialogComponent {

  organizationDetails: OrganizationDetails;
  organizationID: number;
  public breakpoint: number; // Breakpoint observer code
  dataSelf = '';
  condition: boolean;
  currentUser: string;
  organization: Array<OrganizationDetails> = [];
  saveFilter: Array<OrganizationDetails> = [];
  btnDisabled = false;
  licenseDetails: OrganizationLicenseDetails;
  userListData: any;

  constructor(
    public dialogRef: MatDialogRef<OrganizationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dorseyService: DorseyService, private storage: LocalStorageService, private orgService: OrganizationService) {
    //  dialogRef.disableClose=true;
    this.data = this.data.split('æææ');

    if (this.data[1] === 'true') {
      this.condition = true;

    } else {
      this.condition = false;
    }
    this.data = this.data[0];

  }

  ngOnInit() {
    this.breakpoint = window.innerWidth <= 1000 ? 1 : 3;
  }

  onResize(event): void {
    this.breakpoint = (event.target.innerWidth <= 1000) ? 1 : 3;
  }
  onYesClick() {
    this.dialogRef.close(true);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  validateOrganization(): void {
    this.dataSelf = '';
    if (!this.organizationDetails.OrganizationName.trim()
      || !this.organizationDetails.FirstName.trim()
      || !this.organizationDetails.LastName.trim()
      || !this.organizationDetails.Address1.trim()
      || !this.organizationDetails.Country.trim()
      || !this.organizationDetails.State.trim()
      || !this.organizationDetails.City.trim()
      || !this.organizationDetails.Pincode
      || !this.organizationDetails.Phone
      || !this.organizationDetails.Email
      || !this.organizationDetails.UserName.trim()
      || !this.organizationDetails.Password
    ) {
      this.dataSelf = 'Please enter mandatory(*) fields';
      return;
    }
    else {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!this.organizationDetails.Email.match(mailformat)) {
        this.dataSelf = 'Please enter valid email';

        return;
      }
    }
    if (this.organizationDetails.NoOfLicenses == 0) {
      this.dataSelf = 'Please enter No. of Licenses';
      return;
    }
    this.saveOrganization(this.data);

  }


  saveOrganization(msg: string): void {
    if (this.organizationDetails.OrganizationID) {
      this.organizationDetails.UpdatedBy = this.currentUser;
    }
    if (msg == 'new') {
      if (this.organization.find(ent => ent.OrganizationName.toLowerCase().trim() === this.organizationDetails.OrganizationName.toLowerCase().trim())) {
        this.dataSelf = "Organization name already exists";
        return;
      }

      if (this.userListData.find(ent => ent.UserName.toLowerCase().trim() === this.organizationDetails.UserName.toLowerCase().trim())) {
        this.dataSelf = "UserName already exists";
        return;
      }
      this.orgService.saveOrganization(this.organizationDetails).subscribe(resp => {
        if (resp.Data) {
          this.dataSelf = 'Saved successfully';
          setTimeout(() => {
            this.dataSelf = '';
          }, 2000);


          this.dialogRef.close(true);

        } else {
          this.dataSelf = 'Save failed';
        }
      });
    }
    else {
      this.organization = this.organization.filter(ent => ent.OrganizationID !== this.organizationDetails.OrganizationID);
      if (this.organization.find(ent => ent.OrganizationName.toLowerCase().trim() == this.organizationDetails.OrganizationName.toLowerCase().trim())) {
        this.dataSelf = 'Organization name already exists';
        return;
      }

      this.userListData = this.userListData.filter(ent => ent.UserID !== this.organizationDetails.UserID);
      if (this.userListData.find(ent => ent.UserName.toLowerCase().trim() == this.organizationDetails.UserName.toLowerCase().trim())) {
        this.dataSelf = 'UserName already exist';
        return;
      }
      if (this.organizationDetails.NoOfLicenses < Number(this.licenseDetails.UsedLicenses)) {
        this.dataSelf = 'Cannot update licenses as entities exist for this organization';
        return;
      }
      this.orgService.saveOrganization(this.organizationDetails).subscribe(resp => {
        if (resp.Data === true) {
          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Updated successfully';
        }
      });
    }
  }
  clear(): void {
    this.dialogRef.close(false);
  }
  keyPress(event: any): void {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  isAlpha(evt: any) {
    var keyCode = (evt.which) ? evt.which : evt.keyCode
    if ((keyCode < 65 || keyCode > 90) && (keyCode < 97 || keyCode > 123) && keyCode != 32)
      return false;
    return true;
  }

  validatePhone(event: any): void {
    if ((event.key) == "Backspace" || event.key == "ArrowLeft" || event.key == "ArrowRight") {
      return;
    } else {
      const regexpattern = '^[0-9]\d*$';
      if (!event.key.match(regexpattern)) {
        event.preventDefault();
      }
    }

  }

  validateEmail(event: any): void {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!this.organizationDetails.Email) {
      if (event.keyCode == 32) {
        event.preventDefault();
      }
    }
  }


  valZero(): void {
    var re = /^(?=.*\d)(?=.*[1-9]).{1,16}$/;
    if (this.organizationDetails.Phone !== '') {
      if (!this.organizationDetails.Phone.match(re)) {
        this.dataSelf = "Phone number invalid";

        this.organizationDetails.Phone = "";

        setTimeout(() => {
          this.dataSelf = "";
        }, 2000);
      }
    }

  }

  validate() {

  }

}
