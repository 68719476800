import { Component, OnInit } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
})
export class AlertsComponent implements OnInit {

  currentUser: string;
  CompanyID: string;
  empID: string;
  MenuPermissions: any;
  menuData: any;
  menuID: string;
  menuName: string;
  loginCompanyName: any;
  userTeamID: any;
  pointerTypeStatus = false;
  pointerTypeStatusMetric = false;
  PointerTypeID: string;
  alertValue: any;
  alertMetric: any;
  dataSelf: string;
  alertData: any;
  alertMetricData: any;
  saved = true;
  metricSaved = true;
  RowMetric: any;
  AlertRowID: any;
  alertStatus: number;
  alertMetricStatus: number;
  msg: any;
  error = false;
  mError = false;
  constructor(private dorseyService: DorseyService, public storage: LocalStorageService) {
    this.currentUser = this.storage.retrieve('user');
    this.menuData = this.storage.retrieve('menuData');
    this.empID = this.storage.retrieve('EmployeeID');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
    this.loginCompanyName = this.storage.retrieve('loginCompanyName');
    this.userTeamID = this.storage.retrieve("TeamID");
  }

  ngOnInit() {
    this.CompanyID = this.storage.retrieve('companyID');
    this.GetAlertValues();
    this.GetMetricValues();
    let tempdata = this.menuData;
    this.menuData = tempdata.filter(x => x.MenuName.toString() === 'Alerts');
    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }
  }

  ToggleType(event) {
    this.pointerTypeStatus = event.checked;
    if (this.pointerTypeStatus === true) {
      this.PointerTypeID = '1';
      this.alertStatus = 1;
      this.saved = false;
    }
    if (this.pointerTypeStatus === false) {
      this.alertStatus = 0;
      this.saved = true;
    }
  }

  MetricToggleType(event) {
    this.pointerTypeStatusMetric = event.checked;
    if (this.pointerTypeStatusMetric === true) {
      this.alertMetricStatus = 1;
      this.PointerTypeID = '2';
      this.metricSaved = false;
    }
    if (this.pointerTypeStatusMetric === false) {
      this.alertMetricStatus = 0;
      this.metricSaved = true;
    }
  }

  saveAlertValue() {
    this.GetAlertValues();
    if (this.alertValue === '' || this.alertValue === undefined || this.alertValue === 0) {
      this.alertValue = '';
      this.dataSelf = "Please enter mandatory fields";
      this.error = true;
      return;
    }
    if (this.alertStatus === undefined) {
      if (this.alertData.length > 0) {
        let alertFilter = this.alertData.filter(x => x.RowID === this.AlertRowID);
        if (alertFilter.length > 0) {

          if (alertFilter[0].AlertStatus === true) {
            this.alertStatus = 1;
          }
          else {
            this.alertStatus = 0;
          }
        }
      }
    }
    const json = {
      CompanyID: this.CompanyID,
      PointerTypeID: 1,
      AlertValue: this.alertValue,
      UpdatedBy: this.currentUser,
      RowID: this.AlertRowID,
      AlertStatus: this.alertStatus,
      CreatedBy: this.currentUser
    };
    if (this.AlertRowID !== '' && this.AlertRowID !== undefined) {
      if (this.alertData !== undefined) {

        this.dorseyService.SaveAlertValue(json).subscribe(data => {
          if (data['_body'].toString() === 'true') {
            this.dataSelf = 'Updated successfully';
            this.error = false;
            this.GetAlertValues();
          } else {

          }
        });
      }

    }

    else {

      this.dorseyService.SaveAlertValue(json).subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.dataSelf = 'Saved successfully';
          this.error = false;

          this.GetAlertValues();
        } else {
        }
      });
    }

  }

  saveMetricAlertValue() {
    if (this.alertMetric === '' || this.alertMetric === undefined || this.alertMetric === 0) {
      this.alertMetric = '';
      this.msg = "Please enter mandatory fields";
      this.mError = true;
      return;
    }
    if (this.alertMetricStatus === undefined) {
      if (this.alertMetricData.length > 0) {
        let metricFilter = this.alertMetricData.filter(x => x.RowID === this.RowMetric);
        if (metricFilter.length > 0) {

          if (metricFilter[0].AlertStatus === true) {
            this.alertMetricStatus = 1;
          }
          else {
            this.alertMetricStatus = 0;
          }
        }
      }
    }
    const json = {
      CompanyID: this.CompanyID,
      PointerTypeID: 2,
      AlertValue: this.alertMetric,
      UpdatedBy: this.currentUser,
      RowID: this.RowMetric,
      AlertStatus: this.alertMetricStatus,
      CreatedBy: this.currentUser
    };
    if (this.RowMetric !== '' && this.RowMetric !== undefined) {
      if (this.alertData !== undefined) {

        this.dorseyService.SaveAlertValue(json).subscribe(data => {
          if (data['_body'].toString() === 'true') {
            this.msg = 'Updated successfully';
            this.mError = false;
            this.GetMetricValues();
          } else {

          }
        });
      }
    } else {

      this.dorseyService.SaveAlertValue(json).subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.msg = 'Saved successfully'
          this.mError = false;

        } else {

        }
        this.ngOnInit();
      });
    }

  }

  GetAlertValues() {
    this.dorseyService.GetAlertValue(this.CompanyID, 1).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.alertData = subData;
      for (let i = 0; i < this.alertData.length; i++) {
        this.saved = false;
        this.pointerTypeStatus = true;
        this.alertValue = this.alertData[i].AlertValue;
        this.AlertRowID = this.alertData[i].RowID;
        this.pointerTypeStatus = this.alertData[i].AlertStatus;
        if (this.pointerTypeStatus === true) {
          this.saved = false;
        } else {
          this.saved = true;
        }
      }
    });
  }

  GetMetricValues() {
    this.dorseyService.GetAlertValue(this.CompanyID, 2).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.alertMetricData = subData;
      for (let i = 0; i < this.alertMetricData.length; i++) {
        this.metricSaved = false;
        this.pointerTypeStatusMetric = true;
        this.alertMetric = this.alertMetricData[i].AlertValue;
        this.RowMetric = this.alertMetricData[i].RowID;
        this.pointerTypeStatusMetric = this.alertMetricData[i].AlertStatus;
        if (this.pointerTypeStatusMetric === true) {
          this.metricSaved = false;
        } else {
          this.metricSaved = true;
        }
      }
    });
  }

  Validate(event: any) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter
      [46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
      (event.keyCode === 65 && event.ctrlKey === true) || // Allow: Ctrl+A
      (event.keyCode === 67 && event.ctrlKey === true) || // Allow: Ctrl+C
      (event.keyCode === 86 && event.ctrlKey === true) || // Allow: Ctrl+V
      (event.keyCode === 88 && event.ctrlKey === true) || // Allow: Ctrl+X
      (event.keyCode === 65 && event.metaKey === true) || // Cmd+A (Mac)
      (event.keyCode === 67 && event.metaKey === true) || // Cmd+C (Mac)
      (event.keyCode === 86 && event.metaKey === true) || // Cmd+V (Mac)
      (event.keyCode === 88 && event.metaKey === true) || // Cmd+X (Mac)
      (event.keyCode >= 35 && event.keyCode <= 39)  // Home, End, Left, Right
    ) {
      return;
    }

    if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
      event.preventDefault();
    }



  }

  val(event) {
    if (this.alertValue.toString().trim() === '0') {
      this.alertValue = '';
    }
    if (this.alertMetric.toString().trim() === '0') {
      this.alertMetric = '';
    }
  }

}
