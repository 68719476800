import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { environment } from 'src/environments/environment';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.rsxlsx';
@Injectable({
  providedIn: 'root'
})
export class DorseyService {
  teamData: any;
  tempData: any;
  TeamName: any;
  private httpUrl: string = environment.API_URL;
  //  httpUrl = 'https://www.tdgdm.com/DorseyAPI/api/' ;
  //  httpUrl='http://42.104.96.36:6005/api/';
  // httpUrl = 'http://localhost:52718/api/';
  // httpUrl='http://192.168.1.20:8005/api/';
  //  httpUrl='http://192.168.1.20:6005/api/';

  constructor(private http: Http) {

  }

  public generateExcelForScoreCard(allData, filename, sheetname, title, title1, title2, copyRight, headerForeColor?, headerBgColor?) {
    const header = [];
    var data = [];
    if (allData.length > 0) {
      var columnsIn = allData[0];
      for (var key in columnsIn) {
        header.push(key);
      }
      for (let i = 0; i < allData.length; i++) {
        var columnsIn = allData[i];
        var innerData = [];
        for (var val in columnsIn) {
          innerData.push(allData[i][val] != undefined ? allData[i][val] : '');
        }
        data.push(innerData);
      }
    }
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetname);
    if (title != '') {
      let titleRow = worksheet.addRow([title]);
      if (title1 != '') {
        let titleRow1 = worksheet.addRow([title1]);
        titleRow1.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true }
      }
      let titleRow2 = worksheet.addRow([title2]);
      titleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true }
      titleRow2.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true }
      worksheet.addRow([]);
      worksheet.addRow([]);
    }
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: headerForeColor },
        bgColor: { argb: headerBgColor }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    // for (let i = 0; i < allData.length; i++) {
    //   let row = worksheet.addRow(data[i]);
    //   row.eachCell((cell, number) => {
    //     cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    //     cell.alignment = { horizontal: "left", wrapText: true };
    //     var cellBgColor = this.myStyleWeek(data[i], i);
    //     console.log("Background color: ", cellBgColor);
    //     cell.fill = {
    //       type: 'pattern',
    //       pattern: 'solid',
    //       fgColor: { argb: 'black' },
    //       bgColor: { argb: cellBgColor['background-color'] }
    //     }
    //   })
    //   for (let i = 1; i <= row.cellCount; i++) {
    //     worksheet.getColumn(i).width = 35;
    //   }
    // }

    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.eachCell((cell, number) => {
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        cell.alignment = { horizontal: "left", wrapText: true };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: headerForeColor },
          bgColor: { argb: headerBgColor }
        }
      })
      for (let i = 1; i <= row.cellCount; i++) {
        worksheet.getColumn(i).width = 35;
      }
    });

    worksheet.addRow([]);
    let copyrt = worksheet.addRow([copyRight]);
    copyrt.font = { name: 'Calibri', family: 4, size: 10, bold: false }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, filename + '.xlsx');
    })

  }


  public generateExcel(allData, filename, sheetname, title, title1, title2, copyRight, headerForeColor?, headerBgColor?) {
    const header = [];
    const data = [];
    if (allData.length > 0) {
      var columnsIn = allData[0];
      for (var key in columnsIn) {
        header.push(key);
      }
      for (let i = 0; i < allData.length; i++) {
        var columnsIn = allData[i];
        var innerData = [];
        for (var val in columnsIn) {
          innerData.push(allData[i][val] != undefined ? allData[i][val] : '');
        }
        data.push(innerData);
      }
    }

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetname);
    if (title != '') {
      let titleRow = worksheet.addRow([title]);
      if (title1 != '') {
        let titleRow1 = worksheet.addRow([title1]);
        titleRow1.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true }
      }

      let titleRow2 = worksheet.addRow([title2]);
      titleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true }

      titleRow2.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true }


      worksheet.addRow([]);
      worksheet.addRow([]);

    }

    let headerRow = worksheet.addRow(header);

    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: headerForeColor },
        bgColor: { argb: headerBgColor }
        // fgColor: { argb: 'FFFFFF00' },
        // bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })

    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.eachCell((cell, number) => {
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        cell.alignment = { horizontal: "left", wrapText: true };
      })
      for (let i = 1; i <= row.cellCount; i++) {
        worksheet.getColumn(i).width = 35;
      }
    });

    worksheet.addRow([]);
    let copyrt = worksheet.addRow([copyRight]);
    copyrt.font = { name: 'Calibri', family: 4, size: 10, bold: false }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, filename + '.xlsx');
    })

  }


  public generateReportExcel(allData1, allData, image, filename, sheetname, title, title1, title2, copyRight, headerForeColor?, headerBgColor?) {
    const header = [];
    const data = [];
    if (allData.length > 0) {
      var columnsIn = allData[0];
      for (var key in columnsIn) {
        header.push(key);
      }
      for (let i = 0; i < allData.length; i++) {
        var columnsIn = allData[i];
        var innerData = [];
        for (var val in columnsIn) {
          innerData.push(allData[i][val] != undefined ? allData[i][val] : '');
        }
        data.push(innerData);
      }
    }

    const header1 = [];

    var innerData1 = [];
    const data1 = [];
    if (allData1.length > 0) {
      // var columnsIn = allData1[0];
      // console.log(columnsIn);
      // for (var key in columnsIn) {
      //   console.log(columnsIn[key]);
      //   header1.push(key);
      // }
      let total = 0;
      for (let i = 0; i < allData1.length; i++) {
        total = total + allData1[i].value;
      }
      header1.push('Total');
      innerData1.push(total);
      for (let i = 0; i < allData1.length; i++) {
        var columnsIn = allData1[i];

        var valuesData = [];
        // for (var val in columnsIn) {

        header1.push(allData1[i].label);
        innerData1.push(allData1[i].value);
        //innerData.push(allData1[i].label != undefined  ? allData1[i].label : '');
        //  valuesData.push(allData1[i].value != undefined  ? allData1[i].value : '');
        // }
        // data1.push(innerData1);
        // data1.push(valuesData);
      }

    }

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetname);
    if (title != '') {
      let titleRow = worksheet.addRow([title]);
      if (title1 != '') {
        let titleRow1 = worksheet.addRow([title1]);
        titleRow1.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true }
      }

      let titleRow2 = worksheet.addRow([title2]);
      titleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true }

      titleRow2.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true }


      worksheet.addRow([]);
      worksheet.addRow([]);

    }



    let headeRow1COlor = '000000';


    let headerRow1 = worksheet.addRow(header1);
    let headerRow2 = worksheet.addRow(innerData1);
    worksheet.addRow([]);
    worksheet.addRow([]);
    let logo = workbook.addImage({
      base64: image,
      extension: 'png',
    });

    worksheet.addImage(logo, 'B10:D20');
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.mergeCells('A10:F24');

    let headerRow = worksheet.addRow(header);

    headerRow1.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: headerForeColor },
        bgColor: { argb: headerBgColor },

        // fgColor: { argb: 'FFFFFF00' },
        // bgColor: { argb: 'FF0000FF' }
      }
      cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })

    headerRow2.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: headerForeColor },
        bgColor: { argb: headerBgColor }
        // fgColor: { argb: 'FFFFFF00' },
        // bgColor: { argb: 'FF0000FF' }
      }
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })


    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: headerForeColor },
        bgColor: { argb: headerBgColor }
        // fgColor: { argb: 'FFFFFF00' },
        // bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })

    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.eachCell((cell, number) => {

        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        // cell.alignment = { wrapText: true };
        cell.alignment = { horizontal: "left", wrapText: true };

      })
      if (row.cellCount > allData1.length) {
        for (let i = 1; i <= row.cellCount; i++) {
          worksheet.getColumn(i).width = 35;
        }
      } else {
        for (let i = 1; i <= allData1.length; i++) {
          worksheet.getColumn(i).width = 35;
        }
      }

    });
    data1.forEach(d => {
      let row1 = worksheet.addRow(d);
      row1.eachCell((cell, number) => {
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        cell.alignment = { wrapText: true };

      })
      for (let i = 1; i <= row1.cellCount; i++) {
        worksheet.getColumn(i).width = 35;
      }
    });


    worksheet.addRow([]);
    let copyrt = worksheet.addRow([copyRight]);
    copyrt.font = { name: 'Calibri', family: 4, size: 10, bold: false }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, filename + '.xlsx');
    })

  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const title = 'Car Sell Report';

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    //  this.wrapAndCenterCell(worksheet.A1);
    let jsonsheet = {};
    jsonsheet[excelFileName] = worksheet;
    const titile = "Swathi";
    const workbook: XLSX.WorkBook = { Sheets: jsonsheet, SheetNames: [excelFileName] };

    this.wrapAndCenterCell(worksheet.A1);

    //     if(!worksheet.A1.c) worksheet.A1.c = [];
    //     worksheet.A1.c.push({w:"SheetJS", t:"I'm a little comment, short and stout!"});
    //     worksheet['A2'].l = { Target:"#E2" }; /* link to cell E2 */
    // console.log(worksheet);
    // if(!workbook.Props) workbook.Props = {};
    // workbook.Props.Title = "Insert Title Here";
    // let headerRow = worksheet.addRow("Swathi");
    // // Cell Style : Fill and Border
    // headerRow.eachCell((cell, number) => {
    //   cell.fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: 'FFFFFF00' },
    //     bgColor: { argb: 'FF0000FF' }
    //   }
    //   cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    // })
    //worksheet.getColumn(3).width = 15;
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private wrapAndCenterCell(cell: XLSX.CellObject) {
    const wrapAndCenterCellStyle = { font: { bold: true } };
    this.setCellStyle(cell, wrapAndCenterCellStyle);
  }

  private setCellStyle(cell, style: {}) {
    cell.s = style;


  }

  // private wrapAndCenterCell(cell: XLSX.CellObject) {
  //   const wrapAndCenterCellStyle = { alignment: { wrapText: true, vertical: 'center', horizontal: 'center',italic:true } };
  //   this.setCellStyle(cell, wrapAndCenterCellStyle);
  // }

  // private setCellStyle(cell: XLSX.CellObject, style: {}) {
  //   cell.s = style;

  //   console.log(style);
  // }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear() + EXCEL_EXTENSION);
  }

  public GetCompanyData() {
    const Weburl = this.httpUrl + 'Dorsey/GetCompanyDetails';
    return this.FetchData(Weburl);
  }

  FetchData(Weburl: string) {
    const params = new URLSearchParams();
    return this.http.get(Weburl, { search: params });
  }
  // posting the data to the respected Weburl
  // PostData(Weburl: string) {
  //     const headers = new Headers();
  //     headers.append('Content-Type', 'application/x-www-form-urlencoded');
  //     return this.http.post(Weburl, {}, { headers: headers });
  // }
  PostData(Weburl: string, body: any) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(Weburl, JSON.stringify(body), { headers: headers });
  }
  SaveCompany(body: any) {
    const Weburl = this.httpUrl + 'Dorsey/SaveCompany';
    return this.PostData(Weburl, body);
  }

  Export(body: any, fileName: string, companyName: string, pageName: string) {
    const Weburl = this.httpUrl + 'Dorsey/ExportAsExcel?FileName=' + fileName + '&CompnayName=' + companyName + '&pageName=' + pageName;
    return this.PostData(Weburl, body);
  }

  DownloadPdfFile(fileName: string, filePath: string) {
    const Weburl = this.httpUrl + 'Dorsey/DownloadPdfFile?fileName=' + fileName + '&filePath=' + filePath;
    return this.FetchData(Weburl);
  }



  public DeleteCompany(companyID: string) {
    const Weburl = this.httpUrl + 'Dorsey/DeleteCompany?CompanyID=' + companyID;
    return this.FetchData(Weburl);
  }

  SaveProcess(body: any, Isversionize: string) {
    const Weburl = this.httpUrl + 'Dorsey/SaveProcess?isVersioning=' + Isversionize;
    return this.PostData(Weburl, body);
  }
  // http://localhost:56083/api/Sample/GetSample
  public GetProcess(CompanyID: string) {
    const Weburl = this.httpUrl + 'Dorsey/GetProcess?CompanyID=' + CompanyID;
    return this.FetchData(Weburl);
  }

  public DeleteProcess(processID: string, CompanyID: string, TeamID: number, squence: number) {
    const Weburl = this.httpUrl + 'Dorsey/DeleteProcess?ProcessID=' + processID + '&CompanyID=' + CompanyID + '&TeamID=' + TeamID + '&squence=' + squence;
    return this.FetchData(Weburl);
  }

  // public DeleteProcess(processID: string) {
  //   const Weburl = this.httpUrl + 'Dorsey/DeleteProcess?ProcessID=' + processID;
  //   return this.FetchData(Weburl);
  // }
  SaveEmployee(body: any) {
    const Weburl = this.httpUrl + 'Dorsey/SaveEmployee';
    return this.PostData(Weburl, body);
  }

  public GetEmployee(CompanyID: string) {
    const Weburl = this.httpUrl + 'Dorsey/GetEmployee?CompanyID=' + CompanyID;
    return this.FetchData(Weburl);
  }
  public GetCompanyRoleID(CompanyID: string) {
    const Weburl = this.httpUrl + 'Dorsey/GetCompanyRole?CompanyID=' + CompanyID;
    return this.FetchData(Weburl);
  }

  public DeleteEmployee(EmployeeID: string) {
    const Weburl = this.httpUrl + 'Dorsey/DeleteEmployee?EmployeeID=' + EmployeeID;
    return this.FetchData(Weburl);
  }

  public GetRoles(companyID) {
    const Weburl = this.httpUrl + 'Dorsey/GetRoles?companyID=' + companyID;
    return this.FetchData(Weburl);
  }

  public GetDesignation() {
    const Weburl = this.httpUrl + 'Dorsey/GetDesignation';
    return this.FetchData(Weburl);
  }

  public GetDepartment() {
    const Weburl = this.httpUrl + 'Dorsey/GetDepartment';
    return this.FetchData(Weburl);
  }

  SaveActivity(body: any, Isversionize: string) {
    const Weburl = this.httpUrl + 'Dorsey/SaveActivity?isVersioning=' + Isversionize;
    return this.PostData(Weburl, body);
  }

  public GetActivity(CompanyID: string) {
    const Weburl = this.httpUrl + 'Dorsey/GetActivity?CompanyID=' + CompanyID;
    return this.FetchData(Weburl);
  }
  public DeleteActivity(ActivityID: string, CompanyID: string, TeamID: number, squence: number, ProcessID: string, ParentID: number) {
    const Weburl = this.httpUrl + 'Dorsey/DeleteActivity?ActivityID=' + ActivityID + '&CompanyID=' + CompanyID + '&TeamID=' + TeamID + '&squence=' + squence + '&ProcessID=' + ProcessID + '&ParentID=' + ParentID;
    return this.FetchData(Weburl);
  }

  public GetActivityType() {
    const Weburl = this.httpUrl + 'Dorsey/GetActivityType';
    return this.FetchData(Weburl);
  }

  SaveTeam(body: any) {
    const Weburl = this.httpUrl + 'Dorsey/SaveTeam';
    return this.PostData(Weburl, body);
  }

  public GetTeam(CompanyID: string) {
    if (CompanyID) {
      const Weburl = this.httpUrl + 'Dorsey/GetTeam?CompanyID=' + CompanyID;
      return this.FetchData(Weburl);
    }
  }
  public DeleteTeam(TeamID: string) {
    const Weburl = this.httpUrl + 'Dorsey/DeleteTeam?TeamID=' + TeamID;
    return this.FetchData(Weburl);
  }
  public GetPointerType() {
    const Weburl = this.httpUrl + 'Dorsey/GetPointerType';
    return this.FetchData(Weburl);
  }
  public GetFrustrationType(PointerTypeID, CompanyID) {
    const Weburl = this.httpUrl + 'Dorsey/GetFrustrationType?PointerTypeID=' + PointerTypeID + '&CompanyID=' + CompanyID;
    return this.FetchData(Weburl);
  }
  public GetStatus() {
    const Weburl = this.httpUrl + 'Dorsey/GetStatus';
    return this.FetchData(Weburl);
  }
  public GetProjectStatus() {
    const Weburl = this.httpUrl + 'Dorsey/GetProjectStatus';
    return this.FetchData(Weburl);
  }
  public GetSeverity() {
    const Weburl = this.httpUrl + 'Dorsey/GetSeverity';
    return this.FetchData(Weburl);
  }
  public GetFrustration(ProjectID, CompanyID) {
    const Weburl = this.httpUrl + 'Dorsey/GetFrustration?ProjectID=' + ProjectID + '&CompanyID=' + CompanyID;
    return this.FetchData(Weburl);
  }


  SavePointer(body: any, Isversionize: string) {
    const Weburl = this.httpUrl + 'Dorsey/Savepointer?isVersioning=' + Isversionize;
    return this.PostData(Weburl, body);
  }

  public GetPointer(CompanyID: string) {
    const Weburl = this.httpUrl + 'Dorsey/GetPointer?CompanyID=' + CompanyID;
    return this.FetchData(Weburl);
  }
  public DeletePointer(PointerID: string) {
    const Weburl = this.httpUrl + 'Dorsey/DeletePointer?PointerID=' + PointerID;
    return this.FetchData(Weburl);
  }
  SaveCase(body: any) {
    const Weburl = this.httpUrl + 'Dorsey/SaveCase';
    return this.PostData(Weburl, body);
  }
  public GetCase(CompanyID: string) {
    const Weburl = this.httpUrl + 'Dorsey/GetCase?CompanyID=' + CompanyID;
    return this.FetchData(Weburl);
  }
  public GetCaseData(processID: number, activityID: number, PointerTypeID: number, versionID: number, tableName, tableType) {
    const Weburl = this.httpUrl + 'Dorsey/GetCaseData?processID=' + processID + '&activityID=' +
      activityID + '&PointerTypeID=' + PointerTypeID + '&versionID=' + versionID + '&tableName=' + tableName + '&tableType=' + tableType;
    return this.FetchData(Weburl);
  }

  public GetVersions(companyID: number, TeamID: number) {
    //, TeamID
    // + '&TeamID='+ TeamID
    const Weburl = this.httpUrl + 'Dorsey/GetVersions?CompanyID=' + companyID + '&TeamID=' + TeamID;
    return this.FetchData(Weburl);
  }

  public GetSelectedVersionData(versionID: number, companyID: number, teamId, verTable: String) {
    const Weburl = this.httpUrl + 'Dorsey/GetSelectedVersionData?versionID=' + versionID + "&companyID=" + companyID + "&TeamID=" + teamId + "&VerTableName=" + verTable;
    return this.FetchData(Weburl);
  }
  public GetSelectedVersionPointerData(versionID: number, companyID: number, teamId, tableName: string) {
    const Weburl = this.httpUrl + 'Dorsey/GetSelectedVersionPointerData?versionID=' + versionID + '&companyID=' + companyID + "&TeamID=" + teamId + '&tableName=' + tableName;
    return this.FetchData(Weburl);
  }

  public GetSelectedVersionActivityData(versionID: number, companyID: number, teamId, tableName: string) {
    const Weburl = this.httpUrl + 'Dorsey/GetSelectedVersionActivityData?versionID=' + versionID + '&companyID=' + companyID + "&TeamID=" + teamId + '&tableName=' + tableName;
    return this.FetchData(Weburl);
  }
  SaveProjects(body: any) {
    const Weburl = this.httpUrl + 'Dorsey/SaveProject';
    return this.PostData(Weburl, body);
  }
  public GetProjects(CompanyID: string) {
    const Weburl = this.httpUrl + 'Dorsey/GetProjects?CompanyID=' + CompanyID;
    return this.FetchData(Weburl);
  }
  public DeleteProjects(ProjectID: string) {
    const Weburl = this.httpUrl + 'Dorsey/DeleteProjects?ProjectID=' + ProjectID;
    return this.FetchData(Weburl);
  }
  UpdatePointerProject(body: any, Isversionize: string, statusID: string) {
    const Weburl = this.httpUrl + 'Dorsey/UpdatePointerProject?isVersioning=' + Isversionize + '&statusID=' + statusID;
    return this.PostData(Weburl, body);
  }

  public GetFrustNoProcess(CompanyID: string) {
    const Weburl = this.httpUrl + 'Dorsey/NoFrustProcesses?CompanyID=' + CompanyID;
    return this.FetchData(Weburl);
  }

  public GetNoActionProjects(CompanyID: string) {
    const Weburl = this.httpUrl + 'Dorsey/NoActionProject?CompanyID=' + CompanyID;
    return this.FetchData(Weburl);
  }

  public GetFrustrationWithOther(CompanyID: string) {
    const Weburl = this.httpUrl + 'Dorsey/GetFrustrationWithOther?CompanyID=' + CompanyID;
    return this.FetchData(Weburl);
  }

  public GetCommentsOnFrustration(CompanyID: string) {
    const Weburl = this.httpUrl + 'Dorsey/GetCommentsOnFrustration?CompanyID=' + CompanyID;
    return this.FetchData(Weburl);
  }

  public GetNoMetricProcess(CompanyID: string) {
    const Weburl = this.httpUrl + 'Dorsey/NoMetricProcesses?CompanyID=' + CompanyID;
    return this.FetchData(Weburl);
  }

  public GetUsersList() {
    const Weburl = this.httpUrl + 'Dorsey/GetUsers';
    return this.FetchData(Weburl);
  }
  public GetReport() {
    const Weburl = this.httpUrl + 'Dorsey/GetReport';
    return this.FetchData(Weburl);
  }
  public GetUserRoleAssignment() {
    const Weburl = this.httpUrl + 'Dorsey/GetUserRoleAssignment';
    return this.FetchData(Weburl);
  }
  public GetKeyProcessTeam() {
    const Weburl = this.httpUrl + 'Dorsey/GetKeyProcessTeam';
    return this.FetchData(Weburl);
  }
  public GetActivitiesTeam() {
    const Weburl = this.httpUrl + 'Dorsey/GetActivitiesTeam';
    return this.FetchData(Weburl);
  }
  SaveUser(body: any) {
    const Weburl = this.httpUrl + 'Dorsey/SaveUser';
    return this.PostData(Weburl, body);
  }
  ChangePassword(body: any) {
    const Weburl = this.httpUrl + 'Dorsey/ChangePassword';
    return this.PostData(Weburl, body);
  }
  SaveRole(body: any) {
    const Weburl = this.httpUrl + 'Dorsey/SaveRole';
    return this.PostData(Weburl, body);
  }
  public GetSelectedRolesData(companyID: string, TeamID: string, VerTableName: string) {
    const Weburl = this.httpUrl + 'Dorsey/GetSelectedRolesData?companyID=' + companyID + "&TeamID=" + TeamID + "&VerTableName=" + VerTableName;
    return this.FetchData(Weburl);
  }

  public GetSelectedRolesActivityData(companyID: string, TeamID: string, VerTableName: string) {
    const Weburl = this.httpUrl + 'Dorsey/GetSelectedRolesActivityData?companyID=' + companyID + "&teamID=" + TeamID + "&tableName=" + VerTableName;
    return this.FetchData(Weburl);
  }
  public GetMenuMaster() {
    const Weburl = this.httpUrl + 'Dorsey/GetMenuMaster';
    return this.FetchData(Weburl);
  }

  public DeleteRole(RowID: string) {
    const Weburl = this.httpUrl + 'Dorsey/DeleteRole?RowID=' + RowID;
    return this.FetchData(Weburl);
  }
  SaveTypes(body: any, Isversionize: string) {
    const Weburl = this.httpUrl + 'Dorsey/SaveTypes?isVersioning=' + Isversionize;
    return this.PostData(Weburl, body);
  }

  public DeleteType(FrustrationTypeID: string) {
    const Weburl = this.httpUrl + 'Dorsey/DeleteType?FrustrationsTypeID=' + FrustrationTypeID;
    return this.FetchData(Weburl);
  }

  public GetActivityIssues(CompanyID: string) {
    const Weburl = this.httpUrl + 'Dorsey/GetActivityIssues?CompanyID=' + CompanyID;
    return this.FetchData(Weburl);
  }

  public DeleteCase(CasesID: string, PointerID: string) {
    const Weburl = this.httpUrl + 'Dorsey/DeleteCase?CasesID=' + CasesID + '&PointerID=' + PointerID;
    return this.FetchData(Weburl);
  }

  SaveAlertValue(body: any) {
    const Weburl = this.httpUrl + 'Dorsey/SaveAlertValues';
    return this.PostData(Weburl, body);
  }

  public GetAlertValue(companyID: string, pointerID: Number) {
    const Weburl = this.httpUrl + 'Dorsey/GetAlertValue?companyID=' + companyID + '&pointerTypeID=' + pointerID;
    return this.FetchData(Weburl);
  }

  public GetKpiMain(CompanyID) {
    const Weburl = this.httpUrl + 'Dorsey/GetKpiMain?CompanyID=' + CompanyID;
    return this.FetchData(Weburl);
  }

  public GetKpiSub(CompanyID) {
    const Weburl = this.httpUrl + 'Dorsey/GetKpiSub?CompanyID=' + CompanyID;
    return this.FetchData(Weburl);
  }
  SaveMetricData(body: any, Isversionize: string) {
    const Weburl = this.httpUrl + 'Dorsey/SaveMetricData?isVersioning=' + Isversionize;
    return this.PostData(Weburl, body);
  }
  public DeleteMetricData(MainCategoryID: string, RowID: string) {
    const Weburl = this.httpUrl + 'Dorsey/DeleteMetricData?MainCategoryID=' + MainCategoryID + '&RowID=' + RowID;
    return this.FetchData(Weburl);
  }

  public GenerateRandomPasswrd(body: any) {
    const Weburl = this.httpUrl + 'Dorsey/GenerateRandomPasswrd?fullData=' + body;
    return this.PostData(Weburl, body);
  }

  updateEnableScoreCard(body: any) {
    const Weburl = this.httpUrl + 'Dorsey/UpdateEnableScoreCard';
    return this.PostData(Weburl, body);
  }
}
