import { Component, OnInit, Inject } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { Http } from '@angular/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common'
import { Observable } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { GoalService } from '../services/goal.service';
import { EmployeeLoginLevel } from '../common/models/login-level.model';
import { PerformanceScoreCardService } from '../performance-score-card/performance-score-card.service';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-metric',
  templateUrl: './metric.component.html',
  providers: [DorseyService]
})
export class MetricComponent implements OnInit {
  employeeLoginLevel = EmployeeLoginLevel;
  employeeDetails: any;
  greenLow: string;
  greenHigh: string;
  redLow: string;
  GoalID: string;
  redHigh: string;
  yellowLow: string;
  yellowHigh: string;
  condition = false;
  status = false;
  employeeData: any;
  processData: any;
  activityData: any;
  pointerTypeData: any;
  frustrationtypeData: any;
  departmentData: any;
  teamData: any;
  severityData: any;
  statusData: any;
  processTeamData: any;
  EmployeeID: number;
  ProcessID: string;
  ActivityID: string;
  PointerTypeID = "2";
  FrustrationTypeID: string;
  DepartmentID: string;
  TeamID: string;
  StatusID: string;
  SeverityID: string;
  Subject: string;
  Description: string;
  PointerID: string;
  pointerData: any;
  tempActivity = [];
  tempFrustrationType = [];
  metricData: any;
  searchtext: string;
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;
  metricPaging = false;
  saveFilter = [];
  CompanyID: string;
  isDisable = false;
  disableSearch = false;
  tempProcess = [];
  UOM: string;

  currentUser: string;
  MenuPermissions: any;
  menuData: any;
  menuID: string;
  menuName: string;
  ParentID: any;
  subActivityStatus = false;
  SubActivityID: string;
  tempSubActivity = [];
  userTeamID: string;
  Source: string;
  loginCompanyName: any;

  TeamName: any;
  EmpROleID: any;
  UserID: any;
  userRoleAssignmentData: any;
  rolesData: any;
  GoalsList: any;
  constructor(private http: Http, private dorseyService: DorseyService,
    public dialog: MatDialog, private storage: LocalStorageService,
    public goalService: GoalService,
    private scoreCardService: PerformanceScoreCardService) {
    this.CompanyID = this.storage.retrieve('companyID');
    this.userTeamID = this.storage.retrieve('TeamID');
    this.currentUser = this.storage.retrieve('user');
    this.menuData = this.storage.retrieve('menuData');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
    this.loginCompanyName = this.storage.retrieve('loginCompanyName');

    this.UserID = this.storage.retrieve('UserID');
    this.EmpROleID = this.storage.retrieve('EmpRoleID');
    this.employeeDetails = this.storage.retrieve('employeeDetails');
    this.GetTeam();
  }

  ngOnInit() {

    this.GetGoals();

    this.menuData = this.menuData.filter(x => x.MenuName.toString().toLowerCase() === 'metric');
    if (this.employeeDetails) {
      this.EmployeeID = this.employeeDetails.EmployeeID;
      this.TeamID = this.employeeDetails.TeamID;
    }
    else {
      this.EmployeeID = 0;
    }

    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }

    this.GetProcess();
    this.GetActivity();
    this.GetPointerType();

    this.GetPointer();

  }


  GetGoals() {
    this.GoalsList = [];
    this.goalService.getGoals(parseInt(this.CompanyID)).subscribe(resp => {

      this.GoalsList = resp.Data;
    });
  }

  OpenPointer() {
    this.dialogCall('new');
  }
  Searchtext() {
    this.metricData = this.saveFilter;
    let tempMetric = this.metricData.filter(
      (item) => {
        return (
          (item.ProcessName !== null && item.ProcessName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ActivityName !== null && item.ActivityName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.GoalName !== null && item.GoalName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.Description !== null && item.Description.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.TeamName !== null && item.TeamName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||

          (item.Source !== null && item.Source.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)

        );
      }

    );
    this.metricData = tempMetric;
    if (this.metricData.length == 0) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }

    if (this.searchtext === '') {
      this.GoalsList = this.saveFilter;
      this.rowCount = this.GoalsList.length;
      this.isDisable = false;
      this.getMetricPagination(this.rowCount);
    }
    else {
      this.rowCount = tempMetric.length;
      this.getMetricPagination(this.rowCount);
      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)

          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }

  GetPointer() {
    this.metricData = [];
    this.dorseyService.GetPointer(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.pointerData = subData;
      this.metricData = this.pointerData.filter(x => x.PointerTypeID != null && x.PointerTypeID.toString() === "2");
      let tempMetricData = this.metricData;

      if (this.metricData.length === 0) {
        this.metricPaging = false;
        this.isDisable = true;
        this.disableSearch = true;
      }
      else {

        if (this.employeeDetails) {
          if (this.employeeDetails.IsEmployeeLoginLevel == this.employeeLoginLevel.Department) {
            this.metricData = this.metricData.filter(entity1 => this.teamData.some(entity2 => entity1.TeamID == entity2.TeamID));
          }
          else if (this.employeeDetails.IsEmployeeLoginLevel == this.employeeLoginLevel.Team) {
            this.metricData = this.metricData.filter(ent => ent.TeamID == this.employeeDetails.TeamID)
          }
          else {
            let data = this.metricData.filter(x => x.CompanyID === this.CompanyID);
            this.metricData = data;
          }
        }

      }

      this.dynamicRows = 10;
      this.rowsOnPageSet = [];

      if (this.metricData.length === 0) {
        this.metricPaging = false;
        this.isDisable = true;
        this.disableSearch = true;
      }
      else {
        this.saveFilter = JSON.parse(JSON.stringify(this.metricData));
        this.rowCount = this.metricData.length;
        this.getMetricPagination(this.rowCount)

        this.metricPaging = true;
        this.isDisable = false;
        this.disableSearch = false;

      }
    });
  }

  getMetricPagination(RowCount): void {
    this.dynamicRows = 10;
    this.rowsOnPageSet = [];
    this.rowsOnPage = 10;
    this.rowCount = RowCount;
    while (this.dynamicRows < this.rowCount) {
      this.rowsOnPageSet.push(this.dynamicRows);
      this.dynamicRows = this.dynamicRows + 10;
    }
    if (!this.rowsOnPageSet.includes(this.rowCount)) {
      this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }
  exportAsXLSX() {
    let allData = JSON.parse(JSON.stringify(this.metricData));
    for (let i = 0; i < allData.length; i++) {
      allData[i].Team = allData[i].TeamName;
      allData[i].Process = allData[i].ProcessName;
      allData[i].Activity = allData[i].ActivityName;
      allData[i]['Goal'] = allData[i].GoalName;
      allData[i]['Metric'] = allData[i].Description;
      allData[i][' Source'] = allData[i].Source;
      delete (allData[i].DepartmentName);
      delete (allData[i].EmployeeName);
      delete (allData[i].VersionID);
      delete (allData[i].GoalID);
      delete (allData[i].Source);
      delete (allData[i].GoalName);
      delete (allData[i].Target);
      delete (allData[i].RedRange);
      delete (allData[i].GreenRange);
      delete (allData[i].YellowRange);
      delete (allData[i].CompanyID);
      delete (allData[i].ProjectName);
      delete (allData[i].ProcessName);
      delete (allData[i].ActivityName);

      delete (allData[i].TeamName);
      delete (allData[i].PointerID);
      delete (allData[i].Description);

      delete (allData[i].SeverityID);
      delete (allData[i].EmployeeID);

      delete (allData[i].Type);
      delete (allData[i].Subject);
      delete (allData[i].ActivityID);
      delete (allData[i].PointerTypeID);
      delete (allData[i].StatusID);
      delete (allData[i].DepartmentID);
      delete (allData[i].ProcessID)

      delete (allData[i].FrustrationsTypeName);
      delete (allData[i].StatusName);
      delete (allData[i].SeverityName)

      delete (allData[i].UpdatedDate);
      delete (allData[i].UpdatedBy);
      delete (allData[i].ProjectID)

      delete (allData[i].TeamID);
      delete (allData[i].FrustrationsTypeID);
      delete (allData[i].Date)

      delete (allData[i].IsActive);
      delete (allData[i].CreatedDate);
      delete (allData[i].CreatedBy)
    }

    this.dorseyService.generateExcel(allData, this.loginCompanyName + '_metric' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Metric', "Company: " + this.loginCompanyName, '', "Metric Report", copyRight, 'FFFFFF00', 'FF0000FF');

  }
  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }
  GetPointerType() {
    this.dorseyService.GetPointerType().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.pointerTypeData = subData;
    });
  }
  GetTeam() {
    this.dorseyService.GetTeam(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.teamData = subData;

      if (this.employeeDetails) {
        if (this.employeeDetails.IsEmployeeLoginLevel == this.employeeLoginLevel.Department) {
          this.teamData = this.teamData.filter(ent => ent.DepartmentID == this.employeeDetails.DepartmentID)
        }
        else if (this.employeeDetails.IsEmployeeLoginLevel == this.employeeLoginLevel.Team) {
          this.teamData = this.teamData.filter(ent => ent.DepartmentID == this.employeeDetails.DepartmentID && ent.TeamID == this.employeeDetails.TeamID)
          this.TeamName = this.teamData[0].TeamName;
        }
        else {
          this.teamData = subData;
        }
      }
    });
  }
  GetActivity() {
    this.dorseyService.GetActivity(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.activityData = subData;
    });
  }
  GetProcess() {
    this.dorseyService.GetProcess(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.processData = subData;
      if (this.employeeDetails) {
        if (this.employeeDetails.IsEmployeeLoginLevel == this.employeeLoginLevel.Department) {
          this.processData = this.processData.filter(entity1 => this.teamData.some(entity2 => entity1.TeamID == entity2.TeamID));
        }
        else if (this.employeeDetails.IsEmployeeLoginLevel == this.employeeLoginLevel.Team) {
          this.processData = this.processData.filter(ent => ent.TeamID == this.employeeDetails.TeamID)
        }
        else {
          this.processData = subData;
        }
      }
    });
  }

  dialogCall(msg: string) {
    const dialogRef = this.dialog.open(MetricDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: msg + 'æææ' + false,
    });
    if (msg === 'new') {
      this.searchtext = '';
      this.GetPointer();
    }
    dialogRef.componentInstance.EmployeeID = this.EmployeeID;
    dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.componentInstance.GoalsList = this.GoalsList;
    dialogRef.componentInstance.processData = this.processData;

    dialogRef.componentInstance.activityData = this.activityData;
    dialogRef.componentInstance.departmentData = this.departmentData;
    dialogRef.componentInstance.pointerTypeData = this.pointerTypeData;
    dialogRef.componentInstance.severityData = this.severityData;
    dialogRef.componentInstance.statusData = this.statusData;
    dialogRef.componentInstance.teamData = this.teamData;
    dialogRef.componentInstance.employeeData = this.employeeData;
    dialogRef.componentInstance.frustrationtypeData = this.frustrationtypeData;
    dialogRef.componentInstance.tempActivity = this.tempActivity;
    dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
    dialogRef.componentInstance.CompanyID = this.CompanyID;
    dialogRef.componentInstance.tempSubActivity = this.tempSubActivity;
    dialogRef.componentInstance.SubActivityID = this.SubActivityID;
    dialogRef.componentInstance.searchtext = this.searchtext;
    dialogRef.componentInstance.GoalID = this.GoalID;
    this.GetPointer();
    if (msg === 'edit') {
      this.status = true;
      let tempProcessData = this.processData.filter(x => x.TeamID === this.TeamID);
      dialogRef.componentInstance.processData = tempProcessData;
      dialogRef.componentInstance.EmployeeID = this.EmployeeID;
      dialogRef.componentInstance.ProcessID = this.ProcessID;
      dialogRef.componentInstance.ActivityID = this.ActivityID;
      dialogRef.componentInstance.PointerTypeID = this.PointerTypeID;
      dialogRef.componentInstance.PointerID = this.PointerID;
      dialogRef.componentInstance.DepartmentID = this.DepartmentID;
      dialogRef.componentInstance.FrustrationTypeID = this.FrustrationTypeID;
      dialogRef.componentInstance.TeamID = this.TeamID;
      dialogRef.componentInstance.StatusID = this.StatusID;
      dialogRef.componentInstance.SeverityID = this.SeverityID;
      dialogRef.componentInstance.GoalID = this.GoalID
      dialogRef.componentInstance.Subject = this.Subject;
      dialogRef.componentInstance.Description = this.Description;
      dialogRef.componentInstance.tempActivity = this.tempActivity;
      dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;

      dialogRef.componentInstance.Source = this.Source;

      dialogRef.componentInstance.subActivityStatus = this.subActivityStatus;
      dialogRef.componentInstance.SubActivityID = this.SubActivityID;
    }
   
    dialogRef.afterClosed().subscribe(result => {

      //  this.rowsOnPageSet=[];
      this.dynamicRows = 10;
      if (this.searchtext !== '' && this.searchtext !== undefined) {
        this.Searchtext();
      }
      else {

        this.GetPointer();
      }

      if (result.toString() === 'true') {
        ;
        let statusMsg = '';
        if (msg === 'new') {
          statusMsg = 'Saved successfully';
        } else {

          statusMsg = 'Updated successfully';
        }


        //  this.rowsOnPageSet=[];
        this.dynamicRows = 10;
      
        this.GetPointer();

        const dialogRefsub = this.dialog.open(MetricDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: statusMsg + 'æææ' + false,
          //  width: '30%'
        });

        setTimeout(() => {
          this.GetActivity();
        }, 500);
        dialogRefsub.afterClosed().subscribe(result1 => {
        
          if (this.searchtext !== '') {
            this.Searchtext();
          }
          else {
            this.GetPointer();
          }
        });
      }
    });
  }
  GetTempActivity(ProcessID: string) {
    this.tempActivity = [];
    for (let i = 0; i < this.activityData.length; i++) {
      if (this.activityData[i].ProcessID === ProcessID && this.activityData[i].ParentID == 0) {
        this.tempActivity.push(this.activityData[i]);
      }
    }

  }

  GetTempSubActivity(ActivityID) {
    this.tempSubActivity = [];
    for (let i = 0; i < this.activityData.length; i++) {
      if (this.activityData[i].ParentID === ActivityID) {
        this.tempSubActivity.push(this.activityData[i]);
      }
    }
  }

  GetKeyProcessTeam(TeamID) {
    this.tempProcess = [];
    for (let i = 0; i < this.processData.length; i++) {
      if (this.processData[i].TeamID.toString() === TeamID.toString()) {
        this.TeamID = TeamID;
      }
    }
  }

  EditPointer(element, text) {
    this.GetTempActivity(element.ProcessID);
    this.PointerID = element.PointerID;
    this.ProcessID = element.ProcessID;
    this.PointerTypeID = element.PointerTypeID;
    this.EmployeeID = element.EmployeeID;
    this.SeverityID = element.SeverityID;
    this.StatusID = element.StatusID;
    this.Subject = element.Subject;
    this.Description = element.Description;
    this.DepartmentID = element.DepartmentID;
    this.TeamID = element.TeamID;
    this.FrustrationTypeID = element.FrustrationTypeID;
    this.GoalID = element.GoalID;

    this.Source = element.Source;
    for (let i = 0; i < this.activityData.length; i++) {
      if (element.ActivityID === this.activityData[i].ActivityID) {
        this.ParentID = this.activityData[i].ParentID;
      }
    }

    if (!this.ParentID || this.ParentID.toString() === '0') {
      this.ActivityID = element.ActivityID;
      this.subActivityStatus = false;
    } else {
      this.subActivityStatus = true;
      this.SubActivityID = element.ActivityID;
      this.ActivityID = this.ParentID;
    }
    this.dialogCall('edit');
  }

  ifMaxRow() {
    if (this.rowsOnPage == this.metricData.length) {
      this.rowsOnPage = this.rowsOnPage - 1;
    }
  }
  DeletePointer(element) {
    this.PointerID = element.PointerID;
    let status: boolean;
    this.scoreCardService.CheckMetricExistInPSCDetails(element.PointerID).subscribe(resp => {
      status = resp.Data;
    })
    const dialogRef = this.dialog.open(MetricDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'Are you sure you want to delete metric: ' + element.Description + '?æææ' + true,
      width: '400px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (!status) {
          this.dorseyService.DeletePointer(this.PointerID).subscribe(data => {
            if (data.ok) {
              this.ifMaxRow();
              this.condition = true;
              this.dialogCall('Deleted successfully');
            }
            this.GetPointer();
          });
        }
        else {
          this.dialogCall("Delete Failed. This metric is associated with score card");
        }
      }
    });
  }
}

@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
  providers: [DorseyService, DatePipe],
})
export class MetricDialogComponent implements OnInit {
  GoalID: string;
  GoalsList: any;
  Source: string;
  searchtext: string;
  condition = false;
  status = false;
  employeeData: any;
  processData: any;
  activityData: any;
  pointerTypeData: any;
  frustrationtypeData: any;
  departmentData: any;
  teamData: any;
  severityData: any;
  statusData: any;
  dataSelf = '';
  EmployeeID: number;
  ProcessID: string;
  ActivityID: string;
  PointerTypeID = "2";
  FrustrationTypeID: string;
  DepartmentID: string;
  TeamID: string;
  StatusID: string;
  SeverityID: string;
  Subject: string;
  Description: string;
  PointerID: string;
  tempActivity = [];
  tempFrustrationType = [];
  conditionActivity = '';
  processCtrl = new FormControl();
  currentUser: string;
  filteredProcess: Observable<any[]>
  tempDepart: any;
  tempProcess: any;
  CompanyID: string;
  processTeamData: any;

  subActivityStatus = false;
  SubActivityID: string;
  tempSubActivity = [];
  processMap = false;
  pointerData: any;
  breakpoint: number;
  metricData: any;
  btnDisabled = false;
  constructor(
    public dialogRef: MatDialogRef<MetricDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dorseyService: DorseyService, private datepipe: DatePipe) {
    // dialogRef.disableClose=true;
    this.data = this.data.split('æææ');
    if (this.data[1] === 'true') {
      this.condition = true;
    } else if (this.data[1] === 'false') {
      this.condition = false;
    }
    else {
      if (this.data[1] === '') {
        this.conditionActivity = 'process';
      } else {
        this.conditionActivity = 'activity';
      }

    }
    this.data = this.data[0];
  }

  ngOnInit() {
    this.breakpoint = window.innerWidth <= 900 ? 1 : 3;
    if (this.SubActivityID) {
      this.GetTempSubActivity(this.ActivityID);
    }
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 900) ? 1 : 3;
  }


  ValidatePh(event: any) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter
      [46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
      (event.keyCode === 65 && event.ctrlKey === true) || // Allow: Ctrl+A
      (event.keyCode === 67 && event.ctrlKey === true) || // Allow: Ctrl+C
      (event.keyCode === 86 && event.ctrlKey === true) || // Allow: Ctrl+V
      (event.keyCode === 88 && event.ctrlKey === true) || // Allow: Ctrl+X
      (event.keyCode === 65 && event.metaKey === true) || // Cmd+A (Mac)
      (event.keyCode === 67 && event.metaKey === true) || // Cmd+C (Mac)
      (event.keyCode === 86 && event.metaKey === true) || // Cmd+V (Mac)
      (event.keyCode === 88 && event.metaKey === true) || // Cmd+X (Mac)
      (event.keyCode >= 35 && event.keyCode <= 39)  // Home, End, Left, Right
    ) {
      return;
    }

    if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
      event.preventDefault();
    }
  }

  subactivity(event) {
    this.SubActivityID = event.value;
  }
  onYesClick() {
    this.dialogRef.close(true);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ToggleSubActivity() {
    this.subActivityStatus = !this.subActivityStatus;
  }
  AddPointer() {
    this.btnDisabled = true;

    setTimeout(() => {
      this.btnDisabled = false;
    }, 1000);
    this.FrustrationTypeID = '0';
    let count = 0;
    const Editcount = 0;
    if (this.ProcessID === undefined || //this.ActivityID === undefined ||
      //  this.PointerTypeID === undefined || this.FrustrationTypeID === undefined ||    this.DepartmentID === undefined ||this.EmployeeID === undefined ||

      this.TeamID === undefined
      //  || this.SeverityID ===undefined|| this.Subject ==undefined
      || this.Description === undefined || this.GoalID == undefined
      //  || this.StatusID===undefined
      || this.ProcessID === '' ||  //  this.EmployeeID === '' || this.UOM===''||this.Target==='' this.DepartmentID === '' ||this.PointerTypeID === '' || this.FrustrationTypeID === '' ||  this.SeverityID === '' |||| || this.StatusID === ''  this.Subject === '' ||
      this.TeamID === '' || this.Description.trim() === '' || this.GoalID == '') {
      this.dataSelf = 'Please enter mandatory(*) fields';
      if (this.data === 'new') {

      }
      count++;
    }

    if (this.subActivityStatus) {
      if (this.ActivityID === undefined || this.SubActivityID === undefined ||
        this.ActivityID === '' || this.SubActivityID === '') {
        this.dataSelf = 'Please enter mandatory(*) fields';
        if (this.data === 'new') {

        }
        count++;

      }

    }
    if (count === 0 && Editcount === 0) {
      this.dataSelf = '';
      if (this.data === 'new') {

        this.Saving(this.data);
      } else {
        this.Saving(this.data);
      }
    }

  }

  Saving(msg: string) {
    this.FrustrationTypeID = '0';
    this.Subject = "";
    this.SeverityID = '1';
    this.StatusID = '1';

    // if (msg === 'new') {
    //   this.EmployeeID = 0;
    // }

    if (!this.ActivityID) {
      this.ActivityID = "0";
    }

    // if (this.subActivityStatus) {
    //   this.ActivityID = this.SubActivityID;
    // }



    let activityId: string;
    if (this.subActivityStatus) {
      activityId = this.SubActivityID
    }
    else {
      activityId = this.ActivityID;
    }
    const json = {
      // CompanyID: '1',
      CompanyID: this.CompanyID,
      ProjectID: '0',
      PointerID: this.PointerID,
      //EmployeeID: this.EmployeeID,
      ProessID: this.ProcessID,
      ActivityID: activityId,
      PointerTypeID: this.PointerTypeID,
      FrustrationsTypeID: this.FrustrationTypeID,
      DepartmentID: "0",
      EmployeeID: this.EmployeeID,

      // DepartmentID: this.DepartmentID,
      TeamID: this.TeamID,
      SeverityID: this.SeverityID,
      StatusID: this.StatusID,
      Subject: this.Subject,
      Description: this.Description,

      Target: '0',
      CreatedBy: this.currentUser,
      UpdatedBy: this.currentUser,
      Source: this.Source,
      GoalID: this.GoalID
    };

    if (msg === 'new') {
      this.dorseyService.SavePointer(json, 'No').subscribe(data => {

        if (data['_body'].toString() !== "0") {

          // this.dialogRef.close(true);
          this.dataSelf = 'Saved succesfully';
          //this.EmployeeID = '';
          this.DepartmentID = '';
          this.Description = '';
          this.PointerID = '';

          this.Source = '';
          this.GoalID = '';
          if (this.conditionActivity.toString() == 'process' || this.conditionActivity.toString() === 'activity') {
            return;

          }

          else if (this.SubActivityID && this.conditionActivity === '') {
            this.ActivityID = '';
            this.SubActivityID = '';
            this.tempSubActivity = [];
            // this.tempActivity=[];
          }
          else {
            this.ActivityID = '';
          }
        }
        else {
          this.dataSelf = 'Save failed';
        }
      });

    }
    else {

      this.dorseyService.SavePointer(json, 'No').subscribe(data => {

        if (data['_body'].toString() != '0') {

          this.dataSelf = 'Updated successfully';
          this.dialogRef.close(true);


        } else {
          this.dataSelf = 'Updated failed';
        }
      });
    }

  }


  Clear() {


    this.dialogRef.close(false);
  }
  employee(event) {
    this.EmployeeID = event.value;

    let tempEmp = this.employeeData.filter(x => x.EmployeeID === this.EmployeeID);

    if (tempEmp.length > 0 && tempEmp.length === 1) {
      this.tempDepart = this.departmentData.filter(x => x.DepartmentID === tempEmp[0].DepartmentID);
      this.DepartmentID = this.tempDepart[0].DepartmentID;

    }
  }
  GoalChange(event) {
    this.GoalID = event.value;
  }

  process(event) {
    this.ProcessID = event.value;
    this.ActivityID = '';
    this.SubActivityID = '';
    this.tempActivity = [];
    this.tempSubActivity = [];
    this.GetTempActivity(this.ProcessID);
    for (let i = 0; i < this.processData.length; i++) {
      if (this.processData[i].ProcessID === this.ProcessID) {
        this.TeamID = this.processData[i]['TeamID'];

      }
    }
  }
  GetTempActivity(processiD) {
    this.tempActivity = []
    for (let i = 0; i < this.activityData.length; i++) {
      if (this.activityData[i].ProcessID === processiD && this.activityData[i].ParentID === 0) {
        this.tempActivity.push(this.activityData[i]);
      }
    }

  }
  GetTempSubActivity(ActivityID) {
    this.tempSubActivity = [];
    for (let i = 0; i < this.activityData.length; i++) {
      if (this.activityData[i].ParentID === ActivityID) {
        this.tempSubActivity.push(this.activityData[i]);
      }
    }
  }

  activity(event) {
    this.tempSubActivity = [];
    this.ActivityID = event.value;
    this.SubActivityID = '';
    this.GetTempSubActivity(this.ActivityID);
  }

  pointerType(event) {
    this.PointerTypeID = event.value;
    this.tempFrustrationType = [];
    this.GetTempFrastrations();
  }

  GetTempFrastrations() {
    for (let i = 0; i < this.frustrationtypeData.length; i++) {
      if (this.frustrationtypeData[i].PointerTypeID === this.PointerTypeID) {
        this.tempFrustrationType.push(this.frustrationtypeData[i]);
      }
    }
  }

  frustrationType(event) {
    this.FrustrationTypeID = event.value;
  }

  department(event) {
    this.DepartmentID = event.value;
  }

  team(event) {
    this.TeamID = event.value;
    this.tempProcess = [];
  }

  GetKeyProcessTeam(TeamID) {
    this.dorseyService.GetKeyProcessTeam().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.processTeamData = subData;
      for (let i = 0; i < this.processTeamData.length; i++) {
        if (this.processTeamData[i].TeamID === TeamID) {
          this.tempProcess.push(this.processTeamData[i]);
        }
      }

    });
  }

  severity(event) {
    this.SeverityID = event.value;
  }

  statusChange(event) {
    this.StatusID = event.value;
  }

}

