import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'response-dialog',
    templateUrl: 'response-dialog.html',
})
export class ResponseDialogComponent implements OnInit {

    message: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        this.message = this.data.message;
    }
}