import { Component, OnInit, Inject, } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DorseyService } from '../dorsey.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { EmployeeLoginLevel } from '../common/models/login-level.model';
import { copyRight } from '../copyRight.model';



@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  providers: [DorseyService]
})
export class ActivityComponent implements OnInit {
  employeeLoginLevel = EmployeeLoginLevel;
  employeeDetails: any;
  companyName: string;

  granualActivity = [];
  ProcessByTeam = [];
  headerText: string;
  status = false;
  activityData: any;
  originalData: any;
  departmentData: any;
  employeeData: any;
  processData: any;
  CompanyID: string;
  ProcessID: string;
  // ProcessID1: string;
  DepartmentID: string;
  EmployeeID: number;
  ActivityTypeID: string;
  activityTypeData: any;
  ActivityID: string;
  condition = false;
  ActivityName: string;
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;

  parentID: number
  searchTerm: FormControl = new FormControl();
  myBooks = <any>[];
  searchtext: string;
  saveFilter = [];
  activityPaging = false;
  teamData: any;
  //teamids=[];
  keyActivityTeam: any;
  isDisable = false;
  disableSearch = false;
  TeamID: number;
  currentUser: string;
  menuData: any;
  MenuPermissions: any;
  menuName: string;
  menuID: string;
  Permissons: any;
  ParentName = [];
  TeamIDByProcess: string;
  EmpROleID: string;
  squence: number;
  empID: string;
  tempAc = [];
  userTeamID: string;
  loginCompanyName: any;
  lastSequence: number;
  TeamName: any;
  pointerData: any;
  actData: any;
  sequenceList = [];
  usersData: any;
  userRoleAssignmentData: any;
  rolesData: any;
  UserID: any;
  copyDate: number;
  companyData: any;
  constructor(public dialog: MatDialog, public dorseyService: DorseyService, private storage: LocalStorageService) {
    // this.Permissons=this.storage.retrieve("Permissions");
    this.userTeamID = this.storage.retrieve('TeamID');
    this.CompanyID = this.storage.retrieve('companyID');
    this.menuData = this.storage.retrieve('menuData');
    this.currentUser = this.storage.retrieve('user');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');

    this.empID = this.storage.retrieve("EmployeeID");
    this.loginCompanyName = this.storage.retrieve('loginCompanyName');
    this.UserID = this.storage.retrieve('UserID');
    this.EmpROleID = this.storage.retrieve('EmpRoleID');
    this.employeeDetails = this.storage.retrieve('employeeDetails');
    this.GetTeam();
  }

  ngOnInit() {
    if (this.employeeDetails) {
      this.EmployeeID = this.employeeDetails.EmployeeID;
      this.TeamID = this.employeeDetails.TeamID;
    }
    else {
      this.EmployeeID = 0;
    }
    this.menuData = this.menuData.filter(x => x.MenuName.toString().toLowerCase() === 'activity');

    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }
    this.GetProcess();
    this.GetActivity();
    this.GetActivityType();
    this.GetActivitiesTeam();
    this.copyDate = (new Date()).getFullYear();
  }

  GetUsers() {
    this.dorseyService.GetUsersList().subscribe(users => {
      this.usersData = JSON.parse(users['_body']);
    });
  }
  GetRoles() {
    this.dorseyService.GetRoles(this.CompanyID).subscribe(users => {
      this.rolesData = JSON.parse(users['_body']);
    });
  }
  GetUserRoleAssignment() {
    this.dorseyService.GetUserRoleAssignment().subscribe(userRoles => {
      this.userRoleAssignmentData = JSON.parse(userRoles['_body']);
      this.dorseyService.GetRoles(this.CompanyID).subscribe(users => {
        this.rolesData = JSON.parse(users['_body']);
        if (this.empID.toString() !== '0') {
          let roles = this.userRoleAssignmentData.filter(x => x.UserID === this.UserID);
          if (roles.length > 0) {
            this.EmpROleID = roles[0].RoleID;
            let roleData = this.rolesData.filter(x => x.RoleID === this.EmpROleID);
            if (roleData.length > 0) {
              this.MenuPermissions = JSON.parse(roleData[0].MenuJson);
            }

          }
        }
      });
    });
  }

  OpenActivity(type: string) {
    this.dialogCall(type);
  }
  GetTeam() {
    this.dorseyService.GetTeam(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.teamData = subData;

      if (this.employeeDetails) {
        if (this.employeeDetails.IsEmployeeLoginLevel == this.employeeLoginLevel.Department) {
          this.teamData = this.teamData.filter(ent => ent.DepartmentID == this.employeeDetails.DepartmentID)
        }
        else if (this.employeeDetails.IsEmployeeLoginLevel == this.employeeLoginLevel.Team) {
          this.teamData = this.teamData.filter(ent => ent.DepartmentID == this.employeeDetails.DepartmentID && ent.TeamID == this.employeeDetails.TeamID)
          this.TeamName = this.teamData[0].TeamName;
        }
        else {
          this.teamData = subData;
        }
      }
    });
  }

  GetActivitiesTeam() {
    this.dorseyService.GetActivitiesTeam().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.keyActivityTeam = subData;
    });
  }

  Searchtext() {
    this.activityData = this.saveFilter;

    let tempActivity = this.activityData.filter(
      (item) => {
        return (
          (item.ProcessName !== null && item.ProcessName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
          || (item.ActivityName !== null && item.ActivityName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)) ||
          (item.TeamName !== null && item.TeamName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ActivityTypeName !== null && item.ActivityTypeName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.Parentname !== null && item.Parentname.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1);
        ;
      }

    );
    this.activityData = tempActivity;
    if (this.activityData.length == 0) {
      // this.GetActivity();
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.activityData = this.saveFilter;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      this.rowCount = this.activityData.length;
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount))
        this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet) {

        this.rowsOnPage = this.rowsOnPageSet[0];
      }
      this.isDisable = false;
    } else {
      this.rowCount = tempActivity.length;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount)) {
        this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }

      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }
  GetCompanyName() {
    if (this.CompanyID != '0') {
      this.dorseyService.GetCompanyData().subscribe(data => {
        this.companyData = JSON.parse(data['_body']);
        let dataCompany = this.companyData.filter(x => x.CompanyID === this.CompanyID);
        if (dataCompany.length > 0) {
          this.loginCompanyName = dataCompany[0].CompanyName;
        }
      });
    }
  }

  exportAsXLSX(): void {
    let allData = JSON.parse(JSON.stringify(this.activityData));
    for (let i = 0; i < allData.length; i++) {
      allData[i].Team = allData[i].TeamName;
      allData[i].Process = this.activityData[i].ProcessName;

      allData[i].Activity = this.activityData[i].ActivityName;

      allData[i].Parent = this.activityData[i].Parentname;

      allData[i].ActivityType = this.activityData[i].ActivityTypeName;
      allData[i].Sequence = this.activityData[i].squence;

      // if( this.activityData[i].ParentID===0)
      // { allData[i].Parent = this.activityData[i].ProcessName;

      // }
      // else{
      //   allData[i].Parent = this.ParentName[i];

      // }

      delete (allData[i].ActivityTypeName);
      delete (allData[i].ActivityTypeID);
      delete (allData[i].Parentname);
      delete (allData[i].TeamID2);
      delete (allData[i].ProcessID2);
      delete (allData[i].TeamName1);
      delete (allData[i].CompanyID2);
      delete (allData[i].IsActive2);
      delete (allData[i].CreatedDate2);
      delete (allData[i].CreatedBy2);


      delete (allData[i].UpdatedDate2);
      delete (allData[i].UpdatedBy2);
      delete (allData[i].TeamDescription);



      delete (allData[i].TeamName);
      delete (allData[i].squence1);
      delete (allData[i].ProcessName);
      delete (allData[i].ActivityName);
      delete (allData[i].TeamID);
      delete (allData[i].CompanyID);
      delete (allData[i].CompanyID1);
      delete (allData[i].TeamID1);
      delete (allData[i].ProcessID);
      delete (allData[i].ProcessID1);
      delete (allData[i].ActivityID);
      delete (allData[i].location);
      delete (allData[i].ActivityTypeID);

      delete (allData[i].CreatedDate);
      delete (allData[i].CreatedBy);
      delete (allData[i].VersionID);

      delete (allData[i].DepartmentID);
      delete (allData[i].EmployeeID);

      delete (allData[i].ParentID);
      delete (allData[i].UpdatedDate);
      delete (allData[i].UpdatedBy);
      delete (allData[i].VersionID1);
      delete (allData[i].ProcessID1);
      delete (allData[i].IsActive);

      delete (allData[i].ProcessName1);
      delete (allData[i].ProcessDescription1);
      delete (allData[i].DepartmentID1);
      delete (allData[i].EmployeeID1);
      delete (allData[i].CreatedBy1);
      delete (allData[i].CreatedDate1);
      delete (allData[i].UpdatedBy1);
      delete (allData[i].UpdatedDate1);
      delete (allData[i].IsActive1);
      delete (allData[i].squence);

      delete (allData[i].CompanyID);
      delete (allData[i].ParentID1);
      delete (allData[i].ProcessDescription);
      delete (allData[i].R);
      delete (allData[i].C);
      delete (allData[i].U);
      delete (allData[i].D);

    }


    this.dorseyService.generateExcel(allData, this.loginCompanyName + '_activity' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Activity', "Company: " + this.loginCompanyName, '', "Activity Report", copyRight, 'FFFFFF00', 'FF0000FF');
  }


  GetActivity() {
    this.activityData = [];
    this.ParentName = [];
    this.dorseyService.GetActivity(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.activityData = subData;
      this.storage.store("actData", this.activityData);
      this.originalData = subData;
      let tempactivityData = this.activityData;

      if (this.activityData.length === 0) {
        this.activityPaging = false;
        this.isDisable = true;
        this.disableSearch = true;
      }
      else {
        this.rowCount = this.activityData.length;
        this.saveFilter = JSON.parse(JSON.stringify(this.activityData));
        this.activityPaging = true;
        this.isDisable = false;

        this.disableSearch = false;
        this.rowsOnPageSet = [];
        this.dynamicRows = 10;


        if (this.employeeDetails) {
          if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) {
            this.activityData = this.activityData.filter(entity1 => this.teamData.find(entity2 => entity1.TeamID == entity2.TeamID));
          }
          else if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
            this.activityData = this.activityData.filter(ent => ent.TeamID == this.employeeDetails.TeamID)
          }
          else {
            let data = this.activityData.filter(x => x.CompanyID === this.CompanyID);
            this.activityData = data;
          }
          if (!this.employeeDetails.PermissionJson || this.employeeDetails.PermissionJson === "{}") {
            this.rowCount = this.activityData.length;
            this.saveFilter = JSON.parse(JSON.stringify(this.activityData));
            this.getActivityPagination(this.rowCount)
            return;
          }

          this.Permissons = JSON.parse(this.employeeDetails.PermissionJson);
          this.Permissons = this.Permissons["Activity"];
          let activityKeys = Object.keys(this.Permissons);
          let tempProcess = this.processData;
          {
            this.activityData = tempactivityData.filter(x => x.CompanyID === this.CompanyID);
            this.processData = tempProcess.filter(x => x.CompanyID === this.CompanyID);
          }

          for (let i = 0; i < activityKeys.length; i++) {
            let actData = this.activityData.filter(x => x.ActivityID.toString() === activityKeys[i].toString());
            if (actData.length > 0) {
              let permissonKeys = Object.keys(this.Permissons[activityKeys[i]]);

              if (permissonKeys.indexOf('R') > -1)
                actData[0]['R'] = this.Permissons[activityKeys[i]]['R'];
              else
                actData[0]['R'] = false;


              if (permissonKeys.indexOf('C') > -1)
                actData[0]['C'] = this.Permissons[activityKeys[i]]['C'];
              else
                actData[0]['C'] = false;


              if (permissonKeys.indexOf('U') > -1)
                actData[0]['U'] = this.Permissons[activityKeys[i]]['U'];
              else
                actData[0]['U'] = false;


              if (permissonKeys.indexOf('D') > -1)
                actData[0]['D'] = this.Permissons[activityKeys[i]]['D'];
              else
                actData[0]['D'] = false;

              this.granualActivity.push(actData[0]);
            }
          }
          this.activityData = this.granualActivity;
          this.rowCount = this.granualActivity.length;
        }

      }

      if (this.activityData.length > 0) {
        this.rowCount = this.activityData.length;
        this.activityPaging = true;
        this.isDisable = false;
        this.disableSearch = false;
        this.saveFilter = JSON.parse(JSON.stringify(this.activityData));
        this.getActivityPagination(this.rowCount)
      }
      else if (this.activityData.length === 0) {
        this.activityPaging = false;
        this.isDisable = true;
        this.disableSearch = true;
      }
      this.ifEmployeeLogin();
    });


  }

  getActivityPagination(rowCountNo) {
    while (this.dynamicRows < rowCountNo) {
      this.rowsOnPageSet.push(this.dynamicRows);
      this.dynamicRows = this.dynamicRows + 10;
    }
    if (!this.rowsOnPageSet.includes(rowCountNo)) this.rowsOnPageSet.push(rowCountNo);
    if (this.rowsOnPageSet.length === 1) {
      if (this.rowsOnPageSet[0] <= 10)
        this.rowsOnPage = this.rowsOnPageSet[0];
    }

  }


  ifEmployeeLogin() {
    if (this.userTeamID != '0' && this.userTeamID != null) {
      let tempProcessData = this.activityData.filter(x => x.TeamID == this.userTeamID);
      if (tempProcessData.length == 0) {
        this.isDisable = true;
        this.disableSearch = true;
      }
    }
  }

  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  GetEmployee() {
    this.dorseyService.GetEmployee(this.CompanyID).subscribe(subData => {

      subData = JSON.parse(subData['_body']);
      this.employeeData = subData;

      if (this.empID.toString() !== '0') {
        let filterEmp = this.employeeData.filter(x => x.EmployeeID === this.empID);
        if (filterEmp.length > 0) {
          this.userTeamID = filterEmp[0].TeamID;
        }
      }
    });
  }

  GetProcess() {
    this.dorseyService.GetProcess(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.processData = subData;

      if (this.employeeDetails) {
        if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) {
          this.processData = this.processData.filter(entity1 => this.teamData.find(entity2 => entity1.TeamID == entity2.TeamID));
        }
        else if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
          this.processData = this.processData.filter(ent => ent.TeamID == this.employeeDetails.TeamID)
        }
        else {
          let data = this.processData.filter(x => x.CompanyID === this.CompanyID);
          this.processData = data;
        }
      }

    });
  }

  GetActivityType() {
    this.dorseyService.GetActivityType().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.activityTypeData = subData;
    });
  }

  dialogCall(msg: string) {
    const dialogRef = this.dialog.open(ActivityDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: msg + 'æææ' + true,
    });
    dialogRef.componentInstance.EmployeeID = this.EmployeeID;
    dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.componentInstance.processData = this.processData;
    dialogRef.componentInstance.employeeData = this.employeeData;
    dialogRef.componentInstance.departmentData = this.departmentData;
    dialogRef.componentInstance.activityTypeData = this.activityTypeData;
    dialogRef.componentInstance.activityData = this.activityData;
    dialogRef.componentInstance.originalData = this.activityData;
    dialogRef.componentInstance.teamData = this.teamData;
    dialogRef.componentInstance.CompanyID = this.CompanyID;
    dialogRef.componentInstance.TeamIDByProcess = this.TeamIDByProcess;
    dialogRef.componentInstance.TeamID = this.TeamID;


    if (msg === 'new' || msg === 'sub') {
      this.TeamID = null;
      dialogRef.componentInstance.TeamID = this.TeamID;
      this.sequenceList = [];
    }

    if (msg === 'edit') {
      dialogRef.componentInstance.keyActivityTeam = this.keyActivityTeam;
      this.status = true;
      this.headerText = 'Edit Activity';
      let tempProcessData = this.processData.filter(x => x.TeamID === this.TeamID);
      dialogRef.componentInstance.processData = tempProcessData;
      dialogRef.componentInstance.ProcessByTeam = this.ProcessByTeam;
      dialogRef.componentInstance.ActivityID = this.ActivityID;
      dialogRef.componentInstance.ActivityName = this.ActivityName;
      dialogRef.componentInstance.ProcessID = this.ProcessID;
      dialogRef.componentInstance.ParentActivityID = this.parentID;
      dialogRef.componentInstance.DepartmentID = this.DepartmentID;
      dialogRef.componentInstance.EmployeeID = this.EmployeeID;
      dialogRef.componentInstance.ActivityTypeID = this.ActivityTypeID;
      dialogRef.componentInstance.tempActivity = this.activityData;
      dialogRef.componentInstance.squence = this.squence;
      dialogRef.componentInstance.sequenceList = this.sequenceList;
      dialogRef.componentInstance.actData = this.actData;
      dialogRef.componentInstance.oldTeamId = this.TeamID;
      dialogRef.componentInstance.oldProcessID = this.ProcessID;
      dialogRef.componentInstance.oldActivityID = this.parentID;
    }

    if (msg === 'new' || msg === 'sub') {
      this.searchtext = '';
      this.ParentName = [];
      this.GetActivity();
    }

    dialogRef.afterClosed().subscribe(result => {

      //  this.rowsOnPageSet=[];
      this.dynamicRows = 10;
      if (this.searchtext !== '' && this.searchtext !== undefined) {
        this.Searchtext();
      }
      else {

        this.GetActivity();
      }

      if (result.toString() === 'true') {
        ;
        let statusMsg = '';
        if (msg === 'new') {
          statusMsg = 'Saved successfully';
        } else {

          statusMsg = 'Updated successfully';
        }


        //  this.rowsOnPageSet=[];
        this.dynamicRows = 10;
        this.ParentName = [];
        this.GetActivity();

        const dialogRefsub = this.dialog.open(ActivityDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: statusMsg + 'æææ' + false,
          //  width: '30%'
        });

        setTimeout(() => {
          this.GetActivity();
        }, 500);
        dialogRefsub.afterClosed().subscribe(result1 => {
          this.ParentName = [];
          if (this.searchtext !== '') {
            this.Searchtext();
          }
          else {
            this.GetActivity();
          }
        });
      }
    });
  }


  EditActivity(element) {
    this.GetProcess();
    for (let i = 0; i < this.processData.length; i++) {
      if (this.processData[i].ProcessID === element.ProcessID) {
        this.TeamID = this.processData[i]['TeamID'];
      }
    }
    this.parentID = element.ParentID;
    this.ActivityID = element.ActivityID;
    this.ActivityName = element.ActivityName;
    this.ProcessID = element.ProcessID;
    this.ActivityTypeID = element.ActivityTypeID;
    this.DepartmentID = element.DepartmentID;
    this.EmployeeID = element.EmployeeID;
    this.TeamID = element.TeamID;
    this.squence = element.squence;
    this.storage.store("ProcessID", this.ProcessID);
    this.storage.store("ActivityID", this.ActivityID);
    this.actData = this.storage.retrieve("actData");
    for (let i = 0; i < this.actData.length; i++) {
      if (this.TeamID === this.actData[i].TeamID) {
        this.sequenceList.push(Number(this.actData[i].squence));
      }
    }
    this.dialogCall('edit');
  }

  GetPointer() {
    this.dorseyService.GetPointer(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.pointerData = subData;
    });
  }

  ifMaxRow() {
    if (this.rowsOnPage == this.activityData.length) {
      this.rowsOnPage = this.rowsOnPage - 1;
    }
  }

  DeleteActivity(element) {
    this.ActivityID = element.ActivityID;
    this.CompanyID = element.CompanyID;
    this.TeamID = element.TeamID;
    this.squence = element.squence;
    this.ProcessID = element.ProcessID;
    this.parentID = element.ParentID;
    const dialogRef = this.dialog.open(ActivityDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'Are you sure you want to delete activity: ' + element.ActivityName + '?æææ' + true,

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dorseyService.DeleteActivity(this.ActivityID, this.CompanyID, this.TeamID, this.squence, this.ProcessID, this.parentID).subscribe(data => {
          if (data.ok) {
            this.ifMaxRow();
            this.condition = true;
            this.dialogCall('Deleted successfully');
          }
          this.dynamicRows = 10;
          this.rowsOnPageSet = [];
          this.GetActivity();
        });
      }
    });
  }
}


@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
  providers: [DorseyService]
})
export class ActivityDialogComponent implements OnInit {

  public breakpoint: number;
  oldTeamId: any;
  oldProcessID: any;
  oldActivityID: any;
  lastSequence: number;
  condition = false;
  activityData: any;
  departmentData: any;
  employeeData: any;
  processData: any;
  CompanyID: string;
  ProcessID: string;
  DepartmentID: string;
  EmployeeID: number;
  ActivityTypeID: string;
  activityTypeData: any;
  dataSelf: string;
  headerText: string;
  ActivityID: string;
  ParentActivityID = 0;
  ActivityName: string;
  processCtrl = new FormControl();
  filteredProcess: Observable<any[]>;
  tempActivity = [];
  tempDepart: any;
  keyActivityTeam: any;
  teamData: any;
  currentUser: string;
  ProcessByTeam = [];
  TeamIDByProcess: string;
  TeamID: number;
  conditionActivity = '';
  squence: number;
  sequenceList: number[] = [];
  ParentName = [];
  originalData: any;
  actData: any;
  btnDisabled = false;
  constructor(
    public dialogRef: MatDialogRef<ActivityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dorseyService: DorseyService, private storage: LocalStorageService) {
    this.data = this.data.split('æææ');

    if (this.data[1] === 'true') {
      this.condition = true;
    } else if (this.data[1] === 'false') {
      this.condition = false;
    }
    else {
      if (this.data[1] === '') {
        this.conditionActivity = 'process';
      } else {
        this.conditionActivity = 'activity';
      }
    }

    if (this.data[0].toString() === 'Deleted successfully' || this.data[0].toString() === "This Activity have issues or metrics or sub activity") {
      this.condition = false;
    }
    this.data = this.data[0];
  }


  ngOnInit() {

    this.breakpoint = window.innerWidth <= 900 ? 1 : 3;
    if (this.conditionActivity === 'process' || this.conditionActivity === 'activity') {

      for (let i = 0; i < this.activityData.length; i++) {

        if (this.activityData[i].ProcessID === this.ProcessID && this.activityData[i].ParentID === this.ParentActivityID && this.activityData[i].TeamID === this.TeamID) {
          this.sequenceList.push(Number(this.activityData[i].squence));
        }
      }

      let seq = this.sequenceList.length + 1;
      if (this.sequenceList.length !== 0) {
        this.sequenceList.push(seq);
      }
      else this.sequenceList.push(1);
      this.squence = this.sequenceList[this.sequenceList.length - 1]
        ;
    }

    if (this.data === 'edit' || this.data === 'rearrange') {
      this.tempActivity = [];
      this.sequenceList = [];

      if (this.data === 'edit') {
        this.activityData = this.actData;
      }
      for (let i = 0; i < this.activityData.length; i++) {
        if (this.activityData[i].ProcessID === this.ProcessID && this.activityData[i].ParentID === 0) {
          this.tempActivity.push(this.activityData[i]);
        }
      }
      for (let i = 0; i < this.activityData.length; i++) {
        if (this.activityData[i].ProcessID === this.ProcessID && this.activityData[i].ParentID === this.ParentActivityID && this.activityData[i].TeamID === this.TeamID) {
          this.sequenceList.push(this.activityData[i].squence);
        }
      }
    }
    this.sequenceList.sort();
    this.sequenceList.sort(this.sortNumber);
    this.lastSequence = this.sequenceList.length;
  }
  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 1000) ? 1 : 3;
  }
  sortNumber(a, b) {
    return a - b;
  }
  Clear() {
    this.dialogRef.close(false);
  }
  process(event) {
    this.TeamID = 0;
    if (this.ParentActivityID !== 0) {
      this.squence = null;
      this.ParentActivityID = null;

    }
    this.dorseyService.GetActivity(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.activityData = subData;
      this.tempActivity = [];
      this.ProcessByTeam = [];
      this.sequenceList = [];
      this.ProcessID = event.value;
      this.activityData.sort(function (a, b) {
        return a.squence - b.squence;
      });
      for (let i = 0; i < this.processData.length; i++) {
        if (this.processData[i].ProcessID === this.ProcessID) {
          this.TeamID = this.processData[i]['TeamID'];
        }
      }
      for (let i = 0; i < this.activityData.length; i++) {

        if (this.activityData[i].ProcessID === this.ProcessID && this.activityData[i].ParentID === 0 && this.TeamID === this.activityData[i].TeamID) {
          this.tempActivity.push(this.activityData[i]);

        }

        if (this.data !== 'sub' && this.ParentActivityID === 0) {
          if (this.activityData[i].ProcessID === this.ProcessID && this.activityData[i].ParentID === this.ParentActivityID && this.TeamID === this.activityData[i].TeamID) {
            this.sequenceList.push(this.activityData[i].squence);
          }
        }
      }
      if (this.ProcessID != this.oldProcessID) {
        let seq = this.sequenceList.length + 1;
        if (this.data !== 'sub' && this.ParentActivityID === 0) {
          if (this.sequenceList.length !== 0) {
            this.sequenceList.push(seq);
          }
          else this.sequenceList.push(1);
        }
      }
      if (this.data !== 'sub' && this.ParentActivityID === 0) {
        this.squence = this.sequenceList[this.sequenceList.length - 1];
        this.lastSequence = this.sequenceList.length;
      }
    });

  }
  bindSequence() {

    this.dorseyService.GetActivity(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.activityData = subData;
      this.activityData.sort(function (a, b) {
        return a.squence - b.squence;
      });

      this.sequenceList = [];
      for (let i = 0; i < this.activityData.length; i++) {
        if (this.activityData[i].ProcessID === this.ProcessID && this.activityData[i].ParentID === this.ParentActivityID && this.activityData[i].TeamID === this.TeamID) {
          this.sequenceList.push(this.activityData[i].squence);
        }
      }

      if (this.ParentActivityID != this.oldActivityID) {
        let seq = this.sequenceList.length + 1;
        if (this.sequenceList.length !== 0) {
          this.sequenceList.push(seq);
        }
        else this.sequenceList.push(1);
      }

      this.squence = this.sequenceList[this.sequenceList.length - 1];
      this.lastSequence = this.sequenceList.length;
    });
  }
  activityType(event) {
    this.ActivityTypeID = event.value;
  }
  department(event) {
    this.DepartmentID = event.value;
  }
  employee(event) {
    this.EmployeeID = event.value;

    let tempEmp = this.employeeData.filter(x => x.EmployeeID === this.EmployeeID);

    if (tempEmp.length > 0 && tempEmp.length === 1) {
      this.tempDepart = this.departmentData.filter(x => x.DepartmentID === tempEmp[0].DepartmentID);
      this.DepartmentID = this.tempDepart[0].DepartmentID;

    }
  }
  AddActivity() {
    this.btnDisabled = true;

    setTimeout(() => {
      this.btnDisabled = false;
    }, 1000);
    let count = 0;
    const Editcount = 0;
    if (this.data === 'sub' && !this.ParentActivityID || !this.squence) {
      this.dataSelf = 'Please enter mandatory(*) fields';
      return
    }
    if (this.ActivityName === undefined || this.ActivityTypeID === undefined || this.ProcessID === undefined ||

      this.ActivityName.trim() === '' || this.ActivityTypeID === '' || this.ProcessID === '') {
      // this.DepartmentID === undefined || this.EmployeeID === undefined || || this.DepartmentID === '' || this.EmployeeID === ''
      this.dataSelf = 'Please enter mandatory(*) fields';
      if (this.data === 'new') {

      }
      count++;
    }

    if (count === 0 && Editcount === 0) {
      this.dataSelf = '';
      if (this.data === 'new') {
        this.Saving(this.data);
      } else {
        this.Saving(this.data);
      }
    } else {

    }
  }
  GetTempActivity(ProcessID: string) {
    for (let i = 0; i < this.activityData.length; i++) {

      if (this.activityData[i].ProcessID === ProcessID && this.activityData[i].ParentID === 0) {
        this.tempActivity.push(this.activityData[i]);
      }
    }
  }
  onYesClick() {

    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  Saving(msg: string) {

    if (this.data === 'sub' && !this.ParentActivityID || !this.squence) {
      this.dataSelf = 'Please enter mandatory(*) fields';
      return
    }


    const json = {
      CompanyID: this.CompanyID,
      // CompanyID: "1",

      ActivityID: this.ActivityID,
      // TeamID: this.teamID,
      ParentID: this.ParentActivityID,
      ActivityName: this.ActivityName,
      ProcessID: this.ProcessID,
      ActivityTypeID: this.ActivityTypeID,
      // DepartmentID: this.DepartmentID,
      // EmployeeID: this.EmployeeID,
      DepartmentID: "0",
      EmployeeID: this.EmployeeID,
      CreatedBy: this.currentUser,
      UpdatedBy: this.currentUser,
      TeamIDs: this.TeamID,
      squence: this.squence,
      type: msg,
      lastSequence: this.lastSequence
    };
    if (msg === 'new' || msg === 'sub') {
      this.dorseyService.SaveActivity(json, 'No').subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.dataSelf = 'Saved succesfully';
          this.ActivityName = '';
          this.ActivityTypeID = '';
          this.squence = this.sequenceList[this.sequenceList.length - 1] + 1;
          this.sequenceList.push(this.squence);
        } else {
          this.dataSelf = 'Save failed';
        }
      });
    } else {
      this.lastSequence = this.sequenceList.length;
      this.dorseyService.SaveActivity(json, 'No').subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Updated successfully';
        }
      });
    }
  }
  IsAlpha(evt) {
    var keyCode = (evt.which) ? evt.which : evt.keyCode
    if ((keyCode < 65 || keyCode > 90) && (keyCode < 97 || keyCode > 123) && keyCode != 32) {
      return false;
    }
    return true;
  }


}
