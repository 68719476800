import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { IResponsePackage } from "../models/response-package.model";
import { environment } from 'src/environments/environment';
import { CompanyDetails } from '../company/company.model';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    private httpUrl: string = environment.API_URL;
    constructor(private http: HttpClient) { }


    getSubCompanyDetailsByCompanyId(companyId: number): Observable<IResponsePackage> {
        return this.http.get<IResponsePackage>(`${this.httpUrl}Dorsey/GetSubCompanyDetailsByCompanyId?companyId=${companyId}`)
            .pipe(
                catchError(this.handleError)
            );
    }

    getSubCompaniesCountByCompanyId(companyId: number): Observable<IResponsePackage> {
        return this.http.get<IResponsePackage>(`${this.httpUrl}Dorsey/GetSubCompaniesCountByCompanyId?companyId=${companyId}`)
            .pipe(
                catchError(this.handleError)
            );
    }

    saveCompany(entity: CompanyDetails): Observable<any> {
        return this.http.post(`${this.httpUrl}Dorsey/SaveCompany`, entity).pipe(
            catchError(this.handleError)
        );
    }

    deleteCompany(companyId: number): Observable<any> {
        return this.http.get(`${this.httpUrl}Dorsey/DeleteCompany?companyId=${companyId}`).pipe(
            catchError(this.handleError)
        );
    }

    UpdateEnableScoreCard(entity: CompanyDetails): Observable<any> {
        return this.http.post(`${this.httpUrl}Dorsey/UpdateEnableScoreCard`, entity).pipe(
            catchError(this.handleError)
        );
    }

    getUserDetailsByEmailId(emailId: string): Observable<IResponsePackage> {
        return this.http.get<IResponsePackage>(`${this.httpUrl}Dorsey/GetUserDetailsByEmailId?emailId=${emailId}`)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occured: ${err.error.message}`;
        }
        else {
            errorMessage = `Server returned code: ${err.status}, error message is ${err.message}`
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}
