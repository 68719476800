import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LocalStorageService } from 'ngx-webstorage';
import { MessageDialogComponent } from '../common/dialog';
import { PerformanceScoreCardService } from '../performance-score-card/performance-score-card.service';
import { QuarterService } from '../services/quarter.service';
import { Month, WeekDay, QuarterStartDay } from './quarter.model';

@Component({
  selector: 'app-quarter',
  templateUrl: './quarter.component.html',
  styleUrls: ['./quarter.component.scss']
})
export class QuarterComponent implements OnInit {
  errorMsg: string = '';
  months: Array<Month>;
  selectedMonthId: number;
  selectedWeekDayId: number;
  quarterList: any;
  currentUser: string;
  companyId: number;
  quarterWeekDays: Array<WeekDay>;
  selectedWeekDate: string;
  isScoreCardsExist: boolean = false

  quarterWeekStartDate: string[] = [QuarterStartDay.StartDate, QuarterStartDay.EndDate];
  MenuPermissions: any;
  menuData: any;
  menuID: string;
  menuName: string;
  employeeDetails: any;
  constructor(private storage: LocalStorageService,
    private quarterService: QuarterService,
    public dialog: MatDialog) {
    this.currentUser = this.storage.retrieve('user');
    this.menuData = this.storage.retrieve('menuData');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
    this.employeeDetails = this.storage.retrieve('employeeDetails');
  }

  ngOnInit(): void {
    this.menuData = this.menuData.filter(x => x.MenuName.toString().toLowerCase() === 'score card');
    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }

    this.companyId = this.storage.retrieve('companyID');
    this.months = this.quarterService.getMonths();
    this.quarterWeekDays = this.quarterService.getQuarterWeekDays();

    this.selectedWeekDayId = this.quarterWeekDays.find(val => val.WeekDayId == 1).WeekDayId;
    this.selectedWeekDate = this.quarterWeekStartDate.find(val => val == 'Start Date');
    this.quarterService.getQuarters(this.companyId).subscribe(resp => {
      if (resp.Data.length > 0) {
        this.selectedMonthId = this.months.find(val => val.MonthName == resp.Data[0].MonthName).MonthId;
        this.selectedWeekDayId = this.quarterWeekDays.find(val => val.WeekDayId == resp.Data[0].QuarterWeekDay).WeekDayId;
        this.selectedWeekDate = this.quarterWeekStartDate.find(val => val == resp.Data[0].QuarterWeekDate);
        this.quarterList = this.quarterService.generateQuarters(this.selectedMonthId, this.companyId, this.currentUser, this.selectedWeekDayId, this.selectedWeekDate);
      }
      this.isScoreCardsExist = resp.AdditionalData.ScoreCardExistForCompany;
    });
  }

  saveQuarters(): void {
    if (!this.selectedMonthId) {
      this.errorMsg = 'Please enter mandatory(*) fields';
      return;
    }
    this.errorMsg = '';
    this.quarterList = this.quarterService.generateQuarters(this.selectedMonthId, this.companyId, this.currentUser, this.selectedWeekDayId, this.selectedWeekDate);
    this.quarterService.saveQuarters(this.quarterList).subscribe(resp => {
      if (resp.Data) {
        this.dialog.open(MessageDialogComponent);
      }
    });
  }

  monthChange(selectedMonthId): void {
    this.selectedMonthId = selectedMonthId;
    this.quarterList = this.quarterService.generateQuarters(selectedMonthId, this.companyId, this.currentUser, this.selectedWeekDayId, this.selectedWeekDate);
  }

  weekDayChange(selectedWeekDayID): void {
    this.selectedWeekDayId = selectedWeekDayID;
  }

  weekDateChange(selecteweekDate): void {
    this.selectedWeekDate = selecteweekDate;
  }
}
