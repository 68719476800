import { Component, OnInit } from '@angular/core';
import { DorseyService } from '../dorsey.service';

import { LocalStorageService } from 'ngx-webstorage';
import { EmployeeLoginLevel } from '../common/models/login-level.model';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-frustration-comments',
  templateUrl: './frustration-comments.component.html',
})
export class FrustrationCommentsComponent implements OnInit {
  copyRight: string = copyRight;
  employeeDetails: any;
  commentData: any;
  searchtext: string;
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  processPaging = false;
  description = "";
  descriptionList = [];
  mainData = [];
  saveFilter = [];
  CompanyID: string;
  isDisable = false;
  disableSearch = false;
  wrng: string;
  teamData: any;
  TeamID: any;
  filterData: any;
  companyName: string;
  headerDate = new Date();
  tempTeam = [];
  TeamName: string;
  EmployeeID: string;
  employeeData: any;
  copyDate: number;
  constructor(private dorseyService: DorseyService, private storage: LocalStorageService) { }

  ngOnInit() {
    this.CompanyID = this.storage.retrieve('companyID');
    this.companyName = this.storage.retrieve('LoginCompanyName');
    this.TeamID = this.storage.retrieve('TeamID');
    this.EmployeeID = this.storage.retrieve('EmployeeID');
    this.employeeDetails = this.storage.retrieve('employeeDetails');
    this.GetCommentsOnFrustration();
    this.GetTeam();
    if (this.employeeDetails) {
      this.EmployeeID = this.employeeDetails.EmployeeID;
      this.TeamID = this.employeeDetails.TeamID;
    }
    else {
      this.EmployeeID = '0';
      this.TeamID = 0;
    }
    this.copyDate = (new Date()).getFullYear();
  }

  GetSelectedReport(event) {
    this.searchtext = '';
    this.TeamID = event.value;
    let teamd = this.teamData.filter(x => x.TeamID === this.TeamID)
    if (teamd.length > 0) {
      this.TeamName = teamd[0].TeamName;
    }
    this.ConstructReport();
  }

  GetTeam() {
    this.TeamName = '';
    this.dorseyService.GetTeam(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.dorseyService.GetCommentsOnFrustration(this.CompanyID).subscribe(subDat1 => {
        subDat1 = JSON.parse(subDat1['_body']);
        this.commentData = subDat1;
        this.teamData = subData;
        if (this.employeeDetails) {
          if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) {
            this.teamData = this.teamData.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID);
          }
          else if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
            this.teamData = this.teamData.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID && ent.TeamID == this.employeeDetails.TeamID);
            this.TeamID = this.teamData[0].TeamID;
            this.TeamName = this.teamData[0].TeamName;
          }
        }
        if (this.TeamID == 0) {
          this.teamData = this.teamData.filter(entity1 => this.commentData.find(entity2 => entity1.TeamID == entity2.TeamID));
        }
        if (this.teamData.length > 0 && !this.TeamID) { this.teamData.splice(0, 0, { TeamID: 0, TeamName: 'Aggregate Report' }); }
        this.ConstructReport();
      });
    });
  }

  ConstructReport() {
    this.mainData = [];
    if (this.TeamID == 0)
      this.filterData = this.commentData.filter(entity1 => this.teamData.find(entity2 => entity1.TeamID == entity2.TeamID));
    else {
      this.filterData = this.commentData.filter(entity1 => entity1.TeamID == this.TeamID);
    }
    if (this.filterData) {
      for (let i = 0; i < this.filterData.length; i++) {
        this.description = '';
        this.descriptionList = [];
        if (this.filterData[i].Comment !== null) {

          this.description = this.filterData[i].Comment.split(",");
          if (this.description.toString().trim() != '') {
            for (let j = 0; j < this.description.length; j++) {

              if (this.description[j].toString().trim() !== '') {
                this.descriptionList.push(this.description[j]);
              }
            }
          }
        }
        if (this.descriptionList.toString() !== '') {

          this.mainData.push({
            'comment': this.descriptionList,
            'name': this.filterData[i].FrustrationsTypeName,
            'TeamID': this.filterData[i].TeamID,
            'TeamName': this.filterData[i].TeamName
          });
        }
      }
    }
    else {
      this.processPaging = false;
      this.isDisable = true;
      this.disableSearch = true;
      this.wrng = "No data available";
      return;
    }
    this.saveFilter = this.mainData;
    if (this.mainData.length === 0) {
      this.processPaging = false;
      this.isDisable = true;
      this.disableSearch = true;
      this.wrng = "No data available";
    }
    else {
      this.rowCount = this.mainData.length;
      this.processPaging = true;
      this.isDisable = false;
      this.disableSearch = false;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount)) this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet.length > 0) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }

  GetCommentsOnFrustration() {
    this.mainData = [];
    this.descriptionList = [];
    this.dorseyService.GetCommentsOnFrustration(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.commentData = subData;
    });
  }


  Searchtext() {
    this.mainData = JSON.parse(JSON.stringify(this.saveFilter));
    let tempComment = this.mainData.filter(
      (item) => {
        return (
          (item.name != null && item.name.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.TeamName != null && item.TeamName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.comment != null && (item.comment.filter(
            (da) => {
              return (
                da != null && da.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1
              )
            }
          )
          )
          ).length
        )
      });
    this.mainData = tempComment;
    if (this.mainData.length == 0) {
      this.wrng = "No data available";
      this.isDisable = true;
      return;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.mainData = this.saveFilter;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      this.rowCount = this.mainData.length;
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount))
        this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet) {

        this.rowsOnPage = this.rowsOnPageSet[0];
      }
      this.isDisable = false;
    } else {
      this.rowCount = tempComment.length;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount)) {
        this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }

      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }


  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }
  exportAsXLSX() {
    let allData = JSON.parse(JSON.stringify(this.mainData));
    for (let i = 0; i < allData.length; i++) {
      allData[i][" No. "] = i + 1;
      allData[i].Team = allData[i].TeamName;
      allData[i].Frustration = allData[i].name;
      let comment = '';
      let Data = allData[i].comment;
      for (let i = 0; i < Data.length; i++) {
        comment = comment + Data[i] + '\n';
      }
      allData[i].Comment = comment;
      delete allData[i].name;
      delete allData[i].comment;
      delete allData[i].TeamName;
      delete allData[i].TeamID;
    }
    let name: any;
    if (this.TeamName) {
      name = this.TeamName;
    }
    else {
      name = "Aggregate Report";
    }
    this.dorseyService.generateExcel(allData, this.companyName + '_Comments on frustrations' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Comments on frustrations', "Company: " + this.companyName, "Team: " + name, "Comments on frustrations Report", copyRight, 'FFFFFF00', 'FF0000FF');
  }
}
