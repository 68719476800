import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material';
import { DorseyService } from '../dorsey.service';
import { PointerDialogComponent } from '../pointer/pointer.component';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { LocalStorageService } from 'ngx-webstorage';
import { DatePipe } from '@angular/common';
import { EmployeeLoginLevel } from '../common/models/login-level.model';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  //styleUrls: ['./project.component.scss'],
  providers: [DorseyService, DatePipe]
})
export class ProjectComponent implements OnInit {
  copyRight = copyRight;
  employeeDetails: any;
  ProjectID: string;
  ProjectName: string;
  ProjectDescription: string;
  projectData: any;
  headerText: string;
  condition = false;
  showDiv = false;

  frustData: any;
  rowSelected: number;
  errorMsg: string;
  sortedData: [];
  searchtext: string;
  companyData: any;
  StatusID: string;
  statusData: any;
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;
  tempFrustData = [];
  processData: any;
  activityData: any;

  pointerTypeData: any;
  severityData: any;
  teamData: any;
  employeeData: any;
  frustrationtypeData: any;
  tempFrustrationType: any;
  pointerData: any;
  ProcessID: string;
  tempActivity = [];
  ActivityID: string;
  FrustrationTypeID: string;
  pointerFrustration: any;
  tableFormat = false;
  kenbanFormat = false;
  displayAs: string;
  kenvanView = [];
  kenvan = {};
  weeks = [];
  connectedTo = [];
  showpgtn = true;
  statusidlist = "";
  StatusDic = {};
  statusname = "";
  projectPaging = false;
  saveFilter = [];
  CompanyID: string;
  isDisable = false;
  disableSearch = false;
  disableKenban = false;
  CurrentUser: string;
  MenuPermissions: any;
  menuData: any;
  menuID: string;
  menuName: string;
  ProjectDueDate: Date;
  loginCompanyName: any;
  Date = new Date();
  userTeamID: any;
  TeamName: any;
  empID: any;
  userRoleAssignmentData: any;

  UserID: any;

  updatedDate = new Date();
  updatedBy: any;
  TeamID: string;
  ProjectOwner: string;
  ifTouchDevice = false;
  @ViewChild('button', { static: false }) button: ElementRef;
  longPress = 'first state';
  longPressing = 0;
  isLongPressed = false;
  ifLongPress = true;
  constructor(private dorseyService: DorseyService, public dialog: MatDialog, private storage: LocalStorageService, private datepipe: DatePipe) {
    this.CurrentUser = this.storage.retrieve('user');
    this.menuData = this.storage.retrieve('menuData');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
    this.loginCompanyName = this.storage.retrieve('loginCompanyName');
    this.userTeamID = this.storage.retrieve('TeamID');
    this.CompanyID = this.storage.retrieve('companyID');
    this.empID = this.storage.retrieve('EmployeeID');
    // console.log(this.CurrentUser,this.loginCompanyName);

    this.UserID = this.storage.retrieve('UserID');
    this.employeeDetails = this.storage.retrieve('employeeDetails');
    this.GetTeam();
    this.GetStatus();

  }
  ngOnInit() {


    this.tableFormat = true;

    this.menuData = this.menuData.filter(x => x.MenuName.toString().toLowerCase() === 'projects');
    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }


    this.GetActivity();
    // this.GetProcess();
    setTimeout(() => {


      this.GetProjects();
    }, 100);
    this.GetPointer();
    this.GetEmployee();

    this.GetPointerType();
    this.GetSeverity();


    this.GetFrustrationType();


  }

  onLongPress() {
    // this.longPressing = null;
    // if (this.isLongPressed) this.button.nativeElement.style.backgroundColor = 'green';
    // else this.button.nativeElement.style.backgroundColor = 'orange';
    // this.isLongPressed = !this.isLongPressed;
    // this.longPress = this.isLongPressed ? 'second state' : 'first state';
  }

  onLongPressing() {
    // console.log("method called" + this.ifLongPress);

    this.longPressing += 1;
    // console.log(this.longPressing);
    if (this.longPressing > 20) {
      this.ifLongPress = false;
      //  console.log(this.ifLongPress);
    } else {
      this.ifLongPress = true;
      // console.log(this.ifLongPress);
    }
  }
  onMouseUp() {
    // console.log("mouseup");
    this.longPressing = 0;
    this.ifLongPress = true;
  }

  onDblClick() {
    // console.log("double click");
    this.ifLongPress = false;
  }


  GetPointer() {
    this.dorseyService.GetPointer(this.CompanyID).subscribe(subData => {

      subData = JSON.parse(subData['_body']);
      this.pointerData = subData;
      this.pointerFrustration = this.pointerData.filter(x => x.PointerTypeID != null && x.PointerTypeID.toString() === "1")

    });
  }

  exportAsXLSX() {
    this.tableFormat = true;
    this.kenbanFormat = false;
    let allData = JSON.parse(JSON.stringify(this.projectData));
    //console(allData);
    for (let i = 0; i < allData.length; i++) {
      allData[i]["Project Team"] = allData[i].TeamName;
      allData[i].Project = allData[i].ProjectName;
      allData[i].Description = allData[i].ProjectDescription;
      allData[i].Status = allData[i].ProjectStatusName;
      allData[i]["Project Owner"] = allData[i].ProjectOwner;


      if (allData[i].ProjectDueDate !== null) {
        allData[i][" Due Date "] = this.datepipe.transform(allData[i].ProjectDueDate, 'MM/dd/yyyy');
      } else {
        allData[i][" Due Date "] = null;
      }


      delete (allData[i].DepartmentID);
      delete (allData[i].ProcessID);
      delete (allData[i].CompanyID1);
      delete (allData[i].IsActive2);
      delete (allData[i].CreatedDate2);
      delete (allData[i].CreatedBy2);
      delete (allData[i].UpdatedBy2);
      delete (allData[i].UpdatedDate2);
      delete (allData[i].TeamDescription);
      delete (allData[i].TeamName1);

      delete (allData[i].TeamID);
      delete (allData[i].TeamID1);
      delete (allData[i].ProjectName);
      delete (allData[i].ProjectStatusName);
      delete (allData[i].CompanyID);
      delete (allData[i].ProjectDescription);
      delete (allData[i].ProjectDueDate);
      delete (allData[i].ProjectOwner);
      delete (allData[i].TeamName);
      delete (allData[i].ProjectID);
      delete (allData[i].StatusID);
      delete (allData[i].RowID);
      delete (allData[i].ProjectStatusID);
      delete (allData[i].UpdatedBy);
      delete (allData[i].UpdatedDate);
      delete (allData[i].IsActive);
      delete (allData[i].EmployeeID);
      delete (allData[i].CreatedDate);

      delete (allData[i].CreatedBy);
      delete (allData[i].UpdatedDate);
      delete (allData[i].UpdatedBy);

      delete (allData[i].StatusID1);
      delete (allData[i].IsActive1);
      delete (allData[i].CreatedDate1);

      delete (allData[i].CreatedBy1);
      delete (allData[i].UpdatedDate1);
      delete (allData[i].UpdatedBy1);
      delete (allData[i].ProjectStatusName1);
    }

    this.dorseyService.generateExcel(allData, this.loginCompanyName + '_project' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Project', "Company: " + this.loginCompanyName, '', "Project Report", copyRight, 'FFFFFF00', 'FF0000FF');

    // this.dorseyService.exportAsExcelFile(allData,this.loginCompanyName+'_project');
  }
  table() {
    this.tableFormat = true;
    this.kenbanFormat = false;
    this.displayAs = "Table View";
  }
  kenban() {
    this.kenbanFormat = true;
    this.tableFormat = false;
    this.displayAs = "Status view";
    this.GetProjects();
  }

  GetEmployee() {
    this.dorseyService.GetEmployee(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.employeeData = subData;
    });
  }

  GetPointerType() {
    this.dorseyService.GetPointerType().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.pointerTypeData = subData;
    });
  }

  GetSeverity() {
    this.dorseyService.GetSeverity().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.severityData = subData;
    });
  }

  GetTeam() {
    this.dorseyService.GetTeam(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.teamData = subData;
      if (this.employeeDetails) {
        if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) {
          this.teamData = this.teamData.filter(ent => ent.DepartmentID == this.employeeDetails.DepartmentID)
        }
        else if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
          this.teamData = this.teamData.filter(ent => ent.DepartmentID == this.employeeDetails.DepartmentID && ent.TeamID == this.employeeDetails.TeamID)
          this.TeamName = this.teamData[0].TeamName;
        }
        else {
          this.teamData = subData;
        }
      }
    });
  }

  GetProcess() {
    this.dorseyService.GetProcess(this.CompanyID).subscribe(subData => {

      subData = JSON.parse(subData['_body']);
      this.processData = subData;
    });
  }
  GetActivity() {

    this.dorseyService.GetActivity(this.CompanyID).subscribe(activity => {
      activity = JSON.parse(activity['_body']);
      this.activityData = activity;

    });
  }

  GetPointers() {
    this.dorseyService.GetPointer(this.CompanyID).subscribe(pointer => {
      this.pointerTypeData = JSON.parse(pointer['_body']);
    });
  }

  Searchtext() {
    this.projectData = this.saveFilter;
    let tempProjects = this.projectData.filter(
      (item) => {
        return (
          (item.ProjectName !== null && item.ProjectName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ProjectDescription !== null && item.ProjectDescription.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ProjectStatusName !== null && item.ProjectStatusName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.TeamName !== null && item.TeamName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ProjectOwner !== null && item.ProjectOwner.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
        );
      }
    );
    this.projectData = tempProjects;
    if (this.projectData.length == 0) {
      this.isDisable = true;
      this.disableKenban = true;
      this.projectPaging = false;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.weeks = [];
      this.kenvan = [];
      this.kenvanView = [];
      this.projectData = this.saveFilter;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      this.rowCount = this.projectData.length;
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount))
        this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet) {

        this.rowsOnPage = this.rowsOnPageSet[0];
      }
      this.isDisable = false;
      this.disableKenban = false;
      this.projectPaging = true;
    } else {
      // console.log(tempProjects.length);
      this.rowCount = tempProjects.length;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount)) {
        this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }

      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }
  OpenProject() {
    this.dialogCall('new');
  }

  GetProjects() {
    this.weeks = [];
    this.kenvan = [];
    this.kenvanView = [];

    this.dynamicRows = 10;
    this.rowsOnPageSet = [];


    //console(this.weeks);
    //console(this.kenvan);
    //console(this.kenvanView);

    this.dorseyService.GetProjects(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.projectData = subData;
      let tempProjectData = this.projectData;
      // if(this.userTeamID===null || this.userTeamID===undefined)
      // {
      // this.userTeamID="0";
      // }

      if (this.projectData.length === 0) {
        this.projectPaging = false;
        this.isDisable = true;
        this.disableSearch = true;
        this.disableKenban = true;
      }

      else {
        this.saveFilter = JSON.parse(JSON.stringify(this.projectData));
        this.projectPaging = true;
        this.isDisable = false;
        this.disableSearch = false;
        this.disableKenban = false;

        // if (this.userTeamID.toString() !== "0" && this.userTeamID !== null && this.CompanyID.toString() !== "0") {
        //   let dtaa = this.projectData.filter(x => x.TeamID === this.userTeamID && x.CompanyID === this.CompanyID);
        //   this.projectData = dtaa;
        // }
        // else {
        //   let dtaa = this.projectData.filter(x => x.CompanyID === this.CompanyID);
        //   this.projectData = dtaa;
        // }
        // this.saveFilter = JSON.parse(JSON.stringify(this.projectData));
        this.rowsOnPageSet = [];
        this.dynamicRows = 10;
        this.rowCount = this.projectData.length;
        if (this.projectData == "") {
          this.showpgtn = false;
        }

        while (this.dynamicRows < this.rowCount) {
          this.rowsOnPageSet.push(this.dynamicRows);
          this.dynamicRows = this.dynamicRows + 10;
        }
        if (!this.rowsOnPageSet.includes(this.rowCount))
          this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet.length === 1) {
          if (this.rowsOnPageSet[0] <= 10)
            this.rowsOnPage = this.rowsOnPageSet[0];
        }

      }
      //console(this.projectData);
      if (!this.projectData)
        return;
      for (let k = 0; k < this.statusData.length; k++) {
        this.kenvanView = [];

        for (let j = 0; j < this.projectData.length; j++) {

          if (this.statusData[k].StatusID === this.projectData[j].StatusID) {

            this.kenvanView.push(this.projectData[j]);
          }
        }
        this.kenvan['id'] = this.statusData[k].ProjectStatusName;
        this.kenvan['weeklist'] = this.kenvanView;

        // console.log(this.kenvanView);
        this.weeks.push({
          'id': this.statusData[k].ProjectStatusName,
          'weeklist': this.kenvanView,
          'statusID': this.statusData[k].ProjectStatusID,

          'count': this.kenvanView.length
        });
      }
      //console(this.weeks);

      for (let week of this.weeks) {
        this.connectedTo.push(week.id);
      };



    });
    // console.log(this.weeks);
  }


  drop(event: CdkDragDrop<string[]>, statusID: string) {
    //console(statusID);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.SaveProjects(event.container.data, statusID);

    }
    // this.GetCase();
  }


  SaveProjects(dataSave: any, statusID: string) {
    this.ProjectID = dataSave[0].ProjectID;
    const json = {
      ProjectID: dataSave[0].ProjectID,
      ProjectName: dataSave[0].ProjectName,
      ProjectDescription: dataSave[0].ProjectDescription,
      CreatedBy: this.CurrentUser,
      UpdatedBy: this.CurrentUser,
      StatusID: statusID,
      CompanyID: dataSave[0].CompanyID,
      ProjectDueDate: dataSave[0].ProjectDueDate,
      TeamID: dataSave[0].TeamID,
      ProjectOwner: dataSave[0].ProjectOwner
    };

    this.dorseyService.SaveProjects(json).subscribe(data => {
      if (data['_body'].toString() === 'true') {
        this.dorseyService.GetFrustration(this.ProjectID, this.CompanyID).subscribe(subData => {
          subData = JSON.parse(subData['_body']);
          this.frustData = subData;
          const json = {
            ProjectID: this.ProjectID,
            CompanyID: this.CompanyID,
            pointerData: this.frustData,
            ProjectDescription: dataSave[0].ProjectDescription,
            ProjectName: dataSave[0].ProjectName,
            ProjectDueDate: dataSave[0].ProjectDueDate,
            UpdatedBy: this.CurrentUser
          }
          this.dorseyService.UpdatePointerProject(json, 'No', statusID).subscribe(data => {
            this.weeks = [];
            this.kenvan = [];
            this.kenvanView = [];
            this.GetProjects();
          });
        });
      }
      else {

      }
    });
    this.weeks = [];
    this.kenvan = [];
    this.kenvanView = [];
    this.GetPointer();
    this.GetProjects();
  }
  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  GetStatus() {
    this.weeks = [];
    this.kenvan = [];
    this.kenvanView = [];

    this.dorseyService.GetProjectStatus().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.statusData = subData;
      this.StatusID = this.statusData[0].ProjectStatusID;
      this.dorseyService.GetPointer(this.CompanyID).subscribe(subData => {

        subData = JSON.parse(subData['_body']);
        this.pointerData = subData;

        this.dorseyService.GetProjects(this.CompanyID).subscribe(subData => {
          subData = JSON.parse(subData['_body']);
          this.projectData = subData;
          for (let k = 0; k < this.statusData.length; k++) {
            let statuslist = this.statusData[k].StatusName;
            let ststuList = [];
            ststuList.push(this.statusData[k].StatusID);
            for (let i = 0; i < this.projectData.length; i++) {
              for (let j = 0; j < this.pointerData.length; j++) {
                if (this.pointerData[j].ProjectID) {
                  if (this.projectData[i].ProjectID == this.pointerData[j].ProjectID) {
                    this.statusname = this.pointerData[j].StatusName;
                    for (let t = 0; t < ststuList.length; t++) {
                      if (this.pointerData[j].StatusID == ststuList[t]) {
                        const json = {
                          ProjectID: this.projectData[i].ProjectID,
                          ProjectName: this.projectData[i].ProjectName,
                          ProjectDescription: this.projectData[i].ProjectDescription,
                          CreatedBy: this.CurrentUser,
                          UpdatedBy: this.CurrentUser,
                          StatusID: this.statusData[k].ProjectStatusID,
                          ProjectDueDate: this.projectData[i].ProjectDueDate,
                          TeamID: this.projectData[i].TeamID,
                          ProjectOwner: this.projectData[i].ProjectOwner,
                        };
                        this.dorseyService.SaveProjects(json).subscribe(data => {
                        });
                        break;
                      }
                    }
                  }
                }
              }
            }
          }
        });
      });
    });
  }
  dialogCall(msg: string) {
    const dialogRef = this.dialog.open(ProjectDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: msg + 'æææ' + false,
    });
    dialogRef.componentInstance.CurrentUser = this.CurrentUser;
    dialogRef.componentInstance.projectData = this.projectData;
    dialogRef.componentInstance.statusData = this.statusData;
    dialogRef.componentInstance.CompanyID = this.CompanyID;
    dialogRef.componentInstance.teamData = this.teamData;
    dialogRef.componentInstance.ProjectDueDate = null;

    if (msg === 'edit') {

      this.headerText = 'Edit Project';
      dialogRef.componentInstance.ProjectID = this.ProjectID;
      dialogRef.componentInstance.ProjectName = this.ProjectName;
      dialogRef.componentInstance.ProjectDescription = this.ProjectDescription;
      dialogRef.componentInstance.StatusID = this.StatusID;
      dialogRef.componentInstance.updatedBy = this.updatedBy;
      dialogRef.componentInstance.updatedDate = this.updatedDate;
      dialogRef.componentInstance.TeamID = this.TeamID;
      dialogRef.componentInstance.ProjectOwner = this.ProjectOwner;

      if (this.ProjectDueDate)
        dialogRef.componentInstance.ProjectDueDate = this.ProjectDueDate;
      else dialogRef.componentInstance.ProjectDueDate = null;

    }
    if (msg === 'new') {
      this.StatusID = '';
      this.searchtext = '';
      this.rowsOnPageSet = [];
      this.weeks = [];
      this.kenvan = [];
      this.kenvanView = [];
      if (this.statusData.length > 0) {
        this.StatusID = this.statusData[0].ProjectStatusID;
        dialogRef.componentInstance.StatusID = this.StatusID;
      }
      this.GetProjects();
    }

    dialogRef.afterClosed().subscribe(result => {
      if (this.searchtext !== '') {
        //   this.Searchtext();
      }
      else {
        this.weeks = [];
        this.kenvan = [];
        this.kenvanView = [];
        this.GetProjects();
      }

      if (result.toString() === 'true') {
        let statusMsg = '';
        if (msg === 'new') {
          statusMsg = 'Saved successfully';
        }
        else {
          statusMsg = 'Updated successfully';
        }
        const dialogRefsub = this.dialog.open(ProjectDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: statusMsg + 'æææ' + false,
        });
        setTimeout(() => {
          this.GetProjects();
        }, 500);
        dialogRefsub.afterClosed().subscribe(result1 => {
          this.dynamicRows = 10;
          this.weeks = [];
          this.kenvan = [];
          this.kenvanView = [];
          if (this.searchtext !== '') {
            this.Searchtext();
          }
          else {
            this.GetProjects();
          }
        });
      }

    });
  }
  EditProject(element) {

    this.ProjectID = element.ProjectID;
    this.ProjectName = element.ProjectName;
    this.ProjectDescription = element.ProjectDescription;
    this.StatusID = element.StatusID;
    this.ProjectDueDate = element.ProjectDueDate;
    this.updatedDate = element.UpdatedDate;
    this.updatedBy = element.UpdatedBy;
    this.TeamID = element.TeamID;
    this.ProjectOwner = element.ProjectOwner;
    this.dialogCall('edit');
  }
  updatePointer(msg: string) {
    this.dorseyService.GetFrustration(this.ProjectID, this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.frustData = subData;
      const json = {
        ProjectID: this.ProjectID,
        CompanyID: this.CompanyID,
        pointerData: this.frustData,
        ProjectDueDate: this.ProjectDueDate,
        UpdatedBy: this.CurrentUser
      }
      this.dorseyService.UpdatePointerProject(json, 'No', this.StatusID).subscribe(data => {
      });
    });
  }

  GetFrustrationType() {
    this.dorseyService.GetFrustrationType("1", this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.frustrationtypeData = subData;
    });
  }
  AddIssue(element) {
    const dialogRef = this.dialog.open(PointerDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'edit' + 'æææ' + 'issue',
    });
    this.GetTempActivity(element.ProcessID);
    dialogRef.componentInstance.processData = this.processData;
    dialogRef.componentInstance.activityData = this.activityData;
    dialogRef.componentInstance.pointerTypeData = this.pointerTypeData;
    dialogRef.componentInstance.severityData = this.severityData;
    dialogRef.componentInstance.teamData = this.teamData;
    dialogRef.componentInstance.employeeData = this.employeeData;
    dialogRef.componentInstance.frustrationtypeData = this.frustrationtypeData;
    dialogRef.componentInstance.tempActivity = this.tempActivity;
    dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
    dialogRef.componentInstance.EmployeeID = element.EmployeeID;
    dialogRef.componentInstance.ProcessID = element.ProcessID;
    dialogRef.componentInstance.ActivityID = element.ActivityID;
    dialogRef.componentInstance.PointerTypeID = element.PointerTypeID;
    dialogRef.componentInstance.PointerID = element.PointerID;
    dialogRef.componentInstance.DepartmentID = element.DepartmentID;
    dialogRef.componentInstance.FrustrationTypeIDs = element.FrustrationsTypeID;
    dialogRef.componentInstance.FrustrationTypeID = element.FrustrationsTypeID;
    dialogRef.componentInstance.TeamID = element.TeamID;
    dialogRef.componentInstance.StatusID = element.StatusID;
    dialogRef.componentInstance.SeverityID = element.SeverityID;
    dialogRef.componentInstance.Subject = element.Subject;
    dialogRef.componentInstance.Description = element.Description;
    dialogRef.componentInstance.tempFrustrationType = element.tempFrustrationType;
    dialogRef.componentInstance.ProjectID = element.ProjectID;
    dialogRef.componentInstance.CompanyID = element.CompanyID;
    dialogRef.componentInstance.updatedBy = element.UpdatedBy;
    dialogRef.componentInstance.updatedDate = element.UpdatedDate;
    dialogRef.componentInstance.currentUser = this.CurrentUser;
    dialogRef.afterClosed().subscribe(result => {
      this.GetPointer();
      this.dorseyService.GetFrustration(this.ProjectID, this.CompanyID).subscribe(subData => {
        subData = JSON.parse(subData['_body']);
        this.frustData = subData;
      });
    });
  }
  GetTempActivity(processID) {
    for (let i = 0; i < this.activityData.length; i++) {
      if (this.activityData[i].ProcessID.toString() === processID.toString()) {
        this.tempActivity.push(this.activityData[i]);
      }
    }
  }

  ForceClose(element) {
    const dialogRef = this.dialog.open(ProjectDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'Are you sure you want to force close project: ' + element.ProjectName + '?æææ' + true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dorseyService.GetFrustration(element.ProjectID, this.CompanyID).subscribe(subData => {
          subData = JSON.parse(subData['_body']);
          this.frustData = subData;
          for (let i = 0; i < this.frustData.length; i++) {
            if (this.frustData[i].StatusID.toString() != '4' && this.frustData[i].StatusID.toString() != '5') {
              this.tempFrustData.push(this.frustData[i]);
            }
          }
          const json = {
            ProjectID: element.ProjectID,
            CompanyID: element.CompanyID,
            pointerData: this.tempFrustData,
            ProjectName: element.ProjectName,
            ProjectDescription: element.ProjectDescription,
            CreatedBy: this.CurrentUser,
            UpdatedBy: this.CurrentUser,
            StatusID: "5",
            ProjectDueDate: element.ProjectDueDate
          }

          this.dorseyService.UpdatePointerProject(json, 'No', "5").subscribe(data => {
            this.weeks = [];
            this.kenvan = [];
            this.kenvanView = [];
            this.GetProjects();
            if (data.ok) {
              this.condition = true;
              this.dialogCall('Save successfully');
            }
            this.weeks = [];
            this.kenvan = [];
            this.kenvanView = [];
            this.GetProjects();

            const json1 = {
              ProjectID: element.ProjectID,
              ProjectName: element.ProjectName,
              ProjectDescription: element.ProjectDescription,
              CreatedBy: this.CurrentUser,
              UpdatedBy: this.CurrentUser,
              StatusID: "5",
              CompanyID: this.CompanyID,
              ProjectDueDate: element.ProjectDueDate,
              TeamID: element.TeamID,
              ProjectOwner: element.ProjectOwner
            };

            this.dorseyService.SaveProjects(json1).subscribe(data => {
              if (data['_body'].toString() === 'true') {
                this.weeks = [];
                this.kenvan = [];
                this.kenvanView = [];
                this.GetProjects();
              } else {
              }
            });
          });
        });
      }
    });
  }

  ifMaxRow() {

    if (this.rowsOnPage == this.projectData.length) {
      this.rowsOnPage = this.rowsOnPage - 1;
    }
  }
  DeleteProject(element) {
    this.ProjectID = element.ProjectID;
    const dialogRef = this.dialog.open(ProjectDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'Are you sure you want to delete project: ' + element.ProjectName + '?æææ' + true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dorseyService.DeleteProjects(this.ProjectID).subscribe(data => {
          if (data.ok) {
            this.ifMaxRow();
            this.condition = true;
            this.dialogCall('Deleted successfully');
          }
          this.weeks = [];
          this.kenvan = [];
          this.kenvanView = [];
          this.GetProjects();
        });
      }
    });
  }
  showFrust(element) {
    if (this.rowSelected === -1) {
      this.rowSelected = element.ProjectID;
    }
    else {
      if (this.rowSelected == element.ProjectID) {
        this.rowSelected = -1
      }
      else {
        this.rowSelected = element.ProjectID;
      }
    }
    this.ProjectID = element.ProjectID;
    this.dorseyService.GetFrustration(this.ProjectID, this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.frustData = subData;
      if (this.employeeDetails) {
        if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) {
          this.frustData = this.frustData.filter(entity1 => this.teamData.some(entity2 => entity1.TeamID == entity2.TeamID));
        }
        else if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
          this.frustData = this.frustData.filter(ent => ent.TeamID == this.employeeDetails.TeamID)
        }
        else {
          let data = this.frustData.filter(x => x.CompanyID === this.CompanyID);
          this.frustData = data;
        }
      }
    });
  }
}
@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
  // styleUrls: ['dialog.css'],
  providers: [DorseyService, DatePipe],
})
export class ProjectDialogComponent implements OnInit {
  Date = new Date();
  startDate = new Date();
  validDate = new Date();
  minDate = new Date();
  ProjectID: string;
  ProjectName: string;
  ProjectDescription: string;
  projectData: any;
  condition = false;
  dataSelf: string;
  StatusID: string
  statusData: any;
  frustData: any;
  CompanyID: string;
  CurrentUser: string;
  ProjectDueDate = new Date();
  updatedDate = new Date();
  updatedBy: any;
  teamData: any;
  TeamID: any;
  ProjectOwner: any;
  btnDisabled = false;
  constructor(
    public dialogRef: MatDialogRef<ProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dorseyService: DorseyService, private datepipe: DatePipe) {
    this.data = this.data.split('æææ');
    if (this.data[1] === 'true') {
      this.condition = true;
    } else {
      this.condition = false;
    }
    this.data = this.data[0];
  }
  ngOnInit() {
  }
  Clear() {
    this.dialogRef.close(false);
  }
  dob(type: string, event: MatDatepickerInputEvent<Date>) {
    const date = event.value;
    this.ProjectDueDate = date;
    let latest_date = this.datepipe.transform(this.ProjectDueDate, 'MM/dd/yyyy' + " " + new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds());
    this.ProjectDueDate = new Date(latest_date);
  }

  AddProjects() {
    this.btnDisabled = true;

    setTimeout(() => {
      this.btnDisabled = false;
    }, 1000);
    let count = 0;
    const Editcount = 0;
    if (this.ProjectName === undefined || this.StatusID === undefined ||
      this.ProjectName.trim() === '' || this.StatusID.toString() === '' || this.TeamID === null || this.TeamID === undefined || this.TeamID.toString() === '') {
      this.dataSelf = 'Please enter mandatory(*) fields';
      if (this.data === 'new') {
      }
      count++;
    }
    if (count === 0 && Editcount === 0) {
      this.dataSelf = '';
      if (this.data === 'new') {
        this.Saving(this.data);
      } else {
        this.Saving(this.data);
      }
    } else {
    }
  }
  onYesClick() {
    this.dialogRef.close(true);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  Saving(msg: string) {
    const json = {
      ProjectID: this.ProjectID,
      ProjectName: this.ProjectName,
      ProjectDescription: this.ProjectDescription,
      CreatedBy: this.CurrentUser,
      UpdatedBy: this.CurrentUser,
      StatusID: this.StatusID,
      CompanyID: this.CompanyID,
      ProjectDueDate: this.ProjectDueDate,
      TeamID: this.TeamID,
      ProjectOwner: this.ProjectOwner
    };

    let date = this.Date.getFullYear() + '/' + (this.Date.getMonth() + 1) + '/' + this.Date.getDate();
    let date2 = new Date(date)
    if (this.ProjectDueDate !== null) {
      if (this.ProjectDueDate < date2) {
        this.dataSelf = 'Invalid date';
        return;
      }
    }
    if (msg === 'new') {
      this.dorseyService.SaveProjects(json).subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.updatePointer(msg);
          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Save failed';
        }
      });
    } else {
      this.dorseyService.SaveProjects(json).subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.updatePointer(msg);
          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Updated successfully';
        }
      });
    }
  }
  updatePointer(msg: string) {
    let statusRow = this.statusData.filter(x => x.ProjectStatusID === this.StatusID);
    this.StatusID = statusRow[0].StatusID;

    this.dorseyService.GetFrustration(this.ProjectID, this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.frustData = subData;
      const json = {
        ProjectID: this.ProjectID,
        CompanyID: this.CompanyID,
        pointerData: this.frustData,
        ProjectName: this.ProjectName,
        ProjectDescription: this.ProjectDescription,
        ProjectDueDate: this.ProjectDueDate,
        UpdatedBy: this.CurrentUser
      }
      this.dorseyService.UpdatePointerProject(json, 'No', this.StatusID).subscribe(data => {
        this.GetIssues(this.ProjectID);
      });
    });
  }
  GetIssues(id: string) {
    this.dorseyService.GetFrustration(id, this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.frustData = subData;
    });
  }
  statusChange(event) {
    this.StatusID = event.value;
  }
}
