import { Component, OnInit } from '@angular/core';
import { DorseyService } from '../dorsey.service';

@Component({
  selector: 'app-user-permisions',
  templateUrl: './user-permisions.component.html',
  styleUrls: ['./user-permisions.component.scss']
})
export class UserPermisionsComponent implements OnInit {
  index = 0;
  roleData: any;
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;
  processPaging = false;
  saveFilter = [];
  constructor(private dorseyService: DorseyService) { }

  ngOnInit() {
    this.GetUsers();
  }
  onLinkClick(event) {
    this.index = event.index;
  }

  GetUsers() {
    this.dorseyService.GetUsersList().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.roleData = subData;
      this.rowCount = this.roleData.length;
      if (this.roleData.length === 0) {
        this.processPaging = false;
      }
      else {
        this.saveFilter = JSON.parse(JSON.stringify(this.roleData));
        this.processPaging = true;

        while (this.dynamicRows < this.rowCount) {
          this.rowsOnPageSet.push(this.dynamicRows);
          this.dynamicRows = this.dynamicRows + 10;
        }
        if (!this.rowsOnPageSet.includes(this.rowCount)) this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet.length === 1) {
          if (this.rowsOnPageSet[0] <= 10)
            this.rowsOnPage = this.rowsOnPageSet[0];
        }
      }
    });
  }


  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

}
