import { Component, OnInit } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { LocalStorageService } from 'ngx-webstorage';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-projects-noactions',
  templateUrl: './projects-noactions.component.html',
  // styleUrls: ['./projects-noactions.component.scss']
})
export class ProjectsNoactionsComponent implements OnInit {

  NoActionProjectData: any;
  searchtext: string;
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;
  p: number = 1;
  rowsOnPage = 10;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  processPaging = false;
  saveFilter = [];
  CompanyID: string;
  isDisable = false;
  disableSearch = false;
  companyName: string;
  headerDate = new Date();
  copyDate: number;
  EmployeeID: string;
  TeamID: string;
  TeamName: string;

  constructor(public dorseyService: DorseyService, private storage: LocalStorageService) {
    this.EmployeeID = this.storage.retrieve('EmployeeID');
    if (this.EmployeeID.toString() === "0") {

    }
    else {
      this.TeamID = this.storage.retrieve('TeamID');
    }
  }

  ngOnInit() {
    this.CompanyID = this.storage.retrieve('companyID');
    this.companyName = this.storage.retrieve('LoginCompanyName');
    this.GetNoActionProjects();
    this.copyDate = (new Date()).getFullYear();
  }

  GetNoActionProjects() {
    this.dorseyService.GetNoActionProjects(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.NoActionProjectData = subData;
      this.saveFilter = JSON.parse(JSON.stringify(this.NoActionProjectData));
      this.rowCount = this.NoActionProjectData.length;
      if (this.NoActionProjectData.length === 0) {
        this.processPaging = false;
        this.isDisable = true;
        this.disableSearch = true;
      }
      else {
        this.processPaging = true;
        this.isDisable = false;
        this.disableSearch = false;

        while (this.dynamicRows < this.rowCount) {
          this.rowsOnPageSet.push(this.dynamicRows);
          this.dynamicRows = this.dynamicRows + 10;
        }
        if (!this.rowsOnPageSet.includes(this.rowCount)) this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet.length > 0) {
          if (this.rowsOnPageSet[0] <= 10)
            this.rowsOnPage = this.rowsOnPageSet[0];
        }
      }
    });
  }


  Searchtext() {
    this.NoActionProjectData = this.saveFilter;
    let tempNoActionProject = this.NoActionProjectData.filter(
      (item) => {
        return (
          (item.ProjectName !== null && item.ProjectName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ProjectDescription !== null && item.ProjectDescription.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ProjectStatusName !== null && item.ProjectStatusName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.TeamName !== null && item.TeamName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ProjectOwner !== null && item.ProjectOwner.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
        );
      }

    );
    this.NoActionProjectData = tempNoActionProject;
    if (this.NoActionProjectData.length == 0) {
      // this.GetActivity();
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.NoActionProjectData = this.saveFilter;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      this.rowCount = this.NoActionProjectData.length;
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount))
        this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet) {

        this.rowsOnPage = this.rowsOnPageSet[0];
      }
      this.isDisable = false;
    } else {
      this.rowCount = tempNoActionProject.length;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount)) {
        this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }

      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }


  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  exportAsXLSX() {
    let allData = JSON.parse(JSON.stringify(this.NoActionProjectData));


    for (let i = 0; i < this.NoActionProjectData.length; i++) {
      allData[i][" No. "] = i + 1;
      allData[i].Team = this.NoActionProjectData[i].TeamName;
      allData[i].Project = this.NoActionProjectData[i].ProjectName;

      allData[i].Description = this.NoActionProjectData[i].ProjectDescription;
      allData[i].Status = this.NoActionProjectData[i].ProjectStatusName;
      allData[i]['Project Owner'] = this.NoActionProjectData[i].ProjectOwner;

      
      delete (allData[i].DepartmentID);
      delete (allData[i].ProjectName);
      delete (allData[i].TeamName);
      delete (allData[i].ProjectOwner);
      delete (allData[i].ProjectDescription);

      delete (allData[i].ProjectStatusName);
      delete (allData[i].CompanyID);
      delete (allData[i].ProjectID);
      delete (allData[i].EmployeeID);
      delete (allData[i].StatusID);
      delete (allData[i].IsActive);
      delete (allData[i].CreatedDate);

      delete (allData[i].CreatedBy);
      delete (allData[i].UpdatedDate);
      delete (allData[i].UpdatedBy);
      delete (allData[i].ProjectDueDate);

      delete (allData[i].RowID);
      delete (allData[i].ProjectStatusID);
      delete (allData[i].StatusID1);
      delete (allData[i].IsActive1);
      delete (allData[i].CreatedDate1);
      delete (allData[i].CreatedBy1);

      delete (allData[i].UpdatedDate1);
      delete (allData[i].UpdatedBy1);
      delete (allData[i].ProjectStatusName1);

      delete (allData[i].TeamID);
      delete (allData[i].TeamID1);
      delete (allData[i].ProcessID);
      delete (allData[i].CompanyID1);
      delete (allData[i].IsActive2);
      delete (allData[i].CreatedDate2);
      delete (allData[i].CreatedBy2);
      delete (allData[i].UpdatedBy2);
      delete (allData[i].UpdatedDate2);
      delete (allData[i].TeamDescription);
      delete (allData[i].TeamName1);
    }
    let team: any;
    team = '';
    /*if(this.EmployeeID.toString()==='0'){
      team='';
    }else{
    if(this.TeamID.toString()==='0'|| this.TeamID.toString()===null || this.TeamID.toString()===undefined){
      team='';
    }else{
      team= 'Team: '+this.TeamName;
    }
  }*/
    this.dorseyService.generateExcel(allData, this.companyName + '_Projects in backlog' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Projects in backlog', "Company: " + this.companyName, team, "Projects in backlog Report", copyRight, 'FFFFFF00', 'FF0000FF');
  }

}
