import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { Http } from '@angular/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common'
import { Observable } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { EmployeeLoginLevel } from '../common/models/login-level.model';
import { copyRight } from '../copyRight.model';
@Component({
  selector: 'app-pointer',
  templateUrl: './case.component.html',
  //styleUrls: ['./case.component.scss'],
  providers: [DorseyService, DatePipe]
})
export class CaseComponent implements OnInit {
  employeeDetails: any;
  Comments: string;
  condition = false;
  status = false;
  employeeData: any;
  processData: any;
  activityData: any;
  pointerTypeData: any;
  frustrationtypeData: any;
  departmentData: any;
  teamData: any;
  severityData: any;
  statusData: any;
  CaseID: string;
  EmployeeID: string;
  ProcessID: string;
  ActivityID: string;
  PointerTypeID: string;
  FrustrationTypeID: string;
  DepartmentID: string;
  TeamID: string;
  StatusID: string;
  SeverityID: string;
  Subject: string;
  Description: string;
  PointerID: string;
  pointerData: any;
  tempActivity = [];
  tempTeam = [];
  SLAViolation: string;

  tempFrustrationType = []

  tempPointer: any;
  newPointer = false;
  caseData: any;
  newData = [];
  workingData = [];
  escalatedData = [];
  closingData = [];
  NewheaderText: string;
  workingHeader: string;
  Esculatedheader: string;
  Closedheader: string;
  searchtext: string = '';
  kenvanView = [];
  kenvan = {};
  weeks = [];
  connectedTo = [];
  tableFormat = false;
  kenbanFormat = false;
  displayAs: string;
  currentPage: number;
  pageIndex: number;
  endIndex: number;
  startIndex: number;
  ifRowSelected = false;

  p: number;
  rowsOnPage = 10;
  total: number;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 10;
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;

  checklist = [];
  selectedRows = [];
  checkEnable = false;
  checkAll = false;
  ProjectID: string;
  projectData: any;
  ErrorMessage: string;
  casePaging = false;
  saveFilter = [];
  CompanyID: string;
  isDisable = false;
  disableSearch = false;
  disableKenban = false;
  currentUser: string;
  Date = new Date();
  menuName: string;
  menuID: string;
  menuData: any;
  MenuPermissions: any;
  showProject = true;
  enableProject = false;
  userTeamID: string;
  loginCompanyName: any;
  TeamName: string;
  empID: any;
  errorMsg: any;
  projectAvailable = true;
  subActivityStatus = false;
  ParentID: any;
  SubActivityID: string;
  tempSubActivity = [];
  UserID: any;
  userRoleAssignmentData: any;
  rolesData: any;
  EmpROleID: any;
  updatedBy: any;
  @ViewChild('button', { static: false }) button: ElementRef;
  longPress = 'first state';
  longPressing = 0;
  isLongPressed = false;
  ifLongPress = true;
  updatedDate = new Date();
  constructor(private http: Http, private dorseyService: DorseyService, public dialog: MatDialog, private storage: LocalStorageService, private datepipe: DatePipe) {
    this.CompanyID = this.storage.retrieve('companyID');
    this.empID = this.storage.retrieve('EmployeeID');
    this.userTeamID = this.storage.retrieve('TeamID');
    this.currentUser = this.storage.retrieve('user');
    this.menuData = this.storage.retrieve('menuData');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
    this.loginCompanyName = this.storage.retrieve('loginCompanyName');
    this.UserID = this.storage.retrieve('UserID');
    this.EmpROleID = this.storage.retrieve('EmpRoleID');
    this.employeeDetails = this.storage.retrieve('employeeDetails');
    this.GetStatus();

  }

  ngOnInit() {
    this.GetTeam();
    this.menuData = this.menuData.filter(x => x.MenuName.toString().toLowerCase() === 'frustrations');
    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }
    this.tableFormat = true;
    this.GetProcess();
    this.GetActivity();
    this.GetEmployee();
    this.GetDepartment();
    this.GetPointerType();
    this.GetSeverity();
    this.GetPointer();
    this.GetProjects();
    this.GetFrustrationType();
    this.GetCase();


    this.displayAs = "Table";
  }



  onLongPressing() {
    this.longPressing += 1;
    if (this.longPressing > 20) {
      this.ifLongPress = false;
    } else {
      this.ifLongPress = true;
    }
  }
  onMouseUp() {
    this.longPressing = 0;
    this.ifLongPress = true;
  }

  onDblClick() {
    this.ifLongPress = false;
  }

  getPageNum() {
    this.currentPage = this.p;
    if (this.currentPage !== this.pageIndex) {
      this.checkAll = false;
    }
  }

  CheckAll(event) {
    if (this.projectData.length == 0) {
      this.projectAvailable = false;
      this.errorMsg = 'No project available.';
      setTimeout(() => {
        this.projectAvailable = true;
      }, 5000);

    }
    this.selectedRows = this.selectedRows;
    if (event.checked) {
      this.checkEnable = true;
      this.enableProject = true;
    } else {
      this.checkEnable = false;
      this.checkAll = false;
    }
    this.pageIndex = this.p;

    let totalRows = this.caseData.length;
    let RowsPerPage = this.rowsOnPage;

    if (this.pageIndex > 1) {
      this.startIndex = (this.pageIndex - 1) * RowsPerPage;
      this.endIndex = this.startIndex + RowsPerPage;

    } else {
      this.startIndex = 0;
      this.endIndex = this.rowsOnPage;
    }

    try {
      for (let i = this.startIndex; i < this.endIndex; i++) {

        if (this.checkAll) {
          if (!this.checklist[i]) {
            this.checklist[i] = event.checked;
            this.change(this.caseData[i], event, i);
          }
        } else {
          this.checklist[i] = event.checked;
          this.change(this.caseData[i], event, i);
        }
      }
    } catch (error) { }
  }

  checkListCount() {
    for (let i = 0; i < this.caseData.length; i++) {
      if (this.checklist[i]) {
        this.enableProject = true;
        break;
      } else {
        this.enableProject = false;
      }
    }

  }

  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }


  change(element, event, i) {
    if (this.projectData.length === 0) {
      this.projectAvailable = false;
      this.errorMsg = 'No project available.';
      setTimeout(() => {
        this.projectAvailable = true;

      }, 10000);

    }
    element.check = event.checked;
    if (!event.checked) {

      this.checkAll = false;
      const index = this.selectedRows.indexOf(element);
      this.selectedRows.splice(index, 1);
      if (this.selectedRows.length === 0) {
        this.enableProject = false;
      }
      else {
        this.enableProject = true;
      }

    } else {
      this.enableProject = true;
      this.selectedRows.push(element);
      this.checkEnable = true;

    }
    if (this.selectedRows.length === 0) {
      this.checkEnable = false;
    }

  }

  exportAsXLSX() {
    let allData = JSON.parse(JSON.stringify(this.caseData));

    for (let i = 0; i < allData.length; i++) {
      allData[i][" Team "] = allData[i].TeamName;
      allData[i].Process = allData[i].ProcessName;
      allData[i][" Severity "] = allData[i].SeverityName;
      allData[i][" Type "] = allData[i].FrustrationsTypeName;
      allData[i][" Frustration"] = allData[i].Subject;
      allData[i].Status = allData[i].StatusName;
      allData[i].Project = allData[i].ProjectName;
      allData[i][" Description"] = allData[i].Description;
      allData[i]["Date Opened"] = this.datepipe.transform(allData[i].PointerDate, 'MM/dd/yyyy');


      delete (allData[i].TeamName);

      delete (allData[i].SeverityName);
      delete (allData[i].FrustrationsTypeName);
      delete (allData[i].Subject);
      delete (allData[i].Description);
      delete (allData[i].check);
      delete (allData[i].CompanyID);
      delete (allData[i].CasesID);
      delete (allData[i].PointerID);
      delete (allData[i].ProcessName);
      delete (allData[i].ActivityName);
      delete (allData[i].UpdatedBy);
      delete (allData[i].UpdatedDate);

      delete (allData[i].StatusName);
      delete (allData[i].SeverityName);
      delete (allData[i].ProjectName);
      delete (allData[i].PointerDate);

      delete (allData[i].SLAViloation);
      delete (allData[i].CreatedBy);
      delete (allData[i].CreatedDate);
      delete (this.caseData[i].SLAViloation);
      delete (allData[i].Comment);
      delete (allData[i].ActivityID);
      delete (allData[i].PointerTypeID);

      delete (allData[i].EmployeeID);
      delete (allData[i].Type);
      delete (allData[i].StatusID);
      delete (allData[i].ProcessID);
      delete (allData[i].DepartmentID);
      delete (allData[i].TeamID);

      delete (allData[i].FrustrationsTypeID);
      delete (allData[i].PointerCreatedBy);
      delete (allData[i].ProjectID);
      delete (allData[i].SeverityID);


    }

    this.dorseyService.generateExcel(allData, this.loginCompanyName + '_frustration' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Frustration', "Company: " + this.loginCompanyName, '', "Frustration Report", copyRight, 'FFFFFF00', 'FF0000FF');
  }
  table() {
    this.tableFormat = true;
    this.kenbanFormat = false;
    this.displayAs = "Table";
  }
  kenban() {
    this.checkAll = false;
    this.selectedRows = [];
    this.checklist = [];
    this.enableProject = false;
    this.kenbanFormat = true;
    this.tableFormat = false;
    this.displayAs = "Status view";
    this.weeks = [];
    this.kenvan = [];
    this.kenvanView = [];
    this.GetCase();
  }
  GetCase() {

    this.dorseyService.GetCase(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.caseData = subData;

      let tempCaseData = this.caseData;
      if (this.caseData.length === 0) {
        this.casePaging = false;
        this.isDisable = true;
        this.disableSearch = true;
        this.disableKenban = true;
      }
      else {
        this.saveFilter = JSON.parse(JSON.stringify(this.caseData));
        this.casePaging = true;
        this.isDisable = false;
        this.disableSearch = false;
        this.disableKenban = false;

        // if (this.userTeamID != '0' && this.userTeamID !== null && this.CompanyID !== '0') {
        //   this.caseData = tempCaseData.filter(x => x.TeamID.toString() === this.userTeamID.toString() && x.CompanyID === this.CompanyID);
        // }
        // else {
        //   ``
        //   this.caseData = tempCaseData.filter(x => x.CompanyID === this.CompanyID);
        // }

        if (this.employeeDetails) {
          if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) {
            this.caseData = this.caseData.filter(entity1 => this.teamData.some(entity2 => entity1.TeamID == entity2.TeamID));
          }
          else if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
            this.caseData = this.caseData.filter(ent => ent.TeamID == this.employeeDetails.TeamID)
          }
          else {
            let data = this.caseData.filter(x => x.CompanyID === this.CompanyID);
            this.caseData = data;
          }
        }


        this.dynamicRows = 10;
        this.rowsOnPageSet = [];

        if (this.caseData.length === 0) {
          this.casePaging = false;
          this.isDisable = true;
          this.disableSearch = true;
        }
        else {
          this.saveFilter = JSON.parse(JSON.stringify(this.caseData));
          this.rowCount = this.caseData.length;
          this.getCasePagination(this.rowCount)

          this.casePaging = true;
          this.isDisable = false;
          this.disableSearch = false;

        }


      }

      for (let k = 0; k < this.statusData.length; k++) {
        this.kenvanView = [];
        this.kenvan = [];

        // this.statusData=this.statusData.filter(x=>x.StatusID!=5);

        for (let j = 0; j < this.caseData.length; j++) {

          if (this.statusData[k].StatusID === this.caseData[j].StatusID) {

            this.kenvanView.push(this.caseData[j]);
          }
        }
        this.kenvan['id'] = this.statusData[k].StatusName;
        this.kenvan['weeklist'] = this.kenvanView;
        this.weeks.push({
          'id': this.statusData[k].StatusName,
          'weeklist': this.kenvanView,
          'statusID': this.statusData[k].StatusID,
          'count': this.kenvanView.length
        });
      }
      for (let week of this.weeks) {
        this.connectedTo.push(week.id);
      };
      this.ifEmployeeLogin();
    });


  }
  getCasePagination(rowCountNo) {

    while (this.dynamicRows < rowCountNo) {
      this.rowsOnPageSet.push(this.dynamicRows);
      this.dynamicRows = this.dynamicRows + 10;
    }
    if (!this.rowsOnPageSet.includes(rowCountNo)) this.rowsOnPageSet.push(rowCountNo);
    if (this.rowsOnPageSet.length === 1) {
      if (this.rowsOnPageSet[0] <= 10)
        this.rowsOnPage = this.rowsOnPageSet[0];
    }
  }
  ifEmployeeLogin() {
    if (this.userTeamID != '0' && this.userTeamID != null) {
      let tempProcessData = this.caseData.filter(x => x.TeamID == this.userTeamID);
      if (tempProcessData.length == 0) {
        this.isDisable = true;
        this.disableSearch = true;
      }
    }
  }
  Searchtext() {
    this.caseData = this.saveFilter;

    let tempCase = this.caseData.filter(
      (item) => {
        return (
          (item.ProcessName !== null && item.ProcessName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
          || (item.Subject !== null && item.Subject.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
          || (item.Description !== null && item.Description.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
          || (item.StatusName !== null && item.StatusName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
          || (item.ProjectName !== null && item.ProjectName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
          || (item.TeamName !== null && item.TeamName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
          || (item.SeverityName !== null && item.SeverityName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
          || (item.FrustrationsTypeName !== null && item.FrustrationsTypeName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
          || (item.PointerDate !== null && item.PointerDate.indexOf(this.searchtext) > -1))
      }

    );
    this.caseData = tempCase;
    if (this.caseData.length == 0) {
      // this.GetActivity();
      this.isDisable = true;
      this.disableKenban = true;
      this.ErrorMessage = '';
      this.checkEnable = false;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.weeks = [];
      this.kenvanView = [];
      this.kenvan = [];
      this.caseData = this.saveFilter;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      this.rowCount = this.caseData.length;
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount))
        this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet) {

        this.rowsOnPage = this.rowsOnPageSet[0];
      }
      this.isDisable = false;
      this.disableKenban = false;

    } else {
      this.rowCount = tempCase.length;
      this.dynamicRows = 10;
      this.rowsOnPageSet = [];
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 10;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount)) {
        this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }

      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 10)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }

  drop(event: CdkDragDrop<string[]>, statusID: string) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.SavePointer(event.container.data, statusID);

    }
    // this.GetCase();
  }

  SavePointer(dataSave: any, statusID: string) {
    if (dataSave[0].ProjectID === undefined || dataSave[0].ProjectID === '') {
      dataSave[0].ProjectID = "0";

    }
    if (dataSave.length > 0) {

      const pointerJson = {
        CompanyID: this.CompanyID,
        PointerID: dataSave[0].PointerID,
        EmployeeID: dataSave[0].EmployeeID,
        ProessID: dataSave[0].ProcessID,
        ActivityID: dataSave[0].ActivityID,
        PointerTypeID: dataSave[0].PointerTypeID,
        FrustrationsTypeID: dataSave[0].FrustrationsTypeID,
        DepartmentID: dataSave[0].DepartmentID,
        TeamID: dataSave[0].TeamID,
        SeverityID: dataSave[0].SeverityID,
        StatusID: statusID,
        Subject: dataSave[0].Subject,
        Description: dataSave[0].Description,
        CreatedBy: this.currentUser,
        UpdatedBy: this.currentUser,
        ProjectID: dataSave[0].ProjectID,
      };

      this.dorseyService.SavePointer(pointerJson, 'No').subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.weeks = [];
          this.kenvanView = [];
          this.kenvan = [];
          this.GetCase();
        }
      });
      this.weeks = [];
      this.kenvan = [];
      this.kenvanView = [];
      this.GetPointer();
      this.GetCase();

    }
  }
  GetPointer() {
    this.dorseyService.GetPointer(this.CompanyID).subscribe(subData => {

      subData = JSON.parse(subData['_body']);
      this.pointerData = subData;
    });
  }
  GetEmployee() {
    this.dorseyService.GetEmployee(this.CompanyID).subscribe(subData => {

      subData = JSON.parse(subData['_body']);
      this.employeeData = subData;
    });
  }
  GetFrustrationType() {
    this.dorseyService.GetFrustrationType("1", this.CompanyID).subscribe(subData => {

      subData = JSON.parse(subData['_body']);
      this.frustrationtypeData = subData;
    });
  }
  GetDepartment() {
    this.dorseyService.GetDepartment().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.departmentData = subData;
    });
  }
  GetProjects() {
    this.dorseyService.GetProjects(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.projectData = subData;

    });
  }
  GetPointerType() {
    this.dorseyService.GetPointerType().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.pointerTypeData = subData;
    });
  }
  GetStatus() {
    this.dorseyService.GetStatus().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.statusData = subData;
    });
  }
  GetSeverity() {
    this.dorseyService.GetSeverity().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.severityData = subData;
    });
  }

  GetTeam() {
    this.dorseyService.GetTeam(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.teamData = subData;
      if (this.employeeDetails) {
        if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) {
          this.teamData = this.teamData.filter(ent => ent.DepartmentID == this.employeeDetails.DepartmentID)
        }
        else if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
          this.teamData = this.teamData.filter(ent => ent.DepartmentID == this.employeeDetails.DepartmentID && ent.TeamID == this.employeeDetails.TeamID)
          this.TeamName = this.teamData[0].TeamName;
        }
        else {
          this.teamData = subData;
        }
      }

    });
  }
  GetActivity() {
    this.dorseyService.GetActivity(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.activityData = subData;
    });
  }
  GetProcess() {
    this.dorseyService.GetProcess(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.processData = subData;
      let processTemp = this.processData;
      this.processData = processTemp.filter(x => x.TeamID !== 0);

      if (this.userTeamID != "0" && this.CompanyID != "0" && this.userTeamID !== null) {

        let data = this.processData.filter(x => x.TeamID === this.userTeamID);
        this.processData = data;
        this.TeamID = data[0].TeamID;
      }
    });
  }


  dialogCall(msg: string) {

    const dialogRef = this.dialog.open(CaseDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: msg + 'æææ' + false,
    });
    dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.componentInstance.processData = this.processData;
    dialogRef.componentInstance.activityData = this.activityData;
    dialogRef.componentInstance.departmentData = this.departmentData;
    dialogRef.componentInstance.severityData = this.severityData;
    dialogRef.componentInstance.statusData = this.statusData;
    dialogRef.componentInstance.employeeData = this.employeeData;
    dialogRef.componentInstance.tempActivity = this.tempActivity;
    dialogRef.componentInstance.tempFrustrationType = this.tempFrustrationType;
    dialogRef.componentInstance.teamData = this.teamData;
    dialogRef.componentInstance.CompanyID = this.CompanyID;

    if (msg === 'edit') {
      this.status = true;
      let tempProcessData = this.processData.filter(x => x.TeamID === this.TeamID);
      dialogRef.componentInstance.processData = tempProcessData;
      dialogRef.componentInstance.EmployeeID = this.EmployeeID;
      dialogRef.componentInstance.ProcessID = this.ProcessID;
      dialogRef.componentInstance.ActivityID = this.ActivityID;
      dialogRef.componentInstance.PointerID = this.PointerID;
      dialogRef.componentInstance.DepartmentID = this.DepartmentID;
      dialogRef.componentInstance.TeamID = this.TeamID;
      dialogRef.componentInstance.StatusID = this.StatusID;
      dialogRef.componentInstance.SeverityID = this.SeverityID;
      dialogRef.componentInstance.Subject = this.Subject;
      dialogRef.componentInstance.Description = this.Description;
      dialogRef.componentInstance.CaseID = this.CaseID;
      dialogRef.componentInstance.SLAViolation = this.SLAViolation;
      dialogRef.componentInstance.Comments = this.Comments;

      dialogRef.componentInstance.FrustrationTypeID = this.FrustrationTypeID;
      dialogRef.componentInstance.PointerTypeID = this.PointerTypeID;
      dialogRef.componentInstance.ProjectID = this.ProjectID;
      dialogRef.componentInstance.subActivityStatus = this.subActivityStatus;
      dialogRef.componentInstance.SubActivityID = this.SubActivityID;
      dialogRef.componentInstance.updatedBy = this.updatedBy;
      dialogRef.componentInstance.updatedDate = this.updatedDate;

      //dialogRef.componentInstance.tempActivity=this.tempActivity;
      // dialogRef.componentInstance.tempFrustrationType=this.tempFrustrationType;


    }

    if (msg === 'assign') {
      dialogRef.componentInstance.ProjectID = this.ProjectID;
      dialogRef.componentInstance.projectData = this.projectData;
      dialogRef.componentInstance.checklist = this.checklist;
      dialogRef.componentInstance.selectedRows = this.selectedRows;
      dialogRef.componentInstance.checkAll = this.checkAll;
      dialogRef.componentInstance.checkEnable = this.checkEnable;

    }
    if (msg === 'new') {
      this.searchtext = '';
      this.weeks = [];
      this.kenvan = [];
      this.kenvanView = [];
      this.GetCase();
    }
    dialogRef.afterClosed().subscribe(result => {
      this.subActivityStatus = false;
      this.tempActivity = [];
      this.tempTeam = [];
      this.tempFrustrationType = [];
      this.checkEnable = false;
      this.weeks = [];
      this.kenvan = [];
      this.kenvanView = [];
      if (this.searchtext !== '') {
        this.Searchtext();
      }
      else {
        this.weeks = [];
        this.kenvan = [];
        this.kenvanView = [];
        this.GetCase();
      }

      if (msg == 'Assigned successfully' && this.searchtext !== '') {

        this.Searchtext();
      }

      if (result.toString() === 'true') {

        let statusMsg = '';
        if (msg === 'new') {
          statusMsg = 'Saved successfully';

        }
        else {

          statusMsg = 'Updated successfully';

          this.checkEnable = false;
        }
        this.dynamicRows = 10;
        this.rowsOnPageSet = [];
        this.GetPointer();
        this.weeks = [];
        this.kenvan = [];
        this.kenvanView = [];
        this.GetCase();
        const dialogRefsub = this.dialog.open(CaseDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: statusMsg + 'æææ' + false,
          //  width: '30%'
        });


        setTimeout(() => {
          this.GetPointer();
          this.GetCase();
        }, 500);

        dialogRefsub.afterClosed().subscribe(result1 => {
          this.subActivityStatus = false;
          this.tempActivity = [];
          this.tempTeam = [];
          this.tempFrustrationType = [];
          this.weeks = [];
          this.kenvan = [];
          this.kenvanView = [];

          if (this.searchtext !== '') {
            this.Searchtext();
          }
          else {

            this.GetPointer();
            this.GetCase();
          }

          //this.GetPointer();
          //  this.Searchtext();
          // this.GetCase();
        });



      }

    });
  }



  GetTempActivity(ProcessID: string) {
    for (let i = 0; i < this.activityData.length; i++) {
      if (this.activityData[i].ProcessID === ProcessID && this.activityData[i].ParentID == 0) {
        this.tempActivity.push(this.activityData[i]);
      }
    }
  }


  GetTempFrastrations(PointerTypeID: string) {
    for (let i = 0; i < this.frustrationtypeData.length; i++) {
      if (this.frustrationtypeData[i].PointerTypeID === PointerTypeID) {
        this.tempFrustrationType.push(this.frustrationtypeData[i]);
      }
    }
  }

  GetTempTeam(ProcessID: string) {
    for (let i = 0; i < this.teamData.length; i++) {
      if (this.teamData[i].ProcessID === ProcessID) {
        this.tempTeam.push(this.teamData[i]);
      }
    }
  }

  EditCase(element, text) {
    this.ParentID = 0;
    this.GetTempActivity(element.ProcessID);
    this.CaseID = element.CasesID;
    this.PointerID = element.PointerID;
    this.SLAViolation = element.SLAViloation;
    this.Comments = element.Comment;
    this.updatedBy = element.UpdatedBy;
    this.updatedDate = element.UpdatedDate;
    this.tempPointer = this.pointerData.filter(c => c.PointerID != undefined && c.PointerID === this.PointerID);
    if (this.tempPointer.length > 0) {
      if (this.tempPointer[0].ProjectID === undefined || this.tempPointer[0].ProjectID === '') {
        this.tempPointer[0].ProjectID = "0";
      }
      this.FrustrationTypeID = this.tempPointer[0].FrustrationsTypeID;
      this.Subject = this.tempPointer[0].Subject;
      this.Description = this.tempPointer[0].Description;
      this.DepartmentID = this.tempPointer[0].DepartmentID;
      this.TeamID = this.tempPointer[0].TeamID;
      this.ProcessID = this.tempPointer[0].ProcessID;

      this.PointerTypeID = this.tempPointer[0].PointerTypeID;
      this.EmployeeID = this.tempPointer[0].EmployeeID;
      this.SeverityID = this.tempPointer[0].SeverityID;
      this.StatusID = this.tempPointer[0].StatusID;
      this.ProjectID = this.tempPointer[0].ProjectID;
      if (this.tempPointer[0].ActivityID === 0) {
        this.tempPointer[0].ActivityID = "0";
      }
      this.GetTempFrastrations(this.PointerTypeID);
      this.ActivityID = this.tempPointer[0].ActivityID;
    }

    for (let i = 0; i < this.activityData.length; i++) {
      if (element.ActivityID === this.activityData[i].ActivityID) {
        this.ParentID = this.activityData[i].ParentID;

      }

    }

    if (!this.ParentID || this.ParentID.toString() === '0') {
      this.ActivityID = element.ActivityID;
      this.subActivityStatus = false;
    } else {
      this.subActivityStatus = true;
      this.SubActivityID = element.ActivityID;
      this.ActivityID = this.ParentID;
    }

    this.dialogCall('edit');
  }
  project(event) {
    this.ProjectID = event.value;
  }


  Addproject(msg: string) {
    this.updatePointer(msg);
  }
  updatePointer(msg: string) {
    let data = this.projectData.filter(x => x.ProjectID === this.ProjectID);
    if (data.length > 0) {
      this.StatusID = data[0].ProjectStatusID;
    }


    if (msg === 'assign') {
      {
        if (!this.ProjectID && this.ProjectID != '0') {
          this.ErrorMessage = 'Please select Project';
          return;
        }
        else {
          this.ErrorMessage = '';
          this.enableProject = false;
        }


        if (this.ProjectID.toString() === '0') {
          this.StatusID = this.selectedRows[0].StatusID;
        }


        const json = {
          ProjectID: this.ProjectID,
          CompanyID: this.CompanyID,
          pointerData: this.selectedRows,
          UpdatedBy: this.currentUser

        };
        this.dorseyService.UpdatePointerProject(json, 'No', this.StatusID).subscribe(data => {
          if (data.ok) {
            this.condition = true;
            this.dialogCall('Assigned successfully');
            this.GetStatus();
            this.weeks = [];
            this.kenvan = [];
            this.kenvanView = [];
            this.GetCase();
            this.GetProjects();
            this.checkAll = false;
            this.selectedRows = [];
            this.ProjectID = "";
          }


        });
      }

    }
  }


  ifMaxRow() {

    if (this.rowsOnPage == this.caseData.length) {
      this.rowsOnPage = this.rowsOnPage - 1;
    }
  }

  DeleteCase(element) {
    this.CaseID = element.CasesID;
    this.PointerID = element.PointerID;
    const dialogRef = this.dialog.open(CaseDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'Are you sure you want to delete frustration: ' + element.Subject + '?æææ' + true,
      // width: '40%'
    });
    //   this.condition = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        /// this.condition = true;
        this.dorseyService.DeleteCase(this.CaseID, this.PointerID).subscribe(data => {

          if (data.ok) {
            this.ifMaxRow();
            this.condition = false;
            this.dialogCall('Deleted successfully');
          }
          this.dynamicRows = 10;
          this.rowsOnPageSet = [];
          this.GetCase();
        });
      }
    });
  }

  GetTempSubActivity(ActivityID) {
    this.tempSubActivity = [];
    // this.SubActivityID=ActivityID;
    for (let i = 0; i < this.activityData.length; i++) {
      if (this.activityData[i].ParentID === ActivityID) {
        this.tempSubActivity.push(this.activityData[i]);
      }
    }
  }

}
@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
  // styleUrls: ['dialog.css'],
  providers: [DorseyService, DatePipe],
})
export class CaseDialogComponent {
  SLAViolation: string;
  currentUser: string
  condition = false;
  status = false;
  employeeData: any;
  processData: any;
  activityData: any;
  pointerTypeData: any;
  frustrationtypeData: any;
  departmentData: any;
  CaseID: string;
  teamData: any;
  severityData: any;
  statusData: any;
  dataSelf = '';
  EmployeeID: string;
  ProcessID: string;
  ActivityID: string;
  PointerTypeID: string;
  FrustrationTypeID: string;
  DepartmentID: string;
  TeamID: string;
  StatusID: string;
  SeverityID: string;
  Subject: string;
  Description: string;
  PointerID: string;
  tempActivity = [];
  tempTeam = [];
  tempFrustrationType = [];
  Comments: string;
  processCtrl = new FormControl();

  filteredProcess: Observable<any[]>

  checklist = [];
  selectedRows = [];
  checkEnable = false;
  checkAll = false;
  projectData: any;
  ProjectID: string;
  pointerData: any;
  CompanyID: string;
  subActivityStatus = false;
  SubActivityID: string;
  tempSubActivity = [];
  conditionActivity = '';
  updatedBy: any;
  updatedDate = new Date();
  btnDisabled = false;

  constructor(
    public dialogRef: MatDialogRef<CaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dorseyService: DorseyService, private datepipe: DatePipe) {
    //  dialogRef.disableClose=true;
    this.data = this.data.split('æææ');
    if (this.data[1] === 'true') {
      this.condition = true;
    } else if (this.data[1] === 'false') {
      this.condition = false;
    }
    else {
      if (this.data[1] === '') {
        this.conditionActivity = 'process';
      } else {
        this.conditionActivity = 'activity';
      }

    }
    this.data = this.data[0];
  }

  ngOnInit() {
    if (this.SubActivityID) {
      this.GetTempSubActivity(this.ActivityID);
    }
  }

  onYesClick() {
    this.dialogRef.close(true);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  AddCase() {
    this.btnDisabled = true;

    setTimeout(() => {
      this.btnDisabled = false;
    }, 1000);
    let count = 0;
    const Editcount = 0;

    if (this.SeverityID === undefined || this.Subject == undefined || this.StatusID === undefined ||
      this.SeverityID === '' || this.StatusID === '' || this.Subject.trim() === '') {
      this.dataSelf = 'Please enter mandatory(*) fields';
      if (this.data === 'new') {

      }
      count++;
    }

    if (count === 0 && Editcount === 0) {
      this.dataSelf = '';
      if (this.data === 'new') {

        this.Saving(this.data);
      } else {
        this.Saving(this.data);
      }
    } else {

    }



  }



  GetPointer() {
    this.dorseyService.GetPointer(this.CompanyID).subscribe(subData => {

      subData = JSON.parse(subData['_body']);
      this.pointerData = subData;
    });
  }


  Saving(msg: string) {

    if (this.subActivityStatus) {
      this.ActivityID = this.SubActivityID;
    }
    if (this.subActivityStatus) {
      if (this.ActivityID === '' || this.SubActivityID === '') {
        this.dataSelf = 'Please enter mandatory(*) fields';
        return;
      }

    }

    const json = {
      PointerID: this.PointerID,
      CaseID: this.CaseID,
      SLAViloation: this.SLAViolation,
      Comment: this.Comments,
      UpdatedBy: this.currentUser
    };

    if (msg === 'edit') {
      this.dorseyService.SaveCase(json).subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.dialogRef.close(true);
          const pointerJson = {
            CompanyID: this.CompanyID,
            PointerID: this.PointerID,
            EmployeeID: "0",
            ProessID: this.ProcessID,
            ActivityID: this.ActivityID,
            PointerTypeID: this.PointerTypeID,
            FrustrationsTypeID: this.FrustrationTypeID,
            DepartmentID: "0",
            TeamID: this.TeamID,
            SeverityID: this.SeverityID,
            StatusID: this.StatusID,
            Subject: this.Subject,
            Description: this.Description,
            ProjectID: this.ProjectID,
            CreatedBy: this.currentUser,
            UpdatedBy: this.currentUser
          };


          this.dorseyService.SavePointer(pointerJson, 'No').subscribe(data => {
            if (data['_body'].toString() === 'true') {

              this.dialogRef.close(true);
            } else {
              this.dataSelf = 'Save failed';
            }
          });
        }
      });
    }

  }


  Clear() {
    this.dialogRef.close(false);
  }
  employee(event) {
    this.EmployeeID = event.value;
  }
  process(event) {
    this.ProcessID = event.value;
    for (let i = 0; i < this.processData.length; i++) {
      if (this.processData[i].ProcessID === this.ProcessID) {
        this.TeamID = this.processData[i].TeamID;
      }
    }
    this.tempActivity = [];
    this.tempSubActivity = [];
    this.tempTeam = [];
    this.ActivityID = '';

    if (this.subActivityStatus) {
      this.SubActivityID = '';
      this.ActivityID = '';
    }
    this.GetTempActivity();
    this.GetTempFrastrations();
    //this.GetTempTeam();
  }
  GetTempActivity() {
    for (let i = 0; i < this.activityData.length; i++) {

      if (this.activityData[i].ProcessID === this.ProcessID && this.activityData[i].ParentID === 0) {
        this.tempActivity.push(this.activityData[i]);
      }
    }
  }
  activity(event) {
    this.tempSubActivity = [];
    this.ActivityID = event.value;
    this.SubActivityID = '';
    this.GetTempSubActivity(this.ActivityID);
  }
  frustrationType(event) {
    this.FrustrationTypeID = event.value
  }
  GetTempFrastrations() {
    for (let i = 0; i < this.frustrationtypeData.length; i++) {
      if (this.frustrationtypeData[i].PointerTypeID === this.PointerTypeID) {
        this.tempFrustrationType.push(this.frustrationtypeData[i]);
      }
    }
  }

  department(event) {
    this.DepartmentID = event.value;
  }

  team(event) {
    this.TeamID = event.value;
  }

  severity(event) {
    this.SeverityID = event.value;
  }

  statusChange(event) {
    this.StatusID = event.value;
  }

  subactivity(event) {
    this.SubActivityID = event.value;
  }

  GetTempSubActivity(ActivityID) {
    this.tempSubActivity = [];
    for (let i = 0; i < this.activityData.length; i++) {
      if (this.activityData[i].ParentID === ActivityID) {
        this.tempSubActivity.push(this.activityData[i]);
      }
    }
  }

}
