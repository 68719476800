import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DorseyService } from '../dorsey.service';
import { LocalStorageService } from 'ngx-webstorage';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-metricdata',
  templateUrl: './metricdata.component.html',
})
export class MetricdataComponent implements OnInit {

  mainCategoryData: any;
  p: number = 1;
  rowsOnPage = 5;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 5;
  saveFilter = [];
  isDisable = false;
  disableSearch = false;
  kpiPaging = false;
  subCategoryData: any;
  mainData = [];
  searchtext = '';
  menuData: any;
  menuID: string;
  menuName: string;
  empID: string;
  MenuPermissions: any;
  loginCompanyName: any;
  userTeamID: any;
  currentUser: string;
  CompanyID: string;
  MainCategory: any;
  SubCategory: any;
  RowID: any;
  MainCategoryID: any;
  condition = false;

  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;

  constructor(private dorseyService: DorseyService, public dialog: MatDialog, public storage: LocalStorageService) {
    this.currentUser = this.storage.retrieve('user');
    this.menuData = this.storage.retrieve('menuData');
    this.empID = this.storage.retrieve('EmployeeID');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
    this.loginCompanyName = this.storage.retrieve('loginCompanyName');
    this.userTeamID = this.storage.retrieve("TeamID");
  }

  ngOnInit() {
    this.CompanyID = this.storage.retrieve('companyID');

    this.getKpiMainData();
    this.getKpiSubData();
    let tempdata = this.menuData;
    this.menuData = tempdata.filter(x => x.MenuName.toString() === 'Metric Table');
    if (this.menuData.length > 0) {
      this.menuName = this.menuData[0]["MenuName"];
      this.menuID = this.menuData[0]["MenuID"];
    }
  }

  getKpiMainData() {
    this.dorseyService.GetKpiMain(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.mainCategoryData = subData;
    });
  }

  getKpiSubData() {
    this.mainData = [];
    this.dorseyService.GetKpiSub(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.subCategoryData = subData;
      setTimeout(() => {
        this.getKpiMainData();
      }, 500);
      for (let i = 0; i < this.mainCategoryData.length; i++) {
        let dataList = [];
        let dataId = [];
        for (let j = 0; j < this.subCategoryData.length; j++) {
          if (this.mainCategoryData[i].MainCategoryID === this.subCategoryData[j].MainCategoryID) {

            dataList.push(this.subCategoryData[j].SubCategory);
            dataId.push(this.subCategoryData[j].RowID);

          }
        }
        if (dataList.length > 0 && dataId.length > 0) {
          this.mainData.push({
            'MainCategory': this.mainCategoryData[i].MainCategory,
            'MainCategoryID': this.mainCategoryData[i].MainCategoryID,
            'RowID': dataId,
            'SubCategory': dataList
          });
        }

      }
      this.rowCount = this.mainData.length;
      if (this.mainData.length === 0) {
        this.kpiPaging = false;
        this.isDisable = true;
        this.disableSearch = true;
      }
      else {
        this.saveFilter = JSON.parse(JSON.stringify(this.mainData));
        this.kpiPaging = true;
        this.isDisable = false;
        this.disableSearch = false;

        this.dynamicRows = 5;
        this.rowsOnPageSet = [];

        while (this.dynamicRows < this.rowCount) {
          this.rowsOnPageSet.push(this.dynamicRows);
          this.dynamicRows = this.dynamicRows + 5;
        }
        if (!this.rowsOnPageSet.includes(this.rowCount))
          this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet.length === 1) {
          if (this.rowsOnPageSet[0] <= 10)
            this.rowsOnPage = this.rowsOnPageSet[0];
        }
      }
    });




  }


  Searchtext() {
    this.mainData = this.saveFilter;
    let tempMainData = this.mainData.filter(
      (item) => {
        return (
          (item.MainCategory != null && item.MainCategory.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.SubCategory != null && (item.SubCategory.filter(
            (da) => {
              return (
                da != null && da.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1
              )

            }
          )
          )
          ).length


        )



      }

    );


    this.mainData = tempMainData;
    if (this.mainData.length == 0) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.getKpiMainData();
      this.dynamicRows = 5;
      this.rowsOnPageSet = [];
      this.getKpiSubData();
      this.isDisable = false;
    }
  }

  addData() {
    this.dialogCall('new');
  }

  EditMetricData(element, data, ROwiDs) {
    this.MainCategoryID = element.MainCategoryID;
    this.RowID = ROwiDs;
    this.MainCategory = element.MainCategoryID;
    this.SubCategory = data;

    this.storage.store('MCID', this.MainCategoryID);
    this.storage.store('subCat', this.SubCategory);
    this.dialogCall('edit');
  }

  //  AddSubCategory(element,data,ROwiDs){
  //    this.MainCategory = element.MainCategory;
  //    this.dialogCall('subcat');
  //  }

  ifMaxRow() {

    if (this.rowsOnPage == this.mainData.length) {
      this.rowsOnPage = this.rowsOnPage - 1;
    }
  }

  DeleteMetricData(element, data, ROwiDs) {
    this.MainCategoryID = element.MainCategoryID;
    this.RowID = ROwiDs


    const dialogRef = this.dialog.open(MetricDataDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'Are you sure you want to delete category: ' + element.MainCategory + "," + data + '?æææ' + true,
      // width: '40%'
    });
    //   this.condition = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result) {


        this.dorseyService.DeleteMetricData(this.MainCategoryID, this.RowID).subscribe(data => {

          if (data.ok) {
            this.ifMaxRow();
            this.condition = true;
            this.dialogCall('Deleted successfully');
          }
          this.dynamicRows = 5;
          this.rowsOnPageSet = [];
          this.getKpiMainData();
          this.getKpiSubData();
        });

      }
    });
  }

  dialogCall(msg: string) {
    const dialogRef = this.dialog.open(MetricDataDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: msg + 'æææ' + false,

    });
    dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.componentInstance.mainData = this.mainData;
    dialogRef.componentInstance.CompanyID = this.CompanyID;
    dialogRef.componentInstance.mainCategoryData = this.mainCategoryData;
    dialogRef.componentInstance.subCategoryData = this.subCategoryData;

    if (msg === 'new') {

      this.searchtext = '';
      //this.getKpiMainData();
      this.dynamicRows = 5;
      this.rowsOnPageSet = [];
      this.getKpiSubData();
    }
    if (msg === 'edit') {

      dialogRef.componentInstance.MainCategory = this.MainCategory;
      dialogRef.componentInstance.RowID = this.RowID;
      dialogRef.componentInstance.SubCategory = this.SubCategory;
      dialogRef.componentInstance.MainCategoryID = this.MainCategoryID;
    }

    dialogRef.afterClosed().subscribe(result => {
      this.dynamicRows = 5;
      this.rowsOnPageSet = [];
      if (this.searchtext !== '') {
        this.Searchtext();
      }
      else {
        this.getKpiMainData();
        this.getKpiSubData();
      }

      if (result.toString() === 'true') {

        let statusMsg = '';
        if (msg === 'new') {
          statusMsg = 'Saved successfully';
        }
        else {

          statusMsg = 'Updated successfully';
        }
        this.dynamicRows = 5;
        this.rowsOnPageSet = [];
        this.getKpiMainData();
        this.getKpiSubData();

        const dialogRefsub = this.dialog.open(MetricDataDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: statusMsg + 'æææ' + false,
          //  width: '30%'
        });

        setTimeout(() => {
          this.dynamicRows = 5;
          this.rowsOnPageSet = [];
          this.getKpiMainData();
          this.getKpiSubData();
        }, 500);
        dialogRefsub.afterClosed().subscribe(result1 => {
          this.dynamicRows = 5;
          this.rowsOnPageSet = [];

          if (this.searchtext !== '') {
            this.Searchtext();
          }
          else {
            this.getKpiMainData();
            this.getKpiSubData();
          }


          //  this.GetFrustrationType();
        });
      }
    });


  }

  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  exportAsXLSX() {
    let allData: any;
    let subcat = '';
    allData = JSON.parse(JSON.stringify(this.mainData));
    for (let i = 0; i < allData.length; i++) {
      let subcat = '';
      for (let j = 0; j < allData[i].SubCategory.length; j++) {
        subcat = subcat + allData[i].SubCategory[j] + '\n';
      }
      allData[i].SubCategory = subcat;

      delete allData[i].MainCategoryID;
      delete allData[i].RowID;
    }


    this.dorseyService.generateExcel(allData, this.loginCompanyName + '_metric' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Metric', "Company: " + this.loginCompanyName, '', "Metric Table Report", copyRight, 'FFFFFF00', 'FF0000FF');
    // const json = {
    //   data:allData
    //       }
    //       this.dorseyService.Export(json,this.loginCompanyName+'_metric',this.loginCompanyName,'Metric').subscribe(data=>{

    //       });
  }

}

@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
  // styleUrls: ['dialog.css'],
  providers: [DorseyService, MetricdataComponent]
})
export class MetricDataDialogComponent implements OnInit {
  SubCategory: string;
  condition = false;
  dataSelf: string;
  MainCategory: string;
  RowID: string;
  MainCategoryID: string;
  mainData: any;
  CompanyID: string;
  currentUser: string;
  mainCategoryData: any;
  subCategoryData: any;
  editSubData: any;
  btnDisabled = false;
  constructor(
    public dialogRef: MatDialogRef<MetricDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dorseyService: DorseyService, private storage: LocalStorageService) {
    //  dialogRef.disableClose=true;
    this.data = this.data.split('æææ');
    if (this.data[1] === 'true') {
      this.condition = true;
    } else {
      this.condition = false;
    }
    this.data = this.data[0];
  }
  ngOnInit() {
  }
  onYesClick() {
    this.dialogRef.close(true);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }


  getKpiMainData() {
    this.dorseyService.GetKpiMain(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.mainCategoryData = subData;
    });
  }

  getKpiSubData() {
    this.mainData = [];
    this.dorseyService.GetKpiSub(this.CompanyID).subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.subCategoryData = subData;
    });
  }
  AddMetCategory() {
    this.btnDisabled = true;

    setTimeout(() => {
      this.btnDisabled = false;
    }, 1000);
    let count = 0;
    const Editcount = 0;

    if (this.MainCategory == undefined ||
      this.MainCategory === '' || this.SubCategory == undefined ||
      this.SubCategory.trim() === '') {
      this.dataSelf = 'Please enter mandatory(*) fields';
      if (this.data === 'new') {
      }
      count++;
    }

    if (count === 0 && Editcount === 0) {
      this.dataSelf = '';
      if (this.data === 'new') {
        this.Saving(this.data);
      } else {
        this.Saving(this.data);
      }
    } else {
    }
  }
  Saving(msg: string) {
    const json = {
      MainCategoryID: this.MainCategoryID,
      MainCategory: this.MainCategory,
      CreatedBy: this.currentUser,
      RowID: this.RowID,
      CompanyID: this.CompanyID,
      SubCategory: this.SubCategory,
      UpdatedBy: this.currentUser
    };
    if (msg === 'new') {
      let subData = this.subCategoryData.filter(x => x.MainCategoryID === this.MainCategoryID);
      for (let i = 0; i < subData.length; i++) {
        if (subData[i].SubCategory.toLowerCase().trim() === this.SubCategory.toLowerCase().trim()) {
          this.dataSelf = 'Subcategory already exists';
          return;
        }
      }

      this.dorseyService.SaveMetricData(json, 'No').subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.dataSelf = 'Saved successfully';
          this.getKpiSubData();
          this.getKpiMainData();
          this.MainCategory = '';
          this.SubCategory = '';
          setTimeout(() => {
            this.dataSelf = '';
          }, 2000);
        } else {
          this.dataSelf = 'Save failed';
        }

      });
    } else {
      this.editSubData = this.subCategoryData.filter(x => x.MainCategoryID === this.MainCategoryID);
      for (let i = 0; i < this.editSubData.length; i++) {
        if (this.MainCategoryID === this.storage.retrieve('MCID')) {
          if (this.editSubData[i].SubCategory.toLowerCase().trim() === this.SubCategory.toLowerCase().trim() && this.editSubData[i].SubCategory.toLowerCase().trim() !== this.storage.retrieve('subCat').toLowerCase().trim()) {
            this.dataSelf = 'Subcategory already exists';
            return;
          }
        } else {
          if (this.editSubData[i].SubCategory.toLowerCase().trim() === this.SubCategory.toLowerCase().trim()) {
            this.dataSelf = 'Subcategory already exists';
            return;
          }
        }

      }
      this.dorseyService.SaveMetricData(json, 'No').subscribe(data => {
        if (data['_body'].toString() === 'true') {
          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Updated successfully';
          this.getKpiSubData();
          this.getKpiMainData();
        }
      });
    }
  }
  Clear() {
    this.dialogRef.close(false);
  }
  mainChange(event) {
    this.MainCategoryID = event.value;
  }
}
