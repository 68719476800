import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // providers:[LocalStorageService]
})
export class AppComponent implements OnInit {
  menuOpen = false;
  title = 'dorseyGroup';
  menuOpenStatus = true;
  count = 0;
  authenticated = false;
  companyID = "";
  EmployeeID = "";

  constructor(private router: Router, private routerActive: ActivatedRoute, private storage: LocalStorageService) {
    // ,
  }
  ngOnInit() {
    this.companyID = this.storage.retrieve('companyID');
    this.EmployeeID = this.storage.retrieve('EmployeeID');
  }

  onClick(event) {
    if (this.count === 0) {
      this.menuOpen = true;
      this.menuOpenStatus = false;
      this.count++;
    } else {
      this.menuOpen = false;
      this.menuOpenStatus = true;
      this.count = 0;
    }

  }


}
