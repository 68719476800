import { Component, OnInit } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  totalFrustations = [];
  totalFCount: any;
  processData = [];
  dashBoardJson = [];
  dashboard = false;
  dashboardMetric = false;
  dashboardProcess = false;
  dashboardProject = false;

  dashboardCat = false;
  dashboardMe = false;
  dashboardPr = false;
  dashboardPro = false;
  CompanyID: string;

  dashboardSeverity = false;
  id: any;

  dashboardTeam: any;
  dashboardTeamCat: any;
  dashboardTeamPr: any;
  dashboardTeamSeverity: any;
  dashboardTeamPro: any;
  teamData: any

  dashboardSev = false;
  constructor(private dorsey: DorseyService, private storage: LocalStorageService, private route: ActivatedRoute,
    private router: Router) {
    this.id = this.route.snapshot.paramMap.get('TeamID');
    this.dashboardTeam = this.id;
    this.dashboardTeamCat = this.id;
    this.dashboardTeamPr = this.id;
    this.dashboardTeamSeverity = this.id;
    this.dashboardTeamPro = this.id;


    this.storage.store("id", this.id);

  }

  ngOnInit() {
    this.CompanyID = this.storage.retrieve('companyID');
    this.dorsey.GetPointer(this.CompanyID)
      .subscribe((pointer) => {
        this.totalFrustations = (JSON.parse(pointer['_body']));
        let frustrations;
        if (this.id !== 0) {
          frustrations = this.totalFrustations.filter(x => x.PointerTypeID === 1 && x.TeamID.toString() === this.id.toString());

        }
        else {
          frustrations = this.totalFrustations.filter(x => x.PointerTypeID === 1)
        }

        this.totalFCount = frustrations.length;
        this.dorsey.GetProcess(this.CompanyID).subscribe(subData => {

          this.processData = (JSON.parse(subData['_body']));
          if (this.processData.length < 1) {
            //  return;
          }
          for (let j = 0; j < this.processData.length; j++) {
            let processCount = 0; let processName = this.processData[j]['ProcessName'];
            for (let i = 0; i < frustrations.length; i++) {

              if (this.processData[j]['ProcessID'] === frustrations[i]['ProcessID']) {
                processCount++;

              }

            }
            let json = {
              process: processName,
              Issues: processCount
            };
            this.dashBoardJson.push(json);
            this.dashBoardJson.sort(function (obj1, obj2) {

              return obj2.Issues - obj1.Issues;
            });
          }
        });
      });
  }

  GetProcess() {
    this.dorsey.GetProcess(this.CompanyID).subscribe(subData => {
      this.processData = JSON.parse(subData['_body']);
    });
  }


}
