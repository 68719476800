import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LocalStorageService } from 'ngx-webstorage';
import { LoginComponent } from '../login/login.component';
import { OrganizationService } from '../services/organization.service'
import { CompanyDetails, OrganizationLicenseDetails } from './company.model';
import { CompanyService } from '../services/company.service';
import { MatSlideToggleChange, ThemePalette } from '@angular/material';
import { ValidationDialogComponent } from '../add-score-card/validation-dialog';
import { LocationStrategy } from '@angular/common';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  providers: [DorseyService],

})

export class CompanyComponent implements OnInit {
  isMainEntity: boolean;
  EnableSubCompanies: boolean = false;
  NoOfSubCompanies: number;
  EnableScoreCard: boolean = false;
  parentID: number;
  oldCompanyName: string = '';
  companDetails: CompanyDetails;
  companyData: Array<CompanyDetails> = [];
  companyName: string = '';
  dataSelf = '';
  CompanyID: string;
  condition: boolean;
  searchtext: string = '';
  // p: number = 1;
  // rowsOnPage = 10;
  // rowsOnPageSet = [];
  // rowCount: any;
  // dynamicRows = 10;
  isDesc: boolean = false;
  column = '';
  direction: number;
  arrow = true;
  userVisible = false;
  // companyPaging = false;
  displayedColumns: string[] = ['position', 'name'];
  dataSource = new MatTableDataSource();
  saveFilter: Array<CompanyDetails> = [];
  UserName: string;
  Password: string;
  isDisable = false;
  isSearchDisable = false;
  userData: any;
  userRoleData: any;
  UserID: string;
  currentUser: string;
  MenuPermissions: any;
  emailUnique: any;
  menuData: any;
  menuID: string;
  menuName: string;
  FirstName: string;
  LastName: string;
  adminUname = '';
  adminPwd = '';
  adminEmail: string;
  adminPassword: string;
  teamData: any;
  loginUsername: any;
  adminUserName = '';
  organizationId: number;
  userRoleName: string;
  organizationDetails: any;
  showSubEntities: boolean = false;
  parentCompanyId: number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  rolesData: any;
  isSuperAdmin: boolean = false;
  subEntityLevel: number = 0;
  currentRow: number;
  errorMessages: string[] = [];
  entityName: string;
  color: ThemePalette = 'primary';
  isCompanyLogin: boolean;
  isSubCompanyLogin: boolean;
  companiesCountForOrganization: number;
  licenseDetails: OrganizationLicenseDetails;

  constructor(private dorseyService: DorseyService,
    public dialog: MatDialog,
    private location: LocationStrategy,
    private storage: LocalStorageService,
    private login: LoginComponent,
    private organizationService: OrganizationService,
    private companyService: CompanyService) {
    this.currentUser = this.storage.retrieve('user');
    this.menuData = this.storage.retrieve('menuData');
    this.MenuPermissions = this.storage.retrieve('MenuPermissions');
    this.adminUserName = this.storage.retrieve('adminUname');
    this.adminEmail = this.storage.retrieve('username');
    this.adminPassword = this.storage.retrieve('password');
    this.loginUsername = this.storage.retrieve('loginUsername');
    this.isCompanyLogin = this.storage.retrieve("isCompanyLogin");
    this.organizationDetails = this.storage.retrieve("OrganizationDetails");
    this.CompanyID = this.storage.retrieve("companyID");
    this.organizationId = this.organizationDetails.OrganizationId;
    this.isSubCompanyLogin = this.storage.retrieve("isSubCompanyLogin");
    this.isSuperAdmin = this.organizationDetails.IsSuperAdmin;

    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit(): void {
    this.getCompaniesCountByOrganizationId();
    this.GetUsers();
    this.GetMenuMaster();
    this.GetTeam();
    if (this.menuData) {
      let tempdata = this.menuData;
      this.menuData = tempdata.find(x => x.MenuName.toString().toLowerCase() === 'company');
      if (this.menuData) {
        this.menuName = this.menuData["MenuName"];
        this.menuID = this.menuData["MenuID"];
      }
    }
    this.dataSource.paginator = this.paginator;
    if (this.isCompanyLogin) {
      this.getCompanyDetailsByCompanyId(Number(this.CompanyID));
    }
    else if (this.organizationId) {
      this.getCompanyDetailsByOrganizationId(this.organizationId);
    }

  }

  GetTeam() {
    if (this.CompanyID) {
      this.dorseyService.GetTeam(this.CompanyID).subscribe(subData => {
        subData = JSON.parse(subData['_body']);
        this.teamData = subData;

      });
    }
  }

  GetMenuMaster() {
    this.dorseyService.GetMenuMaster().subscribe(subData => {
      subData = JSON.parse(subData['_body']);
      this.menuData = subData;
    });
  }

  getCompanyDetailsByCompanyId(companyId: number): void {
    this.organizationService.getCompanyDetailsByCompanyId(companyId, this.organizationId).subscribe(resp => {
      this.companyData = resp.Data;
      // this.rowsOnPageSet = [];
      // this.dynamicRows = 10;
      if (this.companyData.length === 0) {
        // this.companyPaging = false;
        this.isDisable = true;
        this.isSearchDisable = true;
      }
      else {
        this.saveFilter = JSON.parse(JSON.stringify(this.companyData));
        // // this.companyPaging = true;
        this.isDisable = false;
        this.isSearchDisable = false;
        // this.rowCount = this.companyData.length;
        // this.dataSource = new MatTableDataSource(this.companyData);
        // while (this.dynamicRows < this.rowCount) {
        //   this.rowsOnPageSet.push(this.dynamicRows);
        //   this.dynamicRows = this.dynamicRows + 10;
        // }
        // if (!this.rowsOnPageSet.includes(this.rowCount))
        //   this.rowsOnPageSet.push(this.rowCount);
        // if (this.rowsOnPageSet.length === 1) {
        //   if (this.rowsOnPageSet[0] <= 10)
        //     this.rowsOnPage = this.rowsOnPageSet[0];
        // }
      }
    });
  }

  getCompanyDetailsByOrganizationId(organizationId: number): void {
    this.organizationService.getCompanyDetailsByOrganizationId(organizationId).subscribe(resp => {
      this.companyData = resp.Data;
      // this.rowsOnPageSet = [];
      // this.dynamicRows = 10;
      if (this.companyData.length === 0) {
        // this.companyPaging = false;
        this.isDisable = true;
        this.isSearchDisable = true;
      }
      else {
        this.saveFilter = JSON.parse(JSON.stringify(this.companyData));
        // this.companyPaging = true;
        this.isDisable = false;
        this.isSearchDisable = false;
        // this.rowCount = this.companyData.length;
        // this.dataSource = new MatTableDataSource(this.companyData);
        // while (this.dynamicRows < this.rowCount) {
        //   this.rowsOnPageSet.push(this.dynamicRows);
        //   this.dynamicRows = this.dynamicRows + 10;
        // }
        // if (!this.rowsOnPageSet.includes(this.rowCount))
        //   this.rowsOnPageSet.push(this.rowCount);
        // if (this.rowsOnPageSet.length === 1) {
        //   if (this.rowsOnPageSet[0] <= 10)
        //     this.rowsOnPage = this.rowsOnPageSet[0];
        // }
      }
    });
  }

  GetUsers() {
    this.dorseyService.GetUsersList().subscribe(users => {
      this.userData = JSON.parse(users['_body']);
    });
  }

  GetUserRoleAssignment() {
    this.dorseyService.GetUserRoleAssignment().subscribe(userRoles => {
      this.userRoleData = JSON.parse(userRoles['_body']);
    });
  }

  Searchtext() {
    this.companyData = this.saveFilter;
    let tempCompany = this.companyData.filter(
      (item) => {
        return (
          (item.CompanyName !== null && item.CompanyName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.Email !== null && item.Email.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.Phone1 !== null && item.Phone1.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)
        );
      }

    );
    this.companyData = tempCompany;
    if (this.companyData.length == 0) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
    if (this.searchtext === '') {
      this.companyData = this.saveFilter;
      // this.dynamicRows = 10;
      // this.rowsOnPageSet = [];
      // this.rowCount = this.companyData.length;
      // while (this.dynamicRows < this.rowCount) {
      //   this.rowsOnPageSet.push(this.dynamicRows);
      //   this.dynamicRows = this.dynamicRows + 10;
      // }
      // if (!this.rowsOnPageSet.includes(this.rowCount))
      //   this.rowsOnPageSet.push(this.rowCount);
      // if (this.rowsOnPageSet) {

      //   this.rowsOnPage = this.rowsOnPageSet[0];
      // }
      this.isDisable = false;
    }
    //else {

    // this.rowCount = tempCompany.length;
    // this.dynamicRows = 10;
    // this.rowsOnPageSet = [];
    // while (this.dynamicRows < this.rowCount) {
    //   this.rowsOnPageSet.push(this.dynamicRows);
    //   this.dynamicRows = this.dynamicRows + 10;
    // }
    // if (!this.rowsOnPageSet.includes(this.rowCount)) {
    //   this.rowsOnPageSet.push(this.rowCount);
    //   if (this.rowsOnPageSet)
    //     this.rowsOnPage = this.rowsOnPageSet[0];
    // }

    // if (this.rowsOnPageSet.length === 1) {
    //   if (this.rowsOnPageSet[0] <= 10)
    //     this.rowsOnPage = this.rowsOnPageSet[0];
    // }
    //}
  }

  exportAsXLSX() {
    let allData = JSON.parse(JSON.stringify(this.companyData));
    for (let i = 0; i < allData.length; i++) {
      allData[i].Company = allData[i].CompanyName;
      allData[i]["Email "] = allData[i].Email;
      allData[i].Phone = allData[i].Phone1;
      delete (allData[i].CompanyName);
      delete (allData[i].Phone1);
      delete (allData[i].Address1);
      delete (allData[i].Email);
      delete (allData[i].Address2);
      delete (allData[i].Country);
      delete (allData[i].CIty);
      delete (allData[i].State);
      delete (allData[i].Pincode);
      delete (allData[i].Phone1);
      delete (allData[i].Phone2);
      delete (allData[i].ParentID);
      delete (allData[i].CompanyID);
      delete (allData[i].UpdatedBy);
      delete (allData[i].UpdatedDate);
      delete (allData[i].CreatedBy);
      delete (allData[i].CreatedDate);
      delete (allData[i].IsActive);
    }
    this.dorseyService.generateExcel(allData, 'entity' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Company', "Company: " + this.loginUsername, '', "Company Report", copyRight, 'FFFFFF00', 'FF0000FF');
  }

  enableScoreCardChange(event: MatSlideToggleChange, row): void {
    var msg = row.EnableScoreCard ? 'enable' : 'disable';
    const dialogRef = this.dialog.open(CompanyDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: 'Are you sure you want to ' + msg + ' score card for this entity: ' + row.CompanyName + '?æææ' + true,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.companDetails = row;
      if (result) {
        this.companDetails.EnableScoreCard = event.checked;
        this.companyService.UpdateEnableScoreCard(this.companDetails).subscribe(resp => {
          if (resp.Data) {
            this.condition = false;
            this.dialogCall('Updated successfully');
          }
        });
      }
      else {
        this.companDetails.EnableScoreCard = !this.companDetails.EnableScoreCard;
      }
    });
  }

  getCompaniesCountByOrganizationId(): void {
    this.organizationService.getCompaniesCountByOrganizationId(this.organizationId).subscribe(resp => {
      this.licenseDetails = resp.Data[0];
      this.organizationService.totalLicenses.next(this.licenseDetails.NotUsedLicenses);
      this.organizationService.totalLicenses$ = this.organizationService.totalLicenses.asObservable();
    });
  }

  OpenCompany(): void {
    this.searchtext = '';
    this.errorMessages = [];
    this.organizationService.getCompaniesCountByOrganizationId(this.organizationId).subscribe(resp => {
      this.licenseDetails = resp.Data[0];

      if (this.licenseDetails.NotUsedLicenses <= 0) {
        this.errorMessages.push('Cannot create new entity as there are no licenses');
        this.dialog.open(ValidationDialogComponent, {
          data: {
            errorMessages: this.errorMessages
          }
        });
        return;
      }
      this.licenseDetails.NotUsedLicenses = this.licenseDetails.NotUsedLicenses - 1;
      this.organizationService.totalLicenses.next(this.licenseDetails.NotUsedLicenses);
      this.organizationService.totalLicenses$ = this.organizationService.totalLicenses.asObservable();
      this.companDetails = {
        CompanyID: 0,
        CompanyName: '',
        ParentID: null,
        Address1: '',
        Address2: '',
        Country: '',
        State: '',
        City: '',
        Pincode: '',
        Email: '',
        Phone1: '',
        Phone2: '',
        EnableScoreCard: false,
        EnableSubCompanies: false,
        NoOfSubCompanies: 0,
        OrganizationID: this.organizationId,
        UserID: 0,
        UserName: '',
        Password: '',
        FirstName: '',
        LastName: '',
        EmployeeID: 0,
        CreatedBy: this.currentUser,
        UpdatedBy: this.currentUser,
        RowID: 0,
        RoleID: 0
      }
      this.dialogCall('new');
    });

  }

  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  getSubCompaniesCountByCompanyId(companyId: number): void {
    if (companyId) {

    }
  }

  entityLicenses: number;
  subCompanyCount: number;
  remainingEntityLicenses: number;
  viewSubEntities(element): void {
    this.currentRow = element.CompanyID;
    this.parentCompanyId = element.CompanyID;
    this.subEntityLevel = this.subEntityLevel + 1;
    this.companyService.getSubCompaniesCountByCompanyId(element.CompanyID).subscribe(resp => {
      this.companyService.getSubCompanyDetailsByCompanyId(element.CompanyID).subscribe(resp1 => {
        var subEntitiesAlreadyAssigned = 0;
        if (resp1.Data) {
          resp1.Data.forEach(ent => {
            subEntitiesAlreadyAssigned = subEntitiesAlreadyAssigned + ent.NoOfSubCompanies;
          });
        }
        this.subCompanyCount = resp.Data + subEntitiesAlreadyAssigned;
        this.entityLicenses = element.NoOfSubCompanies;
        this.remainingEntityLicenses = this.entityLicenses - this.subCompanyCount;
        if (this.subEntityLevel > 1) {
          this.showSubEntities = false;
          this.entityName = '';
          setTimeout(() => {
            this.showSubEntities = true;
            this.entityName = element.CompanyName;
          }, 200);
        } else {
          this.showSubEntities = true;
          this.entityName = element.CompanyName;
        }
      });
    });
  }

  Navigate(element) {
    this.login.GetTeam(element.CompanyID);
    this.dorseyService.GetUsersList().subscribe(users => {
      this.userData = JSON.parse(users['_body']);
      let tempUser = this.userData.filter(x => x.CompanyID.toString() === element.CompanyID.toString() && x.EmployeeID.toString() === '0');
      this.login.GetUserRoleAssignment();
      this.storage.store("adminEmail", this.adminEmail);
      this.storage.store("adminPassword", this.adminPassword);
      this.storage.store("adminUserName", this.adminUserName);
      this.storage.store("userData", this.userData);
      this.storage.store("companyNavigate", true);
      var loginLevel = this.storage.retrieve("loginLevel");
      if (loginLevel) {
        this.storage.store("loginLevel", loginLevel);
      }
      if (tempUser.length > 0) {
        this.login.LoginWithCompany(tempUser[0].UserName, tempUser[0].Password, true);
      }
    });
  }

  dialogCall(msg: string) {
    this.GetUsers();
    const dialogRef = this.dialog.open(CompanyDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: msg + 'æææ' + false,
    });
    dialogRef.componentInstance.companyData = this.companyData;
    dialogRef.componentInstance.companyDetails = this.companDetails;
    dialogRef.componentInstance.userListData = this.userData;
    dialogRef.componentInstance.currentUser = this.currentUser;
    dialogRef.componentInstance.isSuperAdmin = this.isSuperAdmin;
    dialogRef.componentInstance.entityLicenses = this.entityLicenses;
    dialogRef.componentInstance.licenseDetails = this.licenseDetails;

    if (msg == 'new') {
      this.searchtext = '';
      this.getCompanyDetailsByOrganizationId(this.organizationId);
    }
    if (msg === 'edit') {
      this.searchtext = '';
    }
    dialogRef.afterClosed().subscribe(result => {
      if (this.searchtext !== '' && this.searchtext !== undefined) {
        this.Searchtext();

      }
      else {
        this.getCompanyDetailsByOrganizationId(this.organizationId);
      }
      if (result && result.toString() === 'true') {
        let statusMsg = '';
        if (msg === 'new') {
          statusMsg = 'Saved successfully';
        }
        else {
          statusMsg = 'Updated successfully';
        }
        // this.dynamicRows = 10;
        const dialogRefsub = this.dialog.open(CompanyDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: statusMsg + 'æææ' + false,
        });
        setTimeout(() => {
          this.getCompanyDetailsByOrganizationId(this.organizationId);
          this.GetUsers();
        }, 500);


        dialogRefsub.afterClosed().subscribe(result1 => {

          this.getCompaniesCountByOrganizationId();
          if (this.searchtext !== '') {
            this.Searchtext();
          }
          else {
            this.getCompanyDetailsByOrganizationId(this.organizationId);
            this.GetUsers();
          }
        });
      } else {
        this.getCompaniesCountByOrganizationId();
      }
    });
  }

  EditCompany(companyRow) {
    this.companDetails = companyRow;
    this.entityLicenses = this.companDetails.NoOfSubCompanies;
    this.storage.store('CName', this.companDetails.CompanyName);
    this.dialogCall('edit');
  }

  // ifMaxRow() {
  //   if (this.rowsOnPage == this.companyData.length) {
  //     this.rowsOnPage = this.rowsOnPage - 1;
  //   }
  // }

  DeleteCompany(element) {
    this.CompanyID = element.CompanyID;
    this.companyService.getSubCompaniesCountByCompanyId(element.CompanyID).subscribe(resp => {
      if (resp.Data > 0) {
        this.dialogCall("Delete Failed. This entity has sub entities");
        return;
      } else {
        const dialogRef = this.dialog.open(CompanyDialogComponent, {
          panelClass: 'custom-dialog-container',
          data: 'Are you sure you want to delete entity: ' + element.CompanyName + '?æææ' + true,
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.companyService.deleteCompany(Number(this.CompanyID)).subscribe(resp => {
              if (resp.Data) {
                // this.ifMaxRow();
                this.condition = false;
                this.dialogCall('Deleted successfully');
                this.showSubEntities = false;
              }
              // this.dynamicRows = 10;
              // this.rowsOnPageSet = [];
              this.getCompanyDetailsByOrganizationId(this.organizationId);
            });
          }
        });
      }
    });
  }
}

@Component({
  selector: 'app-dialog',
  templateUrl: 'dialog.html',
  providers: [DorseyService],
})
export class CompanyDialogComponent {
  public breakpoint: number; // Breakpoint observer code
  saveClick = false;
  CompanyID: number;
  dataSelf = '';
  condition: boolean;
  addCompany: string;
  companyData: Array<CompanyDetails> = [];
  companyDetails: CompanyDetails;
  currentUser: string;
  userListData: any;
  userdata: any;
  oldCompanyName: string;
  btnDisabled = false;
  organizationID: number;
  parentID: number;
  isSuperAdmin: boolean;
  companiesCountForOrganization: number;
  licenseDetails: OrganizationLicenseDetails;
  organizationDetails: any;
  errorMessages: string[] = [];
  entityLicenses: number;
  remainingEntityLicenses: number;
  subEntityLicenses: number;
  existingLicenses: number;
  subCompanyCount: number = 0;
  constructor(
    public dialogRef: MatDialogRef<CompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dorseyService: DorseyService,
    private storage: LocalStorageService,
    private organizationService: OrganizationService,
    private companyService: CompanyService,
    public dialog: MatDialog) {
    this.data = this.data.split('æææ');
    if (this.data[1] === 'true') {
      this.condition = true;
    } else {
      this.condition = false;
    }
    this.data = this.data[0];
    this.organizationDetails = this.storage.retrieve("OrganizationDetails");
  }

  ngOnInit() {
    this.companyData = JSON.parse(JSON.stringify(this.companyData));
    if (this.data === 'new') {
      this.companyDetails.ParentID = this.CompanyID;
    }
    this.breakpoint = window.innerWidth <= 1000 ? 1 : 3;
    this.btnDisabled = this.isSuperAdmin ? true : false;
    if (this.data !== 'new') {
      this.getSubCompaniesCountByCompanyId();
      this.getCompaniesCountByOrganizationId();
    }
  }

  getCompaniesCountByOrganizationId(): void {
    this.organizationService.getCompaniesCountByOrganizationId(this.organizationDetails.OrganizationId).subscribe(resp => {
      this.licenseDetails = resp.Data[0];
      this.organizationService.totalLicenses.next(this.licenseDetails.NotUsedLicenses);
      this.organizationService.totalLicenses$ = this.organizationService.totalLicenses.asObservable();
    });
  }

  getSubCompaniesCountByCompanyId(): void {
    if (this.companyDetails) {
      this.companyService.getSubCompaniesCountByCompanyId(this.companyDetails.CompanyID).subscribe(resp => {
        this.subCompanyCount = resp.Data;
      });
    }
  }

  vaidateCompany(): void {


    this.btnDisabled = true;
    setTimeout(() => {
      this.btnDisabled = false;
    }, 1000);
    if (!this.companyDetails.FirstName.trim() ||
      !this.companyDetails.LastName.trim() ||
      !this.companyDetails.CompanyName.trim() ||
      !this.companyDetails.Address1.trim() ||
      !this.companyDetails.UserName.trim() ||
      !this.companyDetails.Country.trim() ||
      !this.companyDetails.State.trim() ||
      !this.companyDetails.City.trim() ||
      !this.companyDetails.Pincode ||
      !this.companyDetails.Phone1 ||
      !this.companyDetails.Password ||
      !this.companyDetails.Email ||
      (this.companyDetails.EnableSubCompanies &&
        this.companyDetails.NoOfSubCompanies === 0
      )) {
      this.dataSelf = 'Please enter mandatory(*) fields';
      return;
    }
    else {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!this.companyDetails.Email.match(mailformat)) {
        this.dataSelf = 'Please enter valid email';
        this.saveClick = false;
        return;
      }
    }
    //for main entity license validation
    if (this.licenseDetails && (!this.companyDetails.ParentID || this.companyDetails.ParentID === 0)) {
      if (this.data === 'new') {
        if (this.companyDetails.NoOfSubCompanies > this.licenseDetails.NotUsedLicenses) {
          this.dataSelf = 'Cannot provide sub entities as there are no Licenses';
          return;
        }
        this.dorseyService.GetUsersList().subscribe(users => {
          this.userListData = JSON.parse(users['_body']);
          this.saveCompany(this.data);
        });
      } else {
        this.companyService.getSubCompanyDetailsByCompanyId(this.companyDetails.CompanyID).subscribe(resp => {
          var subEntitiesAlreadyAssigned = 0;
          if (resp.Data) {
            resp.Data.forEach(ent => {
              subEntitiesAlreadyAssigned = subEntitiesAlreadyAssigned + ent.NoOfSubCompanies;
            });
          }
          var totalL = this.entityLicenses + this.licenseDetails.NotUsedLicenses;
          if (this.subCompanyCount + subEntitiesAlreadyAssigned > this.companyDetails.NoOfSubCompanies) {
            this.dataSelf = 'No. of sub entities cannot be less than already created sub entities';
            return;
          }
          else if (this.companyDetails.NoOfSubCompanies > totalL) {
            this.dataSelf = 'Cannot provide sub entities as there are no Licenses';
            return;
          }
          this.dorseyService.GetUsersList().subscribe(users => {
            this.userListData = JSON.parse(users['_body']);
            this.saveCompany(this.data);
          });
        });
      }
    } else { //for sub entity license validation

      if (this.data !== 'new') {
        this.companyService.getSubCompanyDetailsByCompanyId(this.companyDetails.CompanyID).subscribe(resp => {
          var subEntitiesAlreadyAssigned = 0;
          if (resp.Data) {
            resp.Data.forEach(ent => {
              subEntitiesAlreadyAssigned = subEntitiesAlreadyAssigned + ent.NoOfSubCompanies;
            });
          }

          if (this.subCompanyCount + subEntitiesAlreadyAssigned > this.companyDetails.NoOfSubCompanies) {
            this.dataSelf = 'No. of sub entities cannot be less than already created sub entities';
            return;
          } else if (this.companyDetails.NoOfSubCompanies > this.remainingEntityLicenses) {
            this.dataSelf = 'Cannot provide sub entities as there are no Licenses';
            return;
          }
          this.dorseyService.GetUsersList().subscribe(users => {
            this.userListData = JSON.parse(users['_body']);
            this.saveCompany(this.data);
          });
        });
      } else {
        if (this.companyDetails.NoOfSubCompanies > this.remainingEntityLicenses) {
          this.dataSelf = 'Cannot provide sub entities as there are no Licenses';
          return;
        }
        this.dorseyService.GetUsersList().subscribe(users => {
          this.userListData = JSON.parse(users['_body']);
          this.saveCompany(this.data);
        });
      }
    }
  }

  enableSubCompanies(event: MatSlideToggleChange, element: CompanyDetails): void {

    // if (element.EnableSubCompanies == false && this.subCompanyCount > 0) {
    //   this.errorMessages.push('Cannot disable Sub Entites as there are already sub entites created for the entity: ' + element.CompanyName);
    // }
    // if (this.errorMessages.length != 0) {
    //   this.dialog.open(ValidationDialogComponent, {
    //     data: {
    //       errorMessages: this.errorMessages
    //     }
    //   });
    //   element.EnableSubCompanies = !event.checked;
    //   this.companyDetails.EnableSubCompanies = !event.checked;
    //   console.log(this.companyDetails)
    //   return;
    // } else {
    //   element.NoOfSubCompanies = element.EnableSubCompanies ? element.NoOfSubCompanies : 0;
    // }

    element.NoOfSubCompanies = element.EnableSubCompanies ? element.NoOfSubCompanies : 0;
  }

  saveCompany(msg: string) {
    if (this.companyDetails.Pincode.length < 5) {
      this.dataSelf = "Zip code should be 5 digits";
      this.saveClick = false;
      setTimeout(() => {
        this.dataSelf = "";
      }, 2000);
      return;
    }
    if (this.companyDetails.CompanyID) {
      this.companyDetails.UpdatedBy = this.currentUser;
    }
    if (msg === 'new') {
      if (this.companyData && this.companyData.find(ent => ent.CompanyName.toLowerCase().trim() == this.companyDetails.CompanyName.toLowerCase().trim())) {
        this.dataSelf = 'Entity name already exist';
        return;
      }
      if (this.userListData && this.userListData.find(ent => ent.UserName.toLowerCase().trim() == this.companyDetails.UserName.toLowerCase().trim())) {
        this.dataSelf = 'UserName already exist';
        return;
      }
      this.companyService.saveCompany(this.companyDetails).subscribe(resp => {
        if (resp.Data) {
          this.dataSelf = 'Saved successfully';
          setTimeout(() => {
            this.dataSelf = '';
            this.dorseyService.GetUsersList().subscribe(users => {
              this.userListData = JSON.parse(users['_body']);
            });
          }, 2000);
          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Save failed';
        }
      })
    } else {
      if (this.companyDetails.CompanyName.toLowerCase().trim() !== this.storage.retrieve('CName').toLowerCase().trim()) {
        var data = this.companyData.filter(ent => ent.CompanyName.toLowerCase().trim() != this.storage.retrieve('CName').toLowerCase().trim());
        if (data.find(ent => ent.CompanyName.toLowerCase().trim() == this.companyDetails.CompanyName.toLowerCase().trim())) {
          this.dataSelf = 'Entity name already exist';
          return;
        }
      }

      if (this.companyDetails.UserName !== this.storage.retrieve('Uname')) {
        this.userListData = this.userListData.filter(ent => ent.UserID !== this.companyDetails.UserID);
        if (this.userListData && this.userListData.find(ent => ent.UserName.toLowerCase().trim() == this.companyDetails.UserName.toLowerCase().trim())) {
          this.dataSelf = 'UserName already exist';
          return;
        }
      }
      this.companyService.saveCompany(this.companyDetails).subscribe(resp => {
        if (resp.Data === true) {
          this.organizationService.getCompaniesCountByOrganizationId(this.organizationDetails.OrganizationId).subscribe(resp => {
            this.licenseDetails = resp.Data[0];
            this.organizationService.totalLicenses.next(this.licenseDetails.NotUsedLicenses);
            this.organizationService.totalLicenses$ = this.organizationService.totalLicenses.asObservable();
          });
          this.dialogRef.close(true);
        } else {
          this.dataSelf = 'Updated successfully';
        }
        this.getCompaniesCountByOrganizationId();
      });
    }
  }

  clear(): void {
    this.dialogRef.close(false);
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 1000) ? 1 : 3;
  }
  onYesClick() {
    this.dialogRef.close(true);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  isAlpha(evt) {
    var keyCode = (evt.which) ? evt.which : evt.keyCode
    if ((keyCode < 65 || keyCode > 90) && (keyCode < 97 || keyCode > 123) && keyCode != 32)
      return false;
    return true;
  }

  validatePhone(event: any) {
    if ((event.key) == "Backspace" || event.key == "ArrowLeft" || event.key == "ArrowRight") {
      return;
    } else {
      const regexpattern = '^[0-9]\d*$';
      if (!event.key.match(regexpattern)) {
        event.preventDefault();
      }
    }

  }

  validateEmail(event: any) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!this.companyDetails.Email) {
      if (event.keyCode == 32) {
        event.preventDefault();
      }
    }
  }

  validate() {
    this.dataSelf = '';
    if (this.storage.retrieve('CName'))
      if (this.companyDetails.CompanyName.toLowerCase().trim() !== this.storage.retrieve('CName').toLowerCase().trim()) {
        var data = this.companyData.filter(ent => ent.CompanyName.toLowerCase().trim() != this.storage.retrieve('CName').toLowerCase().trim());
        if (data.find(ent => ent.CompanyName.toLowerCase().trim() == this.companyDetails.CompanyName.toLowerCase().trim())) {
          this.dataSelf = 'Entity name already exist';
          return;
        }
      }
  }

  valZero() {
    var re = /^(?=.*\d)(?=.*[1-9]).{1,16}$/;
    if (this.companyDetails.Phone1 !== '') {
      if (!this.companyDetails.Phone1.match(re)) {
        this.dataSelf = "Phone number invalid";
        this.saveClick = false;
        this.companyDetails.Phone1 = "";

        setTimeout(() => {
          this.dataSelf = "";
        }, 2000);
      }
    }
    if (this.companyDetails.Phone2 !== '') {
      if (!this.companyDetails.Phone2.match(re)) {
        this.dataSelf = "Phone number invalid";
        this.saveClick = false;
        this.companyDetails.Phone2 = "";
        setTimeout(() => {
          this.dataSelf = "";
        }, 2000);
      }
    }
  }
}
