import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { IResponsePackage } from "../models/response-package.model";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GoalService {

    private httpUrl: string = environment.API_URL;
    constructor(private http: HttpClient) { }

    getGoals(companyId: number): Observable<IResponsePackage> {
        return this.http.get<IResponsePackage>(`${this.httpUrl}Dorsey/GetGoalsByCompanyId?companyId=${companyId}`)
            .pipe(
                catchError(this.handleError)
            );
    }

    saveGoal(entity: any): Observable<any> {
        return this.http.post(`${this.httpUrl}Dorsey/SaveGoal`, entity).pipe(
            catchError(this.handleError)
        );
    }

    deleteGoal(goalId: number): Observable<any> {
        return this.http.get(`${this.httpUrl}Dorsey/DeleteGoal?goalId=${goalId}`).pipe(
            catchError(this.handleError)
        );
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occured: ${err.error.message}`;
        }
        else {
            errorMessage = `Server returned code: ${err.status}, error message is ${err.message}`
        }
        return throwError(errorMessage);
    }

}
