import { Component, OnInit, Inject } from '@angular/core';
import { DorseyService } from '../dorsey.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as c3 from 'c3';
import * as d3 from 'd3';
import { LocalStorageService } from 'ngx-webstorage';
import { tickStep } from 'd3';
import htmlToImage from 'html-to-image';
import { EmployeeLoginLevel } from '../common/models/login-level.model';
import { Department } from '../departments/department.model';
import { ScoreCardTypeEnum } from '../performance-score-card/performance-score-card.model';
import { DepartmentService } from '../services/department.service';
import { copyRight } from '../copyRight.model';

@Component({
  selector: 'app-report-process',
  templateUrl: './report-process.component.html',
  // styleUrls: ['./report-process.component.scss'],
  providers: [DorseyService],
  inputs: ['dashboardProcess', 'dashboardPr', 'dashboardTeamPr']
})
export class ReportProcessComponent implements OnInit {
  copyRight: string = copyRight;
  departmentList: Array<Department> = [];
  employeeDetails: any;
  scoreCardTypes: string[] = [ScoreCardTypeEnum.Department, ScoreCardTypeEnum.Team];

  processData: any;
  activityData: any;
  pointerData: any;
  frustrationData: any;
  selectedDepartmentId: number;
  frustData = [];
  frust = [];
  view = {};
  dataSource: Object;
  totalCount = 0;
  dashboardProcess = true;
  dashboard = true;
  categories = [];
  warningMsg = '';
  dashboardPr = true;
  departmentName: string;
  p: number = 1;
  rowsOnPage = 5;
  rowsOnPageSet = [];
  rowCount: any;
  dynamicRows = 5;
  activityPaging = false;
  metricList = [];
  selectedReportType: string;
  CompanyID: string;
  isDisable = false;
  saveFilter = [];
  searchtext: string = '';
  frustrationbyprocess: any[];
  wrng: string;
  disableSearch = false;
  toggleTeam: boolean = false;
  UserTeamID: string;
  teamData: any;
  TeamID: any;
  selected: any;
  dashboardTeamPr: any;
  EmployeeID: string;
  tempTeam = [];
  companyName: string;
  headerDate = new Date();
  TeamName: string;
  employeeData: any;
  copyDate: number;
  isDesc: boolean = false;
  column = "";
  direction: number;
  arrow = true;
  constructor(private dorseyService: DorseyService,
    private departmentService: DepartmentService, private dialog: MatDialog, private storage: LocalStorageService) {

    this.metricList = [];
    this.companyName = sessionStorage.getItem('loginCompanyName');
    this.EmployeeID = this.storage.retrieve('EmployeeID');
    this.employeeDetails = this.storage.retrieve('employeeDetails');

    if (this.EmployeeID.toString() === "0") {

    }
    else {
      this.TeamID = this.storage.retrieve('TeamID');
    }

  }


  ngOnInit() {

    this.CompanyID = this.storage.retrieve('companyID');
    this.warningMsg = '';

    if (this.employeeDetails) {

      if ((this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) || (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team)) {
        this.toggleTeam = true;

        this.getTeamsByDepartmentId(this.employeeDetails.DepartmentID)
      }
      else {
        if (!this.dashboardTeamPr)

          this.reportTypeChange(ScoreCardTypeEnum.Department)

        else
          this.getTeamsByDepartmentId(0)


      }
    }
    else {
      if (!this.dashboardTeamPr)

        this.reportTypeChange(ScoreCardTypeEnum.Department)

      else
        this.getTeamsByDepartmentId(0)

    }


    this.copyDate = (new Date()).getFullYear();

  }
  teamChange(event): void {
    this.searchtext = '';
    this.selectedDepartmentId = 0;

    this.TeamID = event.value;

    let teamd = this.teamData.filter(x => x.TeamID === this.TeamID)

    if (teamd.length > 0) {
      this.TeamName = teamd[0].TeamName;
    }
    this.ConstructReport();
  }
  reportTypeChange(scoreCardType: string): void {
    this.searchtext = '';
    this.departmentList = [];
    this.teamData = [];
    this.selectedDepartmentId = 0;
    this.selectedReportType = scoreCardType;
    this.toggleTeam = scoreCardType === ScoreCardTypeEnum.Department ? false : true;
    this.getDepartments();
    this.getTeamsByDepartmentId(this.selectedDepartmentId);

  }

  departmentChange(selectedDepartmentId): void {
    this.searchtext = '';
    this.getTeamsByDepartmentId(selectedDepartmentId);

    this.departmentName = this.departmentList.find(ent => ent.DepartmentID === selectedDepartmentId).DepartmentName;
  }

  private getDepartments(): void {
    this.departmentService.getDepartments(Number(this.CompanyID)).subscribe(resp => {
      this.departmentList = resp.Data;
      if (this.employeeDetails) {

        if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) {
          this.departmentList = this.departmentList.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID);
        }
        else if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
          this.departmentList = this.departmentList.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID);
          this.getTeamsByDepartmentId(this.employeeDetails.DepartmentID);
        }

      }
      this.departmentList.splice(0, 0, { DepartmentID: 0, DepartmentName: 'Aggregate Report', DepartmentDescription: '0', CompanyID: Number(this.CompanyID), CreatedBy: '', UpdatedBy: '' });
    });
  }

  private getTeamsByDepartmentId(selectedDepartmentId): void {

    this.dorseyService.GetTeam(this.CompanyID.toString()).subscribe(resp => {
      var companyTeamList = JSON.parse(resp['_body']);
      this.teamData = companyTeamList;

      if (this.employeeDetails && companyTeamList) {
        if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Department) {
          this.teamData = this.teamData.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID);
        }
        else if (this.employeeDetails.IsEmployeeLoginLevel == EmployeeLoginLevel.Team) {
          this.teamData = this.teamData.filter(ent => ent.DepartmentID === this.employeeDetails.DepartmentID && ent.TeamID == this.employeeDetails.TeamID);
          this.selectedDepartmentId = this.teamData[0].DepartmentID;
          this.TeamID = this.teamData[0].TeamID;
        }
        else {

          if (!selectedDepartmentId) {
            this.selectedDepartmentId = 0;
            this.teamData = companyTeamList;
          }
          else if (selectedDepartmentId == 0) {
            this.teamData = companyTeamList;
          }
          else {
            this.teamData = this.teamData.filter(ent => ent.DepartmentID === selectedDepartmentId);
          }

        }
      }
      else {
        if (selectedDepartmentId == 0)
          this.teamData = companyTeamList;
        else

          this.teamData = companyTeamList.filter(ent => ent.DepartmentID === selectedDepartmentId);
      }

      if (this.dashboardTeamPr !== undefined) {
        this.TeamID = this.dashboardTeamPr;
      }
      else if (this.employeeDetails && this.employeeDetails.IsEmployeeLoginLevel === EmployeeLoginLevel.Team) {
        this.TeamID = this.TeamID;
      }
      else {
        this.TeamID = 0;

      }

      this.dorseyService.GetPointer(this.CompanyID).subscribe(pointer => {
        this.pointerData = JSON.parse(pointer['_body']);

        if (this.TeamID == 0) {
          this.teamData = this.teamData.filter(entity1 => this.pointerData.find(entity2 => entity1.TeamID == entity2.TeamID && entity2.PointerTypeID == 1));

          this.teamData.splice(0, 0, { TeamID: 0, TeamName: 'Aggregate Report' });
        }

        this.ConstructReport();

      });

    });
  }


  sort(property) {
    this.arrow = !this.arrow;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }


  Searchtext() {
    this.frustrationbyprocess = this.saveFilter;

    let tempfrustrationbyprocess = this.frustrationbyprocess.filter(
      (item) => {
        return (
          (item.ProcessName !== null && item.ProcessName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ActivityName !== null && item.ActivityName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.FrustrationsTypeName !== null && item.FrustrationsTypeName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.Subject !== null && item.Subject.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.Description !== null && item.Description.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.StatusName !== null && item.StatusName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.SeverityName !== null && item.SeverityName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1) ||
          (item.ProjectName !== null && item.ProjectName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1))
          || (item.TeamName !== null && item.TeamName.toLowerCase().indexOf(this.searchtext.toLowerCase().trim()) > -1)


      }

    );

    this.metricList = tempfrustrationbyprocess;
    if (this.metricList.length == 0) {
      this.isDisable = false;
      setTimeout(() => {
        this.wrng = "No data available";
      }, 100);

    }
    else {
      this.isDisable = true;
    }
    if (this.searchtext === '') {
      this.frustrationbyprocess = this.saveFilter;
      this.dynamicRows = 5;
      this.rowsOnPageSet = [];
      this.rowCount = this.frustrationbyprocess.length;
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 5;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount))
        this.rowsOnPageSet.push(this.rowCount);
      if (this.rowsOnPageSet) {

        this.rowsOnPage = this.rowsOnPageSet[0];
      }
      this.isDisable = true;
    } else {
      this.rowCount = tempfrustrationbyprocess.length;
      this.dynamicRows = 5;
      this.rowsOnPageSet = [];
      while (this.dynamicRows < this.rowCount) {
        this.rowsOnPageSet.push(this.dynamicRows);
        this.dynamicRows = this.dynamicRows + 5;
      }
      if (!this.rowsOnPageSet.includes(this.rowCount)) {
        this.rowsOnPageSet.push(this.rowCount);
        if (this.rowsOnPageSet)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }

      if (this.rowsOnPageSet.length === 1) {
        if (this.rowsOnPageSet[0] <= 5)
          this.rowsOnPage = this.rowsOnPageSet[0];
      }
    }
  }
  exportAsXLSX(): void {
    let allData = JSON.parse(JSON.stringify(this.metricList));

    for (let i = 0; i < allData.length; i++) {

      allData[i][" No. "] = i + 1;
      if (this.selectedReportType === ScoreCardTypeEnum.Department) {
        allData[i]["Department"] = this.metricList[i].DepartmentName;
      }

      allData[i].Team = this.metricList[i].TeamName;
      allData[i].Process = this.metricList[i].ProcessName;
      allData[i].Activity = this.metricList[i].ActivityName;
      allData[i].Team = this.metricList[i].TeamName;
      allData[i][" Type "] = this.metricList[i].FrustrationsTypeName;
      allData[i][" Frustration "] = this.metricList[i].Subject;
      allData[i][" Description "] = this.metricList[i].Description;
      allData[i].Status = this.metricList[i].StatusName;

      allData[i].Severity = this.metricList[i].SeverityName;
      allData[i].Project = this.metricList[i].ProjectName;


      delete (allData[i].DepartmentName);
      delete (allData[i].EmployeeName);
      delete (allData[i].VersionID);
      delete (allData[i].GoalName);
      delete (allData[i].GoalID);
      delete (allData[i].GreenRange);
      delete (allData[i].RedRange);

      delete (allData[i].YellowRange);

      delete (allData[i].ProcessName);
      delete (allData[i].ActivityName);
      delete (allData[i].Source);
      delete (allData[i].TeamName);
      delete (allData[i].FrustrationsTypeName);

      delete (allData[i].Subject);
      delete (allData[i].Description);

      delete (allData[i].StatusName);
      delete (allData[i].SeverityName);
      delete (allData[i].ProjectName);


      delete (allData[i].PointerID);
      delete (allData[i].ActivityID);
      delete (allData[i].PointerTypeID);
      delete (allData[i].CompanyID);
      delete (allData[i].EmployeeID);
      delete (allData[i].UOM);
      delete (allData[i].Target);
      delete (allData[i].Type);
      delete (allData[i].StatusID);

      delete (allData[i].SeverityID);
      delete (allData[i].ProcessID);
      delete (allData[i].DepartmentID);
      delete (allData[i].TeamID);


      delete (allData[i].FrustrationsTypeID);
      delete (allData[i].Date);
      delete (allData[i].IsActive);
      delete (allData[i].CreatedDate);

      delete (allData[i].CreatedBy);
      delete (allData[i].UpdatedDate);
      delete (allData[i].UpdatedBy);
      delete (allData[i].ProjectID);


    }

    let allData1 = JSON.parse(JSON.stringify(this.frust));

    for (let i = 0; i < allData1.length; i++) {
      // this.frust[i].label = this.frust[i].value;
      allData1[i].label = this.frust[i].label;
      allData1[i].value = this.frust[i].value;
      delete (allData1[i].data);
    }

    let Cumulative = document.getElementById('chartProcess').querySelector('svg').querySelectorAll('.c3-target-Cumulative-percentage');
    let Cumulativecircle = document.getElementById('chartProcess').querySelector('svg').querySelectorAll('.c3-circle');
    let circle = Array.from(Cumulativecircle);
    circle.forEach(function (element) {
      element.setAttribute("style", "fill:#e75632")
    })

    let cumulativegraph = Array.from(Cumulative)
    cumulativegraph.forEach(function (element) {
      element.setAttribute("style", "fill:none")
    })

    let image = '';

    htmlToImage.toPng(document.getElementById('chartProcess'),)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;

        image = dataUrl;
      });
    setTimeout(() => {

    }, 500);

    let name: any;
    if (this.selectedReportType == ScoreCardTypeEnum.Department && this.departmentName) {
      name = this.departmentName;
    } else if ((!this.selectedReportType || this.selectedReportType == ScoreCardTypeEnum.Team) && this.TeamName) {
      name = this.TeamName;
    } else {
      name = "Aggregate Report";
    }

    setTimeout(() => {
      this.dorseyService.generateReportExcel(allData1, allData, image, this.companyName + '_Frustrations report by process' + "_" + (new Date().getMonth() + 1) + '-' + new Date().getDate() + '-' + new Date().getFullYear(), 'Frustrations report by process', "Company: " + this.companyName, this.selectedReportType ? this.selectedReportType + ": " + name : 'Team' + ": " + name, "Frustrations report by process Report", copyRight, 'FFFFFF00', 'FF0000FF');
    }, 2000);

  }

  ConstructReport() {
    this.metricList = [];
    this.frustData = [];
    this.categories = [];
    this.frust = [];
    this.rowsOnPageSet = [];
    this.rowsOnPage = 5;
    this.warningMsg = '';
    this.dynamicRows = 5;
    var values = [];
    var perito = [];
    var sum = 0;
    let procData: any;
    this.dorseyService.GetPointer(this.CompanyID).subscribe(pointer => {
      this.pointerData = JSON.parse(pointer['_body']);

      if (this.TeamID == 0)
        this.pointerData = this.pointerData.filter(entity1 => this.teamData.find(entity2 => entity1.TeamID == entity2.TeamID) && entity1.PointerTypeID == 1);
      else {
        this.pointerData = this.pointerData.filter(entity1 => entity1.TeamID == this.TeamID && entity1.PointerTypeID == 1);
      }

      this.totalCount = this.pointerData.length;

      this.dorseyService.GetProcess(this.CompanyID).subscribe(process => {
        this.processData = JSON.parse(process['_body'])
        if (this.TeamID == 0)
          this.processData = this.processData.filter(entity1 => this.teamData.find(entity2 => entity1.TeamID == entity2.TeamID));
        else {
          this.processData = this.processData.filter(entity1 => entity1.TeamID == this.TeamID);
        }

        if (this.totalCount) {

          for (let i = 0; i < this.processData.length; i++) {
            let data = this.pointerData.filter(x => x.ProcessID === this.processData[i].ProcessID);
            if (data.length > 0) {

              if (this.totalCount) {
                this.frustData.push(data);
                this.view['CategoryName'] = this.processData[i].ProcessName;
                if (Object.keys(this.categories).indexOf(this.processData[i].ProcessName) > -1) {
                  this.categories[this.processData[i].ProcessID] = data.length;
                }
                else {
                  this.categories[this.processData[i].ProcessName] = data.length;
                }

                for (let li = 0; li < data.length; li++) {
                  this.metricList.push(data[li]);
                }
                sum = sum + data.length;
                this.frust.push({
                  data: data,
                  label: this.processData[i].ProcessName,
                  value: data.length
                });

                this.frust.sort(function (obj1, obj2) {

                  return obj2.value - obj1.value;
                });

              }

            }
          }
        } else {
          this.warningMsg = 'No data available';

          if (!this.metricList.length) {
            this.isDisable = false;
            this.disableSearch = false;
          }
          else {
            this.isDisable = true;
            this.disableSearch = true;
          }
          return;
        }

        if (this.metricList.length === 0) {
          this.isDisable = false;
          this.disableSearch = false;
          return;
        }
        else {
          this.isDisable = true;
          this.disableSearch = true;
        }

        let sortOrder = this.sortByValue(this.categories);

        let perVal = 0;
        this.categories = [];

        for (let v = 0; v < sortOrder.length; v++) {
          values.push(sortOrder[v][1]);

          let processFilter = this.processData.filter(x => x.ProcessID.toString() === sortOrder[v][0].toString());
          if (processFilter.length > 0) {
            this.categories.push(processFilter[0].ProcessName);
          }
          else {
            this.categories.push(sortOrder[v][0]);
          }
          perVal = perVal + sortOrder[v][1];
          perito.push((perVal / sum));
        }


        var obj = {
          'Frustration Count': values,
          'Cumulative percentage': perito
        };

        let chart = c3.generate({
          bindto: '#chartProcess',
          data: {
            json: obj,
            axes: {
              'Cumulative percentage': 'y2'
            },
            type: 'bar',
            types: {

              'Cumulative percentage': 'line',

            }, groups: [
              ['Frustration Count', 'Cumulative percentage']
            ]


          },

          title: {
            text: "Processes",
            padding: {
              top: 20,
              right: 20,
              bottom: 40,
              left: 50
            }
          },
          color: {
            pattern: ['#4aabe7', '#d55632']
          },
          legend: {
            show: true,
            position: "right"

          },

          axis: {
            x: {
              type: 'category',
              // label: 'Processes',

              //  label: {
              //   text: 'Processes',
              //   position: 'outer-center'
              // },
              categories: this.categories,
              tick: {
                rotate: 50,
                multiline: false
                // multiline: true,
                // multilineMax:2
              },

              height: 100,
            },

            y: {
              //max :10,
              label: 'Frustration Count',
              tick: {


                format: d3.format('d'),
                //values: d3.ticks(0,values[0],values[0]/5)
                values: d3.ticks(0, sortOrder[0][1], sortOrder[0][1])
              }
            },
            y2: {
              show: true,
              label: 'Cumulative percentage',
              max: 1,
              min: 0,
              padding: { top: 3, bottom: 0 },
              tick: {
                format: d3.format(',.0%')
              }

            },

          }

        });
        this.rowCount = this.metricList.length;
        if (this.metricList.length === 0) {
          this.activityPaging = false;
        }
        else {
          this.saveFilter = JSON.parse(JSON.stringify(this.metricList));

          this.activityPaging = true;

          while (this.dynamicRows < this.rowCount) {
            this.rowsOnPageSet.push(this.dynamicRows);
            this.dynamicRows = this.dynamicRows + 10;
          }
          if (!this.rowsOnPageSet.includes(this.rowCount)) this.rowsOnPageSet.push(this.rowCount);
          if (this.rowsOnPageSet.length === 1) {
            if (this.rowsOnPageSet[0] <= 10)
              this.rowsOnPage = this.rowsOnPageSet[0];
          }

        }

      });


    });

  }


  sortByValue(jsObj) {
    var items = Object.keys(jsObj).map(function (key) {
      return [key, jsObj[key]];
    });
    items.sort(function (first, second) {
      return second[1] - first[1];
    });

    return items;

  }
  getData(data) {

    const dialogRef = this.dialog.open(ReportProcessDialogComponent, {
      width: '650px',
      data: { data: data }
    });

    dialogRef.afterClosed().subscribe(result => {

    });

  }
}


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.html',
  // styleUrls: ['dialog.css']
})
export class ReportProcessDialogComponent implements OnInit {

  data: any;
  constructor(
    public dialogRef: MatDialogRef<ReportProcessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) { }

  ngOnInit() {
    this.data = this.dialogData.data;

  }

  close(): void {
    this.dialogRef.close();
  }

}
